import React from 'react';
import PopUp from '../../../components/PopUp';
import './VideoEmbedded.css';
import SourceButton from './SourceButton';

const VideoEmbedded = ({ closeFunc, button }) => {
    const updatedEmbed = button.video.embed
        .replace(/width="[^"]*"/, `width="100%"`)
        .replace(/height="[^"]*"/, `height="100%"`);
    return (
        <PopUp closeFunc={closeFunc}>
            <div
                className="video-embed"
                dangerouslySetInnerHTML={{ __html: updatedEmbed }}
            />
            <SourceButton video={button.video} />
        </PopUp>
    );
};

export default VideoEmbedded;
