import { create } from 'zustand';
const ZoomToolsEnum = {
    MARQUEE: 'marquee',
    ZOOM: 'zoom',
    CUTOUT: 'cutout',
    MOVE: 'move',
    ZOOM_IN: 'zoom-in',
};

export const useZoomStore = create((set) => ({
    currentTool: null,
    insideZoomCanvas: false,
    insidePolygon: false,
    polygonSide: null,
    grabbingPolygon: false,
    altHolding: false,
    selectedPolygon: null,
    setCurrentTool: (tool) => set({ currentTool: tool }),
    setInsideZoomCanvas: (value) => set({ insideZoomCanvas: value }),
    setInsidePolygon: (value) => set({ insidePolygon: value }),
    setPolygonSide: (side) => set({ polygonSide: side }),
    setGrabbingPolygon: (value) => set({ grabbingPolygon: value }),
    setAltHolding: (value) => set({ altHolding: value }),
    setSelectedPolygon: (polygon) => set({ selectedPolygon: polygon }),
}));

const useZoomTool = () => {
    const currentTool = useZoomStore((state) => state.currentTool);
    const setCurrentTool = useZoomStore((state) => state.setCurrentTool);
    return [currentTool, setCurrentTool];
};
const useInsideZoomCanvas = () => {
    const insideZoomCanvas = useZoomStore((state) => state.insideZoomCanvas);
    const setInsideZoomCanvas = useZoomStore(
        (state) => state.setInsideZoomCanvas
    );
    return [insideZoomCanvas, setInsideZoomCanvas];
};
const useInsidePolygon = () => {
    const insidePolygon = useZoomStore((state) => state.insidePolygon);
    const setInsidePolygon = useZoomStore((state) => state.setInsidePolygon);
    return [insidePolygon, setInsidePolygon];
};
const usePolygonSide = () => {
    const polygonSide = useZoomStore((state) => state.polygonSide);
    const setPolygonSide = useZoomStore((state) => state.setPolygonSide);
    return [polygonSide, setPolygonSide];
};
const useGrabbingPolygon = () => {
    const grabbingPolygon = useZoomStore((state) => state.grabbingPolygon);
    const setGrabbingPolygon = useZoomStore(
        (state) => state.setGrabbingPolygon
    );
    return [grabbingPolygon, setGrabbingPolygon];
};
const useAltHolding = () => {
    const altHolding = useZoomStore((state) => state.altHolding);
    const setAltHolding = useZoomStore((state) => state.setAltHolding);
    return [altHolding, setAltHolding];
};
const useSelectedPolygon = () => {
    const selectedPolygon = useZoomStore((state) => state.selectedPolygon);
    const setSelectedPolygon = useZoomStore(
        (state) => state.setSelectedPolygon
    );
    return [selectedPolygon, setSelectedPolygon];
};
export {
    ZoomToolsEnum,
    useZoomTool,
    useInsideZoomCanvas,
    useInsidePolygon,
    usePolygonSide,
    useGrabbingPolygon,
    useAltHolding,
    useSelectedPolygon,
};
