import React from 'react';
import { Group, Rect } from 'react-konva';
import KonvaImage from '~/components/KonvaImage';
import CrossIcon from '~/static/images/close.png';
import { useZoomStore } from '~/stores/zoomStore';
import { setCorrespondingCursor } from '~/utils/zoom_utils';

const CROSS_W_H = 10;
const BORDER_RECT = 20;

const DeletePolygonButton = ({ poly, onRemove, activeTool }) => {
    const handleMouseEnter = (e) => {
        e.target.getStage().container().style.cursor = 'pointer';
    };
    const handleMouseLeave = (e) => {
        setCorrespondingCursor(e, activeTool);
    };
    return (
        <Group
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onRemove}
        >
            <Rect
                x={
                    poly.bounding_rect.x +
                    poly.bounding_rect.width -
                    BORDER_RECT
                }
                y={poly.bounding_rect.y - BORDER_RECT}
                width={BORDER_RECT}
                height={BORDER_RECT}
                fill="blue"
            />
            <KonvaImage
                src={CrossIcon}
                x={
                    poly.bounding_rect.x +
                    poly.bounding_rect.width +
                    CROSS_W_H / 2 -
                    BORDER_RECT
                }
                y={poly.bounding_rect.y + CROSS_W_H / 2 - BORDER_RECT}
                width={CROSS_W_H}
                height={CROSS_W_H}
            />
        </Group>
    );
};

export default DeletePolygonButton;
