import React, { useEffect, useRef, useState } from "react";
import FaviconOfSite from "../../../../components/FaviconOfSite";
import { getPathFromIframe, isUrlValid } from "../../../../utils/path_utils";
import Modal from "../../../../components/Modal";
import ErrorMessage from "../../../../components/ErrorMessageComp/ErrorMessage";
import OneLineInput from "../../../../components/OneLineInput/OneLineInput";
import ThreeWaySwitch from "../../../../components/ThreeWaySwitch/ThreeWaySwitch";
import { Tooltip } from "react-tippy";
import OnClickRedirect from "~/components/OnClickRedirect";
import SplitInfoDisplay from "~/components/SplitInfoDisplay/SplitInfoDisplay";

const checkInput = (e) => {
	if (!isUrlValid(e)) return "Neplatný odkaz";
	return "";
};

const url_type = [
	{
		label: "Odkaz na externí stránku",
		value: 1,
		key: "external",
	},
	{
		label: "Přehrát v interním přehrávači",
		value: 2,
		key: "internal",
	},
];

const InsertVideoUrl = ({ selectedButton, handleChange }) => {
	const [insertingUrl, setInsertingUrl] = useState(false);
	const [autorName, setAutorName] = useState(
		selectedButton.video?.author || ""
	);
	const [sourceName, setSourceName] = useState(
		selectedButton.video?.source || ""
	);
	const [urlType, setUrlType] = useState(() => {
		if (!selectedButton.video) return 1;
		const foundType = url_type.find(
			(type) => selectedButton.video.type === type.key
		);
		return foundType ? foundType.value : 1;
	});
	const [externalUrl, setExternalUrl] = useState(
		selectedButton.video?.externalUrl || ""
	);
	const [url, setUrl] = useState(selectedButton.video?.url || "");
	const [error, setError] = useState("");

	const errorRef = useRef(null);
	const authorRef = useRef(null);
	const sourceRef = useRef(null);
	const urlRef = useRef(null);
	const externalUrlRef = useRef(null);

	useEffect(() => {
		if (urlRef.current && urlType === 2) {
			let result = checkInput(url);
			urlRef.current.handleError(Boolean(result));
			setError(result);
		}
	}, [url]);
	const openEdit = () => {
		setInsertingUrl(true);
		setAutorName(selectedButton.video?.author || "");
		setSourceName(selectedButton.video?.source || "");
		setUrl(selectedButton.video?.url || "");
		setExternalUrl(selectedButton.video?.externalUrl || "");
		setUrlType(() => {
			if (!selectedButton.video) return 1;
			const foundType = url_type.find(
				(type) => selectedButton.video.type === type.key
			);
			return foundType ? foundType.value : 1;
		});
	};
	useEffect(() => {
		setAutorName(selectedButton.video?.author || "");
		setSourceName(selectedButton.video?.source || "");
		setUrl(selectedButton.video?.url || "");
		setExternalUrl(selectedButton.video?.externalUrl || "");
		setUrlType(() => {
			if (!selectedButton.video) return 1;
			const foundType = url_type.find(
				(type) => selectedButton.video.type === type.key
			);
			return foundType ? foundType.value : 1;
		});
	}, [selectedButton]);
	useEffect(() => {
		if (externalUrlRef.current && urlType === 1) {
			const result = checkInput(externalUrl);
			externalUrlRef.current.handleError(Boolean(result));
			setError(result);
		}
	}, [externalUrl]);

	const blinkError = () => {
		setTimeout(() => {
			errorRef.current.blink();
		}, 200);
	};
	const handleAccept = async () => {
		let sendingObject = {};
		const selectedTypeKey = url_type.find(
			(type) => type.value === urlType
		)?.key;
		if (urlType === 1) {
			// Need to check if externalUrl is set
			if (!externalUrl || checkInput(externalUrl)) {
				setError("Neplatný odkaz");
				externalUrlRef.current.handleError(true);
				blinkError();
				return;
			}
			sendingObject = {
				type: selectedTypeKey,

				externalUrl: externalUrl,
			};
		} else if (urlType === 2) {
			// Need to check if URL is set
			const checks = checkInput(url);

			if (!url || checks) {
				setError("Neplatný odkaz");
				urlRef.current.handleError(true);
				blinkError();
				return;
			}
			sendingObject = {
				type: selectedTypeKey,
				author: autorName,
				source: sourceName,
				externalUrl: externalUrl,
				url: url,
			};
		} else {
			return;
		}

		await handleChange(sendingObject);
		setInsertingUrl(false);
	};

	return (
		<>
			{!selectedButton.video && (
				<SplitInfoDisplay
					label="Odkaz"
					value={undefined}
					maxLength={20}
				/>
			)}
			{selectedButton.video?.type === "internal" && (
				// author, source, externalUrl
				<>
					<SplitInfoDisplay
						label="Autor"
						value={selectedButton.video?.author}
					/>
					<SplitInfoDisplay
						label="Zdroj"
						value={selectedButton.video?.source}
						maxLength={20}
						url={true}
					/>
					<SplitInfoDisplay
						label="Stránka"
						value={selectedButton.video?.externalUrl}
						maxLength={20}
						url={true}
					/>
					<SplitInfoDisplay
						label="Video soubor"
						value={selectedButton.video?.url}
						maxLength={20}
						url={true}
					/>
				</>
			)}
			{selectedButton.video?.type === "external" && (
				// author, source, externalUrl
				<SplitInfoDisplay
					label="Odkaz"
					value={selectedButton.video?.externalUrl}
					maxLength={20}
					url={true}
				/>
			)}
			<button
				className="btn btn-purple full-width harder-corners"
				onClick={openEdit}
			>
				Vložit/Upravit odkaz
			</button>

			{insertingUrl && (
				<Modal
					closeFunction={() => {
						setError("");
						setInsertingUrl(false);
					}}
					acceptFuncion={handleAccept}
					acceptText="Uložit odkaz"
				>
					<ThreeWaySwitch
						values={url_type}
						onChange={(value) => {
							setUrlType(value.value);
						}}
						currentValue={urlType}
						header="Typ videa"
						count="two"
						noSeparator={true}
					/>

					{urlType === 2 && (
						<>
							<OneLineInput
								ref={urlRef}
								id="externalUrl"
								label="Odkaz na video soubor"
								value={url}
								onChange={setUrl}
								breakLine
								className="mg-t-2"
								url
							/>
							<OneLineInput
								ref={authorRef}
								id="author"
								label="Autor"
								className="mg-b-1 mg-t-2"
								value={autorName}
								onChange={setAutorName}
								placeholder="Sem napište jméno autora"
							/>
							<OneLineInput
								ref={sourceRef}
								id="zdroj"
								label="Zdroj"
								className="mg-1"
								value={sourceName}
								onChange={setSourceName}
								placeholder="Sem vložte zdroj"
							/>
						</>
					)}
					<OneLineInput
						ref={externalUrlRef}
						id="externalUrl"
						label="Zobrazovaný odkaz"
						value={externalUrl}
						onChange={setExternalUrl}
						className="mg-t-2"
						breakLine
						url
					/>

					{error.length > 0 && (
						<ErrorMessage error={error} ref={errorRef} />
					)}
				</Modal>
			)}
		</>
	);
};

export default InsertVideoUrl;
