import React, { memo } from 'react';
import { Group, Line } from 'react-konva';
import { useZoomTool, ZoomToolsEnum } from '~/stores/zoomStore';
import { useBookForRequest } from '~/stores/interactiveBookStore';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import ZoomService from '~/services/zoom.service';

import DeletePolygonButton from './ChangingPolygons/DeletePolygonButton';
import { setCorrespondingCursor } from '~/utils/zoom_utils';
import { act } from 'react-dom/test-utils';

const ZoomPolygon = ({
    poly,
    setPolygons,
    canDelete,
    setSelectedPolygon,
    selectedPolygon,
    active,
    setVisibleZoom,
}) => {
    const privateAxios = useAxiosPrivate();
    const book = useBookForRequest();
    const [activeTool, setActiveTool] = useZoomTool();
    // When clicked clicked on the delete button
    const handleRemovePolygon = async (e) => {
        if (!canDelete || !setPolygons) return;
        if (!poly?.id) return;
        const response = await ZoomService.delete_polygons(
            book,
            poly.page,
            [poly.id],
            privateAxios
        );
        if (response.err) {
            return;
        }
        setPolygons((prev) => prev.filter((p) => p.id !== poly.id));
        setCorrespondingCursor(e, activeTool);
    };
    const handleSelectPolygon = async (e) => {
        if (e.evt.button !== 0) return;
        if (activeTool === ZoomToolsEnum.ZOOM_IN) {
            const response = await ZoomService.get_zoom(
                book,
                poly.page,
                poly.id,
                privateAxios
            );
            if (response.err) return;
            setVisibleZoom(response.zoom);
            return;
        }
        if (setSelectedPolygon) {
            setSelectedPolygon(poly);
            setActiveTool(ZoomToolsEnum.MOVE);
        }
    };
    const handleMouseIn = (e) => {
        if (activeTool === ZoomToolsEnum.ZOOM_IN) {
            e.target.getStage().container().style.cursor = 'zoom-in';
            return;
        }
        e.target.getStage().container().style.cursor = 'pointer';
    };
    const handleMouseOut = (e) => {
        setCorrespondingCursor(e, activeTool);
    };
    return selectedPolygon?.id !== poly.id || !active ? (
        <Group onMouseEnter={handleMouseIn} onMouseLeave={handleMouseOut}>
            <Line
                points={poly.points}
                fill="#78a5ff44"
                stroke="blue"
                strokeWidth={1}
                closed
                onClick={handleSelectPolygon}
            />
            {canDelete && (
                <DeletePolygonButton
                    poly={poly}
                    onRemove={handleRemovePolygon}
                    activeTool={activeTool}
                />
            )}
        </Group>
    ) : null;
};
// Using memo to prevent re-rendering when the parent component re-renders and this component doesn't change
export default memo(ZoomPolygon);
/*
// When clicked on side or in the polygon
const startAction = (e, type) => {
    // get mouse position in stage
    if (type === "rescale") {
        rescaleStart(e);
    } else if (type === "move") {
        moveStart(e);
    }
    setPolygons((prev) => prev.filter((p) => p.id !== poly.id));
};
// Setting the rescale object (handled in parent component)
const rescaleStart = (e) => {
    const rescalingRectangle = poly.transform_to_rectangle();
    setRescaling({
        id: poly.id,
        rect: rescalingRectangle,
        original_rect: rescalingRectangle,
        mode: hoveringSide,
    });
};
// Setting the move object (handled in parent component)
const moveStart = (e) => {
    setGrabbingPolygon(true);
    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    setMoving({
        id: poly.id,
        originalPoly: poly,
        points: poly.points,
        originalPos: { x: mousePos.x, y: mousePos.y },
        offset: { x: 0, y: 0 },
    });
};

<RescalingBorderRectangles
				isRect={poly.is_rectangle}
				rect={poly.bounding_rect}
				onRescaleStart={(e) => startAction(e, "rescale")}
				show={DEBUG}
			/>
			<MovingPolygon
				poly={poly}
				onMove={(e) => startAction(e, "move")}
				show={DEBUG}
			/>
*/
