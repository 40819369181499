import JSZip from 'jszip';
import { BASE_STATIC } from '../deffault/axios';
import { FILE_TYPES } from './CONSTANTS';
const DEFAULT_PATH = '/static/placeholder.jpg';

const repairPath = (path) => {
    if (!path) {
        return DEFAULT_PATH;
    }
    const new_path = path.replace(/\\/g, '/');
    const new_path1 =
        new_path[0] === '.'
            ? new_path.substring(1)
            : new_path[0] !== '/'
            ? '/' + new_path
            : new_path;

    return new_path1;
};
const addStatic = (path) => {
    return BASE_STATIC + repairPath(path);
};
const assetToPath = (asset) => {
    // BASE STATIC / ASSET FILE PATH ?=v=VERSION
    if (asset.last_update === null) {
        return `${BASE_STATIC}${repairPath(asset.file_path)}`;
    }
    return `${BASE_STATIC}${repairPath(asset.file_path)}?v=${
        asset.last_update
    }`;
};
const excerciseImageToPath = (asset) => {
    // BASE STATIC / ASSET FILE PATH ?=v=VERSION
    return `${BASE_STATIC}${repairPath(asset.picture_ref)}?v=${
        asset.last_update
    }`;
};
const screenToPath = (screen) => {
    return `${BASE_STATIC}${repairPath(screen.screen_path)}?v=${
        screen.last_update
    }`;
};
const checkIfFilePathIsIn = (name, array) => {
    return array.some((elem) => {
        // Elem has file_path attribute with full path
        // need to extract only the file name
        const path = elem.file_path;
        const pathArray = path.split('/');
        const fileName = pathArray[pathArray.length - 1];
        return fileName === name;
    });
};

const getPathWithVersion = (path) => {
    // Forming version from datetime
    const date = new Date();
    const version = date
        .toISOString()
        .replace(/[^0-9]/g, '')
        .substring(0, 14);
    const new_path = repairPath(path);

    return BASE_STATIC + new_path + '?v=' + version;
};

const getExcercisesFromZip = (zipFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const arrayBuffer = e.target.result;
                const zip = await JSZip.loadAsync(arrayBuffer);
                const files = Object.values(zip.files)
                    .filter((file) => file.dir)
                    .map((file) => file.name.replace('/', ''));
                resolve(files);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsArrayBuffer(zipFile);
    });
};

const getPathFromIframe = (iframeStr) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeStr, 'text/html');
    const iframe = doc.querySelector('iframe');
    const iframeUrl = iframe.getAttribute('src');
    return iframeUrl;
};

const isUrlValid = (url) => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};
const isValidIframe = (htmlString) => {
    // Create a DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Find the iframe element in the parsed document
    const iframe = doc.querySelector('iframe');

    // If there's no iframe, return false
    if (!iframe) {
        return { err: true, msg: 'Vámi zadaný řetězec neobsahuje iframe' };
    }

    // Check for essential attributes (e.g., src, width, height)
    const src = iframe.getAttribute('src');
    const width = iframe.getAttribute('width');
    const height = iframe.getAttribute('height');

    if (!src || !width || !height) {
        return {
            err: true,
            msg: 'Zadaný řetězec obsahuje iframe, který neobsahuje src, width nebo height atributy',
        };
    }

    // Ensure the iframe has no unclosed or improperly closed tags inside
    if (doc.querySelectorAll('parsererror').length > 0) {
        return {
            err: true,
            msg: 'Vámi zadaný řetězec obsahuje chybu v HTML syntaxi',
        };
    }

    // If all checks pass, return true
    return { err: false };
};

const checkFileType = (file, mode) => {
    const fileTypes = FILE_TYPES[mode];
    if (!fileTypes) return true;
    if (file.type) {
        return fileTypes.mimes.includes(file.type);
    } else {
        const file_extension = file.name.split('.').pop();
        return fileTypes.extensions.includes(file_extension);
    }
};
const getAcceptingMimes = (fileType) => {
    if (!FILE_TYPES[fileType]) return '*/*';
    const lst = FILE_TYPES[fileType].mimes;
    return lst.join(', ');
};
const getAcceptingExtensions = (fileType) => {
    if (!FILE_TYPES[fileType]) return '*/*';
    const lst = FILE_TYPES[fileType].extensions;
    return lst.join(', ');
};
const formatBook = (book) => {
    return {
        bookid: book.book_id,
        release: Number(book.release),
        version: `${book.version_before_dot}_${book.version_after_dot}`,
    };
};
const getPaths = (chosenObject) => {
    const { bookid, release, version } = formatBook(chosenObject.concrete_book);
    const editor_path = `/interaktivky/${bookid}/${release}/${version}`;
    const preview_path = `/interaktivky/preview/${bookid}/${release}/${version}`;
    const library_path = `/interaktivky/knihovna/${bookid}/${release}/${version}`;
    const zoom_path = `/interaktivky/zoom/${bookid}/${release}/${version}`;
    return {
        editor: editor_path,
        preview: preview_path,
        library: library_path,
        zoom: zoom_path,
    };
};

const checkZipForExcercises = async (zipFile, maxNumOfExcercises) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const arrayBuffer = e.target.result;
                const zip = await JSZip.loadAsync(arrayBuffer);
                const files = Object.values(zip.files)
                    .filter(
                        (file) =>
                            file.dir && !file.name.slice(0, -1).includes('/')
                    )
                    .map((file) => file.name.replace('/', ''));
                console.log('Files in zip', files);
                if (!files.some((file) => file === '_common')) {
                    resolve({
                        error: true,
                        msg: 'Soubor ZIP neobsahuje složku _common',
                    });
                }
                if (files.filter((file) => file !== '_common').length === 0) {
                    resolve({
                        error: true,
                        msg: 'Soubor ZIP neobsahuje žádné cvičení',
                    });
                }
                if (maxNumOfExcercises !== -1) {
                    // -1 means no limit

                    if (files.length > maxNumOfExcercises + 1) {
                        // +1 because of _common folder
                        resolve({
                            error: true,
                            msg: 'Soubor ZIP obsahuje více než jedno cvičení. Pro nahrání více cvičení použijte knihovnu.',
                        });
                    } else {
                        resolve({
                            error: false,
                            msg: 'Soubor ZIP obsahuje cvičení',
                            excercises: files.filter(
                                (file) => file !== '_common'
                            ),
                        });
                    }
                } else {
                    resolve({
                        error: false,
                        msg: 'Soubor ZIP obsahuje cvičení',
                        excercises: files.filter((file) => file !== '_common'),
                    });
                }
            } catch (error) {
                resolve({
                    error: true,
                    msg: 'Nepodařilo se načíst soubor ZIP',
                });
            }
        };
        reader.onerror = (e) => {
            console.log('Error while reading file', e);
            resolve({ error: true, msg: 'Nepodařilo se načíst soubor ZIP' });
        };

        reader.readAsArrayBuffer(zipFile);
    });
};
const setPageFiles = (
    setLeftPage,
    setRightPage,
    setLeftResultPage,
    setRightResultPage,
    twoPage
) => {
    if (twoPage.length !== 2) {
        return;
    }
    const pageLeft = twoPage[0]?.page_file;
    const pageRight = twoPage[1]?.page_file;
    const resultLeft = twoPage[0]?.result_file;
    const resultRight = twoPage[1]?.result_file;
    if (pageLeft) {
        setLeftPage(BASE_STATIC + '/' + pageLeft);
        setLeftResultPage(BASE_STATIC + '/' + resultLeft);
    } else {
        setLeftPage(null);
        setLeftResultPage(null);
    }
    if (pageRight) {
        setRightPage(BASE_STATIC + '/' + pageRight);
        setRightResultPage(BASE_STATIC + '/' + resultRight);
    } else {
        setRightPage(null);
        setRightResultPage(null);
    }
};
const getPathPage = (searchParams) => {
    const page = searchParams.get('page');
    return searchParams.has('page') ? parseInt(page) : 1;
};
export {
    repairPath,
    checkIfFilePathIsIn,
    getPathWithVersion,
    assetToPath,
    getExcercisesFromZip,
    getPathFromIframe,
    isUrlValid,
    isValidIframe,
    screenToPath,
    checkFileType,
    getAcceptingMimes,
    getAcceptingExtensions,
    excerciseImageToPath,
    addStatic,
    getPaths,
    formatBook,
    checkZipForExcercises,
    setPageFiles,
    getPathPage,
};
