import React, { useEffect, useState } from "react";
import { Image } from "react-konva";
const KonvaImage = ({ src, x, y, width, height, onClick, onMouseEnter }) => {
	const [image, setImage] = useState(null);
	useEffect(() => {
		const img = new window.Image();
		img.src = src;
		img.onload = () => {
			setImage(img);
		};
	});
	return (
		<Image
			image={image}
			x={x}
			y={y}
			width={width}
			height={height}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
		/>
	);
};

export default KonvaImage;
