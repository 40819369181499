const extractValuesFromObject = (obj, type = null) => {
    const keys = Object.keys(obj);
    const valueList = keys.map((key) => {
        return {
            key: key,
            ...obj[key],
        };
    });
    if (!type) {
        return valueList.sort((a, b) => a.name.localeCompare(b.name));
    }
    const typeExtracted = valueList
        .filter((asset) => asset.media_kind === type)
        .sort((a, b) => a.name.localeCompare(b.name));
    return typeExtracted;
};

const extractNumberFromPadding = (value) => {
    if (!value) return 0;
    if (typeof value === 'number') return value;
    const number = value.replace('px', '');
    return parseInt(number);
};

export { extractValuesFromObject, extractNumberFromPadding };
