import axios from '../deffault/axios';

const LOGIN_URL = 'auth/login';
const LOGOUT_URL = 'auth/logout';
const GET_USER = (id) => `users/${id}`;
class AuthService {
    static async login(email, password) {
        try {
            const response = await axios.post(
                LOGIN_URL,
                {
                    username: email,
                    password: password,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    withCredentials: true,
                }
            );
            //console.log(response.data);
            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const roles = response?.data?.roles;

            return {
                err: false,
                msg: 'Byl jste úspěšně přihlášen',
                access: accessToken,
                refresh: refreshToken,
                roles: roles,
                user_id: response?.data?.user_id,
            };
        } catch (err) {
            if (!err?.response) {
                return { err: true, msg: 'Server neodpověděl' };
            } else if (err.response?.status === 400) {
                return { err: true, msg: 'Nevyplnil/a jste všechny údaje' };
            } else if (err.response?.status === 401) {
                return {
                    err: true,
                    msg: 'Nesprávné uživatelské jméno nebo heslo',
                };
            } else {
                return { err: true, msg: 'Něco se pokazilo' };
            }
        }
    }
    static async logout() {
        try {
            const response = await axios.get(LOGOUT_URL, {
                withCredentials: true,
            });
            if (response?.data?.msg) {
                return { err: false, msg: response.data.msg };
            }
        } catch (err) {
            return { err: true, msg: 'Něco se pokazilo' };
        }
    }

    static async create_user(email, first_name, surname, role, password) {
        try {
            const data = JSON.stringify({
                first_name: first_name,
                surname: surname,
                e_mail: email,
                password: password,
                role: role,
            });
            const response = await axios.post('auth/register', data, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            });
            if (response.data.created_user) {
                return { err: false, msg: 'Uživatel byl úspěšně vytvořen' };
            } else {
                return { err: true, msg: 'Uživatel nebyl přidán' };
            }
        } catch (err) {
            if (!err?.response) {
                return { err: true, msg: 'Server neodpověděl' };
            } else if (err.response?.status === 403) {
                return {
                    err: true,
                    msg: 'Uživatel s tímto e-mailem již existuje',
                };
            } else {
                return { err: true, msg: 'Něco se pokazilo' };
            }
        }
    }

    static async get_user(id, privateAxios) {
        try {
            const response = await privateAxios.get(GET_USER(id));
            return response.data;
        } catch (err) {
            return { err: true, msg: 'Něco se pokazilo' };
        }
    }
}
export default AuthService;
