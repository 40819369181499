import React from 'react';
import { Rect } from 'react-konva';
import { useZoomStore } from '~/stores/zoomStore';
import {
    getResizingCursor,
    HOVER_SIDE,
    setCorrespondingCursor,
} from '~/utils/zoom_utils';

const OFFSET = 20;
const RECT_WIDTH = 20;
const RescalingBorderRectangles = ({ rect, show, isRect, onRescaleStart }) => {
    const activeTool = useZoomStore((state) => state.currentTool);
    const startRescaling = (e, hover_side) => {
        onRescaleStart(e, hover_side);
    };
    const setHoveringSide = (e, hover_side) => {
        if (hover_side === null) {
            // This is to reset the cursor to default when the mouse leaves the rectangle
            document.body.style.cursor = 'default';
            setCorrespondingCursor(e, activeTool);
            return;
        } else {
            const changeCursor = getResizingCursor(hover_side);
            e.target.getStage().container().style.cursor = changeCursor;
        }
    };
    return isRect ? (
        <>
            {/* Top Rectangle */}
            <Rect
                x={rect.x + 1.5 * OFFSET}
                y={rect.y - OFFSET / 2}
                width={rect.width - 2 * OFFSET - RECT_WIDTH}
                height={OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.TOP)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.TOP)}
            />
            {/* Right Rectangle */}
            <Rect
                x={rect.x + rect.width - OFFSET / 2}
                y={rect.y + RECT_WIDTH}
                width={OFFSET}
                height={rect.height - RECT_WIDTH - 1.5 * OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.RIGHT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.RIGHT)}
            />

            {/* Bottom Rectangle */}
            <Rect
                x={rect.x + 1.5 * OFFSET}
                y={rect.y + rect.height - OFFSET / 2}
                width={rect.width - OFFSET * 3}
                height={OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.BOTTOM)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.BOTTOM)}
            />
            {/* Left Rectangle */}
            <Rect
                x={rect.x - OFFSET / 2}
                y={rect.y + 1.5 * OFFSET}
                width={OFFSET}
                height={rect.height - OFFSET * 3}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.LEFT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.LEFT)}
            />
            {/* Top left square */}
            <Rect
                x={rect.x - OFFSET / 2}
                y={rect.y - OFFSET / 2}
                width={OFFSET}
                height={OFFSET * 2}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.TOP_LEFT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.TOP_LEFT)}
            />
            <Rect
                x={rect.x + OFFSET / 2}
                y={rect.y - OFFSET / 2}
                width={OFFSET}
                height={OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.TOP_LEFT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.TOP_LEFT)}
            />
            {/* Bottom left square */}
            <Rect
                x={rect.x - OFFSET / 2}
                y={rect.y + rect.height - OFFSET - OFFSET / 2}
                width={OFFSET}
                height={OFFSET * 2}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.BOTTOM_LEFT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.BOTTOM_LEFT)}
            />
            <Rect
                x={rect.x + OFFSET / 2}
                y={rect.y + rect.height - OFFSET / 2}
                width={OFFSET}
                height={OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) => setHoveringSide(e, HOVER_SIDE.BOTTOM_LEFT)}
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.BOTTOM_LEFT)}
            />
            {/* Bottom right square */}
            <Rect
                x={rect.x + rect.width - OFFSET - OFFSET / 2 + OFFSET}
                y={rect.y + rect.height - OFFSET - OFFSET / 2}
                width={OFFSET}
                height={OFFSET * 2}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) =>
                    setHoveringSide(e, HOVER_SIDE.BOTTOM_RIGHT)
                }
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.BOTTOM_RIGHT)}
            />
            <Rect
                x={rect.x + rect.width - OFFSET - OFFSET / 2}
                y={rect.y + rect.height - OFFSET / 2}
                width={OFFSET}
                height={OFFSET}
                fill={show ? '#FF0000aa' : 'transparent'}
                onMouseEnter={(e) =>
                    setHoveringSide(e, HOVER_SIDE.BOTTOM_RIGHT)
                }
                onMouseLeave={(e) => setHoveringSide(e, null)}
                onMouseDown={(e) => startRescaling(e, HOVER_SIDE.BOTTOM_RIGHT)}
            />
        </>
    ) : null;
};

export default RescalingBorderRectangles;
