import React from 'react';
import ChosenImageThumnail from './ChosenImageThumnail';
import MediaExcercisesService from '../../../../services/media_excercises.service';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import ChooseImagesLibrary from './ChooseImagesLibrary';
import ButtonsService from '../../../../services/buttons.service';
import { replaceButtonInButtonArray } from '../../../../utils/stateUtils';
import UploadFile, {
    FILE_ENUM,
} from '../../../../components/UploadFileComp/UploadFile';
import { useAssetImages } from '../../../../stores/assetStore';
import ImageEdit from '~/components/ImageEdit';

const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const getIndexInBoundaries = (index, length) => {
    if (index < 0) return length - 1;
    if (index >= length) return 0;
    return index;
};
// Encapsulates the main part of selecting assets of type IMAGE
// Includes both buttons for Selecting from library and uploading new images
const ImagesSelect = ({
    savedButton,
    setSavedButton,
    bookInfo,
    pageNumber,
    setButtons,
    somethingSaving,
}) => {
    // Handles when user clicks on a moving button for pictures (up or down)

    const [selectingImages, setSelectingImages] = useState(false);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [images, setImages] = useAssetImages();
    const [edittingImage, setEdittingImage] = useState(null);

    const privateAxios = useAxiosPrivate();

    const handle_swap_images = async (index1, index2) => {
        // savedButton.pictures[index1].order = index2;
        const response = await MediaExcercisesService.swap_images(
            {
                ...bookInfo,
                page_number: pageNumber,
                button_id: savedButton.key,
                image_id_1: savedButton.pictures[index1]['_id'],
                image_id_2: savedButton.pictures[index2]['_id'],
            },
            privateAxios
        );
        if (response.err) {
            toast.error(response.msg || response.detail, toastError);
            return;
        }
        // Swapping the images in an array
        const new_pictures = [...savedButton.pictures];
        const temp = new_pictures[index1];
        new_pictures[index1] = new_pictures[index2];
        new_pictures[index2] = temp;
        const newButton = { ...savedButton, pictures: new_pictures };
        // Saving it so we can see the info on the right panel
        setSavedButton(newButton);
        for (let i = 0; i < newButton.pictures.length; i++) {
            console.log('PICTURE --->', newButton.pictures[i].name);
        }
        // Setting new info into the array of buttons (if clicked out, the state would not propagate there)
        setButtons.setFunc(
            setButtons.buttons.map((button) => {
                if (button.key === savedButton.key) {
                    return newButton;
                }
                return button;
            })
        );
        // replaceButtonInButtonArray(setButtons, newButton);
    };

    const handleAddNewImages = async (uploadedImages, progressFunc) => {
        somethingSaving(true);
        const response = await ButtonsService.add_new_images_to_button(
            bookInfo,
            pageNumber,
            savedButton.key,
            uploadedImages,
            privateAxios,
            progressFunc
        );
        if (response.err) {
            somethingSaving(false);
            return response;
        }
        const newButton = {
            ...savedButton,
            pictures: response.button.pictures,
        };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);

        setImages(response.images);
        somethingSaving(false);
        return response;
    };

    const selectedNewImagesToButton = async (selectedImages) => {
        if (selectedImages.length === 0) {
            setSelectingImages(false);
            return;
        }
        somethingSaving(true);
        const dataToSend = {
            ...bookInfo,
            page_number: pageNumber,
            images: selectedImages,
        };

        const response = await ButtonsService.add_images_from_library_to_button(
            savedButton.key,
            dataToSend,
            privateAxios
        );
        if (response.err) {
            toast.error('Nepodařilo se přidat nové obrázky.', toastError);
            somethingSaving(false);
            return;
        }
        const new_pictures = [];
        if (savedButton.pictures) {
            new_pictures.push(...savedButton.pictures);
        }
        new_pictures.push(...selectedImages);
        const newButton = { ...savedButton, pictures: new_pictures };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);
        toast.success('Obrázky byly přidány k tlačítku.', toastError);
        setImages(response.images);
        setSelectingImages(false);
    };
    const editNextPicture = () => {
        const index = getIndexInBoundaries(
            edittingImage.index + 1,
            savedButton.pictures.length
        );
        const foundImage = images.find(
            (img) => img.file_path === savedButton.pictures[index].file_path
        );
        setEdittingImage({
            image: foundImage,
            index,
            focusOnHeader: false,
            focusOnDescription: false,
        });
    };
    const editPreviousPicture = () => {
        const index = getIndexInBoundaries(
            edittingImage.index - 1,
            savedButton.pictures.length
        );
        const foundImage = images.find(
            (img) => img.file_path === savedButton.pictures[index].file_path
        );
        setEdittingImage({
            image: foundImage,
            index,
            focusOnHeader: false,
            focusOnDescription: false,
        });
    };
    return (
        <>
            {!savedButton.pictures || savedButton.pictures.length === 0 ? (
                <div className="info-split">
                    <label className="info-label" htmlFor="excercise">
                        Fotografie:
                    </label>
                    <p className="body-3"> Zatím nevybráno</p>
                </div>
            ) : (
                // Displaying already selected images to the button
                <div className="images-list">
                    {savedButton.pictures.map((picture, i) => (
                        <ChosenImageThumnail
                            key={picture['_id']}
                            picture={images.find(
                                (img) => img.file_path === picture.file_path
                            )}
                            index={i}
                            first={i === 0}
                            last={i === savedButton.pictures.length - 1}
                            handle_swap_images={handle_swap_images}
                            bookInfo={bookInfo}
                            pageNumber={pageNumber}
                            savedButton={savedButton}
                            setSavedButton={setSavedButton}
                            setButtons={setButtons}
                            setImages={setImages}
                            setEdittingImage={setEdittingImage}
                        />
                    ))}
                </div>
            )}
            {/* Choosing if user wants to add new images via library or upload */}
            <div className="button-split">
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setSelectingImages(true)}
                >
                    Vybrat z knihovny
                </button>
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setUploadingImages(true)}
                >
                    Nahrát nové
                </button>
            </div>
            {/* After clicking on either buttons - open corresponding selection */}
            {selectingImages && (
                <ChooseImagesLibrary
                    images={images}
                    closeFunc={() => setSelectingImages(false)}
                    acceptFunc={(selectedImages) =>
                        selectedNewImagesToButton(selectedImages)
                    }
                    selectedButton={savedButton}
                />
            )}
            {uploadingImages && (
                <UploadFile
                    closeFunc={() => setUploadingImages(false)}
                    header="Nahrejte nové obrázky do knihovny"
                    fileType={FILE_ENUM.image}
                    acceptText="Nahrát obrázky"
                    acceptFunc={handleAddNewImages}
                    alreadyUploadedFileNames={images.map((image) => image.name)}
                    multiple={true}
                />
            )}
            {edittingImage && (
                <ImageEdit
                    image={edittingImage.image}
                    closeFunction={() => {
                        setEdittingImage(null);
                    }}
                    bookDetails={{ ...bookInfo, page_number: pageNumber }}
                    savedButton={savedButton}
                    setSavedButton={setSavedButton}
                    setButtons={setButtons}
                    focusOnHeader={edittingImage.focusOnHeader}
                    focusOnDescription={edittingImage.focusOnDescription}
                    editNextPicture={editNextPicture}
                    editPreviousPicture={editPreviousPicture}
                    index={edittingImage.index}
                    countOfImages={savedButton.pictures.length}
                />
            )}
        </>
    );
};

export default ImagesSelect;
