import React, {
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import './VideoPlayer.css';
import { assetToPath } from '../../utils/path_utils';
import { PATH_TO_TYPE } from '../../utils/CONSTANTS';
import classNames from 'classnames';
const VideoPlayer = forwardRef(({ video, externalLink, fullscreen }, ref) => {
    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current) {
            const videoElement = videoRef.current;
            videoElement.src = externalLink ? video.url : assetToPath(video);
            videoElement.type = externalLink
                ? PATH_TO_TYPE(video.externalUrl)
                : PATH_TO_TYPE(video.file_path);

            videoElement.load();
        }
    }, [video, externalLink]);
    const handlePauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };
    const isPlaying = () => {
        if (videoRef.current) {
            return !videoRef.current.paused;
        }
        return false;
    };
    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };
    useImperativeHandle(ref, () => ({
        handlePauseVideo,
        isPlaying,
        handlePlayVideo,
    }));
    return (
        <div className={classNames('video-player', { fullscreen: fullscreen })}>
            <video
                width="100%"
                height="100%"
                disablePictureInPicture={true}
                ref={videoRef}
                controls
            >
                <source
                    src={externalLink ? video.url : assetToPath(video)}
                    type={
                        externalLink
                            ? PATH_TO_TYPE(video.url)
                            : PATH_TO_TYPE(video.file_path)
                    }
                />
                Your browser does not support the video tag.
            </video>
        </div>
    );
});

export default VideoPlayer;
