import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import SvgIcon from '~/components/SvgIcon/SvgIcon';
import SvgIconType from '~/components/SvgIcon/SvgIconType';
import {
    useSelectedPolygon,
    useZoomTool,
    ZoomToolsEnum,
} from '~/stores/zoomStore';

const ZoomTools = () => {
    const [activeTool, setActiveTool] = useZoomTool();

    const [selectedPolygon, setSelectedPolygon] = useSelectedPolygon();
    const handleSelectTool = useCallback(
        (tool) => {
            if (activeTool === tool) {
                setActiveTool(null);
            } else {
                setActiveTool(tool);
            }
        },
        [activeTool, setActiveTool]
    );

    useEffect(() => {
        // Add event listener for key down, if M is pressed set active tool to marquee
        const handleKeyDown = (e) => {
            // check if alt is pressed
            if (e.key === 'Alt') {
                e.preventDefault();
                setActiveTool(ZoomToolsEnum.CUTOUT);
            }
            if (e.key === 'm') {
                handleSelectTool(ZoomToolsEnum.MARQUEE);
            }
            if (e.key === 'v') {
                handleSelectTool(ZoomToolsEnum.MOVE);
            }
            if (e.key === 'z') {
                handleSelectTool(ZoomToolsEnum.ZOOM_IN);
            }
        };
        const handleKeyUp = (e) => {
            if (e.key === 'Alt') {
                e.preventDefault();

                handleSelectTool(ZoomToolsEnum.MARQUEE);
            }
        };
        // Remove event listener on unmount
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleSelectTool, setActiveTool, setSelectedPolygon]);

    useEffect(() => {
        if (selectedPolygon) {
            setActiveTool(ZoomToolsEnum.MOVE);
        }
    }, [selectedPolygon, setActiveTool]);

    return (
        <div className="zoom-toolset">
            <Tooltip title="Editace objektu (v)" position="right">
                <button
                    className={classNames('zoom-tool', {
                        active: activeTool === ZoomToolsEnum.MOVE,
                    })}
                    onClick={() => handleSelectTool(ZoomToolsEnum.MOVE)}
                >
                    <SvgIcon type={SvgIconType.move} size="large" />
                </button>
            </Tooltip>
            <Tooltip title="Zvolit oblast (m)" position="right">
                <button
                    className={classNames('zoom-tool', {
                        active:
                            activeTool === ZoomToolsEnum.MARQUEE ||
                            activeTool === ZoomToolsEnum.CUTOUT,
                    })}
                    onClick={() => {
                        handleSelectTool(ZoomToolsEnum.MARQUEE);
                        setSelectedPolygon(null);
                    }}
                >
                    <SvgIcon type={SvgIconType.marquee} size="large" />
                </button>
            </Tooltip>
            <Tooltip title="Odečíst oblast (alt)" position="right">
                <button
                    className={classNames('zoom-tool', {
                        active: activeTool === ZoomToolsEnum.CUTOUT,
                    })}
                    onClick={() => {
                        handleSelectTool(ZoomToolsEnum.CUTOUT);
                        setSelectedPolygon(null);
                    }}
                >
                    <SvgIcon type={SvgIconType.marqueeMinus} size="large" />
                </button>
            </Tooltip>
            <Tooltip title="Přiblížit (z)" position="right">
                <button
                    className={classNames('zoom-tool', {
                        active: activeTool === ZoomToolsEnum.ZOOM_IN,
                    })}
                    onClick={() => {
                        handleSelectTool(ZoomToolsEnum.ZOOM_IN);
                        setSelectedPolygon(null);
                    }}
                >
                    <SvgIcon type={SvgIconType.zoom} size="large" />
                </button>
            </Tooltip>
        </div>
    );
};

export default ZoomTools;
