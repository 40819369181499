import React from 'react';
import './ThreeWaySwitch.css';
import classNames from 'classnames';

const ThreeWaySwitch = ({
    values,
    onChange,
    currentValue,
    header,
    count,
    noSeparator,
}) => {
    return (
        <div>
            {!noSeparator && <div className="separator height-1"></div>}
            <h1 className="header-5 font-weight-500 centered mg-1">{header}</h1>
            <div className={classNames('three-way-switch', count)}>
                {values.map((value) => (
                    <button
                        key={value.value}
                        onClick={() => {
                            onChange(value);
                        }}
                        className={classNames('three-way-switch__button', {
                            selected: currentValue === value.value,
                        })}
                    >
                        {value.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ThreeWaySwitch;
