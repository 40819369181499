import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from 'react';
import '../styles/editor/overPageCanvas.css';
import LibrarySelect from './LibrarySelect';
import { v4 as uuidv4 } from 'uuid';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import ButtonsService from '~/services/buttons.service';
import { axiosPrivate } from '~/deffault/axios';
import { toast } from 'react-toastify';
import { Stage, Layer } from 'react-konva';
import {
    ButtonSprite,
    calculate_right_x,
    calculate_right_y,
    SCALE_FACTOR,
    BUTTON_DEFAULT_H,
    BUTTON_DEFAULT_W,
} from '~/components/ButtonSprite';
import {
    format_buttons,
    get_new_button_label,
    unformat_buttons,
} from '~/utils/canvasesCalculations';
import { useSearchParams } from 'react-router-dom';
import {
    usePageSetFunctions,
    useSavedButton,
    useSelectedButtonStore,
} from '~/stores/selectedButtonStore';
import RemoveButton from '~/components/RemoveIcon/RemoveButton';
import Modal from '~/components/Modal';
import CoordinateChangeInput from '~/components/CoordinateChangeInput/CoordinateChangeInput';
import { extractNumberFromPadding } from '~/utils/objectUtils';
import ReactDOM from 'react-dom';
const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const printButton = (prefix, button, page) => {
    if (!button) return;
    console.log(
        prefix,
        `Key: ${button.key}, x: ${button.x}, y: ${button.y} Page[${page}]`
    );
};
const ButtonCanvas = forwardRef(
    (
        {
            page,
            width,
            height,
            selectedPage,
            getButtons,
            pageNumber,
            currentlyViewedBook,
            buttonsVisible,
            padding,
        },
        ref
    ) => {
        const [buttons, setButtons] = useState([]);
        const [originalButtons, setOriginalButtons] = useState([]);
        const [, setSomethingSaving] = useState(false);
        const [selectedButton, setSelectedButton, setSavedButtons] =
            useSavedButton();

        const [coordinateX, setCoordinateX] = useState(0);
        const [coordinateY, setCoordinateY] = useState(0);
        const [deletingButton, setDeletingButton] = useState(null);
        const privateAxios = useAxiosPrivate();
        const [searchParams] = useSearchParams();
        const setActivePage = usePageSetFunctions();
        const selectedPageSide = useSelectedButtonStore(
            (state) => state.selectedPageSide
        );

        const x_coordinates = useMemo(() => {
            return buttons.map((button) => ({ x: button.x, key: button.key }));
        }, [buttons]);
        const y_coordinates = useMemo(() => {
            return buttons.map((button) => ({ y: button.y, key: button.key }));
        }, [buttons]);
        /**====== Getting all the buttons from API ========**/
        const buttons_call = async (first = false) => {
            if (!getButtons) return;

            const buttons_response = await getButtons(pageNumber);
            let formated = buttons_response.buttons.map((button) => {
                return format_buttons(button, width, height);
            });
            setButtons(formated);
            setOriginalButtons(buttons_response.buttons);
            if (first && searchParams.has('button')) {
                const selected_button = searchParams.get('button');

                const button = formated.find(
                    (button) => button.key === selected_button
                );
                if (button) {
                    setTimeout(() => {
                        setSelectedButton(button);
                        setCoordinateX(button.x);
                        setCoordinateY(button.y);
                        setActivePage(pageNumber, page);
                    }, 200);
                }
            }
        };

        useEffect(() => {
            buttons_call(true);
        }, [pageNumber, searchParams]);

        useEffect(() => {
            // Setting the PAGE_NUMBER, PAGE_SIDE
            if (buttons.some((button) => button.key === selectedButton?.key))
                setActivePage(pageNumber, page);
        }, [selectedButton]);
        useEffect(() => {
            // Propagating change of buttons to the store
            setSavedButtons(buttons);
            setOriginalButtons(
                buttons.map((button) => unformat_buttons(button, width, height))
            );
        }, [buttons]);

        useEffect(() => {
            printButton('Original buttons -- ', originalButtons[0], page);
            printButton('Buttons -- ', buttons[0], page);
            let formated = originalButtons.map((button) => {
                return format_buttons(button, width, height);
            });
            printButton('Formated -- ', formated[0], page);
            setButtons(formated);

            if (selectedButton) {
                const button = formated.find(
                    (button) => button.key === selectedButton.key
                );
                if (button) {
                    setSelectedButton(button);
                    setCoordinateX(button.x);
                    setCoordinateY(button.y);
                }
            }
        }, [width, height]);
        /**====== Changing position of buttons based on their movement ========**/
        const update_list = (x, y, id) => {
            const new_button = { ...selectedButton, x, y };
            setButtons(
                buttons.map((button) => {
                    if (button.key === id) {
                        setSelectedButton(new_button);
                        return new_button;
                    }
                    return button;
                })
            );
        };

        const handleSaveButton = async (x, y, id, permanent = false) => {
            // Temp save is used to change the position of the button on the canvas (not in the database)
            setActivePage(pageNumber, page);
            let temp_x = Math.floor(calculate_right_x(x, width));
            let temp_y = Math.floor(calculate_right_y(y, height));

            setCoordinateX(temp_x);
            setCoordinateY(temp_y);

            if (permanent) {
                setSomethingSaving(true);
                const relative_x = temp_x / width;
                const relative_y = temp_y / height;
                update_list(temp_x, temp_y, id);
                await ButtonsService.edit_button_position(
                    currentlyViewedBook,
                    pageNumber,
                    { key: id, x: relative_x, y: relative_y },
                    privateAxios
                );
                setSomethingSaving(false);
            }
            let buttonToSave =
                selectedButton?.key === id
                    ? selectedButton
                    : buttons.find((button) => button.key === id);

            setSelectedButton({
                ...buttonToSave,
                x: temp_x,
                y: temp_y,
            });
        };

        /**====== Adding new button to the canvas ========**/
        const add_button = async (addingButtonType, new_x, new_y) => {
            // Check if the button is not out of the canvas
            let calculated_x = new_x - BUTTON_DEFAULT_W * SCALE_FACTOR;
            let calculated_y = new_y - BUTTON_DEFAULT_H * SCALE_FACTOR;
            calculated_x = Math.floor(calculate_right_x(calculated_x, width));
            calculated_y = Math.floor(calculate_right_y(calculated_y, height));
            const new_button_data_API = {
                key: uuidv4(),
                type: addingButtonType,
                x: calculated_x / width,
                y: calculated_y / height,
            };
            const new_button_data = format_buttons(
                new_button_data_API,
                width,
                height
            );
            setSomethingSaving(true);
            await ButtonsService.add_buttons(
                currentlyViewedBook.bookid,
                currentlyViewedBook.release,
                currentlyViewedBook.version,
                pageNumber,
                new_button_data_API,
                privateAxios
            );
            setSomethingSaving(false);
            const new_buttons = [...buttons];
            new_buttons.push(new_button_data);
            setButtons(new_buttons);
            setSelectedButton(new_button_data);
            setCoordinateX(new_button_data.x);
            setCoordinateY(new_button_data.y);
            setActivePage(pageNumber, page);
        };

        const handleButtonDelete = async (key) => {
            setSomethingSaving(true);
            const response = await ButtonsService.delete_button(
                currentlyViewedBook,
                pageNumber,
                key,
                axiosPrivate
            );
            if (response.err) {
                toast.error('Nepodařilo se smazat tlačítko', toastError);
                return;
            }

            setButtons(buttons.filter((button) => button.key !== key));
            setDeletingButton(null);
            setSelectedButton(null);
            toast.success('Tlačítko bylo smazáno', toastError);
            setSomethingSaving(false);
        };

        const onBlurSave = async () => {
            await handleSaveButton(
                coordinateX,
                coordinateY,
                selectedButton.key,
                true
            );
        };

        useImperativeHandle(ref, () => ({ add_button }));
        return (
            <>
                {buttonsVisible &&
                    selectedButton &&
                    selectedPageSide === page &&
                    ReactDOM.createPortal(
                        <div className="detailed-button-info visible">
                            <p className="detailed-page-number">
                                Strana č.{pageNumber} -{' '}
                                {page === 0 ? 'Levá' : 'Pravá'}
                            </p>
                            <h4 className="header-4">
                                {get_new_button_label(selectedButton)}
                            </h4>
                            <span className="button-id">
                                {selectedButton.key}
                            </span>
                            <CoordinateChangeInput
                                title="X: "
                                value={coordinateX}
                                setCoordinate={setCoordinateX}
                                onBlurSave={onBlurSave}
                                updateList={(coordinate) =>
                                    update_list(
                                        coordinate,
                                        coordinateY,
                                        selectedButton.key
                                    )
                                }
                            />
                            <CoordinateChangeInput
                                title="Y: "
                                value={coordinateY}
                                setCoordinate={setCoordinateY}
                                onBlurSave={onBlurSave}
                                updateList={(coordinate) =>
                                    update_list(
                                        coordinateX,
                                        coordinate,
                                        selectedButton.key
                                    )
                                }
                            />
                            <LibrarySelect
                                selectedButton={selectedButton}
                                setSelectedButton={setSelectedButton}
                                bookInfo={currentlyViewedBook}
                                pageNumber={pageNumber}
                                somethingSaving={setSomethingSaving}
                                setButtons={{
                                    setFunc: setButtons,
                                    buttons: buttons,
                                }}
                                updatePageButtons={async () =>
                                    await buttons_call(false)
                                }
                            />
                            <div className="side-bottom-part">
                                <RemoveButton
                                    onClick={() => {
                                        setDeletingButton(selectedButton.key);
                                    }}
                                    type={'red'}
                                    title="Smazat"
                                    size={'extra-large'}
                                />
                            </div>
                            {deletingButton && (
                                <Modal
                                    closeFunction={() => {
                                        setDeletingButton(null);
                                    }}
                                    acceptFuncion={() => {
                                        handleButtonDelete(deletingButton);
                                    }}
                                >
                                    <p className="centered header-3 font-weight-500 mg-b-2">
                                        Opravdu si přejete smazat toto tlačítko?
                                    </p>
                                </Modal>
                            )}
                        </div>,
                        document.body
                    )}
                <Stage width={width} height={height}>
                    <Layer>
                        {buttons.map((button) => {
                            return (
                                buttonsVisible && (
                                    <ButtonSprite
                                        key={button.key}
                                        type={button.type}
                                        x={button.x}
                                        y={button.y}
                                        button_info={button}
                                        change_position={handleSaveButton}
                                        set_selected_button={setSelectedButton}
                                        selected_button={selectedButton}
                                        maxWidth={width}
                                        maxHeight={height}
                                        redBorderView={selectedPage === page}
                                        snap_to_x={x_coordinates}
                                        snap_to_y={y_coordinates}
                                    />
                                )
                            );
                        })}
                    </Layer>
                </Stage>
            </>
        );
    }
);

export default ButtonCanvas;
