const replaceButtonInButtonArray = (setButtons, newButton) => {
    // The setButtons object contains - buttons, setFunc
    const newButtons = setButtons.buttons.map((button) => {
        if (button.key === newButton.key) {
            return newButton;
        }
        return button;
    });
    setButtons.setFunc(newButtons);
};
const shouldView = (object_with_name_attribute, searchText) => {
    if (!searchText) return true;
    const lower_case_searched = searchText
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    const lower_case_name = object_with_name_attribute.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    return lower_case_name.includes(lower_case_searched);
};

export { replaceButtonInButtonArray, shouldView };
