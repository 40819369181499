const SvgIconType = {
    pin: 0,
    warning: 1,
    check: 2,
    marquee: 3,
    marqueeMinus: 4,
    move: 5,
    zoom: 6,
};
export default SvgIconType;
