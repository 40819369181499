import React, { useState } from 'react';

const FaviconOfSite = ({ url, alt, classnames, hideOnError }) => {
    const url_obj = new URL(url);
    const faviconPath = `${url_obj.origin}/favicon.ico`;
    const [err, setErr] = useState(false);

    return !err ? (
        <img
            src={faviconPath}
            alt={alt}
            className={classnames}
            style={{ maxHeight: '1.6rem' }}
            onError={() => {
                setErr(true);
            }}
        />
    ) : hideOnError ? null : (
        <p>{url_obj.origin}</p>
    );
};

export default FaviconOfSite;
