import React, { useEffect, useState, useRef } from 'react';
import '../pages/styles/components/imageEdit.css';
import { assetToPath, checkFileType } from '../utils/path_utils';
import EditIcon from '../static/images/pencil.png';
import CheckIcon from '../static/images/check.png';
import MediaExcercisesService from '../services/media_excercises.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import PullUpIcon from '../static/images/pullup.png';
import { toast } from 'react-toastify';
import { replaceButtonInButtonArray } from '../utils/stateUtils';
import { useAssetImages } from '../stores/assetStore';
import PopUp from './PopUp';
import ArrowIcon from '~/static/images/next.png';
import classNames from 'classnames';

const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const ImageEdit = ({
    image,
    closeFunction,
    bookDetails,
    savedButton,
    setSavedButton,
    setButtons,
    focusOnHeader,
    focusOnDescription,
    editNextPicture,
    editPreviousPicture,
    index,
    countOfImages,
    openDescription,
}) => {
    const [header, setHeader] = useState(image.header);
    const [edittingHeader, setEdittingHeader] = useState(focusOnHeader);
    const [description, setDescription] = useState(image.description);
    const [edittingDescription, setEdittingDescription] =
        useState(focusOnDescription);
    const [descriptionOpened, setDescriptionOpened] = useState(
        openDescription ? openDescription : true
    );
    const privateAxios = useAxiosPrivate();
    const headerInputRef = useRef(null);
    const descriptionInputRef = useRef(null);
    const [images, setImages] = useAssetImages();
    const imageRef = useRef(null);
    useEffect(() => {
        console.log('focusOnHeader', focusOnHeader);
        console.log('focusOnDescription', focusOnDescription);
    }, [focusOnHeader, focusOnDescription]);
    useEffect(() => {
        // check if user pressed left or right arrow key
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                editNextPicture();
            } else if (e.key === 'ArrowLeft') {
                editPreviousPicture();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [image, editNextPicture, editPreviousPicture]);

    useEffect(() => {
        setHeader(image.header);
        setDescription(image.description);
    }, [image]);

    useEffect(() => {
        if (focusOnHeader) {
            setTimeout(() => {
                headerInputRef.current.focus();
            }, 300);
        }
        if (focusOnDescription) {
            setTimeout(() => {
                descriptionInputRef.current.focus();
            }, 300);
        }
    }, [focusOnHeader, focusOnDescription]);
    const handleFocusOnHeader = () => {
        setEdittingHeader(true);
        setTimeout(() => {
            headerInputRef.current.focus();
        }, 10);
    };
    const handleFocusOnDescription = () => {
        setEdittingDescription(true);
        setTimeout(() => {
            descriptionInputRef.current.focus();
        }, 10);
    };
    const handleHeaderChange = async (e) => {
        const response = await MediaExcercisesService.change_image_detail(
            {
                ...bookDetails,
                header: header,
                description: description,
                image_id: image['_id'],
                button_id: savedButton && savedButton.key,
            },
            privateAxios
        );
        if (response.err) {
            toast.error(
                'Něco se pokazilo při ukládání nadpisu obrázku',
                toastError
            );
            return;
        }
        if (savedButton) {
            const newButton = { ...savedButton };
            newButton.pictures = response.button.pictures;
            setSavedButton(newButton);
            replaceButtonInButtonArray(setButtons, newButton);
        }
        setImages(response.images);
    };
    const invertDescription = () => {
        setDescriptionOpened(!descriptionOpened);
    };

    return (
        <PopUp
            closeFunc={closeFunction}
            additionalClassnames="image-edit-additional"
        >
            <div className="image-edit-container">
                <div className="image-edit-cover">
                    <img
                        ref={imageRef}
                        src={assetToPath(image)}
                        alt={image.name}
                        className="image-edit-img"
                    />
                </div>
                <div className="bottom-control-bar">
                    <button
                        className={
                            descriptionOpened ? 'pull-down-btn' : 'pull-up-btn'
                        }
                        onClick={invertDescription}
                        title={
                            descriptionOpened ? 'Zavřít popis' : 'Otevřít popis'
                        }
                    >
                        <img src={PullUpIcon} alt="pull-up" />
                    </button>
                    <div className="header-buttons-top-bar mg-b-1">
                        <div className="page-control">
                            <button
                                className="image-edit-arrow back left"
                                onClick={editPreviousPicture}
                            >
                                <img src={ArrowIcon} alt="next" />
                            </button>
                            <div className="image-index body-4">
                                {index + 1}/{countOfImages}
                            </div>
                            <button
                                className="image-edit-arrow  right"
                                onClick={editNextPicture}
                            >
                                <img src={ArrowIcon} alt="next" />
                            </button>
                        </div>
                        {!edittingHeader ? (
                            <div className="image-edit-header body-2">
                                {header}
                                <button
                                    className="edit-btn bigger center-right"
                                    onClick={handleFocusOnHeader}
                                >
                                    <img src={EditIcon} alt="edit" />
                                </button>
                            </div>
                        ) : (
                            <div className="image-edit-header body-3">
                                <input
                                    className="image-editing-header"
                                    value={header}
                                    onChange={(e) => {
                                        setHeader(e.target.value);
                                    }}
                                    ref={headerInputRef}
                                />
                                <button
                                    className="edit-btn bigger center-right"
                                    onClick={() => {
                                        setEdittingHeader(false);
                                        handleHeaderChange();
                                    }}
                                >
                                    <img src={CheckIcon} alt="edit" />
                                </button>
                            </div>
                        )}
                    </div>

                    {descriptionOpened &&
                        (!edittingDescription ? (
                            <div
                                className={classNames(
                                    'image-edit-description',
                                    'body-3',
                                    {
                                        empty: !(
                                            description &&
                                            description.length > 0
                                        ),
                                    }
                                )}
                            >
                                {description?.length > 0
                                    ? description
                                    : 'Popis zatím nezadán'}
                                <button
                                    className="edit-btn bigger center-right"
                                    onClick={handleFocusOnDescription}
                                >
                                    <img src={EditIcon} alt="edit" />
                                </button>
                            </div>
                        ) : (
                            <div className="image-edit-description body-3">
                                <textarea
                                    className="image-editing-description"
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                    ref={descriptionInputRef}
                                    rows={6}
                                />
                                <button
                                    className="edit-btn bigger center-right"
                                    onClick={() => {
                                        setEdittingDescription(false);
                                        handleHeaderChange();
                                    }}
                                >
                                    <img src={CheckIcon} alt="edit" />
                                </button>
                            </div>
                        ))}
                </div>
            </div>
        </PopUp>
    );
};

export default ImageEdit;
/**
 * 
 * 
 * 
 * 
                    <div className="pull-up-container">
                        
                        {descriptionOpened && (
                            <>
                                <p
                                    className={`image-edit-description ${
                                        edittingDescription ? 'not-visible' : ''
                                    }`}
                                >
                                    {description || (
                                        <span style={{ color: 'red' }}>
                                            Ještě zde není žádný popis
                                        </span>
                                    )}
                                    <button
                                        className="edit-btn bigger center-right"
                                        onClick={() => {
                                            setEdittingDescription(true);
                                            setTimeout(() => {
                                                descriptionInputRef.current.focus();
                                            }, 100);
                                        }}
                                    >
                                        <img src={EditIcon} alt="edit" />
                                    </button>
                                </p>
                                <div
                                    className={`image-editing-description-div ${
                                        !edittingDescription
                                            ? 'not-visible'
                                            : ''
                                    }`}
                                >
                                    
                                    <button
                                        className="edit-btn bigger center-right"
                                        onClick={() => {
                                            setEdittingDescription(false);
                                            handleHeaderChange();
                                        }}
                                    >
                                        <img src={CheckIcon} alt="edit" />
                                    </button>
                                </div>
                            </>
                        )}
                    </div>

 */
