import React, { useRef, useState } from 'react';
import './VideoLocal.css';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import PopUp from '../../../components/PopUp';
import SourceButton from './SourceButton';
const VideoLocal = ({ closeFunc, button }) => {
    const popupRef = useRef();
    const videoRef = useRef();
    const [wasPlaying, setWasPlaying] = useState(false);

    const pauseVideo = () => {
        if (videoRef.current) {
            if (videoRef.current.isPlaying()) {
                setWasPlaying(true);
            }
            videoRef.current.handlePauseVideo();
        }
    };
    const maybePlay = () => {
        if (wasPlaying && videoRef.current) {
            videoRef.current.handlePlayVideo();
            setWasPlaying(false);
        }
    };

    return (
        <PopUp ref={popupRef} closeFunc={closeFunc}>
            <VideoPlayer
                ref={videoRef}
                video={button.video}
                fullscreen={true}
            />
            <SourceButton
                video={button.video}
                click={pauseVideo}
                onClose={maybePlay}
            />
        </PopUp>
    );
};

export default VideoLocal;
