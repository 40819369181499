import React, { useEffect } from 'react';

const CoordinateChangeInput = ({
    title,
    value,
    setCoordinate,
    updateList,
    onBlurSave,
}) => {
    return (
        <div className="info-split">
            <label className="info-label" htmlFor={title + '-coordinate'}>
                {title}
            </label>
            <input
                className="info-input"
                type="number"
                id={title + '-coordinate'}
                value={value}
                onChange={(e) => {
                    const coordinate = e.target.value
                        ? parseInt(e.target.value)
                        : 0;
                    setCoordinate(coordinate);
                    updateList(coordinate);
                }}
                onBlur={onBlurSave}
            />
        </div>
    );
};

/*
(coordinate) => update_list(coordinate, coordinateY, selectedButton.key)

*/
export default CoordinateChangeInput;
