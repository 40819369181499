import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./EditorNavbar.css";
import classNames from "classnames";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	EDITOR_PAGES,
	useInteractiveBookStore,
} from "~/stores/interactiveBookStore";
import { getPaths } from "~/utils/path_utils";
import BackToBookMenu from "~/components/BackToBookMenu";
import BookMenuButton from "~/components/BookMenuButton";
import HoverIcon from "~/static/images/down-arrow.png";
import LeftIcon from "~/static/images/left_arrow.png";
import RightIcon from "~/static/images/right_arrow.png";
import SvgIcon from "~/components/SvgIcon/SvgIcon";
import SvgIconType from "~/components/SvgIcon/SvgIconType";
import { Tooltip } from "react-tippy";

const EditorNavbar = ({ pageNumber, previous, next }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [menuOpenTimeout, setMenuOpenTimeout] = useState(null);
	const [pinned, setPinned] = useState(() => {
		const pinned = localStorage.getItem("pinned-navbar");
		return pinned === "true";
	});
	const bookInfo = useInteractiveBookStore((state) => state.bookObject);
	const paths = getPaths({
		concrete_book: { ...bookInfo, book_id: bookInfo.bookid },
	});
	const currentViewedPage = useInteractiveBookStore(
		(state) => state.currentEditorPage
	);
	const currentPage = useInteractiveBookStore((state) => state.currentPage);
	const [searchParams, setSearchParams] = useSearchParams();

	const links = [
		{
			path: paths.editor,
			name: "Editor",
			page: EDITOR_PAGES.BUTTONS,
			pageParam: true,
		},
		{
			path: paths.preview,
			name: "Náhled",
			page: EDITOR_PAGES.PREVIEW,
			pageParam: true,
		},
		{
			path: paths.zoom,
			name: "Zoom Rámečky",
			page: EDITOR_PAGES.ZOOMS,
			pageParam: true,
		},
		{
			path: paths.library,
			name: "Knihovna",
			page: EDITOR_PAGES.LIBRARY,
		},
	];
	const openMenu = () => {
		setIsMenuOpen(true);
		if (menuOpenTimeout) {
			clearTimeout(menuOpenTimeout);
			setMenuOpenTimeout(null);
		}
	};
	const closeMenu = () => {
		if (menuOpenTimeout) {
			clearTimeout(menuOpenTimeout);
			setMenuOpenTimeout(null);
		}
		const timeoutId = setTimeout(() => {
			setIsMenuOpen(false);
		}, 2000);
		setMenuOpenTimeout(timeoutId);
	};
	const handleSwitchPinned = () => {
		if (!pinned) {
			menuOpenTimeout && clearTimeout(menuOpenTimeout);
			setIsMenuOpen(true);
		} else {
			const timeoutId = setTimeout(() => {
				setIsMenuOpen(false);
			}, 2000);
			setMenuOpenTimeout(timeoutId);
		}
		setPinned(!pinned);
		localStorage.setItem("pinned-navbar", !pinned);
	};
	return ReactDOM.createPortal(
		<div
			className="interactive-top-overlay"
			onMouseEnter={openMenu}
			onMouseLeave={closeMenu}
		>
			<nav
				className={classNames("interactive-top-container", {
					opened: isMenuOpen || pinned,
				})}
			>
				<Tooltip
					title={pinned ? "Odepnout lištu" : "Připnout lištu"}
					position="right"
					className="pin-right"
				>
					<button
						className={classNames("pin-bar", { pinned: pinned })}
						onClick={handleSwitchPinned}
					>
						<SvgIcon
							type={SvgIconType.pin}
							className="white"
							size="small"
						/>
					</button>
				</Tooltip>
				<div className="left-part">
					<ul>
						{links.map((link) => (
							<li key={link.name}>
								<Link
									className={classNames(
										"interactive-menu-link",
										{
											active:
												link.page === currentViewedPage,
										}
									)}
									to={
										link.path +
										(link.pageParam && pageNumber
											? `?page=${pageNumber}`
											: link.pageParam && currentPage
											? `?page=${currentPage}`
											: "")
									}
								>
									{link.name}
								</Link>
							</li>
						))}
					</ul>
				</div>
				{pageNumber && (
					<div className="interactive-pages-container">
						<button
							className="no-style"
							onClick={() => {
								previous && previous();
							}}
						>
							<img src={LeftIcon} alt="left" />
						</button>
						<div className="navbar-page-numbers">
							{pageNumber} | {pageNumber + 1}
						</div>
						<button
							className="no-style"
							onClick={() => {
								next && next();
								searchParams.set("button", null);
							}}
						>
							<img src={RightIcon} alt="right" />
						</button>
					</div>
				)}
				<div className="right-part flex-start gap-1">
					<BackToBookMenu />
					<BookMenuButton externalOnclick={closeMenu} />
				</div>
			</nav>

			<div
				className={classNames("hovering-navbar-icon", {
					opened: !isMenuOpen && !pinned,
				})}
			>
				<img src={HoverIcon} alt="hover-icon" />
			</div>
		</div>,
		document.body
	);
};

export default EditorNavbar;
