import AuthService from '../services/auth.service';
import useAuth from './useAuth';

const useLogout = () => {
    const { setAuth } = useAuth();
    const logout = async () => {
        setAuth({});
        await AuthService.logout();
    };
    return logout;
};
export default useLogout;
