import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';

import ZoomPolygon from './ZoomPolygon';
import EdittingPolygon from './ChangingPolygons/EdittingPolygon';
import {
    getHeightWidthFromMouse,
    getKonvaCursorLocation,
} from '~/utils/canvasesCalculations';
import CreatePolygon from './ChangingPolygons/CreatePolygon';
import { toast } from 'react-toastify';
import toastSettings from '~/utils/toastSettings';
import Polygon, {
    format_rectangle,
    getCuttedPolygons,
    getResizedPolygon,
    getResizingCursor,
    setCorrespondingCursor,
    transformNewToApi,
} from '~/utils/zoom_utils';
import ZoomService from '~/services/zoom.service';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { useZoomStore, ZoomToolsEnum } from '~/stores/zoomStore';
import RescalingPolygon from './ChangingPolygons/RescalingPolygon';
const isNewPolygonValid = (polygon) => {
    if (!polygon.width || !polygon.height) {
        return false;
    }
    if (Math.abs(polygon.width) < 10 || Math.abs(polygon.height) < 10) {
        return false;
    }
    return true;
};
const ZoomCanvasNew = ({
    pageNumber,
    width,
    height,
    polygons,
    setPolygons,
    active,
    setActive,
    book,
    setVisibleZoom,
}) => {
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const [creatingPolygon, setCreatingPolygon] = useState(false);
    const [insidePolygon, setInsidePolygon] = useState(false);
    const [rescalingPolygon, setRescalingPolygon] = useState(null);

    const activeTool = useZoomStore((state) => state.currentTool);
    const privateAxios = useAxiosPrivate();
    const stageRef = useRef(null);

    // When active tool changes and cursor is not inside polygon, change cursor
    useEffect(() => {
        if (!insidePolygon) {
            if (activeTool === ZoomToolsEnum.MARQUEE && stageRef.current) {
                stageRef.current.container().style.cursor = 'crosshair';
                resetSelectedPolygon();
            } else if (
                activeTool === ZoomToolsEnum.CUTOUT &&
                stageRef.current
            ) {
                stageRef.current.container().style.cursor = 'crosshair';
                resetSelectedPolygon();
            } else if (activeTool === ZoomToolsEnum.ZOOM_IN) {
                resetSelectedPolygon();
            } else {
                stageRef.current.container().style.cursor = 'default';
            }
        } else {
            if (activeTool === ZoomToolsEnum.MARQUEE) {
                resetSelectedPolygon();
            } else if (activeTool === ZoomToolsEnum.CUTOUT) {
                resetSelectedPolygon();
            } else if (activeTool === null) {
                resetSelectedPolygon();
                if (activeTool === ZoomToolsEnum.ZOOM_IN) {
                    stageRef.current.container().style.cursor = 'zoom-in';
                } else {
                    stageRef.current.container().style.cursor = 'pointer';
                }
            } else if (activeTool === ZoomToolsEnum.ZOOM_IN) {
                stageRef.current.container().style.cursor = 'zoom-in';
                resetSelectedPolygon();
            }
        }

        // eslint-disable-next-line
    }, [activeTool]);

    const handleSelectPolygon = (polygon) => {
        setActive(pageNumber);
        setSelectedPolygon(polygon);
    };
    const handleMouseIn = (e) => {
        setCorrespondingCursor(e, activeTool);
    };

    const handleOnMouseDownCanvas = (e) => {
        if (e.evt.button !== 0) return;
        setActive(pageNumber);
        // If user clicks outside, create polygon
        if (e.target === e.target.getStage()) {
            if (selectedPolygon) setSelectedPolygon(null);
            if (activeTool === ZoomToolsEnum.MARQUEE) {
                const mouse = getKonvaCursorLocation(e);
                setCreatingPolygon(mouse);
            } else if (activeTool === ZoomToolsEnum.CUTOUT) {
                const mouse = getKonvaCursorLocation(e);
                setCreatingPolygon({ ...mouse, deleting: true });
            }
        }
    };
    const handleOnMouseMoveCanvas = (e) => {
        if (e.target !== e.target.getStage()) {
            setInsidePolygon(true);
        } else {
            setInsidePolygon(false);
        }

        if (creatingPolygon) {
            const newCreate = getHeightWidthFromMouse(creatingPolygon, e);
            setCreatingPolygon(newCreate);
        } else if (rescalingPolygon) {
            const newRescalingPolygon = getResizedPolygon(rescalingPolygon, e);
            setRescalingPolygon(newRescalingPolygon);
        }
    };

    const handleOnMouseUpCanvas = async (e) => {
        if (e.evt.button !== 0) return;
        if (creatingPolygon && !creatingPolygon.deleting) {
            await createNewPolygon();
        } else if (creatingPolygon && creatingPolygon.deleting) {
            await removeAreaFromPolygons();
        } else if (rescalingPolygon) {
            await rescalePolygon();
        }
    };

    const createNewPolygon = async () => {
        if (!isNewPolygonValid(creatingPolygon)) {
            // dont allow shape with width or height less than 10px
            console.log(creatingPolygon);
            setCreatingPolygon(null);
            if (!creatingPolygon.width || !creatingPolygon.height) return false;
            toast.error(
                'Zoom rámeček musí být větší než 10 pixelů na výšku i na šířku.',
                toastSettings
            );
            return;
        }
        const newPoly = transformNewToApi(creatingPolygon, width, height);
        setCreatingPolygon(null);
        const newPolygons = polygons.slice();
        newPolygons.push(new Polygon(newPoly, width, height, pageNumber));
        setPolygons(newPolygons);
        const response = await ZoomService.save_new_polygon(
            book,
            pageNumber,
            [newPoly],
            privateAxios
        );
        if (response.err) {
            toast.error(response.detail, toastSettings);
            return;
        }
        const new_polygons = response.zooms.map(
            (p) => new Polygon(p, width, height, pageNumber)
        );
        setPolygons(new_polygons);
    };
    const removeAreaFromPolygons = async () => {
        // dataforApi = { polygon, cuttedOut}
        let { newPolygons, dataForApi } = getCuttedPolygons(
            polygons,
            creatingPolygon,
            width,
            height,
            pageNumber
        );
        let storedPolygons = polygons.slice();
        setPolygons(newPolygons);
        if (dataForApi.length !== 0) {
            const response = await ZoomService.add_cutouts(
                book,
                pageNumber,
                dataForApi,
                privateAxios
            );
            if (response.err) {
                toast.error(response.detail, toastSettings);
                setCreatingPolygon(null);
                setPolygons(storedPolygons);
                return;
            }
            response.zooms.map(
                (p) => new Polygon(p, width, height, pageNumber)
            );
            setPolygons(newPolygons);
        }
        setCreatingPolygon(null);
    };
    const handleMoveEdittingDone = async (newPoints) => {
        if (!selectedPolygon) return;

        selectedPolygon.points = newPoints;
        const apiData = selectedPolygon.polygon_to_api(width, height);
        const response = await ZoomService.update_polygon(
            book,
            pageNumber,
            apiData,
            privateAxios
        );
        if (response.err) {
            toast.error(response.detail, toastSettings);
            return;
        }
        const newPolygon = new Polygon(apiData, width, height, pageNumber);
        const newPolygons = polygons.map((p) =>
            p.id === newPolygon.id ? newPolygon : p
        );
        setPolygons(newPolygons);
    };

    const rescalePolygon = async () => {
        const newPoints = rescalingPolygon.points;
        const apiData = Polygon.points_to_polygon_flat(
            newPoints,
            width,
            height,
            rescalingPolygon.polygonId
        );
        setRescalingPolygon(null);
        const savedPolygons = polygons.slice();
        const newPolygon = new Polygon(apiData, width, height, pageNumber);
        setSelectedPolygon(newPolygon);
        const newPolygons = polygons.map((p) =>
            p.id === newPolygon.id ? newPolygon : p
        );
        setPolygons(newPolygons);
        const response = await ZoomService.update_polygon(
            book,
            pageNumber,
            apiData,
            privateAxios
        );
        if (response.err) {
            toast.error(response.detail, toastSettings);
            setRescalingPolygon(null);
            setPolygons(savedPolygons);
            return;
        }
    };

    const resetSelectedPolygon = () => {
        if (selectedPolygon) setSelectedPolygon(null);
    };
    return (
        <Stage
            ref={stageRef}
            width={width}
            height={height}
            onMouseEnter={handleMouseIn}
            onMouseDown={handleOnMouseDownCanvas}
            onMouseMove={handleOnMouseMoveCanvas}
            onMouseUp={handleOnMouseUpCanvas}
            onContextMenu={(e) => e.evt.preventDefault()}
        >
            <Layer>
                {polygons.map((p) => (
                    <ZoomPolygon
                        key={p.id}
                        poly={p}
                        setPolygons={setPolygons}
                        canDelete={true}
                        selectedPolygon={selectedPolygon}
                        active={active}
                        setSelectedPolygon={handleSelectPolygon}
                        setVisibleZoom={setVisibleZoom}
                    />
                ))}
            </Layer>
            <Layer>
                {selectedPolygon && !rescalingPolygon && active && (
                    <EdittingPolygon
                        polygon={selectedPolygon}
                        editPolyOnAPI={handleMoveEdittingDone}
                        setRescalingPolygon={setRescalingPolygon}
                        setPolygons={setPolygons}
                        canDelete={true}
                        book={book}
                        clearEdittingPolygon={resetSelectedPolygon}
                    />
                )}
                {creatingPolygon && <CreatePolygon polygon={creatingPolygon} />}
                {rescalingPolygon && (
                    <RescalingPolygon points={rescalingPolygon.points} />
                )}
            </Layer>
        </Stage>
    );
};

export default ZoomCanvasNew;
