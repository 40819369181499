import data from '../static/sprite_sheets/lesson_buttons.json';
import { Sprite, Rect, Line } from 'react-konva';
import image from '../static/sprite_sheets/lesson_buttons-0.png';
import useImage from 'use-image';
import { useRef, useEffect, useCallback, useState } from 'react';
import { Tween } from 'konva/lib/Tween';

export function getData() {
    const regex = /^([a-zA-Z_]+)(\d+)\.png/;
    const frames = data.textures[0].frames;
    let categories = [];
    for (const frame in frames) {
        const cur_frame = frames[frame];
        const matched = cur_frame.filename.match(regex);
        if (matched) {
            const [, name] = matched;
            if (!categories.includes(name)) {
                categories.push(name);
            }
        }
    }
    let ordered_frames = [];
    for (const category of categories) {
        const filtered_frames = Object.values(frames).filter((frame) => {
            const matched = frame.filename.match(regex);
            if (matched) {
                const [, name] = matched;
                return name === category;
            }
            return false;
        });
        ordered_frames.push(
            filtered_frames.sort((a, b) => a.filename.localeCompare(b.filename))
        );
    }
    const frame_names = [
        'AUDIO',
        'DRAW',
        'FIRST_DRAW',
        'INTERACTIVE',
        'MOVE',
        'PHOTO',
        'RESULT',
        'SPEAK',
        'VIDEO_LOCAL',
        'VIDEO_WEB',
    ];
    const final = {
        AUDIO: [],
        DRAW: [],
        FIRST_DRAW: [],
        INTERACTIVE: [],
        MOVE: [],
        PHOTO: [],
        RESULT: [],
        SPEAK: [],
        VIDEO_LOCAL: [],
        VIDEO_WEB: [],
    };
    // connect frame names with frames
    for (let i = 0; i < frame_names.length; i++) {
        const name = frame_names[i];
        const frames = ordered_frames[i];
        const details = frames
            .map((frame) => {
                return [
                    frame.frame.x,
                    frame.frame.y,
                    frame.frame.w,
                    frame.frame.h,
                ];
            })
            .flat();
        for (const detail in details) {
            final[name].push(details[detail]);
        }
    }
    return final;
}
var animations = getData();

const SCALE_FACTOR = 0.5;
export const button_types = {
    0: 'PHOTO',
    1: 'AUDIO',
    2: 'VIDEO_LOCAL',
    3: 'INTERACTIVE',
};
const BUTTON_DEFAULT_W = 76;
const BUTTON_DEFAULT_H = 93;

const calculate_right_x = (x, maxWidth) => {
    let x_to_calculate = x < 0 ? 0 : x;
    return x_to_calculate + Math.ceil(BUTTON_DEFAULT_W * SCALE_FACTOR) >
        maxWidth
        ? maxWidth - Math.ceil(BUTTON_DEFAULT_W * SCALE_FACTOR)
        : x_to_calculate;
};
const calculate_right_y = (y, maxHeight) => {
    let y_to_calculate = y < 0 ? 0 : y;
    return y_to_calculate + Math.ceil(BUTTON_DEFAULT_H * SCALE_FACTOR) >
        maxHeight
        ? maxHeight - Math.ceil(BUTTON_DEFAULT_H * SCALE_FACTOR)
        : y_to_calculate;
};

export function ButtonSprite({
    type,
    x,
    y,
    button_info,
    change_position,
    set_selected_button,
    selected_button,
    maxWidth,
    maxHeight,
    redBorderView,
    snap_to_x,
    snap_to_y,
}) {
    const [imageTemp] = useImage(image);
    const spriteRefference = useRef(null);
    const borderRefference = useRef(null);
    const [borderVisible, setBorderVisible] = useState(
        selected_button?.key === button_info?.key
    );
    const [showXLine, setShowXLine] = useState(false);
    const [showYLine, setShowYLine] = useState(false);

    const [lineX, setLineX] = useState(null);
    const [lineY, setLineY] = useState(null);
    useEffect(() => {
        if (spriteRefference.current) {
            spriteRefference.current.start();
        }
    }, [spriteRefference]);

    const handleDragStart = (e) => {
        e.target.getStage().container().style.cursor = 'grabbing';
        handleClick(e);
    };
    const handleDragEnd = async (e) => {
        e.target.getStage().container().style.cursor = 'grab';
        let x = calculate_right_x(e.target.x(), maxWidth);
        let y = calculate_right_y(e.target.y(), maxHeight);
        e.target.x(x);
        e.target.y(y);

        const id = button_info.key;
        await change_position(x, y, id, true);
        setShowXLine(false);
        setShowYLine(false);
    };
    const onDragMove = async (e) => {
        let x = calculate_right_x(e.target.x(), maxWidth);
        let y = calculate_right_y(e.target.y(), maxHeight);
        x = x - (x % 10);
        y = y - (y % 10);
        e.target.x(x);
        e.target.y(y);
        if (borderRefference.current) {
            borderRefference.current.x(x - 3);
            borderRefference.current.y(y - 3);
        }
        const id = button_info.key;
        await change_position(x, y, id);
        let x_in = snap_to_x.some(
            (btn) => btn.x === x && btn.key !== button_info.key
        );
        let y_in = snap_to_y.some(
            (btn) => btn.y === y && btn.key !== button_info.key
        );
        console.log(x_in, y_in);
        if (x_in > 0) {
            setShowXLine(true);
            setLineX(x);
        } else {
            setShowXLine(false);
        }
        if (y_in) {
            setShowYLine(true);
            setLineY(y);
        } else {
            setShowYLine(false);
        }
    };
    const handleMouseEnter = (e) => {
        e.target.getStage().container().style.cursor = 'grab';
    };
    const handleMouseLeave = (e) => {
        e.target.getStage().container().style.cursor = 'default';
    };
    const handleClick = async (e) => {
        set_selected_button(button_info);
        await change_position(button_info.x, button_info.y, button_info.key);
        setBorderVisible(true);
    };
    useEffect(() => {
        setBorderVisible(selected_button?.key === button_info?.key);
    }, [redBorderView, selected_button]);

    return (
        <>
            {redBorderView &&
                borderVisible &&
                selected_button?.key === button_info?.key && (
                    <Rect
                        ref={borderRefference}
                        x={x - 2}
                        y={y - 2}
                        width={BUTTON_DEFAULT_W * SCALE_FACTOR}
                        height={BUTTON_DEFAULT_H * SCALE_FACTOR + 2}
                        stroke="red"
                        strokeWidth={2}
                        cornerRadius={5}
                    />
                )}
            <Sprite
                ref={spriteRefference}
                x={x}
                y={y}
                animation={type}
                animations={animations}
                frameRate={24}
                scale={{ x: SCALE_FACTOR, y: SCALE_FACTOR }}
                frameIndex={0}
                image={imageTemp}
                onDragStart={(e) => {
                    handleDragStart(e);
                }}
                onDragMove={(e) => {
                    onDragMove(e);
                }}
                onDragEnd={(e) => {
                    handleDragEnd(e);
                }}
                draggable
                onMouseEnter={(e) => {
                    handleMouseEnter(e);
                }}
                onMouseLeave={(e) => {
                    handleMouseLeave(e);
                }}
                onClick={handleClick}
                button_info={button_info}
            />
            {showXLine && selected_button?.key === button_info?.key && (
                <Line
                    points={[
                        lineX + (BUTTON_DEFAULT_W * SCALE_FACTOR) / 2 - 2,
                        0,
                        lineX + (BUTTON_DEFAULT_W * SCALE_FACTOR) / 2 - 2,
                        maxHeight,
                    ]}
                    strokeEnabled
                    strokeWidth={0.5}
                    stroke="blue"
                />
            )}
            {showYLine && selected_button?.key === button_info?.key && (
                <Line
                    points={[
                        0,
                        lineY + (BUTTON_DEFAULT_H * SCALE_FACTOR) / 2 - 2,
                        maxWidth,
                        lineY + (BUTTON_DEFAULT_H * SCALE_FACTOR) / 2 - 2,
                    ]}
                    strokeEnabled
                    strokeWidth={0.5}
                    stroke="blue"
                />
            )}
        </>
    );
}
const ButtonSpriteStatic = ({
    type,
    x,
    y,
    button_info,
    clicked,
    disableHoverAnimation,
    disableClickAnimation,
}) => {
    const [imageTemp] = useImage(image);
    const spriteRefference = useRef(null);
    useEffect(() => {
        if (spriteRefference.current) {
            spriteRefference.current.start();
        }
    }, [spriteRefference]);

    const handleMouseLeave = (e) => {
        if (disableHoverAnimation) return;
        e.target.getStage().container().style.cursor = 'default';
        const mouseInTween = new Tween({
            node: spriteRefference.current,
            scaleX: SCALE_FACTOR,
            scaleY: SCALE_FACTOR,
            duration: 0.1, // Adjust the duration as needed
        });
        mouseInTween.play();
    };
    const handleClick = (e) => {
        if (disableClickAnimation) return;
        const initialScale = { x: SCALE_FACTOR, y: SCALE_FACTOR };
        const finalScale = { x: 0.35, y: 0.35 };
        // Create a new tween for scaling
        const scaleTween = new Tween({
            node: spriteRefference.current,
            scaleX: finalScale.x,
            scaleY: finalScale.y,
            duration: 0.1, // Adjust the duration as needed
            onFinish: () => {
                // Reverse the animation by scaling back to the initial size
                const reverseTween = new Tween({
                    node: spriteRefference.current,
                    scaleX: initialScale.x,
                    scaleY: initialScale.y,
                    duration: 0.1, // Adjust the duration as needed
                });
                reverseTween.play();
            },
        });

        // Play the initial scale animation
        scaleTween.play();
        clicked(button_info);
    };
    const handleMouseEnter = useCallback(
        (e) => {
            if (disableHoverAnimation) return;
            e.target.getStage().container().style.cursor = 'pointer';
            const mouseInTween = new Tween({
                node: spriteRefference.current,
                scaleX: 0.7,
                scaleY: 0.7,
                duration: 0.1, // Adjust the duration as needed
            });
            mouseInTween.play();
        },
        [spriteRefference]
    );

    return (
        <Sprite
            ref={spriteRefference}
            x={x + (BUTTON_DEFAULT_W / 2) * SCALE_FACTOR}
            y={y + (BUTTON_DEFAULT_H / 2) * SCALE_FACTOR}
            animation={type}
            animations={animations}
            frameRate={24}
            scale={{ x: SCALE_FACTOR, y: SCALE_FACTOR }}
            frameIndex={0}
            image={imageTemp}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            offset={{ x: BUTTON_DEFAULT_W / 2, y: BUTTON_DEFAULT_H / 2 }}
        />
    );
};
export {
    ButtonSpriteStatic,
    calculate_right_x,
    calculate_right_y,
    SCALE_FACTOR,
    BUTTON_DEFAULT_W,
    BUTTON_DEFAULT_H,
};
