import React from 'react';
import { excerciseImageToPath } from '../../../../utils/path_utils';
import { shortenText } from '../../../../utils/translator';
import DeleteIcon from '../../../../static/images/delete_white.png';
import { getNavigateToButtonPath } from '../../../../utils/navigations';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
/**
 * "_id": "666d81633d70464ab916a0cfb8b62b1f",
 * "file_path": "static/iuc/interactives/33-30/vydani_1/verze_7_0/excercises/cviceni_a_074.html",
 * "media_kind": "excerciseHTML",
 * "name": "cviceni_a_074",
 * "picture_ref": "static/iuc/interactives/33-30/vydani_1/verze_7_0/excercises/cviceni_a_074.jpg"
 */
const ExcerciseElem = ({ excercise, removeFunc, book, playExcercise }) => {
    const navigate = useNavigate();
    const handleClick = (page, button_id) => {
        const path = getNavigateToButtonPath(book, page, button_id);
        navigate(path);
    };
    const handleClickExcercise = () => {
        playExcercise(excercise);
    };
    return (
        <div className="library_elem_outer">
            <div className="library_elem_item" onClick={handleClickExcercise}>
                <div className="libary_image_cover">
                    <img
                        src={excerciseImageToPath(excercise)}
                        alt={excercise.name}
                    />
                </div>
                <div className="library_elem_name">
                    {shortenText(excercise.name, 20)}
                </div>
            </div>
            <div className="library_elem_buttons top-right">
                <Tooltip title="Odstranit video soubor" position="left">
                    <button
                        className="library_elem_button delete"
                        onClick={removeFunc}
                    >
                        <img src={DeleteIcon} alt="delete" />
                    </button>
                </Tooltip>
            </div>
            {excercise.buttons?.length > 0 && (
                <div className="library_elem_buttons bottom-left off">
                    {excercise.buttons
                        .sort((a, b) => a.page - b.page)
                        .map((button, i) => (
                            <button
                                key={i}
                                className="icon-button smaller circular library "
                                title={`Toto cvičení je propojeno s tlačítkem na straně ${button.page}`}
                                onClick={() =>
                                    handleClick(button.page, button.button_id)
                                }
                            >
                                <p>{button.page}</p>
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

export default ExcerciseElem;
