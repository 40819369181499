import React, { useEffect, useState } from 'react';
import { Roller } from 'react-awesome-spinners';
import '../styles/interactives/create.css';
import '../styles/popup.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { BASE_STATIC, axiosPrivate } from '../../deffault/axios';

import InputBox from '../../components/InputBox';
import DragAndDrop from '../../components/DragAndDrop';
import { toast } from 'react-toastify';
import FullScreenLoading from '../../components/FullScreenLoading';
import InteractiveService from '../../services/interactive.service';
import { normalize_string_for_search } from '../../utils/translator';

const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const NUM_OF_STAGES = 2;

const CreateInteractive = ({ visible, closeFunction, addFunction }) => {
    const privateAxios = useAxiosPrivate();

    const [searchCreate, setSearchCreate] = useState('');
    const [books, setBooks] = useState([]);
    const [booksSet, setBooksSet] = useState(false);
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [selectedStage, setSelectedStage] = useState(0);
    const [version, setVersion] = useState('7.0');
    const [release, setRelease] = useState('1.');
    const [iucFile, setIucFile] = useState(null);
    const [iucResultsFile, setIucResultsFile] = useState(null);
    const [submittingForm, setSubmittingForm] = useState(false);
    useEffect(() => {
        const get_books = async () => {
            const response = await privateAxios.get('/books/');
            setBooks(response.data.books);
            // console.log("INFO: ", response.data.books.length, " books loaded");
            setBooksSet(true);
        };
        get_books();
    }, [privateAxios]);

    const get_next_actual_version = async (value) => {
        if (selectedBook.catalog && (release || value)) {
            const working_release = value ? value : release;
            const checkRelease = /^[0-9]{1,2}\.$/;
            if (!checkRelease.test(working_release)) {
                return;
            }
            const response = await InteractiveService.get_info_about_release(
                selectedBook.catalog,
                working_release.replace('.', ''),
                privateAxios
            );
            console.log(response);
            let last_version = response[response.length - 1];
            if (!last_version) {
                return `7.0`;
            }
            return `${last_version.version_before_dot}.${
                last_version.version_after_dot + 1
            }`;
        }
    };
    useEffect(() => {
        if (books.length > 0) {
            const searched = books
                .filter((book) => {
                    //name', 'subject.shortcut', 'catalog
                    const subj = normalize_string_for_search(
                        book.subject.shortcut
                    );
                    const name = normalize_string_for_search(book.name);
                    const catalog = normalize_string_for_search(book.catalog);
                    const current_search =
                        normalize_string_for_search(searchCreate);

                    return (
                        subj.includes(current_search) ||
                        name.includes(current_search) ||
                        catalog.includes(current_search)
                    );
                })
                .map((book) => book.catalog)
                .sort((a, b) => a.localeCompare(b));
            setFilteredBooks(searched);
        }
    }, [books, searchCreate]);

    const handleSubmitIuc = async (e) => {
        e.preventDefault();
        setSubmittingForm(true);
        if (!release.endsWith('.')) {
            toast.error(
                'Číslo vydání je pořadová číslovka. Musí proto končit tečkou.',
                toastError
            );
            setSubmittingForm(false);
            return;
        }
        const checkVersion = /^[0-9]{1,2}\.[0-9]{1,2}$/;

        if (!checkVersion.test(version)) {
            toast.error(
                'Číslo verze musí mít specifický tvar. Musí se skládat ze dvou čísel oddělených tečkou.',
                toastError
            );
            setSubmittingForm(false);
            return;
        }
        if (!iucFile) {
            toast.error(
                'Před pokračováním prosím nahrejte soubor s podkladem interaktivní učebnice',
                toastError
            );
            setSubmittingForm(false);
            return;
        }
        if (!iucResultsFile) {
            toast.error(
                'Před pokračováním prosím nahrejte soubor s řešením interaktivní učebnice',
                toastError
            );
            setSubmittingForm(false);
            return;
        }
        const formData = new FormData();
        formData.append('file', iucFile);
        formData.append('bookid', selectedBook.catalog);
        formData.append('version', version);
        formData.append('release', release);
        formData.append('results', iucResultsFile);
        console.log(formData);
        const response = await axiosPrivate.post(
            '/interactive/create-new',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        toast.success(
            `Úspěšně byla přidána nová interaktivka ${selectedBook.catalog}, která má ${response.data.pages} stránek.`,
            toastError
        );
        setSelectedBook(null);
        setSelectedStage(0);
        setVersion('7.0');
        setRelease('1.');
        setIucFile(null);
        setIucResultsFile(null);
        addFunction();
        closeFunction();
        setSubmittingForm(false);
    };
    const handleNextButtonClick = async () => {
        if (selectedStage === 0) {
            if (!selectedBook) {
                toast.error('Zvolte knihu před dalším krokem.', toastError);
                return;
            }
            const response = await privateAxios.get(
                `/interactive/${selectedBook.catalog}/release`
            );
            setRelease(response.data.release_number);
        }
        setSelectedStage(selectedStage + 1);
    };
    return (
        <>
            {visible && (
                <div className="overlay" onClick={() => closeFunction()}>
                    <div
                        className="create-content"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {selectedStage === 0 && (
                            <>
                                <div className="top-with-input smaller-padding mg-b-1">
                                    <h1 className="header-2">
                                        Zvolte prosím učebnici
                                    </h1>
                                    <input
                                        className="input-search"
                                        placeholder="Vyhledat knihy"
                                        value={searchCreate}
                                        onChange={(e) =>
                                            setSearchCreate(e.target.value)
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        'create-interactive-books-list' +
                                        (booksSet ? ' loaded' : '')
                                    }
                                >
                                    {!booksSet && (
                                        <div className="create-loader-container">
                                            <Roller
                                                color="#00b2ff"
                                                size={64}
                                                sizeUnit="px"
                                            />
                                        </div>
                                    )}
                                    {booksSet !== 0 &&
                                        books.map((book, i) => {
                                            if (
                                                searchCreate === '' ||
                                                filteredBooks.includes(
                                                    book.catalog
                                                )
                                            ) {
                                                return (
                                                    <div
                                                        key={i}
                                                        className={
                                                            'create-book-select' +
                                                            (book ===
                                                            selectedBook
                                                                ? ' selected'
                                                                : selectedBook ===
                                                                  null
                                                                ? ''
                                                                : ' deselected')
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                selectedBook ===
                                                                book
                                                            ) {
                                                                setSelectedBook(
                                                                    null
                                                                );
                                                            } else {
                                                                setSelectedBook(
                                                                    book
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <h4 className="create-book-catalog">
                                                            {book.catalog}
                                                        </h4>
                                                        <div className="create-book-image-div">
                                                            <img
                                                                alt="book"
                                                                className="create-book-image"
                                                                src={
                                                                    BASE_STATIC +
                                                                    book.picture_ref
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return '';
                                        })}
                                </div>
                            </>
                        )}
                        {selectedStage === 1 && (
                            <>
                                <div className="second-stage">
                                    <div className="create-top-buttons">
                                        <InputBox
                                            handleEditState={async (
                                                new_value
                                            ) => {
                                                setRelease(new_value);
                                                let updated_version =
                                                    await get_next_actual_version(
                                                        new_value
                                                    );
                                                if (updated_version) {
                                                    setVersion(updated_version);
                                                }
                                            }}
                                            editState={release}
                                            id="release"
                                            label="Vydání"
                                        />
                                        <InputBox
                                            handleEditState={setVersion}
                                            editState={version}
                                            id="version"
                                            label="Verze"
                                        />
                                        <div className="create-input-box">
                                            <label
                                                htmlFor="version"
                                                className="create-input-label"
                                            >
                                                Zvolená učebnice
                                            </label>
                                            <input
                                                className="create-input-number readonly"
                                                id="version"
                                                value={
                                                    selectedBook
                                                        ? selectedBook.catalog
                                                        : 'Nezvoleno'
                                                }
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            !iucFile
                                                ? 'dropdiv'
                                                : 'uploaded-file-div'
                                        }
                                    >
                                        <DragAndDrop
                                            setFile={setIucFile}
                                            file={iucFile}
                                            acceptFiles={['application/pdf']}
                                            textOnBox="Prosím přetáhněte PDF s učebnicí do tohoto prostoru. Nebo na toto pole klikněte a vyberte soubor."
                                        />
                                    </div>
                                    {iucFile && (
                                        <div
                                            className={
                                                !iucResultsFile
                                                    ? 'dropdiv'
                                                    : 'uploaded-file-div'
                                            }
                                        >
                                            <DragAndDrop
                                                setFile={setIucResultsFile}
                                                file={iucResultsFile}
                                                acceptFiles={[
                                                    'application/pdf',
                                                ]}
                                                textOnBox="Prosím přetáhněte PDF s řešením této učebnice.SOUBOR S ŘEŠENÍM MUSÍ MÍT STEJNÝ POČET STRÁNEK JAKO UČEBNICE SAMOTNÁ!"
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="create-buttons-bottom">
                            {selectedStage === 0 ? (
                                <div></div>
                            ) : (
                                <button
                                    className="btn btn-yellow"
                                    onClick={() =>
                                        setSelectedStage(selectedStage - 1)
                                    }
                                >
                                    Zpět
                                </button>
                            )}
                            {!selectedBook ||
                            selectedStage === NUM_OF_STAGES - 1 ? (
                                <div></div>
                            ) : (
                                <button
                                    className="btn btn-purple"
                                    onClick={handleNextButtonClick}
                                >
                                    Další krok
                                </button>
                            )}
                            {selectedStage === NUM_OF_STAGES - 1 && (
                                <button
                                    className="btn btn-purple"
                                    onClick={handleSubmitIuc}
                                    disabled={submittingForm}
                                >
                                    Nahrát učebnici
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {submittingForm && <FullScreenLoading />}
        </>
    );
};

export default CreateInteractive;
