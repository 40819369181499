import { create } from 'zustand';
import { extractValuesFromObject } from '../utils/objectUtils';
import MediaExcercisesService from '../services/media_excercises.service';
import { PAGE_ENUM } from '../pages/editor/Library/LibrarySwitches';
import InteractiveService from '../services/interactive.service';
import { bookInfoToRequest } from '../utils/translator';
export const useAssetStore = create((set) => ({
    images: [],
    videos: [],
    audios: [],
    excercises: [],
    loadingAssets: false,
    setImages: (images) =>
        set({ images: images.sort((a, b) => a.name.localeCompare(b.name)) }),
    setVideos: (videos) =>
        set({ videos: videos.sort((a, b) => a.name.localeCompare(b.name)) }),
    setAudios: (audios) =>
        set({ audios: audios.sort((a, b) => a.name.localeCompare(b.name)) }),
    setExcercises: (excercises) =>
        set({
            excercises: excercises.sort((a, b) => a.name.localeCompare(b.name)),
        }),
    setAssets: async (bookInfo, privateAxios) => {
        const bookObj = bookInfoToRequest(bookInfo);
        const data1 = await MediaExcercisesService.get_media(
            PAGE_ENUM.EXCERCISE,
            bookObj,
            privateAxios
        );
        const data2 = await InteractiveService.get_media(
            bookObj.bookid,
            bookObj.release,
            bookObj.version,
            privateAxios
        );
        const images_extracted = extractValuesFromObject(data2.assets, 'IMAGE');
        const videos_extracted = extractValuesFromObject(data2.assets, 'VIDEO');
        const audios_extracted = extractValuesFromObject(data2.assets, 'AUDIO');
        const excercisesFormated = extractValuesFromObject(data1.excercises);

        set({
            images: images_extracted,
            videos: videos_extracted,
            audios: audios_extracted,
            excercises: excercisesFormated,
        });
    },
    setLoadingAssets: (loadingAssets) => set({ loadingAssets }),
    clearAssets: () =>
        set({ images: [], videos: [], audios: [], excercises: [] }),
}));

const useAssetImages = () => {
    const images = useAssetStore((state) => state.images);
    const setImages = useAssetStore((state) => state.setImages);
    return [images, setImages];
};
const useAssetVideos = () => {
    const videos = useAssetStore((state) => state.videos);
    const setVideos = useAssetStore((state) => state.setVideos);
    return [videos, setVideos];
};
const useAssetAudios = () => {
    const audios = useAssetStore((state) => state.audios);
    const setAudios = useAssetStore((state) => state.setAudios);
    return [audios, setAudios];
};
const useAssetExcercises = () => {
    const excercises = useAssetStore((state) => state.excercises);
    const setExcercises = useAssetStore((state) => state.setExcercises);
    return [excercises, setExcercises];
};
const useLoadingAssets = () => {
    const loadingAssets = useAssetStore((state) => state.loadingAssets);
    const setLoadingAssets = useAssetStore((state) => state.setLoadingAssets);
    return [loadingAssets, setLoadingAssets];
};
export {
    useAssetImages,
    useAssetVideos,
    useAssetAudios,
    useAssetExcercises,
    useLoadingAssets,
};
