import React, { useEffect, useRef, useState } from "react";
import PopUp from "~/components/PopUp";
import OneLineInput from "~/components/OneLineInput/OneLineInput";
import { useBookForRequest } from "~/stores/interactiveBookStore";
import MediaExcercisesService from "~/services/media_excercises.service";
import { toast } from "react-toastify";
import toastSettings from "~/utils/toastSettings";
import { replaceButtonInButtonArray } from "~/utils/stateUtils";
import useAxiosPrivate from "~/hooks/useAxiosPrivate";
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import NextIcon from "~/static/images/next-white.png";

// SOURCE  MODEL OVERVIEW:
// bookid: str
// release: str
// version: str
// page_number: int
// video_id: str
// author: str | None
// source: str | None
// externalUrl: str | None
// button_id: str | None

const EditVideoSource = ({
	closeFunc,
	video,
	setVideos,
	selectedButton,
	setSavedButton,
	setButtons,
	pageNumber,
	moveToNext,
	moveToPrevious,
	disableCloseAfterSave,
}) => {
	const [autorName, setAutorName] = useState(video.author || "");
	const [sourceName, setSourceName] = useState(video.source || "");
	const [externalUrl, setExternalUrl] = useState(video.externalUrl || "");
	const [displayPopup, setDisplayPopup] = useState(false);

	const bookObject = useBookForRequest();
	const privateAxios = useAxiosPrivate();
	const popupRef = useRef(null);
	useEffect(() => {
		setAutorName(video.author || "");
		setSourceName(video.source || "");
		setExternalUrl(video.externalUrl || "");
	}, [video]);
	const handleClose = () => {
		popupRef.current.handleSlowClose();
	};
	const handleSave = async () => {
		const newSource = {
			...bookObject,
			page_number: pageNumber,
			video_id: selectedButton ? selectedButton.video._id : video._id,
			author: autorName,
			source: sourceName,
			externalUrl: externalUrl,
			button_id: selectedButton?.key || selectedButton?.id,
		};
		const response = await MediaExcercisesService.change_video_source(
			newSource,
			privateAxios
		);
		if (response.err) {
			console.log(response.detail);
			toast.error("Nepodařilo se uložit změny", toastSettings);
			return;
		}
		if (selectedButton && setButtons) {
			const newButton = {
				...selectedButton,
				video: {
					...response.button.video,
				},
			};
			setSavedButton(newButton);
			replaceButtonInButtonArray(setButtons, newButton);
		}
		setVideos(response.media);
		if (!disableCloseAfterSave) popupRef.current.handleSlowClose();
		else {
			toast.success("Zdroj byl úspěšně uložen", toastSettings);
		}
	};
	const switchGuard = (type) => {
		if (
			autorName !== (video.author || "") ||
			sourceName !== (video.source || "") ||
			externalUrl !== (video.externalUrl || "")
		) {
			setDisplayPopup(type);
			return;
		}
		if (type === "next") {
			moveToNext();
		} else {
			moveToPrevious();
		}
	};
	return (
		<PopUp ref={popupRef} closeFunc={closeFunc} color="white">
			<h2 className="header-4 font-weight-500 mg-b-1 centered">
				Zdroj videa: {video.name}
			</h2>
			<div className="flex-centered">
				<div className="video-edit-display mg-b-1">
					<VideoPlayer video={video} />
				</div>
			</div>
			<OneLineInput
				id="author"
				label="Autor"
				value={autorName}
				onChange={setAutorName}
			/>
			<OneLineInput
				id="source"
				label="Zdroj"
				value={sourceName}
				onChange={setSourceName}
			/>
			<OneLineInput
				id="externalUrl"
				label="Odkaz na stránku s videem"
				value={externalUrl}
				onChange={setExternalUrl}
			/>
			<div className="space-between">
				<button
					className="btn btn-yellow btn-smaller"
					onClick={handleClose}
				>
					Zrušit
				</button>
				<button
					className="btn btn-purple btn-smaller"
					onClick={handleSave}
				>
					Uložit
				</button>
			</div>
			{moveToPrevious && (
				<button
					className="left-center-absolute negative-one-left icon-button rotate-180 with-offset pink"
					onClick={() => switchGuard("previous")}
				>
					<img src={NextIcon} alt="next" />
				</button>
			)}
			{moveToNext && (
				<button
					className="right-center-absolute negative-one-right icon-button with-offset pink"
					onClick={() => switchGuard("next")}
				>
					<img src={NextIcon} alt="next" />
				</button>
			)}
			{displayPopup && (
				<PopUp closeFunc={() => setDisplayPopup(false)} color="white">
					<p className="body-1 centered mg-b-2">
						Neuložili jste změny provedené na tomto videu. Opravdu
						<br></br>
						chcete pokračovat na další video?
					</p>
					<div className="space-between">
						<button
							className="btn btn-purple btn-smaller"
							onClick={() => setDisplayPopup(false)}
						>
							Ne
						</button>
						<button
							className="btn btn-yellow btn-smaller"
							onClick={() => {
								setDisplayPopup(false);
								if (displayPopup === "next") {
									moveToNext();
								} else {
									moveToPrevious();
								}
							}}
						>
							Ano
						</button>
					</div>
				</PopUp>
			)}
		</PopUp>
	);
};

export default EditVideoSource;
