import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    GET_STATE,
    GET_STATE_CLASS,
    GET_TYPE_TEXT,
} from '../../utils/translator';
import classNames from 'classnames';
const OneLineInteractive = ({
    interactive_book,
    concrete_book,
    handleOnClick,
}) => {
    const [createdBy, setCreatedBy] = useState(null);
    const privateAxios = useAxiosPrivate();
    useEffect(() => {
        const getUser = async () => {
            const createdBy = await AuthService.get_user(
                concrete_book.created_by_id,
                privateAxios
            );
            setCreatedBy(createdBy.user);
        };
        getUser();
    }, [concrete_book, privateAxios]);

    return (
        <tr
            className={`interactive-row ${GET_STATE_CLASS(
                concrete_book.state
            )}`}
            onClick={handleOnClick}
        >
            <td>{interactive_book.catalog}</td>
            <td>{interactive_book.subject_id}</td>
            <td>{interactive_book.name}</td>
            <td>{concrete_book.release}</td>
            <td>
                {concrete_book.version_before_dot}.
                {concrete_book.version_after_dot}
            </td>
            <td>{GET_STATE(concrete_book.state)}</td>

            <td>{interactive_book.year}</td>
            <td>
                {createdBy?.first_name} {createdBy?.surname}
            </td>
            <td>{GET_TYPE_TEXT(interactive_book.book_type)}</td>
            <td
                className={classNames({
                    'private-note-column': concrete_book.private_note,
                })}
            >
                {concrete_book.private_note && (
                    <div className="private-note">
                        {concrete_book.private_note}
                    </div>
                )}
            </td>
        </tr>
    );
};

export default OneLineInteractive;
