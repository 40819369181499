import React from 'react';
import FolderJPG from '../../../../static/images/folder.png';
import HtmlJPG from '../../../../static/images/html-5.png';
import JavascriptJPG from '../../../../static/images/javascript-1.svg';
import JsonJPG from '../../../../static/images/folderjson.png';
import JpgJPG from '../../../../static/images/jpg.png';
import ZipJPG from '../../../../static/images/zip.png';

// Static element to show the structure of the excercise file .zip
const FileTreeExcercise = () => {
    return (
        <div className="tree">
            <ul>
                <li>
                    <img src={ZipJPG} className="folder-image" alt="folder" />
                    cviceni.zip
                </li>
                <ul>
                    <li>
                        <img
                            src={FolderJPG}
                            className="folder-image"
                            alt="folder"
                        />
                        cviceni
                    </li>
                    <ul>
                        <li>
                            <img
                                src={JsonJPG}
                                className="folder-image"
                                alt="folder"
                            />
                            ..soubory.json <span>- [Nepovinný]</span>
                        </li>
                        <li>
                            <img
                                src={JavascriptJPG}
                                className="folder-image"
                                alt="folder"
                            />
                            Preload.js <span>- [Nepovinný]</span>
                        </li>
                        <li>
                            <img
                                src={JavascriptJPG}
                                className="folder-image"
                                alt="folder"
                            />
                            Level.js
                            <span>- Hlavní Javascript Soubor</span>
                        </li>
                    </ul>
                    <li>
                        <img
                            src={JpgJPG}
                            className="folder-image"
                            alt="folder"
                        />
                        cviceni.jpg <span>- Miniatura cvičení</span>
                    </li>
                    <li>
                        <img
                            src={HtmlJPG}
                            className="folder-image"
                            alt="folder"
                        />
                        cviceni.html
                        <span>- HTML soubor s kostrou cvičení</span>
                    </li>
                </ul>
            </ul>
        </div>
    );
};

export default FileTreeExcercise;
