import React, { useRef, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "~/hooks/useAxiosPrivate";
import InteractiveService from "~/services/interactive.service";

import StaticButtonPage from "./StaticButtonPage";
import "../styles/editor/preview.css";
import {
	EDITOR_PAGES,
	useBackground,
	useFirstPageBackground,
	useInteractiveBookStore,
} from "~/stores/interactiveBookStore";
import EditorNavbar from "./EditorComponents/EditorNavbar";
import { extractBookInfo } from "~/utils/translator";
import DocumentPage from "./EditorComponents/DocumentPage";
import { setPageFiles } from "~/utils/path_utils";
import BottomLeftButtonStack from "./EditorComponents/BottomLeftButtonStack";
import { getPathPage } from "~/utils/path_utils";

const DEFAULT_RENDER_CANVAS = {
	value: false,
	dimensions: { width: 0, height: 0 },
};
const checkedPageNumber = (currentPage, maximumPage) => {
	const newNumber = currentPage % 2 === 0 ? currentPage - 1 : currentPage;
	if (currentPage > maximumPage) {
		return maximumPage - 2;
	}
	if (currentPage < 1) {
		return 1;
	}
	return newNumber;
};

const pdfContainerStyles = (showResults) => {
	return `pages-container${showResults ? " visible" : ""}`;
};

const InteractivePreview = () => {
	const { bookid, release, version } = useParams();
	const leftPageRef = useRef(null);
	const rightPageRef = useRef(null);
	const [, setBook] = useState(null);
	const [boundaries, setBoundaries] = useState({ left: 1, right: 1 });
	const privateAxios = useAxiosPrivate();
	const [pages, setPages] = useState(null);
	const [leftPage, setLeftPage] = useState(null);
	const [rightPage, setRightPage] = useState(null);
	const [leftResultPage, setLeftResultPage] = useState(null);
	const [rightResultPage, setRightResultPage] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [size, setSize] = useState(1.05);
	const [lastPage, setLastPage] = useState(null);
	const [showResults, setShowResults] = useState(false);
	const [buttonsVisible, setButtonsVisible] = useState(true);
	const [pageSize, setPageSize] = useState({ height: 0, width: 0 });
	const [searchParams, setSearchParams] = useSearchParams();
	const [background, setBackground] = useBackground();
	const [firstPageBg, setFirstPageBg] = useFirstPageBackground();
	const [padding, setPadding] = useState(null);
	const [canRenderCanvasLeft, setCanRenderCanvasLeft] = useState(
		DEFAULT_RENDER_CANVAS
	);
	const [canRenderCanvasRight, setCanRenderCanvasRight] = useState(
		DEFAULT_RENDER_CANVAS
	);
	const setCurrentEditorPage = useInteractiveBookStore(
		(state) => state.setCurrentEditorPage
	);
	const setGlobalBook = useInteractiveBookStore(
		(state) => state.setCompleteBook
	);
	const setGlobalCurrentPage = useInteractiveBookStore(
		(state) => state.setCurrentPage
	);

	useEffect(() => {
		if (bookid === "22-6162") {
			setSize(0.95);
		}
		setCurrentEditorPage(EDITOR_PAGES.PREVIEW);
		const getInteractive = async () => {
			const data = await InteractiveService.get_interactive(
				bookid,
				release,
				version,
				privateAxios
			);
			setPages(data.pages);
			setBook(data.book);
			if (boundaries.left === 1 && boundaries.right === 1) {
				setBoundaries({ left: 1, right: data.pages.length });
			}
			const pageFromPath = getPathPage(searchParams);
			const currentPageNum = checkedPageNumber(
				pageFromPath,
				data.pages.length
			);
			// filtering out only the current double page
			const filtered_double_page = data.pages.filter((cur_page) => {
				return (
					cur_page.id === currentPageNum ||
					cur_page.id === currentPageNum + 1
				);
			});
			// Setting left and right page
			setPageFiles(
				setLeftPage,
				setRightPage,
				setLeftResultPage,
				setRightResultPage,
				filtered_double_page
			);
			const bookInfo = extractBookInfo(data);
			setGlobalBook(
				bookInfo.bookid,
				bookInfo.release,
				bookInfo.version_before_dot,
				bookInfo.version_after_dot,
				currentPageNum
			);
		};
		getInteractive();
	}, []);
	useEffect(() => {
		const setSettings = async () => {
			// Get settings and set background
			const data = await InteractiveService.get_settings(
				bookid,
				release,
				version,
				privateAxios
			);
			if (data.settings?.background)
				setBackground(data.settings.background);
			if (data.settings?.padding) setPadding(data.settings.padding);
			if (data.settings?.first_background)
				setFirstPageBg(data.settings.first_background);
		};
		setSettings();
	}, []);
	useEffect(() => {
		const numberInUrl = getPathPage(searchParams);
		if (numberInUrl !== pageNumber) {
			resetPages();
			setPageNumber(numberInUrl);
			setGlobalCurrentPage(numberInUrl);
		}
	}, [searchParams]);
	useEffect(() => {
		if (!pages) return;
		const twoPages = pages.filter((value) => {
			return value.id === pageNumber || value.id === pageNumber + 1;
		});
		setPageFiles(
			setLeftPage,
			setRightPage,
			setLeftResultPage,
			setRightResultPage,
			twoPages
		);
		const numberInUrl = getPathPage(searchParams);
		if (numberInUrl !== pageNumber) {
			setSearchParams((prev) => ({ ...prev, page: pageNumber }));
		}
	}, [pageNumber]);
	const handlePreviousButton = async () => {
		if (pageNumber === 1) return;
		resetPages();
		pageNumber !== 1 && setPageNumber(pageNumber - 2);
		setGlobalCurrentPage(pageNumber - 2);
	};
	const handleNextButton = async () => {
		if (pageNumber + 2 <= boundaries.right) {
			resetPages();
			pageNumber + 2 <= boundaries.right && setPageNumber(pageNumber + 2);
			setGlobalCurrentPage(pageNumber + 2);
		}
	};
	const resetPages = () => {
		setCanRenderCanvasLeft(false);
		setCanRenderCanvasRight(false);
		setLastPage(null);
	};

	return (
		<div className="wholePageContainer">
			{/* <div className="preview-header">Právě jste v náhledovém režimu</div> */}
			<EditorNavbar
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				next={handleNextButton}
				previous={handlePreviousButton}
			/>
			<BottomLeftButtonStack
				setShowResults={setShowResults}
				setButtonsVisible={setButtonsVisible}
			/>
			<div className="preview-pdfs">
				{leftPage && rightPage && (
					<>
						<div
							className={`${pdfContainerStyles(!showResults)}`}
							style={{
								backgroundColor: background,
							}}
						>
							<div
								className="one-page left"
								ref={leftPageRef}
								style={{
									...padding,
									backgroundColor:
										pageNumber === 1 ? firstPageBg : "#fff",
								}}
							>
								<DocumentPage
									file={leftPage}
									pageSide={"left"}
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setCanRenderCanvasLeft}
									loader={true}
									setPageSize={setPageSize}
									pageRef={leftPageRef}
								/>
							</div>
							<div
								className="one-page right"
								ref={rightPageRef}
								style={padding}
							>
								<DocumentPage
									file={rightPage}
									pageSide={"right"}
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setCanRenderCanvasRight}
									loader={true}
									setPageSize={setPageSize}
									pageRef={rightPageRef}
								/>
							</div>
						</div>
						<div
							className={`${pdfContainerStyles(showResults)}`}
							style={{
								backgroundColor: background,
							}}
						>
							<div
								className="one-page left"
								style={{
									...padding,
									backgroundColor:
										pageNumber === 1 ? firstPageBg : "#fff",
								}}
							>
								<DocumentPage
									file={leftResultPage}
									pageSide="left"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setCanRenderCanvasLeft}
									loader={true}
									setPageSize={setPageSize}
									pageRef={rightPageRef}
								/>
							</div>
							<div className="one-page right" style={padding}>
								<DocumentPage
									file={rightResultPage}
									pageSide="right"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setCanRenderCanvasRight}
								/>
							</div>
						</div>
					</>
				)}
			</div>

			<div className="preview-canvases">
				{canRenderCanvasRight && canRenderCanvasLeft && (
					<>
						<StaticButtonPage
							height={pageSize.height}
							width={pageSize.width}
							get_buttons={async () => {
								const buttons =
									await InteractiveService.get_buttons(
										bookid,
										release,
										version,
										pageNumber,
										privateAxios
									);
								return buttons;
							}}
							lastPage={lastPage}
							setLastPage={setLastPage}
							page={pageNumber}
							showButtons={buttonsVisible}
						/>
						<StaticButtonPage
							height={pageSize.height}
							width={pageSize.width}
							get_buttons={async () => {
								const buttons =
									await InteractiveService.get_buttons(
										bookid,
										release,
										version,
										pageNumber + 1,
										privateAxios
									);
								return buttons;
							}}
							lastPage={lastPage}
							setLastPage={setLastPage}
							page={pageNumber + 1}
							showButtons={buttonsVisible}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default InteractivePreview;

/**
 * 
 * 
 * 
 * <div className="pages-control centered">
                <button
                    className="icon-button blue"
                    onClick={() => {
                        handlePreviousButton();
                    }}
                    disabled={pageNumber === 1}
                >
                    <img src={LeftImg} alt="Left arrow" />
                </button>
                <div className="page-numbers">
                    <div className="page-number">{pageNumber}</div>
                    <div className="page-number">|</div>
                    <div className="page-number">{pageNumber + 1}</div>
                </div>
                <button
                    className="icon-button blue"
                    onClick={() => {
                        handleNextButton();
                    }}
                >
                    <img src={RightImg} alt="Right arrow" />
                </button>
            </div>
 */
