import React, { useState } from 'react';
import { assetToPath } from '~/utils/path_utils';
import PopUpConfirm from '~/components/PopUpConfirm';
import CrossIcon from '~/static/images/delete_white.png';
import EditIcon from '~/static/images/pencil.png';
import ArrowPNG from '~/static/images/right_arrow_white.png';
import HeaderIcon from '~/static/images/heading.png';
import TextIcon from '~/static/images/text.png';
import ButtonsService from '~/services/buttons.service';
import { toast } from 'react-toastify';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { replaceButtonInButtonArray } from '~/utils/stateUtils';

const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const getColorOfHeader = (picture) => {
    if (picture.name.length === 0) return 'red';
    return 'green';
};
const getColorOfDescription = (picture) => {
    if (picture.description.length === 0) return 'red';
    if (picture.description === 'empty') return 'red';
    return 'green';
};
const getTitleOfHeader = (picture) => {
    if (picture.name.length === 0) return 'Název obrázku není vyplněn';
    return 'Název obrázku je vyplněn';
};
const getTitleOfDescription = (picture) => {
    if (picture.description.length === 0) return 'Popis obrázku není vyplněn';
    if (picture.description === 'empty') return 'Popis obrázku není vyplněn';
    return 'Popis obrázku je vyplněn';
};
// Single image that is visible when button of type image is SELECTED
// Provides funcionality for: Editing header and description, and swapping the images positions
const ChosenImageThumnail = ({
    picture,
    index,
    first,
    last,
    handle_swap_images,
    bookInfo,
    pageNumber,
    savedButton,
    setSavedButton,
    setButtons,
    setImages,
    setEdittingImage,
}) => {
    const [deletingImage, setDeletingImage] = useState(null);

    const axiosPrivate = useAxiosPrivate();

    const deleteImage = async (image) => {
        const data = {
            button_id: savedButton.key,
            image_id: image._id,
            page_number: pageNumber,
        };
        const response = await ButtonsService.remove_image_from_button(
            bookInfo,
            data,
            axiosPrivate
        );
        if (response.err) {
            toast.error('Nepodařilo se smazat obrázek.', toastError);
            return;
        }
        const new_button = { ...savedButton, pictures: response.pictures };
        setSavedButton(new_button);
        replaceButtonInButtonArray(setButtons, new_button);
        setImages(response.media);
        toast.success('Obrázek byl úspěšně smazán.', toastError);
    };

    return (
        <>
            <div className="image-div">
                <div
                    className="image-div-cover"
                    onClick={() => {
                        setEdittingImage({
                            image: picture,
                            index,
                            focusOnHeader: false,
                            focusOnDescription: false,
                        });
                    }}
                >
                    <img
                        className="image-div-thumbnail"
                        src={assetToPath(picture)}
                        alt="thumnail"
                    />
                </div>

                {/* DELETING BUTTON */}
                <button
                    onClick={() => {
                        setDeletingImage(picture);
                    }}
                    className="close-btn top-right"
                    title="Odstranit obrázek od tlačítka."
                >
                    <img src={CrossIcon} alt="Cross icon" />
                </button>
                {/* EDITTIING BUTTON */}
                <button
                    onClick={() => {
                        setEdittingImage({
                            image: picture,
                            index,
                            focusOnHeader: false,
                            focusOnDescription: false,
                        });
                    }}
                    className="edit-btn bottom-right"
                >
                    <img src={EditIcon} alt="Edit icon" />
                </button>
                {/* Conditionally rendering arrow UP */}
                {!first && (
                    <button
                        className="icon-button text top-center library-select rotate-90-left smaller btn-wider"
                        title="Přesunout o úroveň výše"
                        onClick={() => {
                            handle_swap_images(index, index - 1);
                        }}
                    >
                        <img src={ArrowPNG} alt="nahoru" />
                    </button>
                )}
                {/* Conditionally rendering arrow DOWN */}
                {!last && (
                    <button
                        className="icon-button text bottom-center library-select rotate-90-right smaller btn-wider"
                        title="Přesunout o úroveň níže"
                        onClick={() => {
                            handle_swap_images(index, index + 1);
                        }}
                    >
                        <img src={ArrowPNG} alt="dolů" />
                    </button>
                )}
                <div className="top-left-float stack-vertically">
                    <button
                        className={`icon-button smaller ${getColorOfHeader(
                            picture
                        )}`}
                        title={getTitleOfHeader(picture)}
                        onClick={() => {
                            setEdittingImage({
                                image: picture,
                                index,
                                focusOnHeader: true,
                                focusOnDescription: false,
                            });
                        }}
                    >
                        <img src={HeaderIcon} alt="Ikona headeru" />
                    </button>
                    <button
                        className={`icon-button smaller ${getColorOfDescription(
                            picture
                        )}`}
                        title={getTitleOfDescription(picture)}
                        onClick={() => {
                            setEdittingImage({
                                image: picture,
                                index,
                                focusOnHeader: false,
                                focusOnDescription: true,
                            });
                        }}
                    >
                        <img src={TextIcon} alt="Ikona textu" />
                    </button>
                </div>
            </div>

            {deletingImage && (
                <PopUpConfirm
                    confirmFunction={() => {
                        deleteImage(deletingImage);
                        setDeletingImage(null);
                    }}
                    closeFunction={() => setDeletingImage(null)}
                    question={
                        'Opravdu si přejete smazat obrázek od tohoto tlačítka?'
                    }
                />
            )}
        </>
    );
};

export default ChosenImageThumnail;
