import React from "react";
import "./SourceButton.css";
import GlobalSearchIcon from "../../../static/images/global-search.png";
import { Tooltip } from "react-tippy";
import PopUp from "../../../components/PopUp";
import { isUrlValid } from "../../../utils/path_utils";
import { shortenText } from "../../../utils/translator";
const shouldViewButton = (video) => {
	return video.author || video.source || video.externalUrl;
};
const SourceButton = ({ video, click, onClose }) => {
	const [sourceOpen, setSourceOpen] = React.useState(false);
	const handleClose = () => {
		setSourceOpen(false);
		onClose && onClose();
	};
	const sourceButtonClicked = () => {
		click && click(video);
		setSourceOpen(true);
	};
	return shouldViewButton(video) ? (
		<>
			<Tooltip
				title="Zdroj"
				position="left"
				className="top-left-absolute"
			>
				<button className="source-button" onClick={sourceButtonClicked}>
					<img
						id="source-button-icon"
						src={GlobalSearchIcon}
						alt="source"
					/>
				</button>
			</Tooltip>
			{sourceOpen && (
				<PopUp closeFunc={handleClose} additionalClassnames="white-pop">
					{video.author && (
						<p className="source-text">
							<strong>Autor: </strong>
							{video.author}
						</p>
					)}
					{video.source && (
						<p className="source-text">
							<strong>Zdroj: </strong>
							{isUrlValid(video.source) ? (
								<a
									href={video.source}
									target="_blank"
									rel="noopener noreferrer"
								>
									{video.source}
								</a>
							) : (
								video.source
							)}
						</p>
					)}
					{video.externalUrl && (
						<p className="source-text">
							<strong>Odkaz na video: </strong>
							{isUrlValid(video.externalUrl) ? (
								<a
									href={video.externalUrl}
									target="_blank"
									rel="noopener noreferrer"
									title={video.externalUrl}
								>
									{shortenText(video.externalUrl, 50)}
								</a>
							) : (
								video.externalUrl
							)}
						</p>
					)}
				</PopUp>
			)}
		</>
	) : null;
};

export default SourceButton;
