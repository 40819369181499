import React, { useEffect, useRef, useState } from "react";
import "../styles/editor/zoom.css";

import BookPages from "./EditorComponents/BookPages";
import EditorNavbar from "./EditorComponents/EditorNavbar";
import BottomLeftButtonStack from "./EditorComponents/BottomLeftButtonStack";
import SimpleButtonPage from "./EditorComponents/SimpleButtonPage";
import InteractiveService from "~/services/interactive.service";
import useAxiosPrivate from "~/hooks/useAxiosPrivate";

import { useParams } from "react-router-dom";
import {
	EDITOR_PAGES,
	useBackground,
	useFirstPageBackground,
	useInteractiveBookStore,
	usePageNumber,
} from "~/stores/interactiveBookStore";
import ZoomPages from "./EditorComponents/ZoomPages";
import { extractNumberFromPadding } from "~/utils/objectUtils";

const checkPageSize = (pageSize) => {
	console.log("Calling chekPagesize: ", pageSize.width, pageSize.height);

	return pageSize.height > 0 && pageSize.width > 0;
};
const ZoomEditor = () => {
	const [pageSize, setPageSize] = useState({ height: 0, width: 0 });
	const { bookid, release, version } = useParams();
	const [pageNumber, setPageNumber] = usePageNumber(1);
	const [firstPageBg, setFirstPageBg] = useFirstPageBackground();
	const bookRef = useRef(null);
	const [buttonsVisible, setButtonsVisible] = useState(true);
	const [leftPageVisible, setLeftPageVisible] = useState(true);
	const [rightPageVisible, setRightPageVisible] = useState(true);
	const [padding, setPadding] = useState(null);
	const [padd, setPadd] = useState({ pT: 0, pB: 0, pL: 0, pR: 0 });
	const setCurrentEditorPage = useInteractiveBookStore(
		(state) => state.setCurrentEditorPage
	);
	const [background, setBackground] = useBackground();
	const setCompleteBook = useInteractiveBookStore(
		(state) => state.setCompleteBook
	);
	const privateAxios = useAxiosPrivate();

	useEffect(() => {
		const setSettings = async () => {
			// Get settings and set background
			const data = await InteractiveService.get_settings(
				bookid,
				release,
				version,
				privateAxios
			);
			if (data.settings?.background)
				setBackground(data.settings.background);
			if (data.settings?.padding) {
				setPadding(data.settings.padding);
				const pT = extractNumberFromPadding(
					data.settings.padding.paddingTop
				);
				const pB = extractNumberFromPadding(
					data.settings.padding.paddingBottom
				);
				const pL = extractNumberFromPadding(
					data.settings.padding.paddingLeft
				);
				const pR = extractNumberFromPadding(
					data.settings.padding.paddingRight
				);
				setPadd({ pT, pB, pL, pR });
			}
			if (data.settings?.first_background)
				setFirstPageBg(data.settings.first_background);
		};
		setSettings();
		setCurrentEditorPage(EDITOR_PAGES.ZOOMS);
		const version_before_dot = version.split("_")[0];
		const version_after_dot = version.split("_")[1];
		setCompleteBook(
			bookid,
			release,
			version_before_dot,
			version_after_dot,
			1
		);
		console.log("Calling the useEffect");
	}, [
		bookid,
		release,
		version,
		setBackground,
		setCompleteBook,
		setCurrentEditorPage,
		privateAxios,
		setFirstPageBg,
	]);
	const handleNextButton = () => {
		if (bookRef.current) bookRef.current.handleNextButton();
	};
	const handlePreviousButton = () => {
		if (bookRef.current) bookRef.current.handlePreviousButton();
	};
	const handleShowResults = (value) => {
		if (bookRef.current) bookRef.current.setShowResults(value);
	};

	return (
		<div className="wholePageContainer">
			<EditorNavbar
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				next={handleNextButton}
				previous={handlePreviousButton}
			/>
			<BookPages
				ref={bookRef}
				bookid={bookid}
				release={release}
				version={version}
				setPageSize={setPageSize}
				setLeftPageLoaded={setLeftPageVisible}
				setRightPageLoaded={setRightPageVisible}
				padding={padding}
				background={background}
				firstPageBg={firstPageBg}
			/>
			<BottomLeftButtonStack
				setShowResults={handleShowResults}
				setButtonsVisible={setButtonsVisible}
			/>
			{/* Buttons showing */}
			{leftPageVisible && rightPageVisible && checkPageSize(pageSize) && (
				<div className="centered-canvases button-pages">
					<SimpleButtonPage
						bookid={bookid}
						release={release}
						version={version}
						page={pageNumber}
						width={pageSize.width + padd.pL + padd.pR}
						height={pageSize.height + padd.pT + padd.pB}
						buttonsVisible={buttonsVisible}
					/>
					<SimpleButtonPage
						bookid={bookid}
						release={release}
						version={version}
						page={pageNumber + 1}
						width={pageSize.width + padd.pL + padd.pR}
						height={pageSize.height + padd.pT + padd.pB}
						buttonsVisible={buttonsVisible}
					/>
				</div>
			)}
			{/* Zoom maker */}
			{leftPageVisible && rightPageVisible && checkPageSize(pageSize) && (
				<ZoomPages
					width={pageSize.width}
					height={pageSize.height}
					padding={padd}
				/>
			)}
		</div>
	);
};

export default ZoomEditor;
