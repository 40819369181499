import React, { useEffect, useRef, useState } from 'react';
import DropAnimation from './DropAnimation/DropAnimation';
import BetterModal from './BetterModal/BetterModal';
import { toast } from 'react-toastify';
import toastSettings from '../utils/toastSettings';
import MediaExcercisesService from '../services/media_excercises.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import '../pages/styles/editor/status.css';
import { FILE_TYPE_TO_FILE_EXTENSION } from '../utils/translator';
import FullScreenLoading from './FullScreenLoading';
import FileRow from './UploadFileComp/FileRow';
import { checkZipForExcercises } from '../utils/path_utils';
import SvgIcon from './SvgIcon/SvgIcon';
import SvgIconType from './SvgIcon/SvgIconType';
import { Tooltip } from 'react-tippy';

const progress_bar = (file, now_uploading, percents, uploaded_files) => {
    if (uploaded_files.includes(file.name)) {
        return 100;
    }
    return file.name === now_uploading ? Math.ceil(percents * 100) : 0;
};
const DragAndDropNoStyle = ({
    accept_types,
    collection,
    addToCollection,
    clickHandler,
    type,
    children,
    setCollection,
    additionalClasses,
    excercises,
}) => {
    const [dragging, setDragging] = useState(false);
    const [draggingSomewhere, setDraggingSomewhere] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [sending, setSending] = useState(false);
    const [files, setFiles] = useState([]);
    const [nowUploading, setNowUploading] = useState(null);
    const [percents, setPercents] = useState(0);
    const [fileExtensions, setFileExtensions] = useState([]);
    const privateAxios = useAxiosPrivate();
    const book = useParams();
    const inputRef = useRef();
    const [alreadyUploaded, setAlreadyUploaded] = useState([]);
    const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);

    useEffect(() => {
        function handleDragging(e) {
            e.preventDefault();
            setDraggingSomewhere(true);
        }
        function handleDragLeave(e) {
            e.preventDefault();

            if (e.relatedTarget === null) setDraggingSomewhere(false);
        }
        function handleDrop(e) {
            e.preventDefault();
        }
        document.addEventListener('dragover', handleDragging);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('drop', handleDrop);
        return () => {
            document.removeEventListener('dragover', handleDragging);
            document.removeEventListener('dragleave', handleDragLeave);
            document.removeEventListener('drop', handleDrop);
        };
    }, []);
    useEffect(() => {
        setFileExtensions(
            accept_types.map((type) => FILE_TYPE_TO_FILE_EXTENSION(type))
        );
    }, [accept_types]);
    const handleDrop = async (e) => {
        e.preventDefault();
        setDraggingSomewhere(false);
        let accepting_files = [];
        let rejecting_files = [];
        const files = Array.from(e.dataTransfer.files);

        for (const file of files) {
            if (!file.type && file.name.split('.').length > 1) {
                // If the file has no type, but has a name wit h a dot
                const extension = file.name.split('.').pop();
                if (fileExtensions.includes(extension)) {
                    accepting_files.push(file);
                } else {
                    rejecting_files.push(file);
                }
                continue;
            }
            if (accept_types.includes(file.type)) {
                accepting_files.push(file);
            } else {
                rejecting_files.push(file);
                console.log(file.type);
            }
        }

        if (accepting_files.length === 0) {
            toast.error('Soubor/y nejsou podporovány', toastSettings);
            return;
        }
        const alreadyInNames = collection.map((file) => file.name);
        const new_files = accepting_files.map((file) => {
            file.alreadyIn = alreadyInNames.includes(file.name);
            file.uploaded = true;
            return file;
        });
        if (excercises) {
            for (let i = 0; i < new_files.length; i++) {
                const checkZip = await checkZipForExcercises(new_files[i], -1);
                if (checkZip.error) {
                    toast.error(checkZip.msg, toastSettings);
                    return;
                }
            }
        }

        setFiles(new_files);
        setOpenedModal(true);
    };
    const handleRemoveFileFromList = (file) => {
        const new_files = files.filter((f) => f !== file);
        if (new_files.length === 0) {
            setOpenedModal(false);
        }
        setFiles(new_files);
    };
    const handleConfirmUpload = async () => {
        setSending(true);
        for (let i = 0; i < files.length; i++) {
            /* Creating the data to send */
            const formData = new FormData();
            formData.append(
                'info',
                JSON.stringify({
                    bookid: book.bookid,
                    release: book.release,
                    version: book.version,
                    type: type,
                })
            );
            formData.append('files', files[i]);
            // Marking uploading file - because of progress bar
            setNowUploading(files[i].name);
            setPercents(0);
            const response = await MediaExcercisesService.upload_media(
                formData,
                privateAxios,
                (percentage) => {
                    console.log(files[i].name, 'Percentage: ', percentage);
                    setPercents(percentage);
                    if (percentage === 1 && i === files.length - 1) {
                        setShowFullScreenLoader(true);
                    }
                }
            );
            setAlreadyUploaded((prev) => [...prev, files[i].name]);
            if (
                response?.data?.notUploaded &&
                response?.data?.notUploaded.length > 0
            ) {
                toast.error(
                    'Soubor s názvem: ' +
                        response?.data?.notUploaded[0] +
                        ' nebyl nahrán, jelikož soubor s tímto názvem již existuje.',
                    toastSettings
                );
                return;
            }

            setCollection(response.data.media);
        }
        toast.success('Soubory byly úspěšně nahrány', toastSettings);
        handleCloseModal();
    };
    const handleCloseModal = () => {
        setSending(false);
        setOpenedModal(false);
        setFiles([]);
        setNowUploading(null);
        setPercents(0);
        setAlreadyUploaded([]);
        setShowFullScreenLoader(false);
    };
    const confirmRewrite = (rewrite_file) => {
        const new_files = files.map((file) => {
            if (rewrite_file.name === file.name) {
                file.alreadyIn = false;
            }
            return file;
        });
        setFiles(new_files);
    };
    const confirmRewriteAll = () => {
        const new_files = files.map((file) => {
            file.alreadyIn = false;
            return file;
        });
        setFiles(new_files);
    };
    return (
        <div
            className={additionalClasses}
            onDragOver={(e) => {
                e.preventDefault();
                setDragging(true);
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                setDragging(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();

                if (e.relatedTarget === null) {
                    setDragging(false);
                }
            }}
            onDrop={handleDrop}
        >
            <input type="file" ref={inputRef} style={{ display: 'none' }} />
            <DropAnimation
                dragging_somewhere={draggingSomewhere}
                dragging_over={dragging}
                type={type}
            />

            {children}
            <BetterModal
                opened={openedModal}
                closeModal={handleCloseModal}
                acceptModal={handleConfirmUpload}
                header="Potvrďte prosím nahrání těchto souborů:"
                hideClose={sending}
                disableConfirm={sending || files.some((file) => file.alreadyIn)}
            >
                {files.some((file) => file.alreadyIn) && (
                    <div className="already-in-div mg-b-2">
                        <p className="body-4 font-weight-600 red">
                            Některé soubory se již v knihovně nacházejí. Můžete
                            potrvdit/smazat soubory jednotlivě kliknutím na
                            tlačítko vykřičníku nebo popelnice, nebo klikněte na
                            tlačítko vpravo a tím potvrdíte vše.
                        </p>
                        <Tooltip title="Přepsat všechny" position="left">
                            <button
                                className="icon-button green"
                                onClick={confirmRewriteAll}
                            >
                                <SvgIcon
                                    type={SvgIconType.check}
                                    size="medium"
                                />
                            </button>
                        </Tooltip>
                    </div>
                )}
                {files.map((file, index) => (
                    <FileRow
                        key={index}
                        file={file}
                        removeFromList={() => handleRemoveFileFromList(file)}
                        uploading={
                            file.name === nowUploading ||
                            alreadyUploaded.includes(file.name)
                        }
                        uploadingPercents={progress_bar(
                            file,
                            nowUploading,
                            percents,
                            alreadyUploaded
                        )}
                        allAreUploading={sending}
                        alreadyIn={file.alreadyIn}
                        setConfirmedRewrite={confirmRewrite}
                    />
                ))}
            </BetterModal>
            {showFullScreenLoader && <FullScreenLoading />}
        </div>
    );
};

export default DragAndDropNoStyle;
