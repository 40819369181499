const paths = {
	GET_INTERACTIVE: (book_catalog, release_number, version) =>
		`/interactive/${book_catalog}/${release_number}/${version}`,
	ADD_PRIVATE_NOTE: () => `/interactive/private_note`,
	ADD_DESCRIPTION: () => `/interactive/description`,
	CATALOG_AND_RELEASE: (book_catalog, release_number) =>
		`/interactive/${book_catalog}/${release_number}`,
	REMOVE_VERSION: (book_catalog, release_number, version) =>
		`/interactive/${book_catalog}/${release_number}/${version}`,
	GET_BUTTONS: (book_catalog, release_number, version, page) =>
		`/interactive/buttons/${book_catalog}/${release_number}/${version}/${page}`,
	GET_EXCERCISES: (book_catalog, release_number, version) =>
		`/interactive/excercies-for-book/${book_catalog}/${release_number}/${version}`,
	GET_MEDIA: (book_catalog, release_number, version) =>
		`/interactive/media-for-book/${book_catalog}/${release_number}/${version}`,
	GET_EXCERCISE_FOR_BUTTON: (
		book_catalog,
		release_number,
		version,
		pageNumber,
		button_id
	) =>
		`/interactive/visualize-excercise/${book_catalog}/${release_number}/${version}/${pageNumber}/${button_id}`,
	GET_SETTINGS: (book_id, release, version) =>
		`/interactive/get-settings/${book_id}/${release}/${version}`,
	CHANGE_BACKGROUND: () => `/interactive/change-background`,
	CHANGE_FIRST_PAGE_BACKGROUND: () =>
		`/interactive/change-first-page-background`,
	CHANGE_PADDING: () => `/interactive/change-padding`,
};

class InteractiveService {
	static async get_interactive(
		book_catalog,
		release_number,
		version,
		axios_instance
	) {
		const path = paths.GET_INTERACTIVE(
			book_catalog,
			release_number,
			version
		);
		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return error;
		}
	}
	static async get_info_about_release(
		book_catalog,
		release_number,
		axios_instance
	) {
		const path = paths.CATALOG_AND_RELEASE(book_catalog, release_number);
		try {
			const response = await axios_instance.get(path);
			const new_data = response.data.sort((a, b) => {
				if (a.version_before_dot !== b.version_before_dot) {
					return a.version_before_dot - b.version_before_dot;
				} else {
					return a.version_after_dot - b.version_after_dot;
				}
			});
			return new_data;
		} catch (error) {
			return error;
		}
	}
	static async get_settings(book_id, release, version, axios_instance) {
		const path = paths.GET_SETTINGS(book_id, release, version);
		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async change_background(data, type, axios_instance) {
		let path = paths.CHANGE_BACKGROUND();
		if (type === 1) {
			path = paths.CHANGE_FIRST_PAGE_BACKGROUND();
		}
		try {
			const response = await axios_instance.post(path, data);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}

	static async change_padding(data, axios_instance) {
		const path = paths.CHANGE_PADDING();
		try {
			const response = await axios_instance.post(path, data);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async remove_book(
		book_catalog,
		release_number,
		version,
		axios_instance
	) {
		const path = paths.REMOVE_VERSION(
			book_catalog,
			release_number,
			version
		);
		try {
			const response = await axios_instance.delete(path);

			return response;
		} catch (err) {
			if (!err?.response) {
				return { err: true, msg: "Server nedpověděl." };
			} else if (err.response?.status === 403) {
				return {
					err: true,
					msg: "Tato verze knihy k sobě nemá přiřazené žádné PDF.",
				};
			} else if (err.response?.status === 404) {
				console.log(err.response.detail);
				return { err: true, msg: "Verze nebyla v databázi nalezena." };
			}
		}
	}
	static async add_private_note(data, axios_instance) {
		const path = paths.ADD_PRIVATE_NOTE();
		try {
			const response = await axios_instance.post(path, data);
			return response;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async add_description(data, axios_instance) {
		const path = paths.ADD_DESCRIPTION();
		try {
			const response = await axios_instance.post(path, data);
			return response;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async get_buttons(
		book_catalog,
		release_number,
		version,
		page,
		axios_instance
	) {
		const path = paths.GET_BUTTONS(
			book_catalog,
			release_number,
			version,
			page
		);

		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async get_excercies(
		book_catalog,
		release_number,
		version,
		axios_instance
	) {
		const path = paths.GET_EXCERCISES(
			book_catalog,
			release_number,
			version
		);

		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async get_media(
		book_catalog,
		release_number,
		version,
		axios_instance
	) {
		const path = paths.GET_MEDIA(book_catalog, release_number, version);

		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
	static async get_excercise_for_button(
		book_obj,
		button_id,
		page_number,
		axios_instance
	) {
		const path = paths.GET_EXCERCISE_FOR_BUTTON(
			book_obj.bookid,
			book_obj.release,
			book_obj.version,
			page_number,
			button_id
		);
		try {
			const response = await axios_instance.get(path);
			return response.data;
		} catch (error) {
			return { err: true, detail: error };
		}
	}
}
export default InteractiveService;
