import React, { useCallback, useEffect, useState } from 'react';
import MediaExcercisesService from '../../../services/media_excercises.service';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { PAGE_ENUM } from './LibrarySwitches';
import { toast } from 'react-toastify';
import toastSettings from '../../../utils/toastSettings';
import DragAndDropNoStyle from '../../../components/DragAndDropNoStyle';
import FlexibleLoader from '../../../components/FlexibleLoader/FlexibleLoader';
import AudioElem from './elems/AudioElem';
import PopUpConfirm from '../../../components/PopUpConfirm';
import { shouldView } from '../../../utils/stateUtils';
import { extractValuesFromObject } from '../../../utils/objectUtils';
import { useAssetAudios, useLoadingAssets } from '../../../stores/assetStore';
import BottomAudioPlayer from '~/components/BottomAudioPlayer';

const AUDIO_TYPES = ['audio/mpeg', 'audio/wav', 'audio/ogg'];

const AudiosPage = ({ book, reAsk, search }) => {
    const privateAxios = useAxiosPrivate();
    const [audios, setAudios] = useAssetAudios();
    const [loading, setLoading] = useLoadingAssets();
    const [removingAudio, setRemovingAudio] = useState(null);
    const [playingAudio, setPLayingAudio] = useState(null);

    const get_audios = useCallback(async () => {
        const audio_response = await MediaExcercisesService.get_media(
            PAGE_ENUM.AUDIO,
            book,
            privateAxios
        );
        if (audio_response.err) {
            toast.error('Nastala chyba při načítání audia', toastSettings);
            return;
        }
        if (!audio_response.result) {
            toast.error(audio_response.message, toastSettings);
            return;
        }
        const new_audios = extractValuesFromObject(audio_response.audios).sort(
            (a, b) => a.name.localeCompare(b.name)
        );
        setAudios(new_audios);
        setLoading(false);
    }, [book, privateAxios]);

    useEffect(() => {
        if (reAsk) get_audios();
    }, [reAsk, get_audios]);

    const removeAudio = async () => {
        if (removingAudio.buttons && removingAudio.buttons.length > 0) {
            toast.error(
                'Nelze smazat obrázek, který je přiřazen k nějakému tlačítku',
                toastSettings
            );
            return;
        }
        const remove_obj = {
            ...book,
            asset_id: removingAudio.key,
        };
        const result = await MediaExcercisesService.remove_asset(
            remove_obj,
            false,
            privateAxios
        );
        if (result.err) {
            toast.error('Nastala chyba při mazání audia', toastSettings);
            return;
        }
        setAudios(audios.filter((audio) => audio.key !== removingAudio.key));
        setRemovingAudio(null);
        toast.success('Audio bylo úspěšně smazáno', toastSettings);
    };
    const handlePlayAudio = (audioObj) => {
        setPLayingAudio({
            audio: audioObj,
        });
    };
    return (
        <>
            <DragAndDropNoStyle
                type={PAGE_ENUM.AUDIO}
                accept_types={AUDIO_TYPES}
                additionalClasses={'library-drag-drop'}
                collection={audios}
                setCollection={setAudios}
            >
                {loading ? (
                    <FlexibleLoader />
                ) : audios.length === 0 ? (
                    <div>Žádné audio</div>
                ) : (
                    <div className="library-content content-5">
                        {audios.map(
                            (audio) =>
                                shouldView(audio, search) && (
                                    <AudioElem
                                        audio={audio}
                                        key={audio.key}
                                        removeFunc={() =>
                                            setRemovingAudio(audio)
                                        }
                                        book={book}
                                        playAudio={handlePlayAudio}
                                    />
                                )
                        )}
                    </div>
                )}
            </DragAndDropNoStyle>
            {removingAudio && (
                <PopUpConfirm
                    closeFunction={() => setRemovingAudio(null)}
                    confirmFunction={removeAudio}
                    question={'Doopravdy chcete vymazat toto audio?'}
                />
            )}
            {playingAudio && (
                <BottomAudioPlayer
                    button={playingAudio}
                    closeFunction={() => setPLayingAudio(null)}
                />
            )}
        </>
    );
};

export default AudiosPage;
