import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import classNames from "classnames";
import ReactDom from "react-dom";
import TopRightCloseBtn from "./TopRightCloseBtn";

const PopUp = forwardRef(
	(
		{
			children,
			closeFunc,
			additionalClassnames,
			overlayClose,
			minHeight,
			color,
		},
		ref
	) => {
		const [closing, setClosing] = useState(false);
		useEffect(() => {
			// Check if the user pressed the escape key
			const handleEsc = (event) => {
				if (event.keyCode === 27) {
					handleSlowClose();
				}
			};
			window.addEventListener("keydown", handleEsc);
			return () => {
				window.removeEventListener("keydown", handleEsc);
			};
		}, [closeFunc]);
		const handleSlowClose = () => {
			setClosing(true);
			setTimeout(() => {
				setClosing(false);
				if (closeFunc) closeFunc();
			}, 600);
		};
		useImperativeHandle(ref, () => ({
			handleSlowClose,
		}));
		return ReactDom.createPortal(
			<div
				className={classNames("body-overlay", { closing })}
				onClick={() => {
					overlayClose && handleSlowClose();
				}}
			>
				<div
					className={classNames("body-center", additionalClassnames, {
						closing,
						[color]: color,
					})}
					onClick={(e) => {
						e.stopPropagation();
					}}
					style={
						minHeight
							? {
									minHeight: minHeight,
							  }
							: {}
					}
				>
					<TopRightCloseBtn closeFunc={handleSlowClose} />
					{children}
				</div>
			</div>,
			document.body
		);
	}
);

export default PopUp;
