import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../pages/styles/navbar.css';
import '../pages/styles/buttons.css';
import useLogout from '../hooks/useLogout';
const Navbar = () => {
    const location = useLocation();
    const path = location.pathname;
    const logout = useLogout();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };
    return (
        <nav className="nav">
            <div className="centered">
                <div className="left">
                    <Link
                        to="/admin"
                        className={path === '/admin' ? 'active' : ''}
                    >
                        Domů
                    </Link>
                    <Link
                        to="/admin/uzivatele"
                        className={path === '/admin/uzivatele' ? 'active' : ''}
                    >
                        Uživatelé
                    </Link>
                    <Link
                        to="/admin/knihy"
                        className={path === '/admin/knihy' ? 'active' : ''}
                    >
                        Seznam učebnic a PS
                    </Link>
                    <Link
                        to="/admin/interaktivky"
                        className={
                            path === '/admin/interaktivky' ? 'active' : ''
                        }
                    >
                        Interaktivky
                    </Link>
                </div>

                <div className="right">
                    <Link onClick={handleLogout}>Odhlásit se</Link>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
