const paths = {
    GET_IMAGES: () => `/media_excercises/get-images`,
    GET_AUDIO: () => `/media_excercises/get-audio`,
    GET_VIDEO: () => `/media_excercises/get-video`,
    GET_EXCERCISES: () => `/media_excercises/get-excercises`,
    CHANGE_IMAGE_DETAIL: () => `/media_excercises/image-detail-change`,
    SWAP_IMAGES: () => `/media_excercises/image-swap`,
    LIBRARY_UPLOAD: () => `/media_excercises/library`,
    REMOVE_MEDIA: () => `/media_excercises/library/delete-media`,
    REMOVE_EXCERCISE: () => `/media_excercises/library/delete-excercise`,
    REMOVE_UNUSED: () => `/media_excercises/library/delete-unused-media`,
    SELECT_VIDEO_FROM_LIBRARY: (button_id) =>
        `/media_excercises/library/select-video/${button_id}`,
    UPLOAD_NEW_VIDEO: (button_id) =>
        `/media_excercises/library/upload-new-audio-video/${button_id}`,
    CHANGE_VIDEO_SOURCE: () => `/media_excercises/button/change-video-source`,
};
// ?bookid=${book.bookid}&release=${book.release}&version=${book.version}
export const PAGE_ENUM = {
    AUDIO: 0,
    VIDEO: 1,
    IMAGE: 2,
    EXCERCISE: 3,
};

class MediaExcercisesService {
    static async get_media(type, book, axios_instance) {
        let path = '';
        switch (type) {
            case PAGE_ENUM.IMAGE:
                path = paths.GET_IMAGES();
                break;
            case PAGE_ENUM.AUDIO:
                path = paths.GET_AUDIO();
                break;
            case PAGE_ENUM.VIDEO:
                path = paths.GET_VIDEO();
                break;
            case PAGE_ENUM.EXCERCISE:
                path = paths.GET_EXCERCISES();
                break;
            default:
                return { err: true, detail: 'wrong type' };
        }
        try {
            const response = await axios_instance.get(path, { params: book });
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async change_image_detail(data, axios_instance) {
        const path = paths.CHANGE_IMAGE_DETAIL();
        try {
            const response = await axios_instance.post(path, data);
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async swap_images(data, axios_instance) {
        const path = paths.SWAP_IMAGES();
        try {
            const response = await axios_instance.post(path, data);
            return response;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async upload_media(formData, axios_instance, progress_function) {
        const path = paths.LIBRARY_UPLOAD();
        try {
            const response = await axios_instance.post(path, formData, {
                Headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted =
                        progressEvent.loaded / progressEvent.total;

                    progress_function(percentCompleted);
                },
            });
            return response;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async remove_asset(remove_obj, excercise, axios_instance) {
        let path = paths.REMOVE_MEDIA();
        if (excercise) {
            path = paths.REMOVE_EXCERCISE();
        }
        try {
            const response = await axios_instance.post(path, remove_obj);
            return response;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async remove_unused_media(data, axios_instance) {
        const path = paths.REMOVE_UNUSED();
        try {
            const response = await axios_instance.post(path, data);
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async select_video_from_library(data, button_id, axios_instance) {
        const path = paths.SELECT_VIDEO_FROM_LIBRARY(button_id);
        try {
            const response = await axios_instance.post(path, data);
            if (!response.data.result)
                return { err: true, detail: 'Něco se pokazilo' };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async upload_new_video(
        data,
        button_id,
        axios_instance,
        progressFunc
    ) {
        const path = paths.UPLOAD_NEW_VIDEO(button_id);
        try {
            const response = await axios_instance.post(path, data, {
                Headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted =
                        progressEvent.loaded / progressEvent.total;

                    progressFunc(Math.round(percentCompleted * 100));
                },
            });
            if (!response.data.result)
                return { err: true, detail: 'Něco se pokazilo' };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async change_video_source(data, axios_instance) {
        const path = paths.CHANGE_VIDEO_SOURCE();
        try {
            const response = await axios_instance.post(path, data);
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
}

export default MediaExcercisesService;
