import React from 'react';
import { assetToPath } from '../../../../utils/path_utils';
import { shortenText } from '../../../../utils/translator';
import DeleteIcon from '../../../../static/images/delete_white.png';
import { getNavigateToButtonPath } from '../../../../utils/navigations';
import { useNavigate } from 'react-router-dom';
import HeaderIcon from '~/static/images/heading.png';
import TextIcon from '~/static/images/text.png';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';

/**
 * "_id": "0f360757680c43b18eead3b835489ed4",
 * "file_path": "static/iuc/interactives/33-30/vydani_1/verze_7_0/media/images/Frame 18.png",
 * "media_kind": "IMAGE",
 * "name": "Frame 18",
 * "description": "empty",
 * "header": "Frame 18.png",
 * "naturalWidth": 1038,
 * "naturalHeight": 1038
 */
const getHeader = (header) => {
    const cond = header && header.length !== 0;
    return cond ? 'Nadpis OK' : 'Nadpis chybí';
};
const getDescription = (description) => {
    const cond = description && description.length !== 0;
    return cond ? 'Popis OK' : 'Popis chybí';
};
const ImageElem = ({ image, removeFunc, book, onClick }) => {
    const navigate = useNavigate();
    const handleClick = (page, button_id) => {
        const path = getNavigateToButtonPath(book, page, button_id);
        navigate(path);
    };
    const handleImageClick = (
        focusOnHeader = false,
        focuseOnDescription = false
    ) => {
        onClick && onClick(image, focusOnHeader, focuseOnDescription);
    };
    return (
        <div className="library_elem_outer">
            <div
                className="library_elem_item"
                onClick={() => handleImageClick()}
            >
                <div className="libary_image_cover">
                    <img src={assetToPath(image)} alt={image.name} />
                </div>
                <div className="library_elem_name">
                    {shortenText(image.name, 20)}
                </div>
            </div>
            <div className="library_elem_buttons top-right">
                <Tooltip title="Odstranit video soubor" position="left">
                    <button
                        className="library_elem_button delete"
                        onClick={removeFunc}
                    >
                        <img src={DeleteIcon} alt="delete" />
                    </button>
                </Tooltip>
            </div>
            <div className="library_elem_buttons top-left">
                <Tooltip title={getHeader(image.header)} position="right">
                    <button
                        className={classNames('icon-button', 'smaller', {
                            red: !(image.header && image.header.length !== 0),
                            green: image.header && image.header.length !== 0,
                        })}
                        onClick={() => {
                            handleImageClick(true);
                        }}
                    >
                        <img src={HeaderIcon} alt="header" />
                    </button>
                </Tooltip>
                <Tooltip
                    title={getDescription(image.description)}
                    position="right"
                >
                    <button
                        className={classNames('icon-button', 'smaller', {
                            red: !(
                                image.description &&
                                image.description.length !== 0
                            ),
                            green:
                                image.description &&
                                image.description.length !== 0,
                        })}
                        onClick={() => {
                            handleImageClick(false, true);
                        }}
                    >
                        <img src={TextIcon} alt="text" />
                    </button>
                </Tooltip>
            </div>
            {image.buttons?.length > 0 && (
                <div className="library_elem_buttons bottom-left off">
                    {image.buttons
                        .sort((a, b) => a.page - b.page)
                        .map((button, i) => (
                            <Tooltip
                                title={`Strana č. ${button.page}`}
                                position="right"
                                size="small"
                                key={i}
                            >
                                <button
                                    className="icon-button smaller circular library "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleClick(
                                            button.page,
                                            button.button_id
                                        );
                                    }}
                                >
                                    <p>{button.page}</p>
                                </button>
                            </Tooltip>
                        ))}
                </div>
            )}
        </div>
    );
};

export default ImageElem;
