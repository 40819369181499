import React, { useState } from 'react';
import Modal from '../../../../components/Modal';
import FileTreeExcercise from './FileTreeExcercise';
import FlexibleDragAndDrop from '../../../../components/FlexibleDragAndDrop';
import JSZip from 'jszip';
import { toast } from 'react-toastify';
import toastSettings from '../../../../utils/toastSettings';
import { checkZipForExcercises } from '../../../../utils/path_utils';
import file_types from '../../../../static/images/file_types/file_types.json';
// Used when user clicks on a button: Nahrát nové with button type EXCERCISE
const UploadNewExcercise = ({ closeFunc, acceptFunc }) => {
    const [tempSelected, setTempSelected] = useState(null);
    const [excercisesInZip, setExcercisesInZip] = useState([]);
    const handleNewExcerciseDrop = async (file) => {
        if (!file) return;
        const checkZip = await checkZipForExcercises(file, 1);
        console.log('CheckZipResult >>> ', checkZip);
        if (checkZip.error) {
            toast.error(checkZip.msg, toastSettings);
            resetselection();
            return false;
        }
        setExcercisesInZip(checkZip.excercises);
        return true;
    };
    const resetselection = () => {
        setTempSelected(null);
        setExcercisesInZip([]);
    };
    return (
        <Modal
            closeFunction={closeFunc}
            acceptFuncion={() => acceptFunc(tempSelected)}
        >
            <p className="body-1">
                Vložte soubor .zip. Je důležité, aby soubory html a jpg měly
                stejný název. Zároveň je důležité dodržet následující strukturu.
            </p>
            <FileTreeExcercise />

            <FlexibleDragAndDrop
                file={tempSelected}
                setFile={setTempSelected}
                acceptFiles={file_types.zip_mimes}
                textOnBox="Přetáhněte soubor .zip"
                zipDropFunction={handleNewExcerciseDrop}
            >
                {excercisesInZip.length > 0 && (
                    <div className="excercises-in-zip">
                        Tento archiv obsahuje cvičení:{' '}
                        {excercisesInZip.map((excercise) => excercise)}
                    </div>
                )}
            </FlexibleDragAndDrop>
        </Modal>
    );
};

export default UploadNewExcercise;
