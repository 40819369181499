import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../styles/buttons.css";
import "../styles/editor/editor.css";
import "../styles/text.css";

import ButtonCanvas from "./ButtonCanvas";
import InteractiveService from "~/services/interactive.service";
import { Layer, Stage } from "react-konva";
import { ButtonSpriteStatic } from "~/components/ButtonSprite";
import { extractBookInfo, get_book_version } from "~/utils/translator";
import DocumentPage from "./EditorComponents/DocumentPage";
import {
	EDITOR_PAGES,
	useBackground,
	useFirstPageBackground,
	useInteractiveBookStore,
	usePageBorder,
} from "~/stores/interactiveBookStore";
import { useAssetStore } from "~/stores/assetStore";
import { usePage, useSelectedButtonStore } from "~/stores/selectedButtonStore";
import EditorNavbar from "./EditorComponents/EditorNavbar";
import { setPageFiles } from "~/utils/path_utils";
import BottomLeftButtonStack from "./EditorComponents/BottomLeftButtonStack";
import { toast } from "react-toastify";
import toastSettings from "~/utils/toastSettings";
import { extractNumberFromPadding } from "~/utils/objectUtils";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.js",
	import.meta.url
).toString();

function interactive_get_url(bookid, release, version) {
	return `/interactive/${bookid}/${release}/${version}`;
}
const BUTTON_TYPES = {
	image: 0,
	audio: 1,
	video: 2,
	excercise: 3,
};
const PAGE = {
	left: 0,
	right: 1,
};
const outsidePdfContainerStyles = (choosePage) => {
	return `outside-pdf-container${choosePage ? " faded" : ""}`;
};
const pdfContainerStyles = (showResults) => {
	return `pdf-container${showResults ? " visible" : ""}`;
};
const onePageStyles = (choosePage, side) => {
	return `one-page ${side}${choosePage ? " with-hover-effect" : ""}`;
};

const filterOutTwopages = (pages, currentPage) => {
	const twoPages = pages.filter((value) => {
		return value.id === currentPage || value.id === currentPage + 1;
	}); // filtering out two pages
	return twoPages;
};
const checkedPageNumber = (currentPage, maximumPage) => {
	const newNumber = currentPage % 2 === 0 ? currentPage - 1 : currentPage;
	if (currentPage > maximumPage) {
		return maximumPage - 2;
	}
	if (currentPage < 1) {
		return 1;
	}
	return newNumber;
};
const Editor_interaktivka_konva = () => {
	const { bookid, release, version } = useParams();
	const [background, setBackground] = useBackground();
	const [firstPageBg, setFirstPageBg] = useFirstPageBackground();
	const privateAxios = useAxiosPrivate();
	const [book, setBook] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [maximumPage, setMaximumPage] = useState(null);
	const [leftPage, setLeftPage] = useState();
	const [rightPage, setRightPage] = useState();
	const [pages, setPages] = useState();
	const [choosePage, setChoosePage] = useState(false);
	const [chosenType, setChosenType] = useState(null);
	const [canRenderCanvasLeft, setCanRenderCanvasLeft] = useState(false);
	const [canRenderCanvasRight, setCanRenderCanvasRight] = useState(false);
	const pageRef = useRef(null);
	const pageResultRef = useRef(null);
	const [pageSize, setPageSize] = useState({ height: 0, width: 0 });
	const [pageNum, lastMovedPage, setLastMovedPage] = usePage();
	const [showResults, setShowResults] = useState(false);
	const [leftResultPage, setLeftResultPage] = useState(null);
	const [rightResultPage, setRightResultPage] = useState(null);
	const [buttonsVisible, setButtonsVisible] = useState(true);
	const [bookSize, setBookSize] = useState(1);
	const [padding, setPadding] = useState({
		paddingTop: "0px",
		paddingBottom: "0px",
		paddingLeft: "0px",
		paddingRight: "0px",
	});
	const [leftB, rightB, topB, bottomB] = usePageBorder();
	const [padd, setPadd] = useState({ pT: 0, pB: 0, pL: 0, pR: 0 });

	const [searchParams, setSearchParams] = useSearchParams();
	const leftButtonRef = useRef(null);
	const rightButtonRef = useRef(null);
	const setGlobalBook = useInteractiveBookStore(
		(state) => state.setCompleteBook
	);
	const setGlobalCurrentPage = useInteractiveBookStore(
		(state) => state.setCurrentPage
	);
	const setGlobalAssets = useAssetStore((state) => state.setAssets);
	const setSelectedButton = useSelectedButtonStore(
		(state) => state.setSavedButton
	);
	const rightPageRef = useRef(null);
	const setCurrentEditorPage = useInteractiveBookStore(
		(state) => state.setCurrentEditorPage
	);
	const navigate = useNavigate();
	// Initial load of the book data (called only once!)
	useEffect(() => {
		setCurrentEditorPage(EDITOR_PAGES.BUTTONS);
		// Initial load of the book data (called only once!)
		const getInteractive = async () => {
			const url = interactive_get_url(bookid, release, version);
			if (bookid === "22-6162") {
				setBookSize(0.95);
			}
			const response = await privateAxios.get(url);
			// Initial page number from url (or defaulted to 1)
			const countOfPages = response.data.pages.length;
			const currentPageNum = checkedPageNumber(
				searchParams.has("page")
					? parseInt(searchParams.get("page"))
					: 1,
				countOfPages
			);
			setBook(response.data); // Setting the book data
			setMaximumPage(response.data.pages.length); // setting the maximum page number
			setPages(response.data.pages); // All the pages stored in the state (only paths not actual files - for saving space)
			setCurrentPage(currentPageNum); // Setting the current page number
			const currentTwoPages = filterOutTwopages(
				response.data.pages,
				currentPageNum
			);
			setPageFiles(
				setLeftPage,
				setRightPage,
				setLeftResultPage,
				setRightResultPage,
				currentTwoPages
			);

			const bookInfo = extractBookInfo(response.data);
			setGlobalBook(
				bookInfo.bookid,
				bookInfo.release,
				bookInfo.version_before_dot,
				bookInfo.version_after_dot,
				currentPageNum
			);
			await setGlobalAssets(bookInfo, privateAxios);
			setSelectedButton(null);
		};
		getInteractive();
	}, []);
	// Load settings and set background
	useEffect(() => {
		const setSettings = async () => {
			// Get settings and set background
			const data = await InteractiveService.get_settings(
				bookid,
				release,
				version,
				privateAxios
			);
			if (data.settings?.background)
				setBackground(data.settings.background);
			if (data.settings?.padding) setPadding(data.settings.padding);
			if (data.settings?.first_background)
				setFirstPageBg(data.settings.first_background);
		};
		setSettings();
	}, []);

	// Whenever the search params changes we need to adjust the current page
	// (only when the url in the browser doesnt correspond to the current page)
	useEffect(() => {
		const numberInUrl = searchParams.has("page")
			? parseInt(searchParams.get("page"))
			: 1;
		if (numberInUrl !== currentPage) {
			resetPages();
			setCurrentPage(numberInUrl);
			setGlobalCurrentPage(numberInUrl);
		}
	}, [searchParams]);

	// Whenever page number changes we need to adjust the page files
	useEffect(() => {
		if (!pages) return;
		const twoPages = pages.filter((value) => {
			return value.id === currentPage || value.id === currentPage + 1;
		});
		// Conditionally setting the new pages
		setPageFiles(
			setLeftPage,
			setRightPage,
			setLeftResultPage,
			setRightResultPage,
			twoPages
		);
		const numberInUrl = searchParams.has("page")
			? parseInt(searchParams.get("page"))
			: 1;
		numberInUrl !== currentPage && searchParams.set("page", currentPage);
		navigate(`?${searchParams.toString()}`, { replace: true });
	}, [currentPage]);

	// After previous button is clicked - changing current page accordingly
	const handlePreviousButton = async () => {
		if (currentPage !== 1) {
			resetPages();
			setCurrentPage(currentPage - 2);
			setGlobalCurrentPage(currentPage - 2);
		}
	};

	// After next button is clicked - changing current page accordingly
	const handleNextButton = async () => {
		if (currentPage + 2 <= maximumPage) {
			resetPages();
			setCurrentPage(currentPage + 2);
			setGlobalCurrentPage(currentPage + 2);
		}
	};

	// Deletes the canvases with buttons
	const resetPages = () => {
		setCanRenderCanvasLeft(false);
		setCanRenderCanvasRight(false);
		setLastMovedPage(null);
	};

	const handleAddButtonClick = (type) => {
		setChoosePage(true); // UI change and user have to click on the corresponding page
		setChosenType(type); // User already selected the type, so saving it
	};

	const selectPage = async (e, page) => {
		setChoosePage(false);
		const rect =
			page === PAGE.left
				? pageRef.current.getBoundingClientRect()
				: rightPageRef.current.getBoundingClientRect();
		let x = e.clientX - rect.left;
		let y = e.clientY - rect.top;

		if (page === PAGE.left) {
			await leftButtonRef.current.add_button(chosenType, x, y);
		}
		if (page === PAGE.right) {
			await rightButtonRef.current.add_button(chosenType, x, y);
		}
		disableAdding();
	};

	const disableAdding = () => {
		setChosenType(null);
	};

	const getButtonsFunc = async (page_num) => {
		return await InteractiveService.get_buttons(
			bookid,
			release,
			version,
			page_num,
			privateAxios
		);
	};

	const changeBackgroundColor = async (type) => {
		const post_data = {
			bookid,
			release,
			version,
			background: type === 1 ? firstPageBg : background,
		};
		const data = await InteractiveService.change_background(
			post_data,
			type,
			privateAxios
		);
		if (data.err) {
			toast.error("Nepodařilo se změnit barvu pozadí", toastSettings);
			return;
		}
	};

	const handleChangePadding = async (padding_data) => {
		const post_data = {
			bookid,
			release,
			version,
			...padding_data,
		};

		const data = await InteractiveService.change_padding(
			post_data,
			privateAxios
		);
		if (data.err) {
			toast.error("Nepodařilo se změnit padding", toastSettings);
			return;
		}
	};

	useEffect(() => {
		const pT = extractNumberFromPadding(padding.paddingTop);
		const pB = extractNumberFromPadding(padding.paddingBottom);
		const pL = extractNumberFromPadding(padding.paddingLeft);
		const pR = extractNumberFromPadding(padding.paddingRight);
		setPadd({ pT, pB, pL, pR });
	}, [padding]);
	useEffect(() => {
		console.log("Can render canvas left: ", canRenderCanvasLeft);
		console.log("Can render canvas right: ", canRenderCanvasRight);
	}, [canRenderCanvasLeft, canRenderCanvasRight]);
	return (
		<div className="wholePageContainer">
			<EditorNavbar
				pageNumber={currentPage}
				setPageNumber={setCurrentPage}
				next={handleNextButton}
				previous={handlePreviousButton}
			/>
			{choosePage && (
				<h1 className="header-2 top-position">
					Prosím zvolte na kterou stranu chcete prvek přidat.
				</h1>
			)}
			<BottomLeftButtonStack
				color={background}
				setColor={setBackground}
				confirmChangeColor={(type) => changeBackgroundColor(type)}
				firstPageBackground={
					currentPage === 1 ? firstPageBg : undefined
				}
				setFirstPageBackground={setFirstPageBg}
				setShowResults={setShowResults}
				setButtonsVisible={setButtonsVisible}
				padding={padding}
				setPadding={setPadding}
				confirmChangePadding={handleChangePadding}
			/>
			{leftPage && rightPage && (
				<div className={`${outsidePdfContainerStyles(choosePage)}`}>
					{/* ACTUAL PAGES SHOWING */}
					<div
						className={`${pdfContainerStyles(!showResults)}`}
						style={{ backgroundColor: background }}
					>
						<div
							className={`${onePageStyles(choosePage, "left")}`}
							onClick={(e) => {
								if (choosePage) selectPage(e, PAGE.left);
							}}
							style={{
								...padding,
								borderLeft: leftB
									? "5px solid #6b5de5"
									: "none",
								borderRight: rightB
									? "5px solid #6b5de5"
									: "none",
								borderTop: topB ? "5px solid #6b5de5" : "none",
								borderBottom: bottomB
									? "5px solid #6b5de5"
									: "none",
								backgroundColor:
									currentPage === 1 ? firstPageBg : "#fff",
							}}
							ref={pageRef}
						>
							<DocumentPage
								file={leftPage}
								pageSide="left"
								bookSize={bookSize}
								showResults={showResults}
								setCanRenderCanvas={setCanRenderCanvasLeft}
								loader={true}
								setPageSize={setPageSize}
								pageRef={pageRef}
							/>
						</div>
						<div
							className={`${onePageStyles(choosePage, "right")}`}
							onClick={(e) => {
								if (choosePage) selectPage(e, PAGE.right);
							}}
							style={{
								...padding,
								borderLeft: leftB
									? "5px solid #6b5de5"
									: "none",
								borderRight: rightB
									? "5px solid #6b5de5"
									: "none",
								borderTop: topB ? "5px solid #6b5de5" : "none",
								borderBottom: bottomB
									? "5px solid #6b5de5"
									: "none",
							}}
							ref={rightPageRef}
						>
							<DocumentPage
								file={rightPage}
								pageSide="right"
								bookSize={bookSize}
								showResults={showResults}
								setCanRenderCanvas={setCanRenderCanvasRight}
							/>
						</div>
					</div>
					{/* RESULTS PAGES SHOWING */}
					<div
						className={`${pdfContainerStyles(showResults)}`}
						style={{ backgroundColor: background }}
					>
						<div
							className={`${onePageStyles(choosePage, "left")}`}
							onClick={(e) => {
								if (choosePage) selectPage(e, PAGE.left);
							}}
							style={{
								...padding,
								borderLeft: leftB
									? "5px solid #6b5de5"
									: "none",
								borderRight: rightB
									? "5px solid #6b5de5"
									: "none",
								borderTop: topB ? "5px solid #6b5de5" : "none",
								borderBottom: bottomB
									? "5px solid #6b5de5"
									: "none",
								backgroundColor:
									currentPage === 1 ? firstPageBg : "#fff",
							}}
							ref={pageResultRef}
						>
							<DocumentPage
								file={leftResultPage}
								pageSide="left"
								bookSize={bookSize}
								showResults={showResults}
								setCanRenderCanvas={setCanRenderCanvasLeft}
								loader={true}
								setPageSize={setPageSize}
								pageRef={pageRef}
							/>
						</div>
						<div
							className={`${onePageStyles(choosePage, "right")}`}
							onClick={(e) => {
								if (choosePage) selectPage(e, PAGE.right);
							}}
							style={{
								...padding,
								borderLeft: leftB
									? "5px solid #6b5de5"
									: "none",
								borderRight: rightB
									? "5px solid #6b5de5"
									: "none",
								borderTop: topB ? "5px solid #6b5de5" : "none",
								borderBottom: bottomB
									? "5px solid #6b5de5"
									: "none",
							}}
						>
							<DocumentPage
								file={rightResultPage}
								pageSide="right"
								bookSize={bookSize}
								showResults={showResults}
								setCanRenderCanvas={setCanRenderCanvasRight}
							/>
						</div>
					</div>
				</div>
			)}
			{canRenderCanvasLeft && canRenderCanvasRight && (
				<div
					className={`canvases-overpage ${
						choosePage ? "dissapear" : ""
					} `}
				>
					<ButtonCanvas
						ref={leftButtonRef}
						page={PAGE.left}
						pageNumber={currentPage}
						selectedPage={lastMovedPage}
						height={pageSize.height + padd.pT + padd.pB}
						width={pageSize.width + padd.pL + padd.pR}
						getButtons={getButtonsFunc}
						currentlyViewedBook={{ bookid, release, version }}
						buttonsVisible={buttonsVisible}
						padding={padding}
					/>
					<ButtonCanvas
						ref={rightButtonRef}
						page={PAGE.right}
						pageNumber={currentPage + 1}
						height={pageSize.height + padd.pT + padd.pB}
						width={pageSize.width + padd.pL + padd.pR}
						selectedPage={lastMovedPage}
						getButtons={getButtonsFunc}
						currentlyViewedBook={{ bookid, release, version }}
						buttonsVisible={buttonsVisible}
						padding={padding}
					/>
				</div>
			)}

			<div className="side-buttons">
				<Stage width={64} height={4 * 64}>
					<Layer>
						<ButtonSpriteStatic
							type="PHOTO"
							x={10}
							y={10}
							button_info={null}
							clicked={() => {
								handleAddButtonClick(BUTTON_TYPES.image);
							}}
						/>
						<ButtonSpriteStatic
							type="AUDIO"
							x={10}
							y={70}
							button_info={null}
							clicked={() => {
								handleAddButtonClick(BUTTON_TYPES.audio);
							}}
						/>
						<ButtonSpriteStatic
							type="VIDEO_LOCAL"
							x={10}
							y={130}
							button_info={null}
							clicked={() => {
								handleAddButtonClick(BUTTON_TYPES.video);
							}}
						/>
						<ButtonSpriteStatic
							type="INTERACTIVE"
							x={10}
							y={190}
							button_info={null}
							clicked={() => {
								handleAddButtonClick(BUTTON_TYPES.excercise);
							}}
						/>
					</Layer>
				</Stage>
			</div>

			{book && (
				<div className="bottom-version-info">
					{get_book_version(book)}
				</div>
			)}
		</div>
	);
};

export default Editor_interaktivka_konva;
