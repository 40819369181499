import React, { useEffect, useState } from 'react';
import {
    useBookForRequest,
    useInteractiveBookStore,
} from '~/stores/interactiveBookStore';

import ZoomTools from './ZoomTools';
import ZoomCanvasNew from './ZoomCanvasNew';
import ZoomService from '~/services/zoom.service';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import Polygon from '~/utils/zoom_utils';
import ZoomFrame from './ZoomFrame';
const getLeftPageMargin = (padding) => {
    return {
        marginLeft: padding.pL + 'px',
        marginRight: padding.pR + 'px',
        marginTop: padding.pT + 'px',
        marginBottom: padding.pB + 'px',
    };
};
const getRightPageMargin = (padding) => {
    return {
        marginLeft: padding.pL + 'px',
        marginRight: padding.pR + 'px',
        marginTop: padding.pT + 'px',
        marginBottom: padding.pB + 'px',
    };
};
const formZoom = (zooms, pageNum, width, height) => {
    const api_polygons = zooms.find((zoom) => zoom.page === pageNum).polygons;
    const polygon_list = api_polygons.map(
        (polygon) => new Polygon(polygon, width, height, pageNum)
    );
    return polygon_list;
};

const ZoomPages = ({ width, height, padding }) => {
    const pageNumber = useInteractiveBookStore((state) => state.currentPage);
    const [activePageNum, setActivePageNum] = useState(0);
    const book = useBookForRequest();
    const [leftPolygons, setLeftPolygons] = useState([]);
    const [rightPolygons, setRightPolygons] = useState([]);
    const [visibleZoom, setVisibleZoom] = useState(null);

    const privateAxios = useAxiosPrivate();
    useEffect(() => {
        const get_polygons = async () => {
            const response = await ZoomService.get_polygons(
                book,
                [pageNumber, pageNumber + 1],
                privateAxios
            );
            if (response.err) {
                console.log(response.detail);
                return;
            }
            if (response.zooms) {
                const pls1 = formZoom(
                    response.zooms,
                    pageNumber,
                    width,
                    height
                );
                const pls2 = formZoom(
                    response.zooms,
                    pageNumber + 1,
                    width,
                    height
                );
                setLeftPolygons(pls1);
                setRightPolygons(pls2);
            }
        };
        get_polygons();

        // eslint-disable-next-line
    }, [pageNumber, privateAxios]);

    return (
        <div className="centered-canvases">
            <div style={getLeftPageMargin(padding)}>
                <ZoomCanvasNew
                    pageNumber={pageNumber}
                    width={width}
                    height={height}
                    polygons={leftPolygons}
                    setPolygons={setLeftPolygons}
                    active={activePageNum === pageNumber}
                    setActive={setActivePageNum}
                    book={book}
                    setVisibleZoom={setVisibleZoom}
                />
            </div>
            <div style={getRightPageMargin(padding)}>
                <ZoomCanvasNew
                    pageNumber={pageNumber + 1}
                    width={width}
                    height={height}
                    polygons={rightPolygons}
                    setPolygons={setRightPolygons}
                    active={activePageNum === pageNumber + 1}
                    setActive={setActivePageNum}
                    book={book}
                    setVisibleZoom={setVisibleZoom}
                />
            </div>
            <ZoomTools />
            {visibleZoom && (
                <ZoomFrame
                    zoom={visibleZoom}
                    onClose={() => setVisibleZoom(null)}
                />
            )}
        </div>
    );
};

export default ZoomPages;
