import React, { useEffect, useState } from 'react';
import FlexInputWithLabel from '~/components/FlexInputWithLabel/FlexInputWithLabel';
import {
    useInteractiveBookStore,
    usePageBorder,
} from '~/stores/interactiveBookStore';
import { extractNumberFromPadding } from '~/utils/objectUtils';

const PaddingSetter = ({ padding, setPadding, confirmChange }) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [paddingTop, setPaddingTop] = useState(
        extractNumberFromPadding(padding.paddingTop)
    );
    const [paddingRight, setPaddingRight] = useState(
        extractNumberFromPadding(padding.paddingRight)
    );
    const [paddingBottom, setPaddingBottom] = useState(
        extractNumberFromPadding(padding.paddingBottom)
    );
    const [paddingLeft, setPaddingLeft] = useState(
        extractNumberFromPadding(padding.paddingLeft)
    );
    const [timeoutConfirm, setTimeoutConfirm] = useState(null);
    const setBorder = useInteractiveBookStore((state) => state.setBorder);
    const [leftB, rightB, topB, bottomB] = usePageBorder();
    useEffect(() => {
        if (extractNumberFromPadding(padding.paddingTop) !== paddingTop) {
            setPaddingTop(extractNumberFromPadding(padding.paddingTop));
        }
        if (extractNumberFromPadding(padding.paddingRight) !== paddingRight) {
            setPaddingRight(extractNumberFromPadding(padding.paddingRight));
        }
        if (extractNumberFromPadding(padding.paddingBottom) !== paddingBottom) {
            setPaddingBottom(extractNumberFromPadding(padding.paddingBottom));
        }
        if (extractNumberFromPadding(padding.paddingLeft) !== paddingLeft) {
            setPaddingLeft(extractNumberFromPadding(padding.paddingLeft));
        }
    }, [padding]);
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            return;
        }
        const data = {
            paddingTop: paddingTop.toString() + 'px',
            paddingRight: paddingRight.toString() + 'px',
            paddingBottom: paddingBottom.toString() + 'px',
            paddingLeft: paddingLeft.toString() + 'px',
        };
        setPadding(data);
        if (timeoutConfirm) {
            clearTimeout(timeoutConfirm);
        }

        setTimeoutConfirm(
            setTimeout(() => {
                confirmChange(data);
            }, 1000)
        );
    }, [paddingTop, paddingRight, paddingBottom, paddingLeft]);
    return (
        <>
            <FlexInputWithLabel
                id="padding-top"
                label="Padding top"
                value={paddingTop}
                onChange={setPaddingTop}
                units="px"
                onlyNumbers={true}
                onFocus={() => {
                    setBorder(leftB, rightB, true, bottomB);
                }}
                onBlur={() => {
                    setBorder(leftB, rightB, false, bottomB);
                }}
            />
            <FlexInputWithLabel
                id="padding-right"
                label="Padding right"
                value={paddingRight}
                onChange={setPaddingRight}
                units="px"
                onlyNumbers={true}
                onFocus={() => {
                    setBorder(leftB, true, topB, bottomB);
                }}
                onBlur={() => {
                    setBorder(leftB, false, topB, bottomB);
                }}
            />
            <FlexInputWithLabel
                id="padding-bottom"
                label="Padding bottom"
                value={paddingBottom}
                onChange={setPaddingBottom}
                units="px"
                onlyNumbers={true}
                onFocus={() => {
                    setBorder(leftB, rightB, topB, true);
                }}
                onBlur={() => {
                    setBorder(leftB, rightB, topB, false);
                }}
            />
            <FlexInputWithLabel
                id="padding-left"
                label="Padding left"
                value={paddingLeft}
                onChange={setPaddingLeft}
                units="px"
                onlyNumbers={true}
                onFocus={() => {
                    setBorder(true, rightB, topB, bottomB);
                }}
                onBlur={() => {
                    setBorder(false, rightB, topB, bottomB);
                }}
            />
        </>
    );
};

export default PaddingSetter;
