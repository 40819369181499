import React from 'react';
import '../pages/styles/popup.css';
import Modal from './Modal';
const PopUpConfirm = ({ closeFunction, confirmFunction, appear, question }) => {
    return (
        <Modal
            closeFunction={closeFunction}
            acceptFuncion={confirmFunction}
            acceptText="Ano"
        >
            <p className="body-1 mg-b-1 centered">{question}</p>
        </Modal>
    );
};

export default PopUpConfirm;
