import React from 'react';
import CloseIcon from '../static/images/close.png';
const TopRightCloseBtn = ({ closeFunc, zIndex }) => {
    return (
        <button
            className="close-btn top-right"
            onClick={closeFunc}
            style={{ zIndex: zIndex || 1 }}
        >
            <img src={CloseIcon} alt="close" />
        </button>
    );
};

export default TopRightCloseBtn;
