import React, { useEffect, useState } from 'react';
import ChooseExcerciseLibrary from './ChooseExcerciseLibrary';
import ButtonsService from '../../../../services/buttons.service';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import UploadNewExcercise from './UploadNewExcercise';
import { replaceButtonInButtonArray } from '../../../../utils/stateUtils';
import {
    assetToPath,
    getExcercisesFromZip,
} from '../../../../utils/path_utils';
import { extractValuesFromObject } from '../../../../utils/objectUtils';
import Modal from '../../../../components/Modal';
import { useAssetExcercises } from '../../../../stores/assetStore';
import FullScreenLoading from '../../../../components/FullScreenLoading';
const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};

const getThumbnailPathForExcercise = (selected_excercise_path, excercises) => {
    const excercise = excercises.find(
        (excercise) => excercise.file_path === selected_excercise_path
    );
    if (!excercise) return null;
    const asset_for_conversion = {
        file_path: excercise.picture_ref,
        last_update: excercise.last_update,
    };
    return assetToPath(asset_for_conversion);
};

// Encapsulates the main part of selecting assets of type EXCERCISE
// Includes both buttons for Selecting from library and uploading new excerices
const ExcerciseSelect = ({
    savedButton,
    somethingSaving,
    bookInfo,
    pageNumber,
    setSavedButton,
    setButtons,
}) => {
    const [selectingExcercise, setSelectingExcercise] = useState(false);
    const [addNewExcercise, setAddNewExcercise] = useState(false);
    const [sameNameExcercise, setSameNameExcercise] = useState(false);
    const [uploadingExcercise, setUploadingExcercise] = useState(false);
    const [excercises, setExcercises] = useAssetExcercises();
    const [loading, setLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    // Function used for adding a excercise to the button (from library)
    const handleAddExcercise = async (tempSelected) => {
        if (savedButton.excercise_name === tempSelected.name) {
            setSelectingExcercise(false);
            return;
        }
        somethingSaving(true);
        // Changing it on the backend
        const response = await ButtonsService.add_buttons_excercise(
            bookInfo,
            pageNumber,
            savedButton.key,
            tempSelected,
            axiosPrivate
        );
        // If error occur dont continue and notify user
        if (response.err) {
            toast.error(
                'Nepodařilo se přiřadit cvičení ke tlačítku.',
                toastError
            );
            somethingSaving(false);
            return;
        }
        // Closing the Modal
        setSelectingExcercise(false);
        const newButton = {
            ...savedButton,
            excercise_name: response.elements.result.name,
            excercise_ref: response.elements.result.file_path,
        };

        // UPDATE EXCERCISES -> because of buttons number
        const newExcercises = extractValuesFromObject(
            response.elements.excercises
        );
        setExcercises(newExcercises);

        // Setting the new excercise to the button
        setSavedButton(newButton);
        // Changing the excercise in the buttons array
        replaceButtonInButtonArray(setButtons, newButton);

        //changeButtonsExcercise(savedButton.key, tempSelected);
        toast.success('Cvičení bylo úspěšně přiřazeno.', toastError);
        somethingSaving(false);
    };

    // Function used for adding a NEW excercise to the button (which is not from the library)
    const handleAddNewExcercise = async (
        tempSelected,
        confirmedSameName = false
    ) => {
        const lst_of_files = await getExcercisesFromZip(tempSelected);
        const is_file_in = excercises.some((excercise) =>
            lst_of_files.includes(excercise.name)
        );
        if (is_file_in && !confirmedSameName) {
            setSameNameExcercise(true);
            setUploadingExcercise(tempSelected);
            return;
        }

        somethingSaving(true);
        setLoading(true);
        // Adding the new excercise to the button on the backend
        const response = await ButtonsService.add_new_excercise_to_button(
            bookInfo,
            pageNumber,
            savedButton.key,
            tempSelected,
            axiosPrivate
        );
        if (response.err) {
            toast.error(response.msg, toastError);
            somethingSaving(false);
            return;
        }
        // Saving the things to the selected button
        const newButton = {
            ...savedButton,
            excercise_name: response.result.excercise_name,
            excercise_ref: response.result.excercise_ref,
        };
        // Set the new excercises file to global state variable
        const newExcercises = extractValuesFromObject(response.excercises);
        setExcercises(newExcercises);

        setSavedButton(newButton);
        // Changing the excercise in the buttons array
        replaceButtonInButtonArray(setButtons, newButton);

        toast.success('Cvičení bylo úspěšně přiřazeno.', toastError);
        setLoading(false);
        somethingSaving(false);
        setAddNewExcercise(false);
    };

    return (
        <>
            {loading && <FullScreenLoading />}
            <div className="info-split">
                <label className="info-label" htmlFor="excercise">
                    Cvičení:
                </label>
                {!savedButton.excercise_name && (
                    <p className="body-3">Zatím nevybráno</p>
                )}
                {savedButton.excercise_name && (
                    <p className="body-3">{savedButton.excercise_name}</p>
                )}
            </div>
            {savedButton.excercise_ref && (
                <div className="info-split">
                    <img
                        src={getThumbnailPathForExcercise(
                            savedButton.excercise_ref,
                            excercises
                        )}
                        alt="excercise"
                        className="excercise-thumbnail-selected"
                    />
                </div>
            )}
            <div className="button-split">
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setSelectingExcercise(true)}
                >
                    Vybrat z knihovny
                </button>
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setAddNewExcercise(true)}
                >
                    Nahrát nové
                </button>
            </div>
            {selectingExcercise && (
                <ChooseExcerciseLibrary
                    excercises={excercises}
                    closeFunc={() => setSelectingExcercise(false)}
                    handleAddExcercise={(temp) => handleAddExcercise(temp)}
                    selectedButton={savedButton}
                />
            )}
            {addNewExcercise && (
                <UploadNewExcercise
                    closeFunc={() => setAddNewExcercise(false)}
                    acceptFunc={(temp) => {
                        handleAddNewExcercise(temp);
                    }}
                />
            )}
            {sameNameExcercise && (
                <Modal
                    closeFunction={() => setSameNameExcercise(false)}
                    acceptFuncion={async () => {
                        setSameNameExcercise(false);
                        await handleAddNewExcercise(uploadingExcercise, true);
                    }}
                    acceptText="Přepsat"
                >
                    <p className="body-1 mg-b-1 centered">
                        Cvičení, které se snažíte nahrát již v knihovně
                        existuje. Přejete si jej přepsat?
                    </p>
                </Modal>
            )}
        </>
    );
};

export default ExcerciseSelect;
