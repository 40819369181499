import React, { useState, useRef } from 'react';
import '../pages/styles/components/flexibleDragAndDrop.css';
import DeleteIMG from '../static/images/delete.png';
import { FILE_TYPE_TO_FILE_EXTENSION } from '../utils/translator';
import file_types from '../static/images/file_types/file_types.json';
const FlexibleDragAndDrop = ({
    file,
    setFile,
    acceptFiles,
    textOnBox,
    zipDropFunction,
    children,
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);
        const draggedFile = e.dataTransfer.files[0];
        console.log('Dropped file >> ', draggedFile);
        if (
            draggedFile &&
            file_types.zip_mimes.includes(draggedFile.type) &&
            zipDropFunction
        ) {
            let result = await zipDropFunction(draggedFile);
            if (!result) {
                return;
            }
        }
        console.log('ACCEPTING FILES >> ', acceptFiles);
        console.log('DROPPED FILE TYPE >> ', draggedFile.type);
        if (draggedFile && acceptFiles.includes(draggedFile.type)) {
            setFile(draggedFile);
        } else if (draggedFile && !draggedFile.type) {
            const translatedTypes = acceptFiles.map((type) =>
                FILE_TYPE_TO_FILE_EXTENSION(type)
            );
            if (translatedTypes.includes(draggedFile.name.split('.').pop())) {
                setFile(draggedFile);
            } else {
                console.log('Nahraný soubor není ve specifikovaném formátu.');
            }
        } else {
            console.log('Nahraný soubor není ve specifikovaném formátu.');
        }
    };
    const handleFileUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        console.log('Dropped file >> ', file);
        if (
            file &&
            file_types.zip_mimes.includes(file.type) &&
            zipDropFunction
        ) {
            let result = await zipDropFunction(file);
            if (!result) {
                return;
            }
        }
        console.log('ACCEPTING FILES >> ', acceptFiles);
        console.log('DROPPED FILE TYPE >> ', file.type);
        if (file && acceptFiles.includes(file.type)) {
            setFile(file);
        } else if (file && !file.type) {
            const translatedTypes = acceptFiles.map((type) =>
                FILE_TYPE_TO_FILE_EXTENSION(type)
            );
            if (translatedTypes.includes(file.name.split('.').pop())) {
                setFile(file);
            } else {
                console.log('Nahraný soubor není ve specifikovaném formátu.');
            }
        } else {
            console.log('Nahraný soubor není ve specifikovaném formátu.');
        }
    };
    return (
        <>
            {!file ? (
                <div
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={`flexible-drag-and-drop ${
                        isDragging ? 'dragging' : ''
                    }`}
                    onClick={() => {
                        fileInputRef.current.click();
                    }}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                    />
                    {!isDragging ? (
                        <h1 className="body-1">{textOnBox}</h1>
                    ) : (
                        <h1 className="body-1">Pusťte soubor.</h1>
                    )}
                </div>
            ) : (
                <div className="flexible-drag-and-drop-uploaded">
                    <div className="justify-content">
                        <p className="body-1">Soubor {file.name}</p>
                        <button
                            className="icon-button red bottom-delete-button"
                            onClick={() => {
                                setFile(null);
                            }}
                        >
                            <img
                                className="big"
                                title="Smazat"
                                src={DeleteIMG}
                                alt="smazat"
                            />
                        </button>
                    </div>
                    {children}
                </div>
            )}
        </>
    );
};

export default FlexibleDragAndDrop;
