import React, { useRef, useState } from 'react';

function InputBox({
    handleEditState,
    editState,
    checkInputFunction,
    id,
    label,
}) {
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);
    const handleButtonClick = () => {
        setIsEditing(true);
        inputRef.current.focus();
    };

    const handleInputBlur = () => {
        setIsEditing(false);
        checkInputFunction?.();
    };

    const handleInputChange = (e) => {
        handleEditState(e.target.value);
    };

    return (
        <div className="create-input-box">
            <label htmlFor={id} className="create-input-label">
                {label}
            </label>

            <input
                className={
                    'create-input-number' + (isEditing ? '' : ' readonly')
                }
                id={id}
                ref={inputRef}
                value={editState}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onClick={() => {
                    inputRef.current.blur();
                }}
            />

            <button className="btn btn-icon" onClick={handleButtonClick}>
                E
            </button>
        </div>
    );
}

export default InputBox;
