import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import classNames from 'classnames';
import './UploadingCircle.css';
const UploadingCircle = ({ percentage, uploadingDone, loading }) => {
    return (
        <div
            className={classNames({
                'uploading-circle': true,
                'uploading-circle-done': uploadingDone,
            })}
        >
            <CircularProgressbar
                value={percentage}
                strokeWidth={15}
                styles={buildStyles({
                    strokeLinecap: 'round',
                    pathTransitionDuration: 0.2,
                    pathColor: '#6b5de5',
                    textColor: '#6b5de5',
                    trailColor: '#E0E0E0',
                })}
            />
        </div>
    );
};

export default UploadingCircle;
