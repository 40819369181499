import { ErrorStatements } from './error_statements';
import errorStatementsJson from './error_statements.json';
const paths = {
    ADD_BUTTON: (book_catalog, release_number, version, page_number) =>
        `/interactive/add-button/${book_catalog}/${release_number}/${version}/${page_number}`,
    EDIT_BUTTON_POSITION: (
        book_catalog,
        release_number,
        version,
        page_number
    ) =>
        `/interactive/edit-button-position/${book_catalog}/${release_number}/${version}/${page_number}`,
    DELETE_BUTTON: (
        book_catalog,
        release_number,
        version,
        page_number,
        button_id
    ) =>
        `/interactive/delete-button/${book_catalog}/${release_number}/${version}/${page_number}/${button_id}`,
    ADD_BUTTON_TO_EXCERCISE: (
        book_catalog,
        release_number,
        version,
        page_number,
        button_id
    ) =>
        `/interactive/save-buttons-excercise/${book_catalog}/${release_number}/${version}/${page_number}/${button_id}`,
    ADD_NEW_EXCERCISE: (
        book_catalog,
        release_number,
        version,
        page_number,
        button_id
    ) =>
        `/interactive/add-new-excercise/${book_catalog}/${release_number}/${version}/${page_number}/${button_id}`,
    ADD_NEW_IMAGES: (
        book_catalog,
        release_number,
        version,
        page_number,
        button_id
    ) =>
        `/interactive/add-new-images/${book_catalog}/${release_number}/${version}/${page_number}/${button_id}`,
    REMOVE_IMAGE_FROM_BUTTON: (book_catalog, release_number, version) =>
        `/interactive/remove-image-from-button/${book_catalog}/${release_number}/${version}/`,
    ADD_IMAGES_TO_BUTTON: (button_id) =>
        `/media_excercises/library/add-images-to-button/${button_id}`,
    ADD_AUDIO_TO_BUTTON: (button_id) =>
        `/media_excercises/library/select-audio/${button_id}`,
    ADD_VIDEO_TO_BUTTON: (button_id) =>
        `/media_excercises/library/select-video/${button_id}`,
    UPLOAD_NEW_AUDIO_VIDEO: (button_id) =>
        `/media_excercises/library/upload-new-audio-video/${button_id}`,
    CHANGE_BUTTON_TYPE: () => `/media_excercises/button/change-type`,
    CHANGE_BUTTON_BASIC_ATTRIBUTE: () =>
        `/media_excercises/button/change-video-data`,
};
class ButtonsService {
    static async add_buttons(
        book_catalog,
        release_number,
        version,
        page_number,
        post_data,
        axios_instance
    ) {
        const path = paths.ADD_BUTTON(
            book_catalog,
            release_number,
            version,
            page_number
        );
        try {
            const response = await axios_instance.post(path, post_data);
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async edit_button_position(
        book_obj,
        page_number,
        new_positions,
        axios_instance
    ) {
        const path = paths.EDIT_BUTTON_POSITION(
            book_obj.bookid,
            book_obj.release,
            book_obj.version,
            page_number
        );
        try {
            const response = await axios_instance.post(path, new_positions);
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async delete_button(
        book_obj,
        page_number,
        button_id,
        axios_instance
    ) {
        const path = paths.DELETE_BUTTON(
            book_obj.bookid,
            book_obj.release,
            book_obj.version,
            page_number,
            button_id
        );
        try {
            const response = await axios_instance.delete(path);
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_buttons_excercise(
        book_obj,
        page_number,
        button_id,
        data,
        axios_instance
    ) {
        const path = paths.ADD_BUTTON_TO_EXCERCISE(
            book_obj.bookid,
            book_obj.release,
            book_obj.version,
            page_number,
            button_id
        );
        try {
            const response = await axios_instance.post(path, data);
            console.log(response.data);
            if (response.data.result === false) return { err: true };
            return { err: false, elements: response.data };
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_new_excercise_to_button(
        book_obj,
        page_number,
        button_id,
        zip_file,
        axios_instance
    ) {
        const path = paths.ADD_NEW_EXCERCISE(
            book_obj.bookid,
            book_obj.release,
            book_obj.version,
            page_number,
            button_id
        );
        try {
            const form_data = new FormData();
            form_data.append('file', zip_file);
            const response = await axios_instance.post(path, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            // Check the error code
            const err = ErrorStatements(
                errorStatementsJson.add_new_excercise_to_button,
                error.response.status
            );

            return err;
        }
    }
    static async add_new_images_to_button(
        book_obj,
        page_number,
        button_id,
        media_file,
        axios_instance,
        progress_function
    ) {
        const path = paths.ADD_NEW_IMAGES(
            book_obj.bookid,
            book_obj.release,
            book_obj.version,
            page_number,
            button_id
        );
        try {
            const form_data = new FormData();
            form_data.append('files', media_file);
            const response = await axios_instance.post(path, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted =
                        (progressEvent.loaded / progressEvent.total) * 100;
                    progress_function(percentCompleted);
                },
            });
            if (response.data.result === false) return { err: true };
            return { err: false, ...response.data };
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async remove_image_from_button(book_obj, json_data, axios_instance) {
        const path = paths.REMOVE_IMAGE_FROM_BUTTON(
            book_obj.bookid,
            book_obj.release,
            book_obj.version
        );
        try {
            // send json data to server
            const response = await axios_instance.post(path, json_data);
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_images_from_library_to_button(
        button_id,
        data,
        axios_instance
    ) {
        const path = paths.ADD_IMAGES_TO_BUTTON(button_id);
        try {
            const response = await axios_instance.post(path, data);
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_audio_from_library_to_button(
        button_id,
        data,
        axios_instance
    ) {
        const path = paths.ADD_AUDIO_TO_BUTTON(button_id);
        try {
            const response = await axios_instance.post(path, data);
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_video_from_library_to_button(
        button_id,
        data,
        axios_instance
    ) {
        const path = paths.ADD_VIDEO_TO_BUTTON(button_id);
        try {
            const response = await axios_instance.post(path, data);
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async upload_new_audio_video(
        button_id,
        data,
        axios_instance,
        progress_function
    ) {
        const path = paths.UPLOAD_NEW_AUDIO_VIDEO(button_id);
        try {
            const response = await axios_instance.post(path, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted =
                        (progressEvent.loaded / progressEvent.total) * 100;
                    progress_function(percentCompleted);
                },
            });
            if (response.data.result === false) return { err: true };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async change_button_type(data, axios_instance) {
        const path = paths.CHANGE_BUTTON_TYPE();
        try {
            const response = await axios_instance.post(path, data);
            if (!response.data.result)
                return { err: true, detail: response.data.msg };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async change_button_basic_attribute(data, axios_instance) {
        const path = paths.CHANGE_BUTTON_BASIC_ATTRIBUTE();
        try {
            const response = await axios_instance.post(path, data);
            if (!response.data.result)
                return { err: true, detail: response.data.msg };
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
}
export default ButtonsService;
