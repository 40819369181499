const paths = {
    REFRESH_ASSETS: () => '/interactive/refresh-assets',
};

class OtherService {
    static async refresh_assets(axios_instance) {
        const path = paths.REFRESH_ASSETS();
        try {
            const response = await axios_instance.get(path);
            return response;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
}

export default OtherService;
