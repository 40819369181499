import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import '../styles/auth/login.css';
import '../styles/buttons.css';
import AuthService from '../../services/auth.service';
import { toast } from 'react-toastify';
import { setBodyGradient } from '../../utils/setBodyColor';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Login = () => {
    setBodyGradient('#5ecfff', '#69d2ff');
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/admin';

    const emailRef = useRef(null);

    const [email, setEmail] = useState('test@testing.com');
    const [isEmailValid, setIsEmailValid] = useState(false);

    const [pwd, setPwd] = useState('');

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    useEffect(() => {
        setIsEmailValid(EMAIL_REGEX.test(email));
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isEmailValid) {
            toast.error('Nevalidní email', {
                autoClose: 2000,
                position: 'bottom-right',
                closeOnClick: true,
                draggable: false,
                theme: 'colored',
            });
            return;
        }
        if (pwd === '') {
            toast.error('Musíte vyplnit pole heslo.', {
                autoClose: 2000,
                position: 'bottom-right',
                closeOnClick: true,
                draggable: false,
                theme: 'colored',
            });
            return;
        }
        const result = await AuthService.login(email, pwd);
        if (result.err) {
            toast.error(result.msg, {
                autoClose: 2000,
                position: 'bottom-right',
                closeOnClick: true,
                draggable: false,
                theme: 'colored',
            });
            return;
        }
        setAuth({
            access: result.access,
            roles: result.roles,
            user_id: result.user_id,
        });
        navigate(from, { replace: true });
    };

    const togglePersist = () => {
        setPersist((prev) => !prev);
    };
    useEffect(() => {
        localStorage.setItem('persist', persist);
    }, [persist]);

    return (
        <section className="outer-div">
            <div className="login-center">
                <h1 className="login-header">Přihlášení</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="login-label">
                            E-mail:
                        </label>
                        <input
                            type="text"
                            ref={emailRef}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Sem napište vaši e-mailovou adresu"
                            className="login-input"
                        />
                        {!(isEmailValid || !email) && (
                            <p className="error-msg">
                                Nesprávný formát e-mailové adresy.
                            </p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="password" className="login-label">
                            Heslo:
                        </label>
                        <input
                            type="password"
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                            placeholder="Sem napište vaše heslo"
                            className="login-input"
                        />
                    </div>
                    <div className="right-aligned">
                        <div className="persist-div">
                            <input
                                type="checkbox"
                                id="persist"
                                onChange={togglePersist}
                                checked={persist}
                            />
                            <label htmlFor="persist">
                                Zapamatovat toto zařízení
                            </label>
                        </div>

                        <button
                            className="btn btn-blue"
                            disabled={!isEmailValid}
                        >
                            Přihlásit se
                        </button>
                    </div>
                </form>
            </div>
            <div className="current-version">
                <p>Tato verze pochází z: {/*VERSION_INSERT*/} 08.10.24 18:23</p>
            </div>
        </section>
    );
};

export default Login;
