import AudioIMG from '../static/images/audio50.png';
import VideoIMG from '../static/images/video50.png';
import ImageIMG from '../static/images/fotka50.png';
import ExcerciseIMG from '../static/images/cviceni50.png';
const BUTTON_TYPES = {
    image: 0,
    audio: 1,
    video: 2,
    excercise: 3,
    video_link: 4,
    video_web: 5,
};

const isMouseInObject = (buttons, event, canvas) => {
    // Getting the relative X and Y in the canvas
    if (!buttons) return;
    if (!canvas) return;
    const x = event.nativeEvent.offsetX - canvas.current.clientLeft;
    const y = event.nativeEvent.offsetY - canvas.current.clientTop;

    let inside = -1;
    buttons.forEach((button, index) => {
        ////if (inside !== -1) return;
        if (
            x > button.x &&
            x < button.x + button.width &&
            y > button.y &&
            y < button.y + button.height
        ) {
            inside = index;
        }
    });
    return inside;
};
const printButtons = (page, buttons) => {
    console.log('|--', page);

    buttons.forEach((button, index) => {
        console.log(
            `  |-----Button ${button.key}: x: ${button.x}, y: ${button.y}`
        );
    });
};
const redrawButtons = (
    buttons,
    canvas,
    firstDraw = false,
    selected_button_key = null
) => {
    if (!canvas) return;
    const ctx = canvas.current.getContext('2d');
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    buttons.forEach((button) => {
        const img = new Image();
        img.src = button.img;
        if (firstDraw)
            img.onload = () => ctx.drawImage(img, button.x, button.y, 30, 30);
        else ctx.drawImage(img, button.x, button.y, 30, 30);
        if (selected_button_key && selected_button_key === button.key) {
            ctx.beginPath();
            ctx.lineWidth = '2';
            ctx.strokeStyle = '#ff3d3d';
            ctx.fillStyle = '#ff3d3d';
            ctx.rect(
                button.x - 2,
                button.y - 2,
                button.width + 5,
                button.height + 5
            );
            ctx.stroke();
        }
    });
};
const get_button_label = (button) => {
    if (!button) return 'None';
    switch (button.type) {
        case BUTTON_TYPES.image:
            return 'Tlačítko obrázek';
        case BUTTON_TYPES.audio:
            return 'Tlačítko audio';
        case BUTTON_TYPES.video:
            return 'Tlačítko video';
        case BUTTON_TYPES.excercise:
            return 'Tlačítko cvičení';
        default:
            return 'Image';
    }
};
const get_new_button_label = (button) => {
    if (button.original_type === 4) return 'Tlačítko video (odkaz)';
    if (button.original_type === 5) return 'Tlačítko video (web)';
    switch (button.type) {
        case 'AUDIO':
            return 'Tlačítko audio';
        case 'DRAW':
            return 'Tlačítko kreslit';
        case 'FIRST_DRAW':
            return 'Tlačítko kreslit';
        case 'INTERACTIVE':
            return 'Tlačítko cvičení';
        case 'MOVE':
            return 'Tlačítko pohyb';
        case 'PHOTO':
            return 'Tlačítko obrázek';
        case 'RESULT':
            return 'Tlačítko výsledek';
        case 'SPEAK':
            return 'Tlačítko mluvení';
        case 'VIDEO_LOCAL':
            return 'Tlačítko video (lokální)';
        case 'VIDEO_WEB':
            return 'Tlačítko video web';
        default:
            return 'Neznámé tlačítko';
    }
};
const get_button_image = (button) => {
    switch (button.type) {
        case BUTTON_TYPES.image:
            return ImageIMG;
        case BUTTON_TYPES.audio:
            return AudioIMG;
        case BUTTON_TYPES.video:
            return VideoIMG;
        case BUTTON_TYPES.excercise:
            return ExcerciseIMG;
        default:
            return ImageIMG;
    }
};
const button_types_mode = {
    0: 'PHOTO',
    1: 'AUDIO',
    2: 'VIDEO_LOCAL',
    3: 'INTERACTIVE',
    4: 'VIDEO_WEB',
    5: 'VIDEO_WEB',
};
const format_buttons = (button, width, height) => {
    return {
        ...button,
        x: Math.round(button.x * width),
        y: Math.round(button.y * height),
        original_type: button.type,
        type: button_types_mode[button.type],
    };
};
const unformat_buttons = (button, width, height) => {
    return {
        ...button,
        x: button.x / width,
        y: button.y / height,
        type: button.original_type,
    };
};

const getKonvaCursorLocation = (e) => {
    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    return {
        x: mousePos.x,
        y: mousePos.y,
    };
};
const getHeightWidthFromMouse = (initMouse, e) => {
    const currentMouse = getKonvaCursorLocation(e);

    return {
        ...initMouse,
        width: currentMouse.x - initMouse.x,
        height: currentMouse.y - initMouse.y,
    };
};
export {
    isMouseInObject,
    printButtons,
    redrawButtons,
    get_button_label,
    get_button_image,
    BUTTON_TYPES,
    get_new_button_label,
    format_buttons,
    button_types_mode,
    unformat_buttons,
    getKonvaCursorLocation,
    getHeightWidthFromMouse,
};
