import { HexColorPicker, HexColorInput } from "react-colorful";
import React, { useState, useRef, useEffect } from "react";
import "./ColorPickerLine.css";

const ColorPickerLine = ({ label, color, setColor, confirmChange }) => {
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [changeTimeout, setChangeTimeout] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const colorPickerRef = useRef();
	useEffect(() => {
		const handleClickedOutside = (e) => {
			if (e.target.className === "show-color-from-picker") return;
			if (
				showColorPicker &&
				colorPickerRef.current &&
				!colorPickerRef.current.contains(e.target)
			) {
				setShowColorPicker(false);
			}
		};
		document.addEventListener("mousedown", handleClickedOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickedOutside);
		};
	}, [showColorPicker]);
	useEffect(() => {
		if (initialLoad) {
			setInitialLoad(false);
			return;
		}
		if (changeTimeout) {
			clearTimeout(changeTimeout);
		}
		setChangeTimeout(
			setTimeout(() => {
				confirmChange && confirmChange();
				setChangeTimeout(null);
			}, [1000])
		);
		return () => {
			if (changeTimeout) {
				clearTimeout(changeTimeout);
			}
		};
	}, [color]);
	return (
		<div className="color-picker-line">
			<p className="body-4">{label}</p>
			<div
				className="show-color-from-picker"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setShowColorPicker(!showColorPicker);
				}}
			>
				<div
					className="color-from-picker-inner-div"
					style={{
						backgroundColor: color,
					}}
				></div>
			</div>
			{showColorPicker && (
				<div className="actual-color-picker" ref={colorPickerRef}>
					<HexColorPicker color={color} onChange={setColor} />
					<HexColorInput color={color} onChange={setColor} />
				</div>
			)}
		</div>
	);
};

export default ColorPickerLine;
