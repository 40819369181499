import { BASE_STATIC } from '../deffault/axios';
import fileMapping from '../static/images/file_types/file_types.json';
import React from 'react';

const getFileIcon = (file) => {
    if (file.type) {
        //Check if mime type is in list
        if (fileMapping['mime_type_to_icon'][file.type])
            return fileMapping['mime_type_to_icon'][file.type];

        return '/static/images/file_types/defaultFileType.svg';
    } else {
        // get file extension include dot
        const file_extension = file.name.split('.').pop();
        if (fileMapping['file_extension_to_icon'][`.${file_extension}`]) {
            return fileMapping['file_extension_to_icon'][`.${file_extension}`];
        }
        return '/static/images/file_types/defaultFileType.svg';
    }
};

const getDimension = (dimension) => {
    if (dimension) {
        return dimension;
    }
    return '100%';
};
const FileIcon = ({ file, width, height }) => {
    return (
        <img
            src={BASE_STATIC + getFileIcon(file)}
            alt="file"
            style={{
                width: getDimension(width),
                height: getDimension(height),
            }}
        />
    );
};

export default FileIcon;
