import React, { useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import beautify from "js-beautify";
import { Parser } from "htmlparser2";
import ErrorMessage from "../../../../components/ErrorMessageComp/ErrorMessage";
import FaviconOfSite from "../../../../components/FaviconOfSite";
import { getPathFromIframe, isValidIframe } from "../../../../utils/path_utils";
import OneLineInput from "../../../../components/OneLineInput/OneLineInput";
import SplitInfoDisplay from "~/components/SplitInfoDisplay/SplitInfoDisplay";
import VideoEmbedded from "../../StaticPageComponents/VideoEmbedded";

const formatCode = (code) => {
	try {
		const formattedCode = beautify.html(code, {
			indent_size: 2,
			wrap_line_length: 80,
			preserve_newlines: false, // Disable preserving newlines between tags
			max_preserve_newlines: 0, // Max number of consecutive line breaks to preserve
			end_with_newline: false, // Do not end with a newline
			unformatted: [], // Ensure all tags are formatted
			extra_liners: [], // Disable extra lines before tags
		});

		return { error: false, code: formattedCode };
	} catch (error) {
		const formattedCode = beautify.html(code, {
			indent_size: 2,
			wrap_line_length: 80,
		});
		return { error: true, message: error.message, code: formattedCode };
	}
};

const InsertVideoCode = ({ handleChange, selectedButton }) => {
	const [insertingCode, setInsertingCode] = useState(false);
	const [error, setError] = useState("");
	const [videoCode, setVideoCode] = useState(
		selectedButton.video ? selectedButton.video.embed : ""
	);
	const [autorName, setAutorName] = useState(
		selectedButton.video?.author || ""
	);
	const [sourceName, setSourceName] = useState(
		selectedButton.video?.source || ""
	);
	const [showingButtonVideo, setShowingButtonVideo] = useState(null);
	const errorRef = useRef(null);
	const authorRef = useRef(null);
	const sourceRef = useRef(null);
	const handleInputChange = (e) => {
		const rawCode = e.target.value;
		if (!rawCode) {
			setVideoCode("");
			setError("");
			return;
		}
		const errorCheck = isValidIframe(rawCode);
		if (errorCheck.err) {
			setError(errorCheck.msg);
			setVideoCode(rawCode);
			return;
		}
		const formattedCode = formatCode(rawCode);
		if (formattedCode.error) {
			setError(formattedCode.message);
			setVideoCode(formattedCode.code);
			return;
		}
		setError("");
		setVideoCode(formattedCode.code);
	};
	const handleAccept = async () => {
		if (!videoCode) {
			setError("Kód nesmí být prázdný");
			if (errorRef.current) errorRef.current.blink();
			return;
		}
		const errorCheck = formatCode(videoCode);
		if (errorCheck.error) {
			setError(errorCheck.message);
			if (errorRef.current) errorRef.current.blink();
			return;
		}
		const errorCheck2 = isValidIframe(videoCode);
		if (errorCheck2.err) {
			setError(errorCheck2.msg);
			if (errorRef.current) errorRef.current.blink();
			return;
		}
		const videoData = {
			embed: videoCode,
			author: autorName,
			source: sourceName,
		};
		await handleChange(videoData);
		setInsertingCode(false);
		setError("");
	};

	return (
		<>
			{!selectedButton.video ? (
				<SplitInfoDisplay label="Video" value={undefined} />
			) : (
				<div className="info-split">
					<label className="info-label" htmlFor="excercise">
						Video:
					</label>
					<div
						className="space-between gap-1"
						onClick={() => setShowingButtonVideo(selectedButton)}
						style={{ cursor: "pointer" }}
					>
						<p className="body-4">Video z:</p>
						<FaviconOfSite
							alt="Brand icon"
							url={getPathFromIframe(selectedButton.video.embed)}
						/>
					</div>
				</div>
			)}
			{selectedButton.video && (
				<>
					<SplitInfoDisplay
						label="Autor"
						value={selectedButton.video?.author}
					/>
					<SplitInfoDisplay
						label="Zdroj"
						value={selectedButton.video?.source}
						maxLength={20}
						url={true}
					/>
				</>
			)}
			<button
				className="btn btn-purple full-width harder-corners"
				onClick={() => setInsertingCode(true)}
			>
				Vložit/upravit video
			</button>
			{insertingCode && (
				<Modal
					closeFunction={() => {
						setError("");
						setInsertingCode(false);
					}}
					acceptFuncion={handleAccept}
				>
					<OneLineInput
						ref={authorRef}
						id="author"
						label="Autor"
						s
						className="mg-b-1"
						value={autorName}
						onChange={setAutorName}
					/>
					<OneLineInput
						ref={sourceRef}
						id="zdroj"
						label="Zdroj"
						className="mg-1"
						value={sourceName}
						onChange={setSourceName}
					/>

					<textarea
						className="textarea mg-1"
						onChange={handleInputChange}
						rows={6}
					>
						{videoCode}
					</textarea>
					<SyntaxHighlighter
						language="html"
						style={okaidia}
						customStyle={{
							borderRadius: "0.5rem",
							padding: "10px",
							backgroundColor: "#2b2d3d",
							fontSize: "14px",
						}}
					>
						{videoCode}
					</SyntaxHighlighter>
					<ErrorMessage error={error} ref={errorRef} />
				</Modal>
			)}
			{showingButtonVideo && (
				<VideoEmbedded
					button={showingButtonVideo}
					closeFunc={() => setShowingButtonVideo(null)}
				/>
			)}
		</>
	);
};

export default InsertVideoCode;
