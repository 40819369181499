export const formatDatetimeString = (datetimeString) => {
    const formattedDateTime = new Date(datetimeString).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    return formattedDateTime;
};
