import { useState } from 'react';
import '../styles/admin/mainPage.css';
import '../styles/text.css';
import '../styles/buttons.css';
import { Link } from 'react-router-dom';
import setBodyColor from '../../utils/setBodyColor';
import OtherService from '../../services/others.service';
import FullScreenLoading from '../../components/FullScreenLoading';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
/// comment
const MainPage = () => {
    setBodyColor('#fff');
    const [loading, setLoading] = useState(false);
    const privateAxios = useAxiosPrivate();
    const handleRefreshAssets = async () => {
        setLoading(true);
        const response = await OtherService.refresh_assets(privateAxios);
        if (response.err) {
            toast.error('Nastala chyba při aktualizaci assetů', toastError);
            return;
        }
        toast.success('Assety byly aktualizovány', toastError);
        setLoading(false);
    };
    return (
        <>
            <section className="center-content">
                <div className="admin-list">
                    <Link
                        to="/admin/uzivatele"
                        className="huge-navigator purple"
                    >
                        Uživatelé
                    </Link>
                    <Link to="/admin/knihy" className="huge-navigator blue">
                        Knihy
                    </Link>
                    <Link
                        to="/admin/interaktivky"
                        className="huge-navigator pink"
                    >
                        Interaktivní učebnice
                    </Link>
                    <Link to="" className="huge-navigator yellow">
                        Statistiky
                    </Link>
                    <Link to="" className="huge-navigator purple">
                        Publikované
                    </Link>
                    <Link to="" className="huge-navigator blue">
                        Předměty
                    </Link>
                </div>
            </section>
            <button
                className="btn btn-yellow assets_update"
                onClick={handleRefreshAssets}
            >
                Aktualizace assetů
            </button>

            {loading && <FullScreenLoading />}
        </>
    );
};

export default MainPage;
