import React from 'react';
import './TextAreaComponent.css';
const TextAreaComponent = ({ label, value, icon, onChange, changeAccept }) => {
    return (
        <div className="text-area-component mg-1">
            <div className="flex-start mg-b-1 gap-1">
                <button
                    className="btn btn-purple icon-button medium"
                    onClick={() => {}}
                >
                    <img src={icon} alt="Note" />
                </button>
                <p className="body-3 font-weight-500">{label}</p>
            </div>
            <textarea
                className="text-area"
                value={value}
                onChange={onChange}
                onBlur={changeAccept}
            />
        </div>
    );
};

export default TextAreaComponent;
