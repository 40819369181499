import React from 'react';
import './elems.css';
import AudioIcon from '~/static/images/audio-icon.png';
import { shortenText } from '~/utils/translator';
import DeleteIcon from '~/static/images/delete_white.png';

import { getNavigateToButtonPath } from '~/utils/navigations';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

const AudioElem = ({ audio, removeFunc, book, playAudio }) => {
    const navigate = useNavigate();
    const handleClick = (page, button_id) => {
        const path = getNavigateToButtonPath(book, page, button_id);
        navigate(path);
    };
    const handleClickAudio = () => {
        playAudio(audio);
    };
    return (
        <div className="library_elem_outer">
            <div
                className="library_elem_item smaller"
                onClick={handleClickAudio}
            >
                <img
                    className="libary_image_center"
                    src={AudioIcon}
                    alt="audio"
                />
                <div className="library_elem_name">
                    <p>{shortenText(audio.name, 20)}</p>
                    <p>{audio.length}</p>
                </div>
            </div>
            {removeFunc && (
                <div className="library_elem_buttons top-right">
                    <Tooltip title="Odstranit video soubor" position="left">
                        <button
                            className="library_elem_button delete"
                            onClick={removeFunc}
                        >
                            <img src={DeleteIcon} alt="delete" />
                        </button>
                    </Tooltip>
                </div>
            )}
            {audio.buttons && audio.buttons.length > 0 && (
                <div className="library_elem_buttons bottom-left off">
                    {audio.buttons
                        .sort((a, b) => a.page - b.page)
                        .map((button, i) => (
                            <button
                                key={i}
                                className="icon-button smaller circular library"
                                title={`Tento audio soubor je propojen s tlačítkem na straně ${button.page}`}
                                onClick={() =>
                                    handleClick(button.page, button.button_id)
                                }
                            >
                                <p>{button.page}</p>
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

export default AudioElem;
