import file_types from '../static/images/file_types/file_types.json';

const VIDEO_EXTENSIONS = file_types['video_extensions'];
const IMAGE_EXTENSIONS = file_types['image_extensions'];
const AUDIO_EXTENSIONS = file_types['audio_extensions'];

const VIDEO_MIMES = file_types['video_mimes'];
const IMAGE_MIMES = file_types['image_mimes'];
const AUDIO_MIMES = file_types['audio_mimes'];

const FILE_TYPES = {
    video: {
        extensions: VIDEO_EXTENSIONS,
        mimes: VIDEO_MIMES,
    },
    audio: {
        extensions: AUDIO_EXTENSIONS,
        mimes: AUDIO_MIMES,
    },
    image: {
        extensions: IMAGE_EXTENSIONS,
        mimes: IMAGE_MIMES,
    },
};

const PATH_TO_TYPE = (file_path) => {
    const extension = file_path.split('.').pop();

    return file_types.file_extension_to_mime_type['.' + extension];
};

export {
    VIDEO_EXTENSIONS,
    IMAGE_EXTENSIONS,
    AUDIO_EXTENSIONS,
    VIDEO_MIMES,
    IMAGE_MIMES,
    AUDIO_MIMES,
    FILE_TYPES,
    PATH_TO_TYPE,
};
