import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import './BetterModal.css';
const BetterModal = ({
    opened,
    closeModal,
    acceptModal,
    children,
    header,
    disableClose = false,
    disableConfirm = false,
    hideClose = false,
    hideOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState(opened);
    const [closing, setClosing] = useState(false);

    // Used for delaying closing animations
    useEffect(() => {
        if (opened) {
            setIsOpen(true);
        } else {
            setClosing(true);
            setTimeout(() => {
                setIsOpen(false);
                setClosing(false);
            }, 300);
        }
    }, [opened]);

    const modal = isOpen && (
        <div className={`better-modal-overlay ${closing ? 'closing' : ''}`}>
            <div className={`better-modal ${closing ? 'closing' : ''}`}>
                <h4 className="body-1 font-weight-500 centered">{header}</h4>
                <div className="better-modal-content">{children}</div>
                <div className="bottom-buttons">
                    {hideClose ? (
                        <div></div>
                    ) : (
                        <button
                            className="btn btn-yellow"
                            onClick={() => closeModal()}
                            disabled={disableClose}
                        >
                            Zavřít
                        </button>
                    )}
                    {hideOpen ? (
                        <div></div>
                    ) : (
                        <button
                            className="btn btn-blue"
                            onClick={() => acceptModal()}
                            disabled={disableConfirm}
                            id="confirm-better-modal"
                        >
                            Potvrdit
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
    return ReactDom.createPortal(modal, document.getElementById('modal-popup'));
};

export default BetterModal;
