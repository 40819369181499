import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../styles/text.css';
import { Roller } from 'react-awesome-spinners';
import { BASE_STATIC } from '../../deffault/axios';

const Interactive = () => {
    let { bookid } = useParams();
    let privateAxios = useAxiosPrivate();
    let [book, setBook] = useState(null);
    useEffect(() => {
        const get_interactive_books = async () => {
            const response = await privateAxios.get(`/interactive/${bookid}/`);
            console.log(response.data);
            setBook(response.data);
        };
        get_interactive_books();
    }, [bookid, privateAxios]);
    return (
        <>
            {!book ? (
                <div className="loader-container">
                    <Roller color="#00b2ff" size={64} sizeUnit="px" />
                </div>
            ) : (
                <div className="centered-content">
                    <div className="interactive-book-detail">
                        <div className="rounded-corners-image">
                            <img
                                src={BASE_STATIC + book.picture_ref}
                                alt="Obrazek knihy"
                                className="interactive-book-detail-image"
                            />
                        </div>
                        <div className="interactive-book-text">
                            <div>
                                <h1 className="header-3 underlined">
                                    {book.name}
                                </h1>
                                <p className="body-1">
                                    Katalogové číslo: {book.catalog}
                                </p>
                                <p className="body-1">
                                    Předmět: {book.subject_id}
                                </p>
                                <p className="body-1">
                                    Hlavní produkt:{' '}
                                    {book.main_product ? 'Ano' : 'Ne'}
                                </p>
                            </div>
                            <div className="version-selection">
                                <button className="btn btn-yellow">
                                    Editovat
                                </button>
                                <button className="btn btn-pink">
                                    Vytvořit novou
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Interactive;
