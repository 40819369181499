import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const CustomAudio = ({ src, onPlay }) => {
    const [currentVolume, setCurrentVolume] = useState(() => {
        const savedVolume = localStorage.getItem('audioPlayerVolume');
        return savedVolume !== null ? parseFloat(savedVolume) : 0.5;
    });
    const handleVolumeChange = (event) => {
        const newVolume = event.target.volume;
        setCurrentVolume(newVolume);
        localStorage.setItem('audioPlayerVolume', newVolume);
    };

    return (
        <AudioPlayer
            src={src}
            style={{
                width: '100%',
            }}
            showJumpControls={false}
            customAdditionalControls={[]}
            autoPlayAfterSrcChange={false}
            volume={currentVolume}
            onVolumeChange={handleVolumeChange}
        />
    );
};

export default CustomAudio;
