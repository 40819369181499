import React from 'react';
import IosSwitchMaterialUi from 'ios-switch-material-ui';

const SwitchButton = ({ label, onClick, defaultValue }) => {
    return (
        <div className="space-between gap-2">
            <p className="body-4">{label}</p>
            <IosSwitchMaterialUi
                onChange={(checked) => onClick(!checked)}
                knobSize={12}
                defaultKnobOnLeft={defaultValue}
            />
        </div>
    );
};

export default SwitchButton;
