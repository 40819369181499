import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import SearchInput from '../../../../components/SearchInput';
import AudioIcon from '../../../../static/images/audio-icon.png';
import LibraryElem from './LibraryElem';

const shouldView = (audio, searchedText) => {
    if (!searchedText) return true;
    const lower_case_searched = searchedText
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    const lower_case_name = audio.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    return lower_case_name.includes(lower_case_searched)
        ? 'visible'
        : 'not-visible';
};

const isSelected = (audio, selectedAudio) => {
    if (selectedAudio === null) return '';
    return audio.key === selectedAudio?.key ? 'selected' : 'not-selected';
};

const ChooseAudiosLibrary = ({
    audios,
    savedButton,
    closeFunc,
    acceptFunc,
}) => {
    const [tempSelected, setTempSelected] = useState(null);
    const [searchText, setSearchText] = useState('');
    const handleSelect = (audio) => {
        if (audio.key === tempSelected?.key) {
            setTempSelected(null);
        } else {
            setTempSelected(audio);
        }
    };
    useEffect(() => {
        if (savedButton.audio) {
            if (savedButton.audio.key) {
                const newTemp = audios.find(
                    (audio) => audio.key === savedButton.audio.key
                );
                setTempSelected(newTemp);
            } else {
                const newTemp = audios.find(
                    (audio) => audio.key === savedButton.audio['_id']
                );
                setTempSelected(newTemp);
            }
        }
    }, [savedButton, audios]);
    return (
        <Modal
            closeFunction={() => {
                closeFunc();
                setTempSelected(null);
            }}
            acceptFuncion={() => {
                acceptFunc(tempSelected);
            }}
        >
            <div className="library-header-split">
                <h1>Vyberte audio.</h1>
                <SearchInput
                    placeholder="Hledat"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="library-audios-select">
                {audios.map((audio, i) => (
                    <LibraryElem
                        key={i}
                        elem={audio}
                        searchText={searchText}
                        image={AudioIcon}
                        clickedFunc={handleSelect}
                        pageSuggestion={true}
                        selected={audio.key === tempSelected?.key}
                        selectedAny={tempSelected !== null}
                        smaller={true}
                        buttons={audio.buttons}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default ChooseAudiosLibrary;
