import { diff, intersection } from "martinez-polygon-clipping";
import { toast } from "react-toastify";
import toastSettings from "./toastSettings";
// Helper function to check if a point is inside a polygon
function isPointInPolygon(point, polygon) {
	let x = point.x,
		y = point.y;
	let inside = false;
	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		let xi = polygon[i].x,
			yi = polygon[i].y;
		let xj = polygon[j].x,
			yj = polygon[j].y;

		let intersect =
			yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi + xi);
		if (intersect) inside = !inside;
	}
	return inside;
}

// Helper function to check if a point is inside a rectangle
function isPointInRectangle(point, rect) {
	return (
		point.x >= rect.x &&
		point.x <= rect.x + rect.width &&
		point.y >= rect.y &&
		point.y <= rect.y + rect.height
	);
}

// Helper function to check if two line segments intersect
function doLinesIntersect(a1, a2, b1, b2) {
	function ccw(p1, p2, p3) {
		return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
	}
	return (
		ccw(a1, b1, b2) !== ccw(a2, b1, b2) &&
		ccw(a1, a2, b1) !== ccw(a1, a2, b2)
	);
}

// Main function to check if rectangle intersects with polygon
function isRectangleIntersectingPolygon(rect, polygon) {
	// Step 1: Check if any corner of the rectangle is inside the polygon
	let rectCorners = [...rect];
	for (let corner of rectCorners) {
		if (isPointInPolygon(corner, polygon)) {
			return true;
		}
	}

	// Step 2: Check if any vertex of the polygon is inside the rectangle
	for (let point of polygon) {
		if (isPointInRectangle(point, rect)) {
			return true;
		}
	}

	// Step 3: Check for edge intersections
	// Rectangle edges
	let rectEdges = [
		[rectCorners[0], rectCorners[1]],
		[rectCorners[1], rectCorners[2]],
		[rectCorners[2], rectCorners[3]],
		[rectCorners[3], rectCorners[0]],
	];
	// Polygon edges
	let polyEdges = [];
	for (let i = 0; i < polygon.length; i++) {
		let nextIndex = (i + 1) % polygon.length;
		polyEdges.push([polygon[i], polygon[nextIndex]]);
	}
	// Check for intersections
	for (let [a1, a2] of rectEdges) {
		for (let [b1, b2] of polyEdges) {
			if (doLinesIntersect(a1, a2, b1, b2)) {
				return true;
			}
		}
	}

	// Step 4: Check if polygon is completely inside the rectangle
	// (Handled in Step 2)

	// If none of the above, they do not intersect
	return false;
}

const closePolygonIfNeeded = (points) => {
	const firstPoint = points[0];
	const lastPoint = points[points.length - 1];
	if (firstPoint.x !== lastPoint.x || firstPoint.y !== lastPoint.y) {
		points.push(firstPoint);
	}
	return points;
};
// Convert your polygon and rectangle to the format required by martinez
const convertToMartinezFormat = (points) => {
	const closedPolygon = closePolygonIfNeeded(points);

	return [closedPolygon.map((point) => [point.x, point.y])];
};
// Main function to subtract rectangle from polygon
const subtractRectangleFromPolygon = (polygonPoints, rectangle) => {
	const formatedPolygon = convertToMartinezFormat(polygonPoints);
	const formatedRectangle = convertToMartinezFormat(rectangle);

	// Perform the difference operation
	const result = diff(formatedPolygon, formatedRectangle);

	// Convert back to your format
	function convertFromMartinezFormat(polygons) {
		return polygons.map((polygon) =>
			polygon.map((point) => ({ x: point[0], y: point[1] }))
		);
	}

	const resultPolygons = result ? result.map(convertFromMartinezFormat) : [];

	return resultPolygons;
};

const getCutOutSection = (polygon, rectangle) => {
	const formatedPolygon = convertToMartinezFormat(polygon);
	const formatedRectangle = convertToMartinezFormat(rectangle);

	const result = intersection(formatedPolygon, formatedRectangle);

	function convertFromMartinezFormat(multiPolygon) {
		if (!multiPolygon) return [];
		return multiPolygon.flatMap((polygon) =>
			polygon.map((ring) =>
				ring.map((point) => ({ x: point[0], y: point[1] }))
			)
		);
	}

	const cutOutSection = convertFromMartinezFormat(result);
	return cutOutSection;
};

const cutRectangleOut = (polygon, rectangle) => {
	let cutout = getCutOutSection(polygon, rectangle);
	let result = subtractRectangleFromPolygon(polygon, rectangle);
	if (result.length !== 1) {
		toast.error("Nepodporované ořezání polygonu!", toastSettings);
		return null;
	}
	result = result[0][0].slice(0, -1);
	cutout = cutout[0].slice(0, -1);

	return { newPolygon: result, cuttedOut: cutout };
};
export {
	isRectangleIntersectingPolygon,
	subtractRectangleFromPolygon,
	getCutOutSection,
	cutRectangleOut,
};
