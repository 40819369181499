import React, { useCallback, useEffect, useState } from 'react';
import MediaExcercisesService from '../../../services/media_excercises.service';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { PAGE_ENUM } from './LibrarySwitches';
import { toast } from 'react-toastify';
import toastSettings from '../../../utils/toastSettings';
import DragAndDropNoStyle from '../../../components/DragAndDropNoStyle';
import FlexibleLoader from '../../../components/FlexibleLoader/FlexibleLoader';
import ImageElem from './elems/ImageElem';
import PopUpConfirm from '../../../components/PopUpConfirm';
import { shouldView } from '../../../utils/stateUtils';
import { extractValuesFromObject } from '../../../utils/objectUtils';
import { useAssetImages, useLoadingAssets } from '../../../stores/assetStore';
import ImageEdit from '~/components/ImageEdit';

const IMAGE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'image/webp',
    'image/tiff',
    'image/bmp',
];

const ImagesPage = ({ book, reAsk, search }) => {
    const privateAxios = useAxiosPrivate();
    const [images, setImages] = useAssetImages();
    const [loading, setLoading] = useLoadingAssets();
    const [removingImage, setRemovingImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const get_images = useCallback(async () => {
        const images_response = await MediaExcercisesService.get_media(
            PAGE_ENUM.IMAGE,
            book,
            privateAxios
        );
        if (images_response.err) {
            toast.error('Nastala chyba při načítání obrázků', toastSettings);
            return;
        }
        if (!images_response.result) {
            toast.error(images_response.message, toastSettings);
            return;
        }
        const new_images = extractValuesFromObject(images_response.images).sort(
            (a, b) => a.name.localeCompare(b.name)
        );
        setImages(new_images);
        setLoading(false);
    }, [book, privateAxios]);

    useEffect(() => {
        if (reAsk) get_images();
    }, [reAsk, get_images]);

    const removeImage = async () => {
        // Look if the image is used in the book
        if (removingImage.buttons && removingImage.buttons.length > 0) {
            toast.error(
                'Nelze smazat obrázek, který je přiřazen k nějakému tlačítku',
                toastSettings
            );
            return;
        }
        const remove_obj = {
            ...book,
            asset_id: removingImage.key,
        };
        const result = await MediaExcercisesService.remove_asset(
            remove_obj,
            false,
            privateAxios
        );
        if (result.err) {
            toast.error('Nastala chyba při mazání obrázku', toastSettings);
            return;
        }
        setImages(images.filter((img) => img.key !== removingImage.key));
        setRemovingImage(null);
        toast.success('Obrázek byl úspěšně smazán', toastSettings);
    };

    const handleEditPicture = (
        image,
        focusOnHeader = false,
        focusOnDescription = false
    ) => {
        setSelectedImage({
            image,
            index: images.findIndex((img) => img.key === image.key),
            focusOnHeader,
            focusOnDescription,
        });
    };

    const handleEditNextPicture = () => {
        setSelectedImage({
            image: images[(selectedImage.index + 1) % images.length],
            index: (selectedImage.index + 1) % images.length,
            focusOnDescription: false,
            focusOnHeader: false,
        });
    };

    const handleEditPreviousPicture = () => {
        setSelectedImage({
            image: images[
                (selectedImage.index - 1 + images.length) % images.length
            ],
            index: (selectedImage.index - 1 + images.length) % images.length,
            focusOnDescription: false,
            focusOnHeader: false,
        });
    };
    return (
        <>
            <DragAndDropNoStyle
                type={PAGE_ENUM.IMAGE}
                accept_types={IMAGE_TYPES}
                additionalClasses={'library-drag-drop'}
                setCollection={setImages}
                collection={images}
            >
                {loading ? (
                    <FlexibleLoader />
                ) : images.length === 0 ? (
                    <div>Žádné obrázky</div>
                ) : (
                    <div className="library-content">
                        {images.map(
                            (image, i) =>
                                shouldView(image, search) && (
                                    <ImageElem
                                        image={image}
                                        key={image.key}
                                        removeFunc={() => {
                                            setRemovingImage(image);
                                        }}
                                        book={book}
                                        onClick={handleEditPicture}
                                    />
                                )
                        )}
                    </div>
                )}
            </DragAndDropNoStyle>
            {removingImage && (
                <PopUpConfirm
                    closeFunction={() => setRemovingImage(null)}
                    confirmFunction={removeImage}
                    question={'Doopravdy chcete vymazat tento obrázek?'}
                />
            )}
            {selectedImage && (
                <ImageEdit
                    image={selectedImage.image}
                    closeFunction={() => setSelectedImage(null)}
                    bookDetails={book}
                    savedButton={null}
                    focusOnDescription={selectedImage.focusOnDescription}
                    focusOnHeader={selectedImage.focusOnHeader}
                    editNextPicture={handleEditNextPicture}
                    editPreviousPicture={handleEditPreviousPicture}
                    index={selectedImage.index}
                    countOfImages={images.length}
                    openDescription={true}
                />
            )}
        </>
    );
};

export default ImagesPage;
