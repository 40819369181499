import React from 'react';
import './FlexibleLoader.css';
import { Roller } from 'react-awesome-spinners';

const FlexibleLoader = () => {
    return (
        <div className="flexible-outlier">
            <Roller color="#00b2ff" />
        </div>
    );
};

export default FlexibleLoader;
