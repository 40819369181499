import React from 'react';
import { Link } from 'react-router-dom';
import BooksIcon from '../static/images/books.png';
import { Tooltip } from 'react-tippy';
import { useInteractiveBookStore } from '~/stores/interactiveBookStore';

const BackToBookMenu = () => {
    return (
        <Tooltip title="Zpět na menu knih" position="bottom">
            <Link to="/admin/interaktivky" className="no-style-button">
                <img
                    src={BooksIcon}
                    alt="Books"
                    className="no-bg-icon-button"
                />
            </Link>
        </Tooltip>
    );
};

export default BackToBookMenu;
