import React, { useState } from 'react';
import { Line } from 'react-konva';

const RescalingPolygon = ({ points }) => {
    return (
        <Line
            points={points}
            fill="#78a5ff44"
            stroke="blue"
            strokeWidth={1}
            closed
        />
    );
};

export default RescalingPolygon;
