import React, {
	useEffect,
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
} from "react";
import { useSearchParams } from "react-router-dom";
import InteractiveService from "~/services/interactive.service";
import {
	useInteractiveBookStore,
	usePageNumber,
} from "~/stores/interactiveBookStore";
import { setPageFiles } from "~/utils/path_utils";
import { extractBookInfo } from "~/utils/translator";
import { getPathPage } from "~/utils/path_utils";
import "~/pages/styles/editor/preview.css";
import classNames from "classnames";
import DocumentPage from "./DocumentPage";
import useAxiosPrivate from "~/hooks/useAxiosPrivate";

const checkedPageNumber = (currentPage, maximumPage) => {
	const newNumber = currentPage % 2 === 0 ? currentPage - 1 : currentPage;
	if (currentPage > maximumPage) {
		return maximumPage - 2;
	}
	if (currentPage < 1) {
		return 1;
	}
	return newNumber;
};

const BookPages = forwardRef(
	(
		{
			bookid,
			release,
			version,
			setPageSize,
			setLeftPageLoaded,
			setRightPageLoaded,
			padding,
			background,
			firstPageBg,
		},
		ref
	) => {
		const [book, setBook] = useState(null);
		const [pages, setPages] = useState([]);
		const [leftPage, setLeftPage] = useState(null);
		const [rightPage, setRightPage] = useState(null);
		const [leftResultPage, setLeftResultPage] = useState(null);
		const [rightResultPage, setRightResultPage] = useState(null);
		const [pageNumber, setPageNumber] = usePageNumber(1);
		const [boundaries, setBoundaries] = useState({ min: 0, max: 0 });
		const [searchParams, setSearchParams] = useSearchParams();

		const setGlobalBook = useInteractiveBookStore(
			(state) => state.setCompleteBook
		);
		const [showResults, setShowResults] = useState(false);
		const [size, setSize] = useState(1);
		const leftPageRef = useRef(null);
		const rightPageRef = useRef(null);
		const leftResultPageRef = useRef(null);
		const rightResultPageRef = useRef(null);
		const privateAxios = useAxiosPrivate();

		useEffect(() => {
			const getInteractive = async () => {
				const data = await InteractiveService.get_interactive(
					bookid,
					release,
					version,
					privateAxios
				);
				setBook(data.book);
				setPages(data.pages);
				if (boundaries.min === 0 && boundaries.max === 0) {
					setBoundaries({ min: 1, max: data.pages.length });
				}
				const pageFromPath = getPathPage(searchParams);
				const checkedPage = checkedPageNumber(
					pageFromPath,
					data.pages.length
				);
				const double_page = data.pages.filter((curr_page) => {
					return (
						curr_page.id === checkedPage ||
						curr_page.id === checkedPage + 1
					);
				});
				setPageFiles(
					setLeftPage,
					setRightPage,
					setLeftResultPage,
					setRightResultPage,
					double_page
				);
				const bookInfo = extractBookInfo(data);
				setGlobalBook(
					bookInfo.bookid,
					bookInfo.release,
					bookInfo.version_before_dot,
					bookInfo.version_after_dot,
					checkedPage
				);
			};
			getInteractive();
		}, [bookid, release, version]);

		useEffect(() => {
			if (pages.length === 0) return;
			const numberInUrl = checkedPageNumber(
				getPathPage(searchParams),
				pages.length
			);
			if (numberInUrl !== pageNumber) {
				resetPages();
				setPageNumber(numberInUrl);
			}
		}, [searchParams]);

		useEffect(() => {
			if (!pages) return;
			if (pages.length === 0) return;
			const double_page = pages.filter((val) => {
				return val.id === pageNumber || val.id === pageNumber + 1;
			});
			setPageFiles(
				setLeftPage,
				setRightPage,
				setLeftResultPage,
				setRightResultPage,
				double_page
			);
			const urlNumber = getPathPage(searchParams);
			if (urlNumber !== pageNumber) {
				setSearchParams((prev) => ({ ...prev, page: pageNumber }));
			}
		}, [pageNumber]);

		const handleNextButton = async () => {
			if (pageNumber + 2 <= boundaries.max) {
				resetPages();
				pageNumber + 2 <= boundaries.max &&
					setPageNumber(pageNumber + 2);
			}
		};
		const handlePreviousButton = async () => {
			if (pageNumber - 2 >= boundaries.min) {
				resetPages();
				setPageNumber(pageNumber - 2);
			}
		};

		const resetPages = () => {
			setLeftPageLoaded(false);
			setRightPageLoaded(false);
		};
		useImperativeHandle(ref, () => ({
			handleNextButton,
			handlePreviousButton,
			resetPages,
			setShowResults,
		}));
		return (
			<div className="preview-pdfs">
				{leftPage && rightPage && (
					<>
						<div
							className={classNames("pdf-container", {
								visible: !showResults,
							})}
							style={{ backgroundColor: background }}
						>
							<div
								className="one-page left"
								ref={leftPageRef}
								style={{
									...padding,
									backgroundColor:
										pageNumber === 1 ? firstPageBg : "#fff",
								}}
							>
								<DocumentPage
									file={leftPage}
									pageSide="left"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setLeftPageLoaded}
									loader={true}
									setPageSize={setPageSize}
									pageRef={leftPageRef}
								/>
							</div>
							<div
								className="one-page right"
								ref={rightPageRef}
								style={padding}
							>
								<DocumentPage
									file={rightPage}
									pageSide="right"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setRightPageLoaded}
									loader={true}
									pageRef={rightPageRef}
								/>
							</div>
						</div>
						<div
							className={classNames("pdf-container", {
								visible: showResults,
							})}
							style={{ backgroundColor: background }}
						>
							<div
								className="one-page left"
								style={{
									...padding,
									backgroundColor:
										pageNumber === 1 ? firstPageBg : "#fff",
								}}
								ref={leftResultPageRef}
							>
								<DocumentPage
									file={leftResultPage}
									pageSide="left"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setLeftPageLoaded}
									loader={true}
									// setPageSize={setPageSize}
									pageRef={leftResultPageRef}
								/>
							</div>
							<div
								className="one-page right"
								style={padding}
								ref={rightPageRef}
							>
								<DocumentPage
									file={rightResultPage}
									pageSide="right"
									bookSize={size}
									showResults={showResults}
									setCanRenderCanvas={setRightPageLoaded}
									loader={true}
									// setPageSize={setPageSize}
									pageRef={rightResultPageRef}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
);

export default BookPages;
