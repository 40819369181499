import React from "react";
import "./SplitInfoDisplay.css";
import { shortenText } from "~/utils/translator";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import OnClickRedirect from "../OnClickRedirect";

const SplitInfoDisplay = ({ label, value, maxLength, url }) => {
	return (
		<div
			className={classNames("info-div", {
				wrong: value === null || value === undefined || value === "",
			})}
		>
			<h5 className="body-2">{label}</h5>
			{value === null || value === undefined || value === "" ? (
				<p className="body-3 font-weight-500">NEZADÁNO</p>
			) : url ? (
				<OnClickRedirect
					position="left"
					title={value}
					urlRedirect={value}
				>
					{maxLength && (
						<Tooltip position="left" title={value}>
							<p className="body-3">
								{shortenText(
									value.replace(/^https?:\/\/(www\.)?/, ""),
									maxLength
								)}
							</p>
						</Tooltip>
					)}
					{!maxLength && (
						<p className="body-3">
							{value.replace(/^https?:\/\/(www\.)?/, "")}
						</p>
					)}
				</OnClickRedirect>
			) : (
				<>
					{maxLength && (
						<Tooltip position="left" title={value}>
							<p className="body-3">
								{shortenText(value, maxLength)}
							</p>
						</Tooltip>
					)}
					{!maxLength && <p className="body-3">{value}</p>}
				</>
			)}
		</div>
	);
};

export default SplitInfoDisplay;
