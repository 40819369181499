import React from 'react';
import './IconButtonWithTooltip.css';
import classNames from 'classnames';
import { Tooltip } from 'react-tippy';
const IconButtonWithTooltip = ({
    className,
    obj,
    titleFunc,
    positiveTitle,
    negativeTitle,
    pos,
    positiveIcon,
    negativeIcon,
    bgColor,
    size,
    onClick,
}) => {
    return (
        <Tooltip
            className={className}
            title={`${
                titleFunc && titleFunc(obj)
                    ? positiveTitle
                    : titleFunc
                    ? negativeTitle
                    : positiveTitle
            }`}
            position={pos}
        >
            <button
                className={classNames(
                    'button-icon-round',
                    bgColor || (titleFunc && titleFunc(obj) ? 'green' : 'red'),
                    size || 'medium'
                )}
                onClick={onClick}
            >
                <img
                    src={
                        titleFunc && titleFunc(obj)
                            ? positiveIcon
                            : titleFunc
                            ? negativeIcon
                            : positiveIcon
                    }
                    alt="icon"
                />
            </button>
        </Tooltip>
    );
};

export default IconButtonWithTooltip;
