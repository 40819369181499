import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './ErrorMessage.css';
import ErrorIcon from '../../static/images/exclamation.png';
import classNames from 'classnames';

const ErrorMessage = forwardRef(({ error }, ref) => {
    const [blinking, setBlinking] = useState(false);

    const blink = () => {
        setBlinking(true);
        setTimeout(() => {
            setBlinking(false);
        }, 300);
    };

    useImperativeHandle(ref, () => ({
        blink,
    }));

    return (
        <>
            {error && (
                <div
                    className={classNames('error-message', 'mg-b-1', {
                        blinking: blinking,
                    })}
                >
                    <img src={ErrorIcon} alt="Error" />
                    <p className="error-text">{error}</p>
                </div>
            )}
        </>
    );
});

export default ErrorMessage;
