import React, { useEffect, useRef, useState } from 'react';
import FileIcon from '../FileIcon';
import { formatFileSize, shortenText } from '../../utils/translator';
import RemoveButton from '../RemoveIcon/RemoveButton';
import UploadingCircle from './UploadingCircle';
import CheckIcon from '~/static/images/check_blue.png';
import ReactLoading from 'react-loading';
import './FileRow.css';
import classNames from 'classnames';
import SvgIcon from '../SvgIcon/SvgIcon';
import SvgIconType from '../SvgIcon/SvgIconType';
import { Tooltip } from 'react-tippy';

const FileRow = ({
    file,
    removeFromList,
    uploading,
    allAreUploading,
    uploadingPercents,
    alreadyIn,
    setConfirmedRewrite,
}) => {
    const [uploadingDone, setUploadingDone] = useState(false);
    const [hoveringIcon, setHoveringIcon] = useState(false);
    const fileRef = useRef(null);
    useEffect(() => {
        if (uploadingPercents === 100) {
            setTimeout(() => {
                setUploadingDone(true);
            }, 200);
        }
    }, [uploadingPercents]);
    useEffect(() => {
        if (uploading && !uploadingDone && fileRef.current) {
            fileRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [uploading, uploadingDone]);
    return (
        <div
            className={classNames('upload-file-div', {
                'with-already': alreadyIn,
            })}
            ref={fileRef}
        >
            {alreadyIn && (
                <Tooltip title="Přepsat soubor" position="right">
                    <div
                        className={classNames('warning-icon', {
                            hovering: hoveringIcon,
                        })}
                        onMouseEnter={() => setHoveringIcon(true)}
                        onMouseLeave={() => setHoveringIcon(false)}
                        onClick={() => {
                            setConfirmedRewrite(file);
                        }}
                    >
                        {hoveringIcon ? (
                            <SvgIcon
                                type={SvgIconType.check}
                                size="medium"
                                className="white"
                            />
                        ) : (
                            <SvgIcon
                                type={SvgIconType.warning}
                                size="medium"
                                className="white"
                            />
                        )}
                    </div>
                </Tooltip>
            )}
            <FileIcon file={file} width={'2.5rem'} height={'2.5rem'} />
            <div className="file-info">
                <p className="body-4 font-weight-600">
                    {shortenText(file.name, 25)}
                </p>
                <p className="body-6">{formatFileSize(file.size)}</p>
            </div>
            <div>
                {!uploading && !allAreUploading && (
                    <RemoveButton
                        onClick={() => removeFromList(file)}
                        type="red"
                        circle={true}
                        size="medium"
                        title={`Odebrat soubor ${file.name}`}
                    />
                )}
                {!uploading && allAreUploading && (
                    <ReactLoading
                        color="#a297ff"
                        height={24}
                        width={24}
                        type="spin"
                    />
                )}
                {uploading && !uploadingDone && (
                    <UploadingCircle
                        percentage={uploadingPercents}
                        uploadingDone={uploadingPercents === 100}
                    />
                )}
                {uploading && uploadingDone && (
                    <img src={CheckIcon} alt="check" className="check-icon" />
                )}
            </div>
        </div>
    );
};

export default FileRow;
