import React, { useEffect, useRef, useState } from 'react';
import '../styles/popup.css';
import '../styles/interactives/releasechoose.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InteractiveService from '../../services/interactive.service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ChangesHistory from './ChangesHistory';
import { addStatic, getPaths } from '../../utils/path_utils';
import PopUp from '../../components/PopUp';
import NoteIcon from '../../static/images/note.png';
import { formatBook } from '../../utils/path_utils';
import Modal from '../../components/Modal';
import TextAreaComponent from '../../components/TextAreaComponent/TextAreaComponent';
const toastPresetOptions = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
// just a placeholder data
const historyObjects = [
    {
        date: 'před 5 dny',
        change: 'Přidání tlačítka cvičení',
        page: 5,
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 6 dny',
        change: 'Přidání tlačítka audio',
        page: 5,
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 8 dny',
        change: 'Přidání tlačítka video',
        page: 47,
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 8 dny',
        change: 'Přidání tlačítka cvičení',
        page: 31,
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 10 dny',
        change: 'Přidání tlačítka cvičení',
        page: 24,
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 12 dny',
        change: 'Přidání tlačítka cvičení',
        page: 9,
        author: 'Tomáš Křivánek',
    },
];
const historyStateObjects = [
    {
        date: 'před 2 dny',
        change: 'Změna stavu na publikováno',
        author: 'Tomáš Křivánek',
    },
    {
        date: 'před 8 dny',
        change: 'Změna stavu na testování',
        author: 'Tomáš Křivánek',
    },
];

const ReleaseChoose = ({
    chosenObject,
    closeFunction,
    removeFunction,
    actualizeBooks,
}) => {
    const axiosPrivate = useAxiosPrivate();
    const [removeObject, setRemoveObject] = useState(null);
    const [interactivePaths, setInteractivePaths] = useState(null);
    const [chosenHistory, setChosenHistory] = useState(0);

    const [privateNote, setPrivateNote] = useState(
        chosenObject?.concrete_book.private_note
    );
    const [publicNote, setPublicNote] = useState(
        chosenObject?.concrete_book.description
    );

    const openedModal = useRef(null);

    const historyModes = [
        {
            id: 0,
            text: 'Stav',
        },
        {
            id: 1,
            text: 'Změny',
        },
    ];
    useEffect(() => {
        if (!chosenObject) return;
        const paths = getPaths(chosenObject);
        setInteractivePaths(paths);
    }, [chosenObject]);
    const handleSetRemove = () => {
        const removeObj = {
            book_id: chosenObject?.concrete_book.book_id,
            release: chosenObject.concrete_book.release,
            version: `${chosenObject.concrete_book.version_before_dot}_${chosenObject.concrete_book.version_after_dot}`,
        };
        setRemoveObject(removeObj);
    };
    const handleConfirmRemoveBook = async () => {
        const response = await InteractiveService.remove_book(
            removeObject.book_id,
            removeObject.release,
            removeObject.version,
            axiosPrivate
        );

        if (response.status === 200) {
            toast.success(
                `Úspěšně byla smazána interaktivní učebnice  ${
                    removeObject.book_id
                }  (Vyd.:  ${
                    removeObject.release
                }., Ver: ${removeObject.version.replace('_', '.')})`,
                toastPresetOptions
            );
            const book_id = removeObject.book_id;
            const release = removeObject.release;
            const version = removeObject.version;
            openedModal.current.handleClose();
            setTimeout(() => {
                removeFunction && removeFunction(book_id, release, version);
                closeFunction();
            }, 300);
        } else {
            toast.error(response.msg, toastPresetOptions);
        }
    };
    const handleEditPrivateNote = async () => {
        const formatedBook = formatBook(chosenObject.concrete_book);
        const data = {
            bookid: formatedBook.bookid,
            release: formatedBook.release,
            version: formatedBook.version,
            private_note: privateNote,
        };
        const response = await InteractiveService.add_private_note(
            data,
            axiosPrivate
        );
        if (response.err) {
            toast.error(
                'Něco se při změně private note pokazilo',
                toastPresetOptions
            );
            return;
        }
        toast.success(
            'Soukromá poznámka byla úspěšně změněna',
            toastPresetOptions
        );

        actualizeBooks && actualizeBooks();
    };
    const handleEditDescription = async () => {
        const formatedBook = formatBook(chosenObject.concrete_book);
        const data = {
            bookid: formatedBook.bookid,
            release: formatedBook.release,
            version: formatedBook.version,
            description: publicNote,
        };
        const response = await InteractiveService.add_description(
            data,
            axiosPrivate
        );
        if (response.err) {
            toast.error(
                'Něco se při změně private note pokazilo',
                toastPresetOptions
            );
            return;
        }
        toast.success(
            'Veřejná poznámka byla úspěšně změněna',
            toastPresetOptions
        );

        actualizeBooks && actualizeBooks();
    };
    return (
        <PopUp
            closeFunc={closeFunction}
            additionalClassnames="release-choose"
            // overlayClose={true}
            minHeight="50rem"
        >
            {chosenObject && (
                <>
                    <div className="left-part">
                        <div className="book-header-image mg-b-2">
                            <img
                                src={addStatic(
                                    chosenObject.interactive_book.picture_ref
                                )}
                                alt={chosenObject.interactive_book.name}
                            />
                            <div className="release-info">
                                <h4 className="body-2 font-weight-500">
                                    {chosenObject.interactive_book.catalog}{' '}
                                    {chosenObject.interactive_book.name}
                                </h4>
                                <div className="flex-start gap-1 mg-t-1">
                                    <div className="release-info-cell">
                                        <span>Vydání:</span>{' '}
                                        {chosenObject.concrete_book.release}
                                    </div>
                                    <div className="space-between gap-1">
                                        <div className="release-info-cell">
                                            <span>Verze:</span>{' '}
                                            {
                                                chosenObject.concrete_book
                                                    .version_before_dot
                                            }
                                            .
                                            {
                                                chosenObject.concrete_book
                                                    .version_after_dot
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action-buttons">
                            <Link
                                className="btn btn-green btn-smaller"
                                to={interactivePaths?.editor}
                            >
                                Přejít do editoru
                            </Link>

                            <Link
                                to={interactivePaths?.library}
                                className="btn btn-purple btn-smaller"
                            >
                                Administrace knihovny
                            </Link>
                            <Link
                                className="btn btn-yellow btn-smaller"
                                to={interactivePaths?.preview}
                            >
                                Prohlédnout učebnici
                            </Link>
                            <button className="btn btn-pink btn-smaller">
                                Zkopírovat a upravit
                            </button>
                            <button
                                className="btn btn-red bold btn-smaller"
                                title="Smazat interaktivní učebnici"
                                onClick={handleSetRemove}
                            >
                                Smazat
                            </button>
                        </div>
                        <TextAreaComponent
                            label="Soukromá poznámka"
                            value={privateNote}
                            icon={NoteIcon}
                            onChange={(e) => setPrivateNote(e.target.value)}
                            changeAccept={handleEditPrivateNote}
                        />
                        <TextAreaComponent
                            label="Veřejná poznámka"
                            value={publicNote}
                            icon={NoteIcon}
                            onChange={(e) => setPublicNote(e.target.value)}
                            changeAccept={handleEditDescription}
                        />
                    </div>

                    <div className="right-part">
                        {chosenHistory === 0 && (
                            <ChangesHistory
                                historyObjs={historyStateObjects}
                                title="Historie změn stavu"
                                historyButtons={historyModes}
                                selectMode={setChosenHistory}
                                activeMode={chosenHistory}
                                stateHistory={true}
                            />
                        )}
                        {chosenHistory === 1 && (
                            <ChangesHistory
                                historyObjs={historyObjects}
                                title="Historie změn"
                                historyButtons={historyModes}
                                selectMode={setChosenHistory}
                                activeMode={chosenHistory}
                                stateHistory={false}
                            />
                        )}
                    </div>
                </>
            )}
            {removeObject && (
                <Modal
                    closeFunction={() => {
                        setRemoveObject(null);
                    }}
                    acceptFuncion={handleConfirmRemoveBook}
                    acceptText="Smazat"
                    ref={openedModal}
                >
                    <p className="body-1 font-weight-500 centered mg-b-2">
                        Doopravdy si přejete smazat tuto verzi interaktivní
                        učebnice? Pokud tak učiníte ztratíte veškeré informace o
                        knize: veškerá tlačítka i celkový obsah knihovny. <br />
                        Tato akce nelze vrátit zpět.
                    </p>
                </Modal>
            )}
        </PopUp>
    );
};

export default ReleaseChoose;
