import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PopUp from '~/components/PopUp';
import './ZoomFrame.css';
import { Document, Page } from 'react-pdf';
import { BASE_STATIC } from '~/deffault/axios';
import { repairPath } from '~/utils/path_utils';
import throttle from 'lodash/throttle';
import { Layer, Line, Stage } from 'react-konva';

const formatZoomFile = (zoom) => {
    let path = BASE_STATIC + repairPath(zoom.cutout_file);
    if (zoom.last_update) {
        path += '?=' + zoom.last_update;
    }
    return path;
};
const ZoomFrame = ({ zoom, onClose }) => {
    const outerDivRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [resolution, setResolution] = useState(1);
    const setPdfWidth = useCallback(() => {
        if (outerDivRef.current) {
            setWidth(outerDivRef.current.offsetWidth - 40);
        }
    }, []);
    const cutouts = useMemo(() => {
        if (!zoom) return [];
        if (width === 0 || height === 0) return [];
        return zoom.cutouts.map((polygon) => {
            let mappedPoints = polygon.map((point, index) => {
                return index % 2 === 0 ? point * width : point * height;
            });
            console.log(mappedPoints);
            return mappedPoints;
        });
    }, [zoom, width, height]);
    const setPdfHeight = () => {
        const newHeight = outerDivRef.current.offsetHeight - 40;
        const resolution = width / newHeight;

        setHeight(newHeight);
        setResolution(resolution);
    };
    useEffect(() => {
        setPdfWidth();

        window.addEventListener('resize', throttle(setPdfWidth, 500));
        return () => {
            window.removeEventListener('resize', throttle(setPdfWidth, 500));
        };
    }, [setPdfWidth]);
    return (
        <PopUp closeFunc={onClose} additionalClassnames="cutout-zoom">
            <div className="centered-cutout" ref={outerDivRef}>
                <Document file={formatZoomFile(zoom)}>
                    <Page
                        onRenderSuccess={setPdfHeight}
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={width}
                    />
                </Document>
                <div className="overlay-polygons">
                    <Stage height={height} width={width}>
                        <Layer>
                            {cutouts.map((polygon, index) => (
                                <Line
                                    key={index}
                                    points={polygon}
                                    fill="white"
                                    closed
                                />
                            ))}
                        </Layer>
                    </Stage>
                </div>
            </div>
        </PopUp>
    );
};

export default ZoomFrame;
