import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import SearchInput from '../../../../components/SearchInput';
import ChooseExcerciseItem from './ChooseExcerciseItem';

// Opens up after clicking on a button: Vybrat z knihovny
const ChooseExcerciseLibrary = ({
    excercises,
    closeFunc,
    handleAddExcercise,
    selectedButton,
}) => {
    const [tempSelected, setTempSelected] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (!selectedButton) return;
        const newTemp = excercises.find(
            (excercise) => excercise.name === selectedButton.excercise_name
        );
        setTempSelected(newTemp);
    }, [selectedButton, excercises]);

    return (
        <Modal
            closeFunction={() => {
                closeFunc();
                setTempSelected(null);
            }}
            acceptFuncion={() => {
                handleAddExcercise(tempSelected);
            }}
        >
            <div className="library-header-split">
                <h1>Vyberte cvičení.</h1>
                <SearchInput
                    placeholder="Hledat"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            {excercises.length !== 0 && (
                <div className="excercises-list">
                    {excercises?.map((excercise) => (
                        <ChooseExcerciseItem
                            key={excercise['_id']}
                            excercise={excercise}
                            searchText={searchText}
                            tempSelected={tempSelected}
                            setTempSelected={setTempSelected}
                        />
                    ))}
                </div>
            )}
            {excercises.length === 0 && searchText === '' && (
                <p className="no-results mg-b-3 centered body-2">
                    V galerii nejsou žádné {excercises.length > 0 && 'další '}
                    cvičení.
                </p>
            )}
        </Modal>
    );
};

export default ChooseExcerciseLibrary;
