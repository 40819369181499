import { useEffect } from "react";
import { create } from "zustand";

const EDITOR_PAGES = {
	BUTTONS: 0,
	PREVIEW: 1,
	LIBRARY: 2,
	GENERAL: 3,
	ZOOMS: 4,
};

export const useInteractiveBookStore = create((set) => ({
	// State
	currentPage: 1,
	bookObject: {
		bookid: null,
		release: null,
		version_before_dot: null,
		version_after_dot: null,
	},
	currentEditorPage: EDITOR_PAGES.BUTTONS,
	bookBackground: "#F8EDCC",
	firstPageBackground: "#FFFFFF",
	leftBorder: false,
	rightBorder: false,
	topBorder: false,
	bottomBorder: false,
	// Actions
	setBackground: (color) => set({ bookBackground: color }),
	setFirstPageBackground: (color) => set({ firstPageBackground: color }),
	setCurrentPage: (page) => set({ currentPage: page }),
	setBorder: (left, right, top, bottom) => {
		set({
			leftBorder: left,
			rightBorder: right,
			topBorder: top,
			bottomBorder: bottom,
		});
	},
	setCompleteBook: (
		bookid,
		release,
		version_before_dot,
		version_after_dot,
		page
	) =>
		set({
			bookObject: {
				bookid: bookid,
				release: release,
				version_before_dot: version_before_dot,
				version_after_dot: version_after_dot,
			},
			currentPage: page,
		}),
	setOnlyBook: (bookid, release, version_before_dot, version_after_dot) =>
		set({
			bookObject: {
				bookid: bookid,
				release: release,
				version_before_dot: version_before_dot,
				version_after_dot: version_after_dot,
			},
		}),
	setCurrentEditorPage: (page) => set({ currentEditorPage: page }),
	// Reset
	reset: () => {
		set({
			currentPage: 0,
			bookObject: { bookid: null, release: null, version: null },
			currentEditorPage: EDITOR_PAGES.BUTTONS,
			bookBackground: "#F8EDCC",
			firstPageBackground: "#FFFFFF",
			leftBorder: false,
			rightBorder: false,
			topBorder: false,
			bottomBorder: false,
		});
	},
}));

const useBookForRequest = () => {
	const bookObject = useInteractiveBookStore((state) => state.bookObject);
	const bookid = bookObject.bookid;
	const release = bookObject.release;
	const version = `${bookObject.version_before_dot}_${bookObject.version_after_dot}`;
	const currentPage = useInteractiveBookStore((state) => state.currentPage);
	return {
		bookid,
		release,
		version,
		page_number: currentPage,
	};
};
const usePageNumber = (number = undefined) => {
	const currentPage = useInteractiveBookStore((state) => state.currentPage);
	const setCurrentPage = useInteractiveBookStore(
		(state) => state.setCurrentPage
	);

	useEffect(() => {
		if (!number) return;
		if (currentPage === number) return;
		setCurrentPage(number);
	}, []);

	return [currentPage, setCurrentPage];
};
const useBackground = () => {
	const background = useInteractiveBookStore((state) => state.bookBackground);
	const setBackground = useInteractiveBookStore(
		(state) => state.setBackground
	);
	return [background, setBackground];
};
const useFirstPageBackground = () => {
	const background = useInteractiveBookStore(
		(state) => state.firstPageBackground
	);
	const setBackground = useInteractiveBookStore(
		(state) => state.setFirstPageBackground
	);
	return [background, setBackground];
};
const usePageBorder = () => {
	const leftBorder = useInteractiveBookStore((state) => state.leftBorder);
	const rightBorder = useInteractiveBookStore((state) => state.rightBorder);
	const topBorder = useInteractiveBookStore((state) => state.topBorder);
	const bottomBorder = useInteractiveBookStore((state) => state.bottomBorder);
	return [leftBorder, rightBorder, topBorder, bottomBorder];
};
export {
	useBookForRequest,
	EDITOR_PAGES,
	usePageNumber,
	useBackground,
	usePageBorder,
	useFirstPageBackground,
};
