import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_STATIC = process.env.REACT_APP_STATIC_API_URL;
export const BASE_WEB_SOCKET = process.env.REACT_APP_SOCKET_APP_URL;

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});
