import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import SearchInput from '../../../../components/SearchInput';
import LibraryElem from './LibraryElem';
import { screenToPath } from '../../../../utils/path_utils';

const ChooseVideosLibrary = ({
    closeFunc,
    videos,
    acceptFunc,
    savedButton,
}) => {
    const [tempSelected, setTempSelected] = useState(null);
    const [searchText, setSearchText] = useState('');
    const handleSelect = (video) => {
        if (video.key === tempSelected?.key) {
            setTempSelected(null);
        } else {
            setTempSelected(video);
        }
    };
    useEffect(() => {
        if (savedButton.video) {
            if (savedButton.video.key) {
                const newTemp = videos.find(
                    (video) => video.key === savedButton.video.key
                );
                setTempSelected(newTemp);
            } else {
                const newTemp = videos.find(
                    (video) => video.key === savedButton.video['_id']
                );
                setTempSelected(newTemp);
            }
        }
    }, [savedButton, videos]);
    return (
        <Modal
            closeFunction={closeFunc}
            acceptFuncion={() => {
                acceptFunc(tempSelected);
                setTempSelected(null);
            }}
        >
            <div className="library-header-split">
                <h1>Vyberte video.</h1>
                <SearchInput
                    placeholder="Hledat"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="library-videos-select">
                {videos.map((video, i) => (
                    <LibraryElem
                        key={i}
                        elem={video}
                        searchText={searchText}
                        image={screenToPath(video)}
                        selected={tempSelected?.key === video.key}
                        selectedAny={tempSelected !== null}
                        clickedFunc={handleSelect}
                        buttons={video.buttons}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default ChooseVideosLibrary;
