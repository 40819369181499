import React, { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import "./UploadFile.css";
import classNames from "classnames";
import UploadFileIcon from "../../static/images/uploadFile.png";
import TopRightCloseBtn from "../TopRightCloseBtn";
import FileRow from "./FileRow";
import {
	checkFileType,
	getAcceptingExtensions,
	getAcceptingMimes,
} from "../../utils/path_utils";
import { toast } from "react-toastify";
import toastSettings from "../../utils/toastSettings";
import Modal from "../Modal";
import FullScreenLoading from "../FullScreenLoading";
const isFileBeingDragged = (e) => {
	if (e?.dataTransfer?.items && e?.dataTransfer?.items?.length > 0) {
		const isFile = [...e.dataTransfer.items].some(
			(item) => item.kind === "file"
		);
		return isFile;
	}
	return false;
};
const FILE_ENUM = {
	video: "video",
	audio: "audio",
	image: "image",
	other: "other",
};

const UploadFile = ({
	header,
	fileType,
	multiple,
	acceptText,
	alreadyUploadedFileNames,
	closeFunc,
	acceptFunc,
}) => {
	const [closing, setClosing] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [dragging, setDragging] = useState(false);
	const [draggingOver, setDraggingOver] = useState(false);
	const [confirming, setConfirming] = useState(false);
	const [sameNameFiles, setSameNameFiles] = useState([]);
	const [lastUploaded, setLastUploaded] = useState(null);
	const acceptingMimes = getAcceptingMimes(fileType);
	const inputRef = useRef();
	useEffect(() => {
		const handleEsc = (event) => {
			if (event.keyCode === 27) {
				handleSlowClose();
			}
		};
		window.addEventListener("keydown", handleEsc);
		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, [closeFunc]);
	const handleSlowClose = () => {
		setClosing(true);
		setTimeout(() => {
			closeFunc();
			setClosing(false);
		}, 600);
	};

	const removeFromList = (file) => {
		setUploadedFiles(
			uploadedFiles.filter((f) => f.file.name !== file.name)
		);
	};

	const handleClick = () => {
		if (inputRef.current) inputRef.current.click();
	};

	const handleFileUpload = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = e.target.files;
		if (files.length > 0) {
			addFiles(files);
		}
	};

	const handleDragStart = (e) => {
		e.preventDefault();
		const isFile = isFileBeingDragged(e);
		setDragging(isFile);
	};

	const handleDragLeaveMain = (e) => {
		e.preventDefault();

		if (e.relatedTarget === null) {
			setDragging(false);
			setDraggingOver(false);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		if (isFileBeingDragged(e)) {
			setDraggingOver(true);
		}
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		// Check if the element being dragged over leaves to nested
		if (
			e.relatedTarget === null ||
			!e.currentTarget.contains(e.relatedTarget)
		) {
			setDraggingOver(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = multiple
			? e.dataTransfer.files
			: [e.dataTransfer.files[0]];
		addFiles(files);
	};

	const addFiles = (files) => {
		if (!multiple && uploadedFiles.length > 0) {
			toast.error("Nelze nahrát více souborů", toastSettings);
			setDragging(false);
			setDraggingOver(false);
			return;
		}
		const addTheseFiles = [];
		const notAdded = [];
		for (let i = 0; i < files.length; i++) {
			if (checkFileType(files[i], fileType)) {
				addTheseFiles.push(files[i]);
			} else {
				notAdded.push(files[i]);
			}
		}
		if (notAdded.length > 0) {
			const filesNotAddedFormated = notAdded
				.map((f) => f.name)
				.join(", ");
			toast.error(
				notAdded.length > 1
					? `Soubory ${filesNotAddedFormated} nebyly nahrány, protože nesplňují povolené typy souborů.`
					: `Soubor ${filesNotAddedFormated} nebyl nahrán, protože nesplňuje povolený typ souborů.`,

				toastSettings
			);
		}
		if (addTheseFiles.length > 0) {
			const formattedAdd = addTheseFiles.map((f) => ({
				file: f,
				uploading: false,
				uploadingPercents: 0,
			}));
			setUploadedFiles([...uploadedFiles, ...formattedAdd]);
		}
		setDragging(false);
		setDraggingOver(false);
	};

	const handleUpload = async (confirmedChange = false) => {
		const fileNames = uploadedFiles.map((f) => f.file.name);
		// check if there are any files that have the same name as the ones already uploaded
		const duplicates = fileNames.filter((name) =>
			alreadyUploadedFileNames.includes(name)
		);
		if (duplicates.length > 0 && !confirmedChange) {
			setConfirming(true);
			setSameNameFiles(duplicates);
			return;
		}
		// Setting all the files to uploading
		setUploadedFiles(uploadedFiles.map((f) => ({ ...f, uploading: true })));
		const uploads = [];
		for (let i = 0; i < uploadedFiles.length; i++) {
			const response = await acceptFunc(
				uploadedFiles[i].file,
				(percents) => {
					setUploadedFiles(
						uploadedFiles.map((file) => {
							if (
								!lastUploaded &&
								i === uploadedFiles.length - 1
							) {
								setLastUploaded(true);
							}
							if (uploadedFiles[i].file.name === file.file.name) {
								return {
									...file,
									uploading: true,
									uploadingPercents: percents,
								};
							} else {
								return file;
							}
						})
					);
				}
			);

			uploads.push(response);
		}

		if (uploads.some((r) => r?.err)) {
			toast.error("Některé soubory se nepodařilo nahrát", toastSettings);
			setLastUploaded(false);
		} else {
			toast.success("Soubory byly úspěšně nahrány", toastSettings);
			handleSlowClose();
			setSameNameFiles([]);
			setLastUploaded(false);
		}
	};
	return ReactDom.createPortal(
		<>
			{lastUploaded && <FullScreenLoading />}
			<div
				className={classNames("overall-grey-background", {
					closing: closing,
				})}
				onDragEnter={handleDragStart}
				onDragLeave={handleDragLeaveMain}
				onDrop={(e) => handleDrop(e)}
				onDragOver={(e) => e.preventDefault()}
			>
				<div
					className={classNames("upload-file-container", {
						closing: closing,
					})}
					onDrop={handleDrop}
				>
					<TopRightCloseBtn
						closeFunc={handleSlowClose}
						zIndex={5003}
					/>
					{uploadedFiles.length > 0 && (
						<>
							<button
								className="btn btn-purple"
								id="confirm-upload-button"
								onClick={() => handleUpload(false)}
							>
								{acceptText || "Odeslat"}
							</button>
							<div className="files-to-upload">
								<div className="centered-file-upload">
									{uploadedFiles.map((file, i) => (
										<FileRow
											key={i}
											file={file.file}
											removeFromList={removeFromList}
											uploading={file.uploading}
											uploadingPercents={
												file.uploadingPercents
											}
										/>
									))}
								</div>
							</div>
						</>
					)}
					<div
						className={classNames("upload-file-part", {
							"with-files": uploadedFiles.length > 0,
						})}
					>
						<h1 className="body-1 font-weight-400 centered">
							{header}
						</h1>

						<div
							className={classNames(
								"drag-and-drop-file",
								"mg-2",
								"custom-dashed-border",
								{
									"dragging-started": dragging,
									"dragging-over": draggingOver,
								}
							)}
							onDragEnter={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
							onDragOver={(e) => e.preventDefault()}
						>
							<img src={UploadFileIcon} alt="upload file" />
							<p className="body-3 centered">
								{draggingOver
									? `Pusťte soubor${multiple ? "y" : ""}`
									: `Přetáhněte soubor${multiple ? "y" : ""}`}
							</p>
							<p className="body-6 centered">
								Povolené typy souborů:{" "}
								{getAcceptingExtensions(fileType)}
							</p>
						</div>
						<div className="or-click-to-upload">
							<p className="body-3 centered">
								nebo klikněte na následující tlačítko
							</p>
							<button
								className="btn btn-yellow btn-smaller"
								onClick={handleClick}
								disabled={uploadedFiles.length > 0 && !multiple}
							>
								Vybrat soubor{multiple ? "y" : ""}
							</button>
						</div>
						<input
							ref={inputRef}
							type="file"
							multiple={multiple}
							style={{ display: "none" }}
							onChange={handleFileUpload}
							accept={acceptingMimes}
						/>
					</div>
				</div>
			</div>
			{confirming && (
				<Modal
					closeFunction={() => setConfirming(false)}
					acceptFuncion={() => {
						setConfirming(false);
						handleUpload(true);
					}}
					acceptText="Přepsat"
				>
					<p className="body-1 mg-b-1 centered">
						{sameNameFiles.length > 1 ? (
							<p className="body-1 mg-b-1 centered">
								Soubory: {sameNameFiles.join(", ")} jsou již
								uloženy v knihovně. Pokud toto nahrání
								potvrdíte, budou soubory v knihovně
								aktualizovány.
							</p>
						) : (
							<p className="body-1 mg-b-1 centered">
								Soubor: {sameNameFiles.join(", ")} je již uložen
								v knihovně. Pokud toto nahrání potvrdíte, budou
								soubory v knihovně aktualizovány.
							</p>
						)}
					</p>
				</Modal>
			)}
		</>,
		document.getElementById("file-upload")
	);
};

export default UploadFile;
export { FILE_ENUM };
