import React, { useEffect, useState } from 'react';
import ArrowLeft from '../../static/animations/LeftArrow.gif';
import ArrowUp from '../../static/animations/UpArrow.gif';
import ArrowRight from '../../static/animations/RightArrow.gif';
import ArrowDown from '../../static/animations/DownArrow.gif';
import { PAGE_ENUM } from '../../pages/editor/Library/LibrarySwitches';
import './DropAnimation.css';
const get_style_for_type = (type) => {
    switch (type) {
        case PAGE_ENUM.AUDIO:
            return 'audio';
        case PAGE_ENUM.EXCERCISE:
            return 'excercise';
        case PAGE_ENUM.IMAGE:
            return 'image';
        case PAGE_ENUM.VIDEO:
            return 'video';
        default:
            return 'image';
    }
};
const DropAnimation = ({ dragging_somewhere, dragging_over, type }) => {
    const [appearDragging, setAppearDragging] = useState(dragging_somewhere);
    const [closing, setClosing] = useState(false);
    useEffect(() => {
        if (dragging_somewhere) {
            setAppearDragging(true);
        } else {
            setClosing(true);
            setTimeout(() => {
                setAppearDragging(false);
                setClosing(false);
            }, 300);
        }
    }, [dragging_somewhere]);

    return (
        <>
            {appearDragging && (
                <div
                    className={`dragging_wrapper ${
                        closing ? 'dragging_wrapper_closing' : ''
                    } ${get_style_for_type(type)}`}
                >
                    {!dragging_over ? (
                        <>
                            <img
                                src={ArrowDown}
                                className="dragging_gif"
                                alt="arrow down"
                            />

                            <div className="dragging_wrapper_middle_row">
                                <img
                                    src={ArrowRight}
                                    className="dragging_gif"
                                    alt="arrow right"
                                />
                                <img
                                    src={ArrowLeft}
                                    className="dragging_gif"
                                    alt="arrow left"
                                />
                            </div>
                            <img
                                src={ArrowUp}
                                className="dragging_gif"
                                alt="arrow up"
                            />
                        </>
                    ) : (
                        <img
                            src={ArrowUp}
                            className="dragging_gif"
                            alt="arrow up"
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default DropAnimation;
