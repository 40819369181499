import React, { useEffect, useState } from "react";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";

import AuthorIconWhite from "~/static/images/user_white.png";
import SourceIcon from "~/static/images/global-search-white.png";
import VideoIcon from "~/static/images/video_white.png";
import IconButtonWithTooltip from "~/components/IconButtonWithTooltip/IconButtonWithTooltip";
import EditVideoSource from "./EditVideoSource";
import { useAssetVideos } from "~/stores/assetStore";
import SplitInfoDisplay from "~/components/SplitInfoDisplay/SplitInfoDisplay";
import { checkIfSet } from "~/utils/basic_utils";
const getVideoFromVideos = (videos, video_id) => {
	const video = videos.find((video) => video.key === video_id);
	return video;
};
const ChosenVideoThumbnail = ({
	selectedButton,
	setSavedButton,
	setButtons,
	pageNumber,
}) => {
	const [videos, setVideos] = useAssetVideos();
	const [edittingSource, setEdittingSource] = useState(false);
	const [video, setVideo] = useState(() => {
		return getVideoFromVideos(videos, selectedButton.video._id);
	});
	useEffect(() => {
		setVideo(getVideoFromVideos(videos, selectedButton.video._id));
	}, [videos, selectedButton]);
	const handleOpenSourceEdit = (type) => {
		setEdittingSource({ open: true, type });
	};
	const openSourceAuthor = () => {
		handleOpenSourceEdit("author");
	};
	const openSourceSource = () => {
		handleOpenSourceEdit("source");
	};
	const openSourceExternalUrl = () => {
		handleOpenSourceEdit("externalUrl");
	};
	return (
		<>
			<div className="relative">
				<VideoPlayer
					video={videos.find(
						(video_from_lib) =>
							selectedButton.video.file_path ===
							video_from_lib.file_path
					)}
				/>
				<div className="top-left--1  space-between gap-05">
					<IconButtonWithTooltip
						obj={video?.author}
						titleFunc={checkIfSet}
						positiveTitle="Autor OK"
						negativeTitle="Autor nezadáno"
						pos="top"
						positiveIcon={AuthorIconWhite}
						negativeIcon={AuthorIconWhite}
						onClick={openSourceAuthor}
					/>

					<IconButtonWithTooltip
						obj={video?.source}
						titleFunc={checkIfSet}
						positiveTitle="Zdroj OK"
						negativeTitle="Zdroj nezadán"
						pos="top"
						positiveIcon={SourceIcon}
						negativeIcon={SourceIcon}
						onClick={openSourceSource}
					/>
					<IconButtonWithTooltip
						obj={video?.externalUrl}
						titleFunc={checkIfSet}
						positiveTitle="Odkaz na video OK"
						negativeTitle="Odkaz na video nezadán"
						pos="top"
						positiveIcon={VideoIcon}
						negativeIcon={VideoIcon}
						onClick={openSourceExternalUrl}
					/>
				</div>

				{edittingSource && edittingSource.open && (
					<EditVideoSource
						closeFunc={() => setEdittingSource(false)}
						selectedButton={selectedButton}
						setSavedButton={setSavedButton}
						setButtons={setButtons}
						video={video}
						setVideos={setVideos}
						pageNumber={pageNumber}
					/>
				)}
			</div>
			<SplitInfoDisplay label={"Autor"} value={video?.author} />
			<SplitInfoDisplay
				label={"Zdroj"}
				value={video?.source}
				maxLength={30}
				url={true}
			/>
			<SplitInfoDisplay
				label={"Odkaz"}
				value={video?.externalUrl}
				maxLength={30}
				url={true}
			/>
		</>
	);
};

export default ChosenVideoThumbnail;
