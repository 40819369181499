import React from 'react';
import ReactDOM from 'react-dom/client';
import Admin from './Admin';
import Interaktivka from './Interaktivky';
import 'react-tippy/dist/tippy.css';
import './pages/styles/base.css';
import './pages/styles/input.css';
import './pages/styles/animations.css';
import './pages/styles/layout.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/interaktivky/*" element={<Interaktivka />} />
                    <Route path="/*" element={<Admin />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
