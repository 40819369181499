import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const PAGE_ENUM = {
    AUDIO: 0,
    VIDEO: 1,
    IMAGE: 2,
    EXCERCISE: 3,
};

export const LibrarySwitches = ({ changePageFunc, activeSection }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const page = searchParams.get('library_page');
        if (page) {
            changePageFunc(parseInt(page));
        } else {
            handleRedirectToPage(PAGE_ENUM.AUDIO);
        }
    }, [searchParams]);

    const handleRedirectToPage = (page) => {
        setSearchParams({ library_page: page });
        changePageFunc(page);
    };
    return (
        <ul className="library-switches">
            <li
                onClick={() => handleRedirectToPage(PAGE_ENUM.AUDIO)}
                className={classNames('library-section', 'purple', {
                    selected: activeSection === PAGE_ENUM.AUDIO,
                })}
            >
                Audio
            </li>
            <li
                onClick={() => handleRedirectToPage(PAGE_ENUM.VIDEO)}
                className={classNames('library-section', 'pink', {
                    selected: activeSection === PAGE_ENUM.VIDEO,
                })}
            >
                Video
            </li>
            <li
                onClick={() => handleRedirectToPage(PAGE_ENUM.IMAGE)}
                className={classNames('library-section', 'yellow', {
                    selected: activeSection === PAGE_ENUM.IMAGE,
                })}
            >
                Fotky
            </li>
            <li
                onClick={() => handleRedirectToPage(PAGE_ENUM.EXCERCISE)}
                className={classNames('library-section', 'blue', {
                    selected: activeSection === PAGE_ENUM.EXCERCISE,
                })}
            >
                Cvičení
            </li>
        </ul>
    );
};
