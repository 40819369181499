import React from "react";
import { Document, Page } from "react-pdf";
import { Roller } from "react-awesome-spinners";
import { extractNumberFromPadding } from "~/utils/objectUtils";

const DocumentPage = ({
	file,
	bookSize,
	showResults,
	setCanRenderCanvas,
	pageSide,
	loader,
	setPageSize,
	pageRef,
}) => {
	const LoaderComponent = () => (
		<div className="loader-container">
			<Roller color="#00b2ff" size={64} sizeUnit="px" />
		</div>
	);
	return (
		<Document
			file={file}
			className={`pdf-page ${pageSide}`}
			loading={loader ? <LoaderComponent /> : ""}
		>
			<Page
				pageNumber={1}
				scale={bookSize}
				renderTextLayer={false}
				renderAnnotationLayer={false}
				loading={""}
				onRenderSuccess={() => {
					if (!showResults) {
						setCanRenderCanvas(true);
						const padding = window.getComputedStyle(
							pageRef.current
						);
						const pT = extractNumberFromPadding(padding.paddingTop);
						const pR = extractNumberFromPadding(
							padding.paddingRight
						);
						const pB = extractNumberFromPadding(
							padding.paddingBottom
						);
						const pL = extractNumberFromPadding(
							padding.paddingLeft
						);
						const newWidth = pageRef.current.clientWidth - pR - pL;
						const newHeight =
							pageRef.current.clientHeight - pT - pB;
						if (newWidth === undefined || newHeight === undefined)
							return;
						if (newWidth === 0 || newHeight === 0) return;
						setPageSize &&
							setPageSize({
								height: pageRef.current.clientHeight - pT - pB,
								width: pageRef.current.clientWidth - pR - pL,
							});
					}
				}}
			/>
		</Document>
	);
};

export default DocumentPage;
