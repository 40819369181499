import { useEffect, useState } from 'react';
import '../styles/tables.css';
import '../styles/buttons.css';
import '../styles/text.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Roller } from 'react-awesome-spinners';
import Fuse from 'fuse.js';
import PopUpConfirm from '../../components/PopUpConfirm';
import { toast } from 'react-toastify';
import { formatDatetimeString } from '../../utils/datetimeFormat';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import setBodyColor from '../../utils/setBodyColor';

const fuseOptions = {
    keys: ['first_name', 'surname', 'e_mail'],
    threshold: 0.2,
    shouldSort: true,
};
const translateRole = (number) => {
    return number === 0 ? 'Super Admin' : 'Pracovník';
};
const UserTable = () => {
    setBodyColor('#fff');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [removePopUp, setRemovePopUp] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const { auth } = useAuth();
    const actual_user_email = jwtDecode(auth.access)?.sub;

    const privateAxios = useAxiosPrivate();

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await privateAxios.get('/users/');

                setUsers(response?.data?.users);
            } catch (err) {}
        };
        getUsers();
    }, [privateAxios]);
    useEffect(() => {
        if (users.length !== 0) {
            const fuse = new Fuse(users, fuseOptions);
            setFilteredUsers(fuse.search(search).map((val) => val.item.e_mail));
            console.log(fuse.search(search).map((val) => val.item.e_mail));
        }
    }, [search, users]);
    const handleConfirmDelete = async () => {
        try {
            await privateAxios.delete(`/users/${selectedUser.id}`);
            setRemovePopUp(false);
            const updatedListOfUsers = users.filter(
                (user) => user.id !== selectedUser.id
            );
            const updatedListOfFiltered = filteredUsers.filter(
                (user) => user.item.id !== selectedUser.id
            );
            setUsers(updatedListOfUsers);
            setFilteredUsers(updatedListOfFiltered);
            toast.success('Uživatel byl úspěšně odstraněn ', {
                autoClose: 2000,
                position: 'bottom-right',
                closeOnClick: true,
                draggable: false,
                theme: 'colored',
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleRemoveClick = (user) => {
        if (actual_user_email === user.e_mail) {
            toast.success('Nemůžete smazat sám sebe.', {
                autoClose: 2000,
                position: 'bottom-right',
                closeOnClick: true,
                draggable: false,
                theme: 'colored',
            });
        } else {
            setRemovePopUp(true);
            setSelectedUser(user);
        }
    };
    return (
        <>
            <div className="center-content-tables">
                <div className="top-with-input">
                    <h1 className="header-2">Uživatelé</h1>
                    <input
                        className="input-search"
                        placeholder="Vyhledejte uživatele"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                {users.length === 0 ? (
                    <div className="loader-container">
                        <Roller color="#00b2ff" size={64} sizeUnit="px" />
                    </div>
                ) : (
                    <div className="table-div">
                        <div className="table-header">
                            <div className="table-header-element">E-mail</div>
                            <div className="table-header-element center">
                                Křestní
                            </div>
                            <div className="table-header-element center">
                                Příjmení
                            </div>
                            <div className="table-header-element center">
                                Vytvořeno
                            </div>
                            <div className="table-header-element center">
                                Role
                            </div>
                            <div className="table-header-element right">
                                Akce
                            </div>
                        </div>
                        <div className="table-body">
                            {users.map((user, i) => {
                                if (
                                    search === '' ||
                                    filteredUsers.includes(user.e_mail)
                                ) {
                                    return (
                                        <div className="table-row" key={i}>
                                            <div className="table-row-element">
                                                {user.e_mail}
                                            </div>
                                            <div className="table-row-element center">
                                                {user.first_name}
                                            </div>
                                            <div className="table-row-element center">
                                                {user.surname}
                                            </div>
                                            <div className="table-row-element center">
                                                {formatDatetimeString(
                                                    user.created
                                                )}
                                            </div>
                                            <div className="table-row-element center">
                                                {translateRole(user.role)}
                                            </div>
                                            <div
                                                className="table-row-element right"
                                                onClick={() =>
                                                    handleRemoveClick(user)
                                                }
                                            >
                                                {'akce'}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>

            {removePopUp && (
                <PopUpConfirm
                    question="Opravdu chcete smazat tohoto uživatele?"
                    confirmFunction={handleConfirmDelete}
                    closeFunction={() => {
                        setRemovePopUp(false);
                    }}
                />
            )}
        </>
    );
};

export default UserTable;
