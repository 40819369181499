import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Group, Line, Rect } from 'react-konva';
import Konva from 'konva';
import { useZoomStore } from '~/stores/zoomStore';
import { setCorrespondingCursor } from '~/utils/zoom_utils';
import RescalingBorderRectangles from './RescalingBorderRectangles';
import { getKonvaCursorLocation } from '~/utils/canvasesCalculations';
import ZoomService from '~/services/zoom.service';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import DeletePolygonButton from './DeletePolygonButton';

const SIZE_OFFSET = 6;

const EdittingPolygon = ({
    polygon,
    editPolyOnAPI,
    setRescalingPolygon,
    setPolygons,
    canDelete,
    book,
    clearEdittingPolygon,
}) => {
    const [points, setPoints] = useState(polygon.points);
    const [dragging, setDragging] = useState(false);
    const privateAxios = useAxiosPrivate();
    const boundingBox = useMemo(() => {
        const xPoints = points.filter((_, index) => index % 2 === 0);
        const yPoints = points.filter((_, index) => index % 2 === 1);

        const minX = Math.min(...xPoints);
        const minY = Math.min(...yPoints);
        const maxX = Math.max(...xPoints);
        const maxY = Math.max(...yPoints);
        return {
            x: minX - SIZE_OFFSET,
            y: minY - SIZE_OFFSET,
            width: maxX - minX + 2 * SIZE_OFFSET,
            height: maxY - minY + 2 * SIZE_OFFSET,
        };
    }, [points]);

    const rescaleBox = useMemo(() => {
        if (!boundingBox) return null;
        return {
            x: boundingBox.x + SIZE_OFFSET,
            y: boundingBox.y + SIZE_OFFSET,
            width: boundingBox.width - 2 * SIZE_OFFSET,
            height: boundingBox.height - 2 * SIZE_OFFSET,
        };
    }, [boundingBox]);

    const activeTool = useZoomStore((state) => state.currentTool);
    const outerRef = useRef(null);

    // When user clicks on different polygon, change points
    useEffect(() => {
        setPoints(polygon.points);
    }, [polygon]);

    useEffect(() => {
        if (!outerRef.current) return;
        const anim = new Konva.Animation((frame) => {
            const scale = Math.sin(frame.time * 0.007) * 0.005 + 1;
            // Need to be here also, because when user clicks out - the animation will still go on for a short bit of time
            if (!outerRef.current) return;
            outerRef.current.scale({ x: scale, y: scale });
            outerRef.current.position({
                x: boundingBox.x + (boundingBox.width * (1 - scale)) / 2,
                y: boundingBox.y + (boundingBox.height * (1 - scale)) / 2,
            });
        }, outerRef.current.getLayer());
        anim.start();
        return () => anim.stop();
    }, [boundingBox]);
    const handleDragStart = (e) => {
        setDragging(true);
        e.target.getStage().container().style.cursor = 'grabbing';
    };
    const handleDragEnd = async (e) => {
        setDragging(false);
        const dx = e.target.x();
        const dy = e.target.y();
        const newPoints = points.map((point, index) => {
            return index % 2 === 0 ? point + dx : point + dy;
        });
        setPoints(newPoints);
        e.target.position({ x: 0, y: 0 });
        editPolyOnAPI && (await editPolyOnAPI(newPoints));
        e.target.getStage().container().style.cursor = 'grab';
    };

    const handleMouseIn = (e) => {
        e.target.getStage().container().style.cursor = 'grab';
    };

    const handleMouseOut = (e) => {
        setCorrespondingCursor(e, activeTool);
    };

    const handleRemovePolygon = async (e) => {
        if (!canDelete || !setPolygons) return;
        if (!polygon?.id) return;
        setPolygons((prev) => prev.filter((p) => p.id !== polygon.id));
        clearEdittingPolygon();
        const response = await ZoomService.delete_polygons(
            book,
            polygon.page,
            [polygon.id],
            privateAxios
        );
        if (response.err) {
            return;
        }
    };

    return (
        <>
            <Group
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onMouseEnter={handleMouseIn}
                onMouseOut={handleMouseOut}
            >
                <Line
                    id="polygon"
                    points={points}
                    closed
                    stroke="#5a08bf"
                    strokeWidth={1}
                    fill="#5a08bf66"
                />
                {!dragging && (
                    <Rect
                        ref={outerRef}
                        x={boundingBox.x}
                        y={boundingBox.y}
                        width={boundingBox.width}
                        height={boundingBox.height}
                        stroke="#444"
                        strokeWidth={1.2}
                        dash={[10, 10]}
                        listening={false}
                    />
                )}
                {canDelete && !dragging && (
                    <DeletePolygonButton
                        poly={{ bounding_rect: boundingBox }}
                        onRemove={handleRemovePolygon}
                        activeTool={activeTool}
                    />
                )}
            </Group>
            {rescaleBox && (
                <RescalingBorderRectangles
                    rect={rescaleBox}
                    isRect={polygon.is_rectangle}
                    onRescaleStart={(e, hoverSide) => {
                        const mousePos = getKonvaCursorLocation(e);
                        setRescalingPolygon({
                            polygonId: polygon.id,
                            side: hoverSide,
                            mousePos: mousePos,
                            points: polygon.points,
                        });
                    }}
                />
            )}
        </>
    );
};

export default EdittingPolygon;
