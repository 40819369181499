import React from 'react';
import './FlexInputWithLabel.css';
const FlexInputWithLabel = ({
    id,
    label,
    value,
    onChange,
    units,
    onlyNumbers,
    onFocus,
    onBlur,
}) => {
    const handleOnChange = (e) => {
        if (onlyNumbers) {
            const value = e.target.value;
            if (value === '') {
                console.log('value is empty');
                onChange(0);
                return;
            }
            if (isNaN(value)) return;
        }
        onChange(e.target.value);
    };

    return (
        <div className="flex-input-line">
            <label htmlFor={id}>{label}</label>
            <div>
                <input
                    onFocus={() => {
                        onFocus && onFocus();
                    }}
                    onBlur={() => {
                        onBlur && onBlur();
                    }}
                    type="text"
                    id={id}
                    value={value}
                    onChange={handleOnChange}
                />
                {units && <p className="body-5">{units}</p>}
            </div>
        </div>
    );
};

export default FlexInputWithLabel;
