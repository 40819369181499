import React from 'react';
import '../pages/styles/components/searchInput.css';
import SearchIcon from '../static/images/search.svg';

const SearchInput = ({ placeholder, onChange, value }) => {
    return (
        <div className="search-input-outer">
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
            />
            <img
                src={SearchIcon}
                alt="search icon"
                className="search-input-icon"
            />
        </div>
    );
};

export default SearchInput;
