//REACT
import { Routes, Route } from 'react-router-dom';
//STYLING
import './pages/styles/app.css';
// PAGES
import Layout from './components/Layout';

// Auth
import RequireAuth from './pages/auth/RequireAuth';
import PersistLogin from './pages/auth/PersistLogin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Library from './pages/editor/Library/Library';
import setBodyColor from './utils/setBodyColor';
import EditorInteraktivkaKonva from './pages/editor/EditorInteraktivka';
import InteractivePreview from './pages/editor/InteractivePreview';
import ZoomEditor from './pages/editor/ZoomEditor';
function Interaktivka() {
    setBodyColor('#fff');
    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRoles={[1]} />}>
                            <Route
                                path="preview/:bookid/:release/:version"
                                element={<InteractivePreview />}
                            />
                            <Route
                                path="knihovna/:bookid/:release/:version"
                                element={<Library />}
                            />
                            <Route
                                path="zoom/:bookid/:release/:version"
                                element={<ZoomEditor />}
                            />
                            <Route
                                path=":bookid/:release/:version"
                                element={<EditorInteraktivkaKonva />}
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
            <ToastContainer />
        </>
    );
}

export default Interaktivka;
