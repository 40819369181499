import React, { useEffect, useRef } from 'react';
import '../../styles/editor/interaction.css';
import { useState } from 'react';
import { BUTTON_TYPES } from '../../../utils/canvasesCalculations';
import { BASE_STATIC } from '../../../deffault/axios';
import { assetToPath, repairPath } from '../../../utils/path_utils';
import ReactDOM from 'react-dom';
import TopRightCloseBtn from '../../../components/TopRightCloseBtn';
const ExcerciseCenter = ({ button, closeFunction, pageNumber }) => {
    const [messageQueue, setMessageQueue] = useState([]);
    const [excerciseInited, setExcerciseInited] = useState(false);

    const iFrameRef = useRef(null);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = event.data;
            if (message.type === 'EXCERCISE_LOADED') {
                setExcerciseInited(true);
                console.log('Help is loaded ---> ', message.showHelp);
            } else {
                console.log('Unknown message from iframe:', message);
            }
        };
        /** EXAMPLE HOW TO COMMUNICATE FROM IFRAME TO PARENT **/
        // parent.postMessage(
        //     {
        //         type: 'EXAMPLE_CARD_DROPPED',
        //         event: 'card_dropped',
        //         card: card.name,
        //     },
        //     '*'
        // );
        // console.log('SHOULD APPEAR MESSAGE');

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (excerciseInited) {
            messageQueue.forEach((message) => {
                sendMessageToIframe({
                    type: message.type,
                    pageIndex: message.data,
                });
            });
            setMessageQueue([]);
        }
    }, [excerciseInited]);

    const sendMessageToIframe = (message) => {
        if (iFrameRef.current && iFrameRef.current.contentWindow) {
            console.log('sent');
            iFrameRef.current.contentWindow.postMessage(message, '*');
        }
    };

    const handleSendMessage = (typeOfMessage, data) => {
        if (!excerciseInited) {
            // exccercise is not loaded yet we need to add it to queue
            setMessageQueue((prev) => [...prev, { type: typeOfMessage, data }]);
        } else {
            sendMessageToIframe({
                type: typeOfMessage,
                pageIndex: data,
            });
        }
    };
    return ReactDOM.createPortal(
        <div className="interaction-overlay">
            <div className="interaction-center">
                <TopRightCloseBtn closeFunc={closeFunction} />
                <iframe
                    ref={iFrameRef}
                    title={`Excercise ${button.key}`}
                    src={assetToPath(button.excercise)}
                    width="1000"
                    height="800"
                    onLoad={() => {
                        handleSendMessage('PAGE_INDEX', pageNumber);
                    }}
                />
            </div>
        </div>,
        document.getElementById('excercise-player')
    );
};

export default ExcerciseCenter;
