import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InteractiveService from '../../services/interactive.service';
import '../styles/editor/librarySelect.css';
import '../styles/components/fileTree.css';

import ExcerciseSelect from './Library/LibrarySelectComponents/ExcerciseSelect';
import ImagesSelect from './Library/LibrarySelectComponents/ImagesSelect';
import { extractValuesFromObject } from '../../utils/objectUtils';
import { repairPath } from '../../utils/path_utils';
import AudioSelect from './Library/LibrarySelectComponents/AudioSelect';
import MediaExcercisesService from '../../services/media_excercises.service';
import { PAGE_ENUM } from './Library/LibrarySwitches';
import VideoSelect from './Library/LibrarySelectComponents/VideoSelect';

const BUTTON_TYPES = {
    image: 0,
    audio: 1,
    video: 2,
    excercise: 3,
    video_link: 4,
    video_web: 5,
};

const isVideo = (original_type) => {
    return (
        original_type === BUTTON_TYPES.video ||
        original_type === BUTTON_TYPES.video_link ||
        original_type === BUTTON_TYPES.video_web
    );
};
const LibrarySelect = ({
    selectedButton,
    setSelectedButton,
    bookInfo,
    pageNumber,
    somethingSaving,
    setButtons,
}) => {
    return (
        <>
            {selectedButton.original_type === BUTTON_TYPES.excercise && (
                <ExcerciseSelect
                    savedButton={selectedButton}
                    somethingSaving={somethingSaving}
                    bookInfo={bookInfo}
                    pageNumber={pageNumber}
                    setSavedButton={setSelectedButton}
                    setButtons={setButtons}
                />
            )}
            {selectedButton.original_type === BUTTON_TYPES.image && (
                <ImagesSelect
                    savedButton={selectedButton}
                    setSavedButton={setSelectedButton}
                    bookInfo={bookInfo}
                    pageNumber={pageNumber}
                    setButtons={setButtons}
                    somethingSaving={somethingSaving}
                />
            )}
            {selectedButton.original_type === BUTTON_TYPES.audio && (
                <AudioSelect
                    savedButton={selectedButton}
                    setSavedButton={setSelectedButton}
                    bookInfo={bookInfo}
                    pageNumber={pageNumber}
                    setButtons={setButtons}
                    somethingSaving={somethingSaving}
                />
            )}
            {isVideo(selectedButton.original_type) && (
                <VideoSelect
                    savedButton={selectedButton}
                    setSavedButton={setSelectedButton}
                    bookInfo={bookInfo}
                    pageNumber={pageNumber}
                    setButtons={setButtons}
                    somethingSaving={somethingSaving}
                />
            )}
        </>
    );
};

export default LibrarySelect;
