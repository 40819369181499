const ErrorStatements = (lstOfErrors, statusOfError) => {
    const error = lstOfErrors.find((error) => error.code === statusOfError);

    if (!error) {
        return {
            err: true,
            code: statusOfError,
            msg: 'Neznámá chyba',
        };
    }
    return { ...error, err: true };
};

export { ErrorStatements };
