import React, { useEffect, useState } from "react";
import { Layer, Stage } from "react-konva";
import { ButtonSpriteStatic } from "~/components/ButtonSprite";
import useAxiosPrivate from "~/hooks/useAxiosPrivate";
import InteractiveService from "~/services/interactive.service";
import { format_buttons } from "~/utils/canvasesCalculations";

const SimpleButtonPage = ({
	bookid,
	release,
	version,
	page,
	width,
	height,
	buttonsVisible,
}) => {
	const [buttons, setButtons] = useState([]);
	const [originalButtons, setOriginalButtons] = useState([]);
	const privateAxios = useAxiosPrivate();
	useEffect(() => {
		const download_buttons = async () => {
			const buttons = await InteractiveService.get_buttons(
				bookid,
				release,
				version,
				page,
				privateAxios
			);
			let formatted = buttons.buttons.map((button) => {
				return format_buttons(button, width, height);
			});
			// console.log("For page - ", page, " - ", formatted);
			setOriginalButtons(buttons.buttons);
			setButtons(formatted);
		};
		download_buttons();
	}, [page]);
	useEffect(() => {
		const new_buttons = originalButtons.map((button) => {
			return format_buttons(button, width, height);
		});
		setButtons(new_buttons);
	}, [width, height]);
	return (
		<Stage width={width} height={height}>
			<Layer>
				{buttons.map((button) => {
					return (
						buttonsVisible && (
							<ButtonSpriteStatic
								key={button.key}
								type={button.type}
								x={button.x}
								y={button.y}
								button_info={button}
								clicked={(button) => {
									console.log(button);
								}}
								disableHoverAnimation={true}
								disableClickAnimation={true}
							/>
						)
					);
				})}
			</Layer>
		</Stage>
	);
};

export default SimpleButtonPage;
