import React from 'react';
import { Roller } from 'react-awesome-spinners';
import ReactDom from 'react-dom';
const FullScreenLoading = () => {
    return ReactDom.createPortal(
        <div className="fullscreen-loader">
            <Roller color="#00b2ff" size={128} sizeUnit="px" />
        </div>,
        document.getElementById('fullscreen-loader')
    );
};

export default FullScreenLoading;
