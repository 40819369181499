import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./OneLineInput.css";
import classNames from "classnames";

const OneLineInput = forwardRef(
	(
		{
			id,
			label,
			className,
			value,
			onChange,
			breakLine,
			url,
			placeholder,
			smaller,
		},
		ref
	) => {
		const [isError, setIsError] = useState(false);
		const handleError = (error) => {
			setIsError(error);
		};
		useImperativeHandle(ref, () => ({
			handleError,
		}));
		return (
			<div
				className={classNames("one-line-input smaller", className, {
					"break-line": breakLine,
				})}
			>
				{!breakLine && (
					<label htmlFor={id} className="one-line-input-label">
						{label}
					</label>
				)}
				<input
					type="text"
					className={classNames("one-line-input-input", {
						error: isError,
						url: url,
					})}
					id={id}
					value={value}
					onChange={(e) => onChange(e.target.value)}
					placeholder={
						breakLine ? label : placeholder ? placeholder : ""
					}
					onKeyDown={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
		);
	}
);

export default OneLineInput;
