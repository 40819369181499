import React, { useCallback, useEffect, useRef, useState } from "react";
import MediaExcercisesService from "../../../services/media_excercises.service";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { PAGE_ENUM } from "./LibrarySwitches";
import { toast } from "react-toastify";
import toastSettings from "../../../utils/toastSettings";
import DragAndDropNoStyle from "../../../components/DragAndDropNoStyle";
import VideoElem from "./elems/VideoElem";
import FlexibleLoader from "../../../components/FlexibleLoader/FlexibleLoader";
import PopUpConfirm from "../../../components/PopUpConfirm";
import { shouldView } from "../../../utils/stateUtils";
import { extractValuesFromObject } from "../../../utils/objectUtils";
import { useAssetVideos, useLoadingAssets } from "../../../stores/assetStore";
import EditVideoSource from "./LibrarySelectComponents/EditVideoSource";

const VIDEO_TYPES = ["video/mp4", "video/webm", "video/ogg", "video/x-flv"];

const VideosPage = ({ book, reAsk, search }) => {
	const privateAxios = useAxiosPrivate();
	const [videos, setVideos] = useAssetVideos();
	const [loading, setLoading] = useLoadingAssets();
	const [removingVideo, setRemovingVideo] = useState(null);
	const [openedVideoSource, setOpenedVideoSource] = useState(null);
	const get_videos = useCallback(async () => {
		const videos_response = await MediaExcercisesService.get_media(
			PAGE_ENUM.VIDEO,
			book,
			privateAxios
		);
		if (videos_response.err) {
			toast.error("Nastala chyba při načítání videí", toastSettings);
			return;
		}
		if (!videos_response.result) {
			toast.error(videos_response.message, toastSettings);
			return;
		}
		const new_videos = extractValuesFromObject(videos_response.videos).sort(
			(a, b) => a.name.localeCompare(b.name)
		);

		setVideos(new_videos);
		setLoading(false);
	}, [book, privateAxios, setVideos, setLoading]);
	const videoRefs = useRef([]);
	useEffect(() => {
		if (reAsk) get_videos();
	}, [reAsk, get_videos]);

	const removeVideo = async () => {
		if (removingVideo.buttons && removingVideo.buttons.length > 0) {
			toast.error(
				"Nelze smazat video, které je přiřazeno k nějakému tlačítku",
				toastSettings
			);
			return;
		}
		const remove_obj = {
			...book,
			asset_id: removingVideo.key,
		};
		const result = await MediaExcercisesService.remove_asset(
			remove_obj,
			false,
			privateAxios
		);
		if (result.err) {
			toast.error("Nastala chyba při mazání videa", toastSettings);
			return;
		}
		setVideos(videos.filter((video) => video.key !== removingVideo.key));
		setRemovingVideo(null);
		toast.success("Video bylo úspěšně smazáno", toastSettings);
	};

	const moveToPrevious = useCallback(() => {
		if (!openedVideoSource) return;
		let newIndex = openedVideoSource.index - 1;
		if (openedVideoSource.index === 0) {
			newIndex = videos.length - 1;
		}
		const selectedVideo = videos[newIndex];
		if (videoRefs.current[newIndex])
			videoRefs.current[newIndex].scrollVideoToView();
		setOpenedVideoSource({
			video: selectedVideo,
			index: newIndex,
		});
	}, [openedVideoSource, videos]);
	const moveToNext = useCallback(() => {
		if (!openedVideoSource) return;
		let newIndex = openedVideoSource.index + 1;
		if (openedVideoSource.index === videos.length - 1) {
			newIndex = 0;
		}
		const selectedVideo = videos[newIndex];
		if (videoRefs.current[newIndex])
			videoRefs.current[newIndex].scrollVideoToView();
		setOpenedVideoSource({
			video: selectedVideo,
			index: newIndex,
		});
	}, [openedVideoSource, videos]);

	useEffect(() => {
		const handleMove = (e) => {
			if (e.key === "ArrowRight") {
				moveToNext();
			}
			if (e.key === "ArrowLeft") {
				moveToPrevious();
			}
		};
		window.addEventListener("keydown", handleMove);
		return () => {
			window.removeEventListener("keydown", handleMove);
		};
	}, [moveToNext, moveToPrevious]);
	return (
		<>
			<DragAndDropNoStyle
				type={PAGE_ENUM.VIDEO}
				accept_types={VIDEO_TYPES}
				setCollection={setVideos}
				additionalClasses={"library-drag-drop"}
				collection={videos}
			>
				{loading ? (
					<FlexibleLoader />
				) : videos.length === 0 ? (
					<div>Žádná videa</div>
				) : (
					<div className="library-content">
						{videos.map(
							(video, index) =>
								shouldView(video, search) && (
									<VideoElem
										key={video.key}
										video_obj={video}
										removeFunc={() =>
											setRemovingVideo(video)
										}
										book={book}
										index={index}
										setOpenedVideoSource={
											setOpenedVideoSource
										}
										editingSourceNow={
											openedVideoSource?.index === index
										}
										ref={(el) => {
											videoRefs.current[index] = el;
										}}
									/>
								)
						)}
					</div>
				)}
			</DragAndDropNoStyle>
			{removingVideo && (
				<PopUpConfirm
					closeFunction={() => setRemovingVideo(null)}
					confirmFunction={removeVideo}
					question={"Doopravdy chcete vymazat toto video?"}
				/>
			)}
			{openedVideoSource && (
				<EditVideoSource
					closeFunc={() => setOpenedVideoSource(null)}
					video={openedVideoSource.video}
					setVideos={setVideos}
					moveToNext={moveToNext}
					moveToPrevious={moveToPrevious}
					disableCloseAfterSave={true}
				/>
			)}
		</>
	);
};

export default VideosPage;
