import React from 'react';
import { assetToPath } from '../../../../utils/path_utils';
import classNames from 'classnames';
const shouldView = (image, searchedText) => {
    if (!searchedText) return true;
    const lower_case_searched = searchedText
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    const lower_case_name = image.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    return lower_case_name.includes(lower_case_searched)
        ? 'visible'
        : 'not-visible';
};

const LibraryElem = ({
    elem,
    searchText,
    clickedFunc,
    orderingNumber,
    pageSuggestion,
    selectedAny,
    selected,
    image,
    smaller,
    buttons,
}) => {
    const classes = classNames(
        'library-item-select-outer',
        shouldView(elem, searchText),
        {
            selected: selectedAny && selected,
            'not-selected': selectedAny && !selected,
        }
    );
    return (
        <div className={classes} onClick={() => clickedFunc(elem)}>
            <div
                className={classNames('library-item-select', {
                    smaller: smaller,
                })}
            >
                {image ? (
                    <img src={image} alt={elem.name} />
                ) : (
                    <img src={assetToPath(elem)} alt={elem.name} />
                )}
            </div>
            <p className="libary-item-header">{elem.name}</p>
            {pageSuggestion && elem.suggested_page && (
                <p className="library-item-pageNum">{elem.suggested_page}</p>
            )}
            {orderingNumber !== undefined && orderingNumber !== -1 && (
                <p className="library-item-order-selected">
                    {orderingNumber + 1}
                </p>
            )}
            {buttons && (
                <div className="library_elem_buttons bottom-left off">
                    {buttons
                        .sort((a, b) => a.page - b.page)
                        .map((btn, i) => (
                            <button
                                key={i}
                                className="icon-button smaller circular library"
                                title={`Toto cvičení je propojeno s tlačítkem na straně ${btn.page}`}
                            >
                                <p>{btn.page}</p>
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

export default LibraryElem;
