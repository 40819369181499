import { create } from 'zustand';

export const useSelectedButtonStore = create((set) => ({
    savedButton: null,
    currentPageNumber: 0,
    selectedPageSide: -1,
    buttons: [],
    setSavedButtonAll: (buttons, pageNumber, pageSide) =>
        set({
            buttons: buttons,
            currentPageNumber: pageNumber,
            selectedPageSide: pageSide,
        }),
    setSavedButton: (button) => set({ savedButton: button }),
    setActivePage: (pageNumber, pageSide) =>
        set({ currentPageNumber: pageNumber, selectedPageSide: pageSide }),
    setSavedButtons: (buttons) => set({ buttons }),
}));

const useSavedButton = () => {
    const savedButton = useSelectedButtonStore((state) => state.savedButton);
    const setSavedButton = useSelectedButtonStore(
        (state) => state.setSavedButton
    );
    const setSavedButtons = useSelectedButtonStore(
        (state) => state.setSavedButtons
    );

    return [savedButton, setSavedButton, setSavedButtons];
};
const usePage = () => {
    const pageNumber = useSelectedButtonStore(
        (state) => state.currentPageNumber
    );
    const page = useSelectedButtonStore((state) => state.selectedPageSide);
    const selectPage = useSelectedButtonStore((state) => state.setActivePage);

    return [pageNumber, page, selectPage];
};
const useLastMovedPage = () => {
    const lastMovedPage = useSelectedButtonStore(
        (state) => state.selectedPageSide
    );
    return lastMovedPage;
};
const usePageSetFunctions = () => {
    const setActivePage = useSelectedButtonStore(
        (state) => state.setActivePage
    );
    return setActivePage;
};
const useSetAllButton = () => {
    const setSavedButtonAll = useSelectedButtonStore(
        (state) => state.setSavedButtonAll
    );
    return setSavedButtonAll;
};
const useSaveButtons = () => {
    const buttons = useSelectedButtonStore((state) => state.buttons);
    const setSavedButtons = useSelectedButtonStore(
        (state) => state.setSavedButtons
    );
    return [buttons, setSavedButtons];
};
export {
    useSavedButton,
    usePage,
    usePageSetFunctions,
    useSetAllButton,
    useSaveButtons,
};

// page -> LEFT = 0, RIGHT = 1
// selectedPage -> lastMovedPage
// pageNumber - currentPage - cislo stranky
// setLastMovedPage - leva nebo prava
