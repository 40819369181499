import React, { useState } from 'react';
import ReactDom from 'react-dom';
import '../pages/styles/components/bottomAudioPlayer.css';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { assetToPath } from '../utils/path_utils';
import CloseIcon from '../static/images/close.png';

const BottomAudioPlayer = ({ button, closeFunction }) => {
    const [closing, setClosing] = useState(false);
    const [currentVolume, setCurrentVolume] = useState(() => {
        const savedVolume = localStorage.getItem('audioPlayerVolume');
        return savedVolume !== null ? parseFloat(savedVolume) : 0.5;
    });
    const handleClosing = () => {
        setClosing(true);
        setTimeout(() => {
            closeFunction();
        }, 300);
    };
    const handleVolumeChange = (event) => {
        const newVolume = event.target.volume;
        setCurrentVolume(newVolume);
        localStorage.setItem('audioPlayerVolume', newVolume);
    };
    console.log(assetToPath(button.audio));
    return ReactDom.createPortal(
        <div className={`bottom-player ${closing ? 'closing' : ''}`}>
            <div className="audio-player-outer">
                <div className="audio-player-section">
                    <AudioPlayer
                        autoPlay
                        src={assetToPath(button.audio)}
                        customProgressBarSection={[
                            RHAP_UI.CURRENT_TIME,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.DURATION,
                        ]}
                        customAdditionalControls={[]}
                        volume={currentVolume}
                        onVolumeChange={handleVolumeChange}
                    />
                </div>
                <button onClick={handleClosing} className="close-btn top-right">
                    <img src={CloseIcon} alt="close" />
                </button>
            </div>
        </div>,
        document.getElementById('bottom-player')
    );
};

export default BottomAudioPlayer;
