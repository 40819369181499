import React from 'react';
import { Rect } from 'react-konva';

const CreatePolygon = ({ polygon }) => {
    return (
        <Rect
            x={polygon.x}
            y={polygon.y}
            width={polygon.width}
            height={polygon.height}
            stroke={polygon.deleting ? '#ff3d3d' : '#5a08bf'}
            strokeWidth={1}
            fill={polygon.deleting ? '#ff3d3d66' : '#5a08bf66'}
        />
    );
};

export default CreatePolygon;
