const paths = {
    GET_ZOOM_POLYGONS: () => '/zoom/get-polygons',
    SAVE_NEW_POLYGON: () => '/zoom/polygons',
    DELETE_POLYGONS: () => '/zoom/polygons/delete',
    UPDATE_POLYGON: () => '/zoom/polygons/update',
    ADD_CUTOUT: () => '/zoom/polygons/make-cutout',
    GET_ZOOM: () => '/zoom/zoom-file',
};

class ZoomService {
    static async get_polygons(book, pages, axios_instance) {
        const post_data = {
            ...book,
            pages,
        };
        try {
            const response = await axios_instance.post(
                paths.GET_ZOOM_POLYGONS(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async save_new_polygon(book, page, polygons, axios_instance) {
        const post_data = {
            ...book,
            page,
            polygons,
        };
        try {
            const response = await axios_instance.post(
                paths.SAVE_NEW_POLYGON(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async delete_polygons(book, page, polygons, axios_instance) {
        const post_data = {
            ...book,
            page,
            polygons,
        };
        try {
            const response = await axios_instance.post(
                paths.DELETE_POLYGONS(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async update_polygon(book, page, polygon, axios_instance) {
        const post_data = {
            ...book,
            page,
            polygon,
        };
        try {
            const response = await axios_instance.post(
                paths.UPDATE_POLYGON(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async add_cutouts(book, page, polygonsWithCutouts, axios_instance) {
        const post_data = {
            ...book,
            page,
            polys: polygonsWithCutouts,
        };

        try {
            const response = await axios_instance.post(
                paths.ADD_CUTOUT(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
    static async get_zoom(book, page, polygon_id, axios_instance) {
        const post_data = {
            ...book,
            page,
            polygon_id,
        };
        try {
            const response = await axios_instance.post(
                paths.GET_ZOOM(),
                post_data
            );
            if (response.data.err) {
                return { err: true, detail: response.data.detail };
            }
            return response.data;
        } catch (error) {
            return { err: true, detail: error };
        }
    }
}
export default ZoomService;
