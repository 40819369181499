import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../styles/tables.css';
import '../styles/text.css';
import '../styles/buttons.css';
import { Roller } from 'react-awesome-spinners';
import Fuse from 'fuse.js';
import { BASE_STATIC } from '../../deffault/axios';

const fuseOptions = {
    keys: ['name', 'subject.shortcut', 'catalog'],
    threshold: 0.2,
    shouldSort: true,
};
const Books = () => {
    const privateAxios = useAxiosPrivate();
    const [books, setBooks] = useState([]);
    const [booksSet, setBooksSet] = useState(false);
    const [filteredBooks, setFilteredBooks] = useState([]);

    const [search, setSearch] = useState('');
    useEffect(() => {
        const get_books = async () => {
            const response = await privateAxios.get('/books/');
            setBooks(response.data.books);
            setBooksSet(true);
        };
        get_books();
    }, [privateAxios]);

    useEffect(() => {
        if (books.length > 0) {
            const fuse = new Fuse(books, fuseOptions);
            const searched = fuse.search(search).map((val) => val.item.catalog);
            setFilteredBooks(searched);
        }
    }, [search, books]);
    return (
        <>
            {!booksSet ? (
                <div className="loader-container">
                    <Roller color="#00b2ff" size={64} sizeUnit="px" />
                </div>
            ) : (
                <div className="books-container">
                    <div className="top-with-input smaller-padding">
                        <h1 className="header-2">Knihy</h1>
                        <input
                            className="input-search"
                            placeholder="Vyhledat knihy"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div className="books-list">
                        {books.length > 0 &&
                            books.map((book, i) => {
                                if (
                                    search === '' ||
                                    filteredBooks.includes(book.catalog)
                                ) {
                                    return (
                                        <div className="book-detail" key={i}>
                                            <div className="book-image-div">
                                                <img
                                                    key={i}
                                                    src={
                                                        BASE_STATIC +
                                                        book.picture_ref
                                                    }
                                                    alt="book"
                                                />
                                            </div>
                                            <div className="book-detail-text">
                                                <div>
                                                    <p className="book-text">
                                                        {book.subject.shortcut}
                                                        {' - '}
                                                        {book.catalog}
                                                    </p>
                                                    <p className="book-text">
                                                        {book.name}
                                                    </p>
                                                </div>
                                                <div className="bottom-div-with-button">
                                                    {/* <Link
                                                        to={`/admin/interaktivka/${book.catalog}`}
                                                        className="btn btn-yellow"
                                                        relative={false}
                                                    >
                                                        Interaktivka
                                                    </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                    </div>
                </div>
            )}
        </>
    );
};

export default Books;
