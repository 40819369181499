import React, { useEffect, useState } from 'react';
import { BUTTON_TYPES, format_buttons } from '../../utils/canvasesCalculations';
import { Stage, Layer } from 'react-konva';
import { ButtonSpriteStatic } from '../../components/ButtonSprite';
import ExcerciseCenter from './StaticPageComponents/ExcerciseCenter';
import { toast } from 'react-toastify';
import ImageCorousel from './StaticPageComponents/ImageCorousel';
import BottomAudioPlayer from '../../components/BottomAudioPlayer';
import VideoLocal from './StaticPageComponents/VideoLocal';
import VideoLink from './StaticPageComponents/VideoLink';
import VideoEmbedded from './StaticPageComponents/VideoEmbedded';

const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};

const errorMapping = {
    [BUTTON_TYPES.image]: {
        message: 'Toto tlačítko nemá přiřazené obrázky.',
        checkFcn: (button) => !button.pictures || button.pictures.length === 0,
    },
    [BUTTON_TYPES.audio]: {
        message: 'Toto tlačítko nemá přiřazené audio.',
        checkFcn: (button) => !button.audio || !button.audio.file_path,
    },
    [BUTTON_TYPES.excercise]: {
        message: 'Toto tlačítko nemá přiřazené cvičení.',
        checkFcn: (button) => !button.excercise_ref,
    },
    [BUTTON_TYPES.video]: {
        message: 'Toto tlačítko nemá přiřazené video.',
        checkFcn: (button) => !button.video || !button.video.file_path,
    },
    [BUTTON_TYPES.video_link]: {
        message: 'Toto tlačítko nemá přiřazené video.',
        checkFcn: (button) =>
            !button.video ||
            (button.video.type === 'external' && !button.video.externalUrl) ||
            (button.video.type === 'internal' && !button.video.url),
    },
    [BUTTON_TYPES.video_web]: {
        message: 'Toto tlačítko nemá přiřazené video.',
        checkFcn: (button) => !button.video || !button.video.embed,
    },
};

const StaticButtonPage = ({
    width,
    height,
    get_buttons,
    page,
    lastPage,
    setLastPage,
    showButtons,
}) => {
    const [buttons, setButtons] = useState([]);
    const [clickedButton, setClickedButton] = useState(null);
    useEffect(() => {
        const download_buttons = async () => {
            const data = await get_buttons();
            let formated = data?.buttons?.map((button) => {
                return format_buttons(button, width, height);
            });
            setButtons(formated);
        };
        download_buttons();
    }, [width, height, get_buttons]);

    const handleButtonClicked = (button) => {
        setLastPage(page);
        const errorObject = errorMapping[button.original_type];
        if (errorObject && errorObject.checkFcn(button)) {
            toast.error(errorObject.message, toastError);
            return;
        }
        setClickedButton(button);
    };

    return (
        <>
            {showButtons && buttons && buttons.length > 0 ? (
                <Stage width={width} height={height}>
                    <Layer>
                        {buttons.map((button) => {
                            return (
                                <ButtonSpriteStatic
                                    key={button.key}
                                    type={button.type}
                                    x={button.x}
                                    y={button.y}
                                    button_info={button}
                                    clicked={() => {
                                        handleButtonClicked(button);
                                    }}
                                />
                            );
                        })}
                    </Layer>
                </Stage>
            ) : (
                <Stage width={width} height={height}>
                    <Layer></Layer>
                </Stage>
            )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.excercise && (
                    <ExcerciseCenter
                        button={clickedButton}
                        closeFunction={() => setClickedButton(null)}
                        pageNumber={page}
                    />
                )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.image && (
                    <ImageCorousel
                        button={clickedButton}
                        closeFunction={() => setClickedButton(null)}
                    />
                )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.audio && (
                    <BottomAudioPlayer
                        button={clickedButton}
                        closeFunction={() => setClickedButton(null)}
                    />
                )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.video && (
                    <VideoLocal
                        button={clickedButton}
                        closeFunc={() => setClickedButton(null)}
                    />
                )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.video_link && (
                    <VideoLink
                        button={clickedButton}
                        closeFunc={() => setClickedButton(null)}
                    />
                )}
            {lastPage === page &&
                clickedButton?.original_type === BUTTON_TYPES.video_web && (
                    <VideoEmbedded
                        button={clickedButton}
                        closeFunc={() => setClickedButton(null)}
                    />
                )}
        </>
    );
};

export default StaticButtonPage;
