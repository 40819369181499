import React, { useEffect, useRef, useState } from "react";
import SwitchButton from "~/components/SwitchButton";
import ReactDOM from "react-dom";
import ColorPickerLine from "./ColorPickerLine";
import PaddingSetter from "./PaddingSetter";

const BottomLeftButtonStack = ({
	setShowResults,
	setButtonsVisible,
	color,
	setColor,
	confirmChangeColor,
	firstPageBackground,
	setFirstPageBackground,
	padding,
	setPadding,
	confirmChangePadding,
}) => {
	return ReactDOM.createPortal(
		<div className="bottom-left-button-stack">
			{padding && setPadding && (
				<PaddingSetter
					padding={padding}
					setPadding={setPadding}
					confirmChange={confirmChangePadding}
				/>
			)}
			{/*  confirmChangeColor -- 0=BACKGROUND, 1=FIRST_PAGE*/}
			{color && setColor && (
				<ColorPickerLine
					label="Barva pozadí"
					color={color}
					setColor={setColor}
					confirmChange={() => confirmChangeColor(0)}
				/>
			)}
			{firstPageBackground && setFirstPageBackground && (
				<ColorPickerLine
					label="První stránka"
					color={firstPageBackground}
					setColor={setFirstPageBackground}
					confirmChange={() => confirmChangeColor(1)}
				/>
			)}
			<SwitchButton
				label="Řešení"
				onClick={setShowResults}
				defaultValue={true}
			/>
			<SwitchButton
				label="Tlačítka"
				onClick={setButtonsVisible}
				defaultValue={false}
			/>
		</div>,
		document.body
	);
};

export default BottomLeftButtonStack;
