import React, { useState } from 'react';
import ThreeWaySwitch from '../../../../components/ThreeWaySwitch/ThreeWaySwitch';
import ButtonsService from '../../../../services/buttons.service';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import toastSettings from '../../../../utils/toastSettings';
import { replaceButtonInButtonArray } from '../../../../utils/stateUtils';
import { button_types_mode } from '../../../../utils/canvasesCalculations';
import VideoLibrarySelect from './VideoLibrarySelect';
import InsertVideoCode from './InsertVideoCode';
import InsertVideoUrl from './InsertVideoUrl';
import Modal from '../../../../components/Modal';
import MediaExcercisesService from '../../../../services/media_excercises.service';
import { PAGE_ENUM } from '../LibrarySwitches';
import { useAssetVideos } from '../../../../stores/assetStore';

const video_selections = [
    {
        label: 'Lokální',
        value: 2,
    },
    {
        label: 'Odkaz',
        value: 4,
    },
    {
        label: 'Embed',
        value: 5,
    },
];
const buttonHasVideo = (button) => {
    if (!button.video) return false;
    if (Object.keys(button.video).length === 0) return false;
    return true;
};
const VIDEO_LOCAL_TYPE = 2;
const VIDEO_LINK_TYPE = 4;
const VIDEO_WEB_TYPE = 5;

const VideoSelect = ({
    savedButton,
    setSavedButton,
    bookInfo,
    pageNumber,
    setButtons,
    somethingSaving,
}) => {
    const privateAxios = useAxiosPrivate();
    const [confirmingChangeType, setConfirmingChangeType] = useState(null);
    const [confirmingChangeVideo, setConfirmingChangeVideo] = useState(null);
    const [videos, setVideos] = useAssetVideos();

    const handleChangeType = async (type, confirmedChange = false) => {
        if (buttonHasVideo(savedButton) && !confirmedChange) {
            // Video is already selected - ASK FOR CONFIRM
            setConfirmingChangeType(type);
            return;
        }

        somethingSaving(true);
        const data = {
            bookid: bookInfo.bookid,
            release: bookInfo.release,
            version: bookInfo.version,
            page_number: pageNumber,
            button_id: savedButton.key,
            new_type: type.value,
        };
        const response = await ButtonsService.change_button_type(
            data,
            privateAxios
        );
        if (response.err) {
            console.log('Error');
            return;
        }
        const newButton = {
            ...savedButton,
            type: button_types_mode[type.value],
            original_type: type.value,
            video: null,
        };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton, pageNumber);
        somethingSaving(false);
    };

    const handleVideoCodeChange = async (
        new_data_object,
        confirmedChange = false
    ) => {
        if (buttonHasVideo(savedButton) && !confirmedChange) {
            setConfirmingChangeVideo(new_data_object);
            return;
        }
        somethingSaving(true);
        const data = {
            bookid: bookInfo.bookid,
            release: bookInfo.release,
            version: bookInfo.version,
            page_number: pageNumber,
            button_id: savedButton.key,
            video_data: new_data_object,
        };
        const response = await ButtonsService.change_button_basic_attribute(
            data,
            privateAxios
        );
        if (response.err) {
            toast.error('Nepodařilo se uložit video', toastSettings);
            return;
        }
        const newButton = {
            ...savedButton,
            video: { ...response.button.video },
        };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);
        somethingSaving(false);
        toast.success('Video bylo úspěšně uloženo', toastSettings);
    };

    const handleAddVideoFromLibrary = async (video) => {
        const data = {
            bookid: bookInfo.bookid,
            release: bookInfo.release,
            version: bookInfo.version,
            page_number: pageNumber,
            video: video,
        };
        const response = await MediaExcercisesService.select_video_from_library(
            data,
            savedButton.key,
            privateAxios
        );
        if (response.err) {
            toast.error('Nepodařilo se přidat video', toastSettings);
            return;
        }
        const newButton = {
            ...savedButton,
            video: { ...response.button.video },
        };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);
        setVideos(response.videos);
        toast.success('Video bylo úspěšně přidáno', toastSettings);
    };

    const handleAddNewVideo = async (video, progressFunc) => {
        const info_object = {
            bookid: bookInfo.bookid,
            release: bookInfo.release,
            version: bookInfo.version,
            type: PAGE_ENUM.VIDEO,
            page_number: pageNumber,
        };
        const formData = new FormData();
        formData.append('info', JSON.stringify(info_object));
        formData.append('file', video);
        const response = await MediaExcercisesService.upload_new_video(
            formData,
            savedButton.key,
            privateAxios,
            progressFunc
        );

        setVideos(response.videos);
        const newButton = {
            ...savedButton,
            video: { ...response.button.video },
        };
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);
        return response;
    };

    return (
        <>
            <ThreeWaySwitch
                header="Typ videa"
                values={video_selections}
                onChange={handleChangeType}
                currentValue={savedButton.original_type}
            />
            {savedButton.original_type === VIDEO_LOCAL_TYPE && (
                <VideoLibrarySelect
                    savedButton={savedButton}
                    setSavedButton={setSavedButton}
                    videos={videos}
                    handleAddVideo={handleAddVideoFromLibrary}
                    handleAddNewVideo={handleAddNewVideo}
                    setButtons={setButtons}
                    pageNumber={pageNumber}
                />
            )}
            {savedButton.original_type === VIDEO_LINK_TYPE && (
                <InsertVideoUrl
                    selectedButton={savedButton}
                    handleChange={handleVideoCodeChange}
                />
            )}
            {savedButton.original_type === VIDEO_WEB_TYPE && (
                <InsertVideoCode
                    selectedButton={savedButton}
                    handleChange={handleVideoCodeChange}
                />
            )}
            {confirmingChangeType && (
                <Modal
                    closeFunction={() => setConfirmingChangeType(null)}
                    acceptFuncion={async () => {
                        setConfirmingChangeType(null);
                        await handleChangeType(confirmingChangeType, true);
                    }}
                    acceptText="Přepsat"
                >
                    <p className="body-1 mg-b-1 centered">
                        Toto tlačítko má již připsané video. Opravdu si přejete
                        změnit typ videa? Video od tlačítka bude smazáno.
                    </p>
                </Modal>
            )}
            {confirmingChangeVideo && (
                <Modal
                    closeFunction={() => setConfirmingChangeVideo(null)}
                    acceptFuncion={async () => {
                        await handleVideoCodeChange(
                            confirmingChangeVideo,
                            true
                        );
                        setConfirmingChangeVideo(null);
                    }}
                    acceptText="Ano"
                >
                    <p className="body-1 mg-b-1 centered">
                        Opravdu si přejete přepsat již uložené video?
                    </p>
                </Modal>
            )}
        </>
    );
};

export default VideoSelect;
