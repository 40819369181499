import React from 'react';

const ChangesHistory = ({
    historyObjs,
    title,
    historyButtons,
    selectMode,
    activeMode,
    stateHistory,
}) => {
    return (
        <div className="history">
            <div className="history-header-div">
                <h5 className="body-1">{title}</h5>
                {historyButtons && historyButtons.length > 0 && (
                    <div className="history-changes-mode">
                        {historyButtons.map((histButton) => {
                            return (
                                <button
                                    key={histButton.id}
                                    className={`small-btn-switchers ${
                                        activeMode === histButton.id
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        console.log(
                                            'selecting with id: ',
                                            histButton.id
                                        );
                                        selectMode(histButton.id);
                                    }}
                                >
                                    {histButton.text}
                                </button>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="changes">
                <div
                    className={`change change-header ${
                        stateHistory ? 'state' : ''
                    }`}
                >
                    <div className="change-date">Datum</div>
                    <div className="change-info">Změna</div>
                    {!stateHistory && <div className="change-page">Strana</div>}
                    <div className="change-author">Autor</div>
                </div>

                {historyObjs.map((historyObj, index) => {
                    return (
                        <div
                            className={`change ${stateHistory ? 'state' : ''}`}
                            key={index}
                        >
                            <div className="change-date">{historyObj.date}</div>
                            <div className="change-info">
                                {historyObj.change}
                            </div>
                            {!stateHistory && (
                                <div className="change-page">
                                    Str. {historyObj.page}
                                </div>
                            )}
                            <div className="change-author">
                                {historyObj.author}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ChangesHistory;
