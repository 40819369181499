import { ZoomToolsEnum } from '~/stores/zoomStore';
import {
    cutRectangleOut,
    isRectangleIntersectingPolygon,
} from './intersectingUtils';
import ClipperLib from 'clipper-lib';
import { getKonvaCursorLocation } from './canvasesCalculations';
class Polygon {
    constructor(polygon_from_api, width, height, page) {
        this.id = polygon_from_api.id;
        this.original_points = polygon_from_api.points;
        this.canvas_width = width;
        this.canvas_height = height;
        this.page = page;
        const new_points = rescale_polygon(polygon_from_api, width, height);
        this.points = new_points.points;
        this.formated_points = new_points.formated_points;
        this.bounding_rect = this.bounding_rectangle;
    }
    get bounding_rectangle() {
        const x_values = this.formated_points.map((point) => point.x);
        const y_values = this.formated_points.map((point) => point.y);
        const min_x = Math.min(...x_values);
        const min_y = Math.min(...y_values);
        const max_x = Math.max(...x_values);
        const max_y = Math.max(...y_values);
        return {
            x: min_x,
            y: min_y,
            width: max_x - min_x,
            height: max_y - min_y,
        };
    }
    get is_rectangle() {
        if (this.formated_points.length !== 4) {
            return false;
        }
        const point_1 = this.formated_points[0];
        const point_2 = this.formated_points[1];
        const point_3 = this.formated_points[2];
        const point_4 = this.formated_points[3];

        const horizontalPoints =
            point_1.x === point_4.x && point_2.x === point_3.x;
        const verticalPoints =
            point_1.y === point_2.y && point_3.y === point_4.y;
        return horizontalPoints && verticalPoints;
    }
    static nestedPolygon(points, offset) {
        let negative_offset = -offset;
        let rounded_points = [];
        for (let i = 0; i < points.length; i += 2) {
            rounded_points.push({
                X: Math.round(points[i]),
                Y: Math.round(points[i + 1]),
            });
        }

        const co = new ClipperLib.ClipperOffset();
        co.AddPath(
            rounded_points,
            ClipperLib.JoinType.jtMiter,
            ClipperLib.EndType.etClosedPolygon
        );
        const offsetPaths = new ClipperLib.Paths();
        co.Execute(offsetPaths, negative_offset);
        const offsetPolygon = offsetPaths[0];
        if (!offsetPolygon) return [];
        const offsetPoints = offsetPolygon.flatMap((point) => [
            point.X,
            point.Y,
        ]);
        return offsetPoints;
    }
    nested_poly(offset) {
        let negative_offset = -offset;
        const rounded_points = this.formated_points.map((point) => {
            return {
                X: Math.round(point.x),
                Y: Math.round(point.y),
            };
        });
        const co = new ClipperLib.ClipperOffset();
        co.AddPath(
            rounded_points,
            ClipperLib.JoinType.jtMiter,
            ClipperLib.EndType.etClosedPolygon
        );
        const offsetPaths = new ClipperLib.Paths();
        co.Execute(offsetPaths, negative_offset);
        const offsetPolygon = offsetPaths[0];
        if (!offsetPolygon) return [];
        const offsetPoints = offsetPolygon.flatMap((point) => [
            point.X,
            point.Y,
        ]);
        return offsetPoints;
    }
    transform_to_rectangle = () => {
        if (!this.is_rectangle) return;
        const point_1 = this.formated_points[0];
        const point_2 = this.formated_points[1];
        const point_3 = this.formated_points[2];

        return {
            x: point_1.x,
            y: point_1.y,
            width: point_2.x - point_1.x,
            height: point_3.y - point_2.y,
        };
    };
    polygon_to_api = (width, height) => {
        const new_polygon = [];

        for (let i = 0; i < this.points.length; i += 2) {
            let new_x = this.points[i] / width;
            let new_y = this.points[i + 1] / height;
            new_x = new_x > 1 ? 1 : new_x < 0 ? 0 : new_x;
            new_y = new_y > 1 ? 1 : new_y < 0 ? 0 : new_y;
            new_polygon.push(new_x);
            new_polygon.push(new_y);
        }

        return {
            id: this.id,
            points: new_polygon,
        };
    };
    cutoutRectangle = (rectangle) => {
        if (isRectangleIntersectingPolygon(rectangle, this.formated_points)) {
            let result = cutRectangleOut(this.formated_points, rectangle);
            if (!result) return null;

            const { newPolygon, cuttedOut } = result;

            const formatedPolygon = Polygon.points_to_polygon(
                newPolygon,
                this.canvas_width,
                this.canvas_height,
                this.id
            );
            const formatedCuttedOut = Polygon.points_to_polygon(
                cuttedOut,
                this.canvas_width,
                this.canvas_height
            );

            return {
                polygon: formatedPolygon,
                cuttedOut: formatedCuttedOut,
            };
        } else {
            return null;
        }
    };

    static points_to_polygon = (points, width, height, id = undefined) => {
        const new_points = [];
        points.forEach((point) => {
            new_points.push(point.x / width);
            new_points.push(point.y / height);
        });

        return {
            id: id,
            points: new_points,
        };
    };
    static points_to_polygon_flat = (points, width, height, id = undefined) => {
        const new_points = [];
        for (let i = 0; i < points.length; i += 2) {
            new_points.push(points[i] / width);
            new_points.push(points[i + 1] / height);
        }

        return {
            id: id,
            points: new_points,
        };
    };
}
export default Polygon;

const rescale_polygon = (polygon, width, height) => {
    const rescaled_points = [];
    const formated_points = [];
    for (let i = 0; i < polygon.points.length; i += 2) {
        const new_x = polygon.points[i] * width;
        const new_y = polygon.points[i + 1] * height;
        rescaled_points.push(new_x);
        rescaled_points.push(new_y);
        formated_points.push({ x: new_x, y: new_y });
    }
    return {
        points: rescaled_points,
        formated_points: formated_points,
    };
};
const movePolygonPoints = (
    points,
    mousePosition,
    originalPos,
    originalPoly
) => {
    const offsetX = mousePosition.x - originalPos.x;
    const offsetY = mousePosition.y - originalPos.y;

    const new_points = [];

    for (let i = 0; i < points.length; i += 2) {
        new_points.push(points[i] + offsetX);
        new_points.push(points[i + 1] + offsetY);
    }
    return new_points;
};
// Formatting rectangle so beginning is top left and width and height are positive
const format_rectangle = (rectangle) => {
    let new_rectangle = { ...rectangle };

    if (rectangle.width < 0) {
        new_rectangle.width = Math.abs(rectangle.width);
        new_rectangle.x = rectangle.x - new_rectangle.width;
    }
    if (rectangle.height < 0) {
        new_rectangle.height = Math.abs(rectangle.height);
        new_rectangle.y = rectangle.y - new_rectangle.height;
    }

    // RECTANGLE -> POLYGON
    const point_1 = { x: new_rectangle.x, y: new_rectangle.y };
    const point_2 = {
        x: new_rectangle.x + new_rectangle.width,
        y: new_rectangle.y,
    };
    const point_3 = {
        x: new_rectangle.x + new_rectangle.width,
        y: new_rectangle.y + new_rectangle.height,
    };
    const point_4 = {
        x: new_rectangle.x,
        y: new_rectangle.y + new_rectangle.height,
    };
    const points = [point_1, point_2, point_3, point_4];

    return points;
};
const transformNewToApi = (rectangle, width, height) => {
    const new_rectangle = format_rectangle(rectangle);
    const apiPoligon = Polygon.points_to_polygon(new_rectangle, width, height);
    return apiPoligon;
};
const getRelativePosition = (e, rect) => {
    const shape = e.target;
    const stage = shape.getStage();
    const pointerPosition = stage.getPointerPosition();
    const relativeX = pointerPosition.x - rect.x;
    const relativeY = pointerPosition.y - rect.y;
    return { x: relativeX, y: relativeY };
};

const RESCALE_OFFSET = 8;

const topLeftCorner = (relativeX, relativeY, rect) => {
    if (relativeX < RESCALE_OFFSET && relativeY < RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const bottomLeftCorner = (relativeX, relativeY, rect) => {
    if (
        relativeX < RESCALE_OFFSET &&
        relativeY > rect.height - RESCALE_OFFSET
    ) {
        return true;
    }
    return false;
};

const bottomRightCorner = (relativeX, relativeY, rect) => {
    if (
        relativeX > rect.width - RESCALE_OFFSET &&
        relativeY > rect.height - RESCALE_OFFSET
    ) {
        return true;
    }
    return false;
};

const horizontalSide = (relativeX, rect) => {
    if (relativeX < RESCALE_OFFSET || relativeX > rect.width - RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const verticalSide = (relativeY, rect) => {
    if (
        relativeY < RESCALE_OFFSET ||
        relativeY > rect.height - RESCALE_OFFSET
    ) {
        return true;
    }
    return false;
};

const topSide = (relativeY) => {
    if (relativeY < RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const bottomSide = (relativeY, rect) => {
    if (relativeY > rect.height - RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const leftSide = (relativeX) => {
    if (relativeX < RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const rightSide = (relativeX, rect) => {
    if (relativeX > rect.width - RESCALE_OFFSET) {
        return true;
    }
    return false;
};

const getHoveringSide = (coordinate, bounding_rect) => {
    const top = topSide(coordinate.y);
    const bottom = bottomSide(coordinate.y, bounding_rect);
    const left = leftSide(coordinate.x);
    const right = rightSide(coordinate.x, bounding_rect);

    const bL = bottom && left;
    const tL = top && left;
    const bR = bottom && right;
    const tR = top && right;

    if (bL) return HOVER_SIDE.BOTTOM_LEFT;
    if (tL) return HOVER_SIDE.TOP_LEFT;
    if (bR) return HOVER_SIDE.BOTTOM_RIGHT;
    if (tR) return HOVER_SIDE.TOP_RIGHT;
    if (top) return HOVER_SIDE.TOP;
    if (right) return HOVER_SIDE.RIGHT;
    if (bottom) return HOVER_SIDE.BOTTOM;
    if (left) return HOVER_SIDE.LEFT;

    // Check if cursor is inside of the rectangle

    return HOVER_SIDE.INSIDE;
};

const getResizingCursor = (hovering_side) => {
    switch (hovering_side) {
        case HOVER_SIDE.TOP:
            return 'ns-resize';
        case HOVER_SIDE.RIGHT:
            return 'ew-resize';
        case HOVER_SIDE.BOTTOM:
            return 'ns-resize';
        case HOVER_SIDE.LEFT:
            return 'ew-resize';
        case HOVER_SIDE.TOP_LEFT:
            return 'nwse-resize';
        case HOVER_SIDE.BOTTOM_RIGHT:
            return 'nwse-resize';
        case HOVER_SIDE.BOTTOM_LEFT:
            return 'nesw-resize';
        case HOVER_SIDE.TOP_RIGHT:
            return 'grab';
        case HOVER_SIDE.INSIDE:
            return 'grab';
        case HOVER_SIDE.REMOVE_BUTTON:
            return 'pointer';
        default:
            return 'default';
    }
};

const HOVER_SIDE = {
    TOP: 'TOP',
    RIGHT: 'RIGHT',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    TOP_LEFT: 'TOP_LEFT',
    TOP_RIGHT: 'TOP_RIGHT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    INSIDE: 'INSIDE',
    REMOVE_BUTTON: 'REMOVE_BUTTON',
    OUTSIDE: 'OUTSIDE',
};
// const ROUNDING_CONSTANT = 10;
const getResizedPolygon = (polygon, e) => {
    const coordinates = getKonvaCursorLocation(e);
    let newPoints = [...polygon.points];
    if (newPoints.length < 8) {
        return newPoints;
    }
    const roundedY =
        Math.round(coordinates.y) - (Math.round(coordinates.y) % 10);
    const roundedX =
        Math.round(coordinates.x) - (Math.round(coordinates.x) % 10);
    switch (polygon.side) {
        case HOVER_SIDE.TOP:
            newPoints[1] = roundedY;
            newPoints[3] = roundedY;
            break;
        case HOVER_SIDE.BOTTOM:
            newPoints[5] = roundedY;
            newPoints[7] = roundedY;
            break;
        case HOVER_SIDE.RIGHT:
            newPoints[2] = roundedX;
            newPoints[4] = roundedX;
            break;
        case HOVER_SIDE.LEFT:
            newPoints[0] = roundedX;
            newPoints[6] = roundedX;
            break;
        case HOVER_SIDE.TOP_LEFT:
            // Editting top left corner
            newPoints[0] = roundedX;
            newPoints[1] = roundedY;
            // Editting top right corner y coordinate
            newPoints[3] = roundedY;
            // Editting bottom left corner x coordinate
            newPoints[6] = roundedX;
            break;
        case HOVER_SIDE.BOTTOM_RIGHT:
            // Editting bottom right corner
            newPoints[4] = roundedX;
            newPoints[5] = roundedY;
            // Editting top right corner x coordinate
            newPoints[2] = roundedX;
            // Editting bottom left corner y coordinate
            newPoints[7] = roundedY;
            break;
        case HOVER_SIDE.BOTTOM_LEFT:
            //Editting bottom left corner
            newPoints[6] = roundedX;
            newPoints[7] = roundedY;
            //Editting top left corner x coordinate
            newPoints[0] = roundedX;
            //Editting bottom right corner y coordinate
            newPoints[5] = roundedY;
            break;
        default:
            break;
    }

    return { ...polygon, points: newPoints };
};
const setCorrespondingCursor = (e, activeTool) => {
    switch (activeTool) {
        case ZoomToolsEnum.MARQUEE:
            e.target.getStage().container().style.cursor = 'crosshair';
            break;
        case ZoomToolsEnum.CUTOUT:
            e.target.getStage().container().style.cursor = 'crosshair';
            break;
        case ZoomToolsEnum.MOVE:
            e.target.getStage().container().style.cursor = 'default';
            break;
        case ZoomToolsEnum.ZOOM_IN:
            e.target.getStage().container().style.cursor = 'default';
            break;
        default:
            e.target.getStage().container().style.cursor = 'default';
            break;
    }
};
const getCuttedPolygons = (
    polygons,
    cuttedPolygon,
    width,
    height,
    pageNumber
) => {
    let cuttingRectangle = format_rectangle(cuttedPolygon);
    let newPolygons = [];
    let dataForApi = [];

    for (let p of polygons) {
        const result = p.cutoutRectangle(cuttingRectangle);
        if (!result) {
            newPolygons.push(p);
            continue;
        }
        const { polygon, cuttedOut } = result;
        dataForApi.push({ polygon, cuttedOut: cuttedOut.points });
        const newFormatedPoly = new Polygon(polygon, width, height, pageNumber);
        newPolygons.push(newFormatedPoly);
    }
    return {
        newPolygons,
        dataForApi,
    };
};
export {
    rescale_polygon,
    format_rectangle,
    getRelativePosition,
    topLeftCorner,
    bottomLeftCorner,
    bottomRightCorner,
    horizontalSide,
    verticalSide,
    getResizingCursor,
    HOVER_SIDE,
    getHoveringSide,
    getResizedPolygon,
    movePolygonPoints,
    transformNewToApi,
    setCorrespondingCursor,
    getCuttedPolygons,
};
