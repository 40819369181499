import './RemoveButton.css';
import React from 'react';
import DeleteIconWhite from '../../static/images/delete_white.png';
import DeleteIconGrey from '../../static/images/delete.png';
import DeleteIconRed from '../../static/images/delete_red.png';
import classNames from 'classnames';

const withCircleIcon = {
    white: DeleteIconRed,
    grey: DeleteIconGrey,
    red: DeleteIconWhite,
};
const RemoveButton = ({ onClick, type, circle, size, title }) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                'remove-button',
                {
                    circle: circle,
                },
                type,
                size
            )}
            title={title}
        >
            <img
                src={type ? withCircleIcon[type] : withCircleIcon.white}
                alt="delete"
            />
        </button>
    );
};

export default RemoveButton;
