//REACT
import { Routes, Route, Navigate } from 'react-router-dom';
//STYLING
import './pages/styles/app.css';
// PAGES
import Login from './pages/auth/Login';
import MainPage from './pages/admin/mainPage';
import Layout from './components/Layout';
import Unauthorized from './pages/generic/Unauthorized';

// Auth
import RequireAuth from './pages/auth/RequireAuth';
import PersistLogin from './pages/auth/PersistLogin';

import UserTable from './pages/admin/UserTable';
import Books from './pages/admin/Books';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Navbar from './components/Navbar';
import useAuth from './hooks/useAuth';
import InteractiveBooks from './pages/admin/InteractiveBooks';
import Interactive from './pages/interactives/Interactive';
import setBodyColor from './utils/setBodyColor';

// Notificatons
function Admin() {
    const { auth } = useAuth();
    setBodyColor('#fff');
    return (
        <>
            {auth.access && <Navbar />}

            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* Public routes */}
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="login" element={<Login />} />
                    <Route path="unauthorized" element={<Unauthorized />} />
                    {/* Private routes */}
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRoles={[1]} />}>
                            <Route
                                path="admin/"
                                element={
                                    <div className="content-container">
                                        <MainPage />
                                    </div>
                                }
                            />
                            <Route
                                path="admin/uzivatele"
                                element={
                                    <div className="content-container">
                                        <UserTable />
                                    </div>
                                }
                            />
                            <Route
                                path="admin/knihy"
                                element={
                                    <div className="content-container">
                                        <Books />
                                    </div>
                                }
                            />
                            <Route
                                path="admin/interaktivky"
                                element={
                                    <div className="content-container">
                                        <InteractiveBooks />
                                    </div>
                                }
                            />
                            <Route
                                path="admin/interaktivka/:bookid"
                                element={
                                    <div className="content-container">
                                        <Interactive />
                                    </div>
                                }
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
            <ToastContainer />
        </>
    );
}

export default Admin;
