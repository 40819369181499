import React, { useRef } from "react";
import { useImperativeHandle, forwardRef } from "react";
import "./elems.css";
import { screenToPath } from "../../../../utils/path_utils";
import DeleteIcon from "../../../../static/images/delete_white.png";
import { shortenText } from "../../../../utils/translator";
import { getNavigateToButtonPath } from "../../../../utils/navigations";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tippy";
import IconButtonWithTooltip from "~/components/IconButtonWithTooltip/IconButtonWithTooltip";
import { checkIfSet } from "~/utils/basic_utils";
import AuthorIconWhite from "~/static/images/user_white.png";
import SourceIcon from "~/static/images/global-search-white.png";
import VideoIcon from "~/static/images/video_white.png";
import classNames from "classnames";
/**
 * Sent video has attributes
 * {
 *      "file_path":
 *      "media_kind":
 *      "name":
 *      "screen_path":
 *      "naturalWidth":
 *      "naturalHeight":
 *      "buttons":
 *      "length"
 * }
 */
const VideoElem = forwardRef(
	(
		{
			video_obj,
			removeFunc,
			book,
			index,
			setOpenedVideoSource,
			editingSourceNow,
		},
		ref
	) => {
		const navigate = useNavigate();
		const videoRef = useRef(null);
		const handleClick = (page, button_id) => {
			const path = getNavigateToButtonPath(book, page, button_id);
			navigate(path);
		};
		const clickedSource = (vid) => {
			setOpenedVideoSource({
				index: index,
				video: video_obj,
			});
		};
		const scrollVideoToView = () => {
			if (videoRef.current) {
				videoRef.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		};
		useImperativeHandle(ref, () => ({
			scrollVideoToView: scrollVideoToView,
		}));
		return (
			<div
				className={classNames("library_elem_outer", {
					"currently-editing": editingSourceNow,
				})}
				ref={videoRef}
			>
				<div className="library_elem_item" onClick={clickedSource}>
					<div className="libary_image_cover">
						<img
							src={screenToPath(video_obj)}
							alt={video_obj.name}
						/>
					</div>
					<div className="library_elem_name">
						<p>{shortenText(video_obj.name, 20)}</p>
						<p>{video_obj.length}</p>
					</div>
				</div>
				{removeFunc && (
					<div className="library_elem_buttons top-right">
						<Tooltip title="Odstranit video soubor" position="left">
							<button
								className="library_elem_button delete"
								onClick={removeFunc}
							>
								<img src={DeleteIcon} alt="delete" />
							</button>
						</Tooltip>
					</div>
				)}
				<div className="top-left-absolute space-between gap-05">
					<IconButtonWithTooltip
						obj={video_obj?.author}
						titleFunc={checkIfSet}
						positiveTitle="Autor OK"
						negativeTitle="Autor nezadán"
						pos="top"
						positiveIcon={AuthorIconWhite}
						negativeIcon={AuthorIconWhite}
						onClick={clickedSource}
					/>
					<IconButtonWithTooltip
						obj={video_obj?.source}
						titleFunc={checkIfSet}
						positiveTitle="Zdroj OK"
						negativeTitle="Zdroj nezadán"
						pos="top"
						positiveIcon={SourceIcon}
						negativeIcon={SourceIcon}
						onClick={clickedSource}
					/>
					<IconButtonWithTooltip
						obj={video_obj?.externalUrl}
						titleFunc={checkIfSet}
						positiveTitle="Odkaz na video OK"
						negativeTitle="Odkaz na video nezadán"
						pos="top"
						positiveIcon={VideoIcon}
						negativeIcon={VideoIcon}
						onClick={clickedSource}
					/>
				</div>
				{video_obj.buttons && video_obj.buttons.length > 0 && (
					<div className="library_elem_buttons bottom-left off">
						{video_obj.buttons
							.sort((a, b) => a.page - b.page)
							.map((button, i) => (
								<button
									key={i}
									className="icon-button smaller circular library"
									title={`Tento audio soubor je propojen s tlačítkem na straně ${button.page}`}
									onClick={() =>
										handleClick(
											button.page,
											button.button_id
										)
									}
								>
									<p>{button.page}</p>
								</button>
							))}
					</div>
				)}
			</div>
		);
	}
);

export default VideoElem;
