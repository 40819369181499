import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import { BASE_STATIC } from '../../../../deffault/axios';
import { repairPath } from '../../../../utils/path_utils';
import SearchInput from '../../../../components/SearchInput';
import LibraryElem from './LibraryElem';

const isSelected = (image, selectedImages) => {
    for (let i = 0; i < selectedImages.length; i++) {
        if (selectedImages[i].key === image.key) {
            return i;
        }
    }
    return -1;
};
const shouldView = (image, searchedText) => {
    if (!searchedText) return true;
    const lower_case_searched = searchedText
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    const lower_case_name = image.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    return lower_case_name.includes(lower_case_searched)
        ? 'visible'
        : 'not-visible';
};
const viewedAny = (images, searchedText) => {
    for (let i = 0; i < images.length; i++) {
        if (shouldView(images[i], searchedText) === 'visible') {
            return true;
        }
    }
    return false;
};
const ChooseImagesLibrary = ({
    images,
    acceptFunc,
    closeFunc,
    selectedButton,
}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredImages, setFilteredImages] = useState([]);
    // const [selectingIndex, setSelectingIndex] = useState(null);
    const selectImage = (image) => {
        if (isSelected(image, selectedImages) !== -1) {
            const newList = selectedImages.filter(
                (item) => item.key !== image.key
            );
            setSelectedImages(newList);
            return;
        }
        const newList = [...selectedImages, image];
        setSelectedImages(newList);
    };

    useEffect(() => {
        if (selectedButton) {
            if (
                !selectedButton.pictures ||
                selectedButton.pictures.length === 0
            ) {
                setFilteredImages(images);
                return;
            }
            const alreadySelected = selectedButton.pictures?.map(
                (pic) => pic['_id']
            );
            const newImages = images.filter(
                (image) => !alreadySelected.includes(image.key)
            );
            setFilteredImages(newImages);
        } else {
            setFilteredImages(images);
        }
    }, [selectedButton, images]);
    return (
        <Modal
            closeFunction={closeFunc}
            acceptFuncion={() => acceptFunc(selectedImages)}
            extra="bigger"
        >
            <div className="library-header-split">
                <h1>Vyberte jednu či více fotografií.</h1>
                <SearchInput
                    placeholder="Hledat"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="library-images-select">
                {filteredImages.map((image, i) => (
                    <LibraryElem
                        key={i}
                        elem={image}
                        searchText={searchText}
                        clickedFunc={selectImage}
                        orderingNumber={isSelected(image, selectedImages)}
                        pageSuggestion={true}
                        buttons={image.buttons}
                    />
                ))}
            </div>
            {!viewedAny(filteredImages, searchText) && searchText !== '' && (
                <p className="no-results mg-b-3 centered body-2">
                    Žádné výsledky
                </p>
            )}
            {filteredImages.length === 0 && searchText === '' && (
                <p className="no-results mg-b-3 centered body-2">
                    V galerii nejsou žádné {images.length > 0 && 'další '}
                    obrázky.
                </p>
            )}
        </Modal>
    );
};

export default ChooseImagesLibrary;
/* <div
    className={`library-image-select-outer ${shouldView(
        image,
        searchText
    )}`}
    key={i}
    onClick={() => selectImage(image)}
>
    <div className="library-image-select">
        <img
            src={BASE_STATIC + repairPath(image.file_path)}
            key={i}
            alt={image.name}
        />
    </div>
    <p className="libary-item-header">{image.name}</p>
    {image.suggested_page && (
        <p className="library-item-pageNum">
            {image.suggested_page}
        </p>
    )}
    {isSelected(image, selectedImages) !== -1 && (
        <p className="library-item-order-selected">
            {isSelected(image, selectedImages) + 1}
        </p>
    )}
</div> */
