import React, { useCallback, useEffect, useState } from 'react';
import MediaExcercisesService from '../../../services/media_excercises.service';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { PAGE_ENUM } from './LibrarySwitches';
import { toast } from 'react-toastify';
import toastSettings from '../../../utils/toastSettings';
import DragAndDropNoStyle from '../../../components/DragAndDropNoStyle';
import FlexibleLoader from '../../../components/FlexibleLoader/FlexibleLoader';
import ExcerciseElem from './elems/ExcerciseElem';
import PopUpConfirm from '../../../components/PopUpConfirm';
import { shouldView } from '../../../utils/stateUtils';
import { extractValuesFromObject } from '../../../utils/objectUtils';
import {
    useAssetExcercises,
    useLoadingAssets,
} from '../../../stores/assetStore';
import file_types from '../../../static/images/file_types/file_types.json';
import ExcerciseCenter from '../StaticPageComponents/ExcerciseCenter';

const ExcercisesPage = ({ book, reAsk, search }) => {
    const privateAxios = useAxiosPrivate();
    const [excercises, setExcercises] = useAssetExcercises();
    const [loading, setLoading] = useLoadingAssets();
    const [removingExcercise, setRemovingExcercise] = useState(null);
    const [playingExcercise, setPLayingExcercise] = useState(null);

    const get_excercises = useCallback(async () => {
        const excercises_response = await MediaExcercisesService.get_media(
            PAGE_ENUM.EXCERCISE,
            book,
            privateAxios
        );
        if (excercises_response.err) {
            toast.error('Nastala chyba při načítání cvičení', toastSettings);
            return;
        }
        if (!excercises_response.result) {
            toast.error(excercises_response.message, toastSettings);
            return;
        }
        const new_excercises = extractValuesFromObject(
            excercises_response.excercises
        ).sort((a, b) => a.name.localeCompare(b.name));

        setExcercises(new_excercises);
        setLoading(false);
    }, [book, privateAxios]);

    useEffect(() => {
        if (reAsk) get_excercises();
    }, [reAsk, get_excercises]);
    const removeExcercise = async () => {
        if (removingExcercise.buttons && removingExcercise.buttons.length > 0) {
            toast.error(
                'Nelze smazat obrázek, který je přiřazen k nějakému tlačítku',
                toastSettings
            );
            return;
        }
        const remove_obj = {
            ...book,
            asset_id: removingExcercise.key,
        };
        const result = await MediaExcercisesService.remove_asset(
            remove_obj,
            true,
            privateAxios
        );
        if (result.err) {
            toast.error('Nastala chyba při mazání cvičení', toastSettings);
            return;
        }
        setExcercises(
            excercises.filter((exc) => exc.key !== removingExcercise.key)
        );
        setRemovingExcercise(null);
        toast.success('Cvičení bylo úspěšně smazáno', toastSettings);
    };
    const handlePlayExcercise = (excerciseObj) => {
        setPLayingExcercise({ excercise: excerciseObj });
    };
    return (
        <>
            <DragAndDropNoStyle
                type={PAGE_ENUM.EXCERCISE}
                accept_types={file_types.zip_mimes}
                additionalClasses={'library-drag-drop'}
                collection={excercises}
                setCollection={setExcercises}
                excercises={true}
            >
                {loading ? (
                    <FlexibleLoader />
                ) : excercises.length === 0 ? (
                    <div>Žádné cvičení</div>
                ) : (
                    <div className="library-content ">
                        {excercises.map(
                            (excercise) =>
                                shouldView(excercise, search) && (
                                    <ExcerciseElem
                                        excercise={excercise}
                                        key={excercise.key}
                                        removeFunc={() =>
                                            setRemovingExcercise(excercise)
                                        }
                                        book={book}
                                        playExcercise={handlePlayExcercise}
                                    />
                                )
                        )}
                    </div>
                )}
            </DragAndDropNoStyle>
            {removingExcercise && (
                <PopUpConfirm
                    closeFunction={() => setRemovingExcercise(null)}
                    confirmFunction={removeExcercise}
                    question={'Doopravdy chcete vymazat toto cvičení?'}
                />
            )}
            {playingExcercise && (
                <ExcerciseCenter
                    button={playingExcercise}
                    closeFunction={() => setPLayingExcercise(null)}
                    pageNumber={0}
                />
            )}
        </>
    );
};

export default ExcercisesPage;
