import React, { useCallback, useEffect, useState } from 'react';

import PullUpIcon from '~/static/images/pullup.png';
import { assetToPath } from '~/utils/path_utils';

import PopUp from '~/components/PopUp';
import ArrowIcon from '~/static/images/next.png';
const ImageCorousel = ({ button, closeFunction }) => {
    const [current_image, setCurrentImage] = useState(0);
    const [current_image_url, setCurrentImageUrl] = useState('');

    const [descriptionOpened, setDescriptionOpened] = useState(false);

    const handleImageChange = useCallback(
        (direction) => {
            if (direction === 'left') {
                setCurrentImage((prevImage) =>
                    prevImage === 0 ? button.pictures.length - 1 : prevImage - 1
                );
            } else if (direction === 'right') {
                setCurrentImage((prevImage) =>
                    prevImage === button.pictures.length - 1 ? 0 : prevImage + 1
                );
            }
        },
        [button.pictures.length]
    );
    useEffect(() => {
        setCurrentImageUrl(assetToPath(button.pictures[current_image]));
    }, [current_image, button]);

    useEffect(() => {
        // event handler for left and right arrow keys
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                handleImageChange('left');
            } else if (event.key === 'ArrowRight') {
                handleImageChange('right');
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleImageChange, current_image, button]);

    return (
        <PopUp
            closeFunc={closeFunction}
            additionalClassnames="image-edit-additional"
        >
            <div className="image-edit-container">
                <div className="image-edit-cover">
                    <img
                        src={current_image_url}
                        alt={button.pictures[current_image].name}
                        className="image-edit-img"
                    />
                </div>
                <div className="bottom-control-bar">
                    <button
                        className={
                            descriptionOpened ? 'pull-down-btn' : 'pull-up-btn'
                        }
                        onClick={() => setDescriptionOpened(!descriptionOpened)}
                        title={
                            descriptionOpened ? 'Zavřít popis' : 'Otevřít popis'
                        }
                    >
                        <img src={PullUpIcon} alt="pull-up" />
                    </button>
                    <div className="header-buttons-top-bar mg-b-1">
                        <div className="page-control">
                            <button
                                className="image-edit-arrow back left"
                                onClick={() => {
                                    handleImageChange('left');
                                }}
                            >
                                <img src={ArrowIcon} alt="next" />
                            </button>
                            <div className="image-index body-4">
                                {current_image + 1}/{button.pictures.length}
                            </div>
                            <button
                                className="image-edit-arrow  right"
                                onClick={() => {
                                    handleImageChange('right');
                                }}
                            >
                                <img src={ArrowIcon} alt="next" />
                            </button>
                        </div>

                        <div className="image-edit-header body-2">
                            {button.pictures[current_image].header}
                        </div>
                    </div>

                    {descriptionOpened && (
                        <div className="image-edit-description body-3">
                            {button.pictures[current_image].description}
                        </div>
                    )}
                </div>
            </div>
        </PopUp>
    );
};

export default ImageCorousel;
