import React, { useRef } from 'react';
import Modal from '../../../components/Modal';
import FaviconOfSite from '../../../components/FaviconOfSite';
import './VideoLink.css';
import { shortenText } from '../../../utils/translator';
import PopUp from '../../../components/PopUp';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import TopRightCloseBtn from '../../../components/TopRightCloseBtn';
import SourceButton from './SourceButton';
const VideoLink = ({ closeFunc, button }) => {
    const modalRef = useRef();
    const popupRef = useRef();
    const videoRef = useRef();
    const handleClicked = () => {
        window.open(button.video.externalUrl, '_blank', 'noopener,noreferrer');
        if (modalRef.current) modalRef.current.handleClose();
    };
    const closeVideoLocal = () => {
        if (popupRef.current) {
            popupRef.current.handleSlowClose();
        }
    };
    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.handlePauseVideo();
        }
    };
    return button.video.type === 'external' ? (
        <Modal
            closeFunction={closeFunc}
            acceptFuncion={handleClicked}
            acceptText="Přejít na odkaz"
            ref={modalRef}
        >
            <p className="body-1 font-weight-500 centered mg-b-1">
                Po kliknutí na potvrzovací tlačítko bude otevřeno nové okno s
                odkazovaným videem.
            </p>
            <p className="body-1 font-weight-500 centered mg-b-1">
                Přejete si pokračovat?
            </p>
            <div id="video-link-href" className="mg-b-1">
                <div className="hover-anim" onClick={handleClicked}>
                    <FaviconOfSite
                        url={button.video.externalUrl}
                        alt="favicon"
                        hideOnError={true}
                    />
                    {shortenText(button.video.externalUrl, 70)}
                </div>
            </div>
        </Modal>
    ) : (
        <PopUp ref={popupRef} closeFunc={closeFunc}>
            <VideoPlayer
                ref={videoRef}
                video={{ ...button.video, file_path: button.video.url }}
                externalLink
                fullscreen
            />
            <TopRightCloseBtn closeFunc={closeVideoLocal} />
            <SourceButton video={button.video} click={pauseVideo} />
        </PopUp>
    );
};

export default VideoLink;
