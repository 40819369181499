import React from 'react';

const Unauthorized = () => {
    return (
        <div className="centered-div">
            <p className="unauthorized">Na tuto stránku nemáte práva</p>
        </div>
    );
};

export default Unauthorized;
