import React, { useState } from 'react';
import { useBookForRequest } from '../stores/interactiveBookStore';
import InteractiveService from '../services/interactive.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import SettingsIcon from '../static/images/settings_grez.png';
import ReleaseChoose from '../pages/admin/ReleaseChoose';
import { Tooltip } from 'react-tippy';
import { useNavigate } from 'react-router-dom';
import { remove } from 'jszip';

const BookMenuButton = ({ externalOnclick, removeBookFunction }) => {
    const bookInfo = useBookForRequest();
    const privateAxios = useAxiosPrivate();
    const navigate = useNavigate();
    const [selectedBook, setSelectedBook] = useState(null);
    const handleClick = async () => {
        const interactive = await InteractiveService.get_interactive(
            bookInfo.bookid,
            bookInfo.release,
            bookInfo.version,
            privateAxios
        );
        setSelectedBook({
            interactive_book: interactive.book,
            concrete_book: interactive,
        });
        externalOnclick && externalOnclick();
    };
    return (
        <>
            <Tooltip title="Administrace knihy" position="bottom">
                <button className="no-style-button" onClick={handleClick}>
                    <img
                        src={SettingsIcon}
                        alt="Settings"
                        className="no-bg-icon-button"
                    />
                </button>
            </Tooltip>
            {selectedBook && (
                <ReleaseChoose
                    chosenObject={selectedBook}
                    closeFunction={() => setSelectedBook(null)}
                    removeFunction={(...args) => {
                        if (removeBookFunction) {
                            removeBookFunction(...args);
                        } else {
                            navigate('/admin/interaktivky');
                        }
                    }}
                />
            )}
        </>
    );
};

export default BookMenuButton;
