import React from 'react';
import './SvgIcon.css';
import SvgIconType from './SvgIconType';
import classNames from 'classnames';

const SvgIcon = ({ type, className, size }) => {
    return (
        <div className={classNames('svg-icon-holder', size)}>
            {type === SvgIconType.pin && (
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 193.826 193.826"
                    className={className}
                >
                    <path
                        d="M191.495,55.511L137.449,1.465c-1.951-1.953-5.119-1.953-7.07,0l-0.229,0.229c-3.314,3.313-5.14,7.72-5.14,12.406
            c0,3.019,0.767,5.916,2.192,8.485l-56.55,48.533c-4.328-3.868-9.852-5.985-15.703-5.985c-6.305,0-12.232,2.455-16.689,6.913
            l-0.339,0.339c-1.953,1.952-1.953,5.118,0,7.07l32.378,32.378l-31.534,31.533c-0.631,0.649-15.557,16.03-25.37,28.27
            c-9.345,11.653-11.193,13.788-11.289,13.898c-1.735,1.976-1.639,4.956,0.218,6.822c0.973,0.977,2.256,1.471,3.543,1.471
            c1.173,0,2.349-0.41,3.295-1.237c0.083-0.072,2.169-1.885,13.898-11.289c12.238-9.813,27.619-24.74,28.318-25.421l31.483-31.483
            l30.644,30.644c0.976,0.977,2.256,1.465,3.535,1.465s2.56-0.488,3.535-1.465l0.339-0.339c4.458-4.457,6.913-10.385,6.913-16.689
            c0-5.851-2.118-11.375-5.985-15.703l48.533-56.55c2.569,1.425,5.466,2.192,8.485,2.192c4.687,0,9.093-1.825,12.406-5.14l0.229-0.229
            C193.448,60.629,193.448,57.463,191.495,55.511z"
                    />
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            )}
            {type === SvgIconType.warning && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="512"
                    height="512"
                    className={className}
                >
                    <path d="M11,13V7c0-.55,.45-1,1-1s1,.45,1,1v6c0,.55-.45,1-1,1s-1-.45-1-1Zm1,2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Zm11.58,4.88c-.7,1.35-2.17,2.12-4.01,2.12H4.44c-1.85,0-3.31-.77-4.01-2.12-.71-1.36-.51-3.1,.5-4.56L8.97,2.6c.71-1.02,1.83-1.6,3.03-1.6s2.32,.58,3,1.57l8.08,12.77c1.01,1.46,1.2,3.19,.49,4.54Zm-2.15-3.42s-.02-.02-.02-.04L13.34,3.67c-.29-.41-.79-.67-1.34-.67s-1.05,.26-1.36,.71L2.59,16.42c-.62,.88-.76,1.84-.4,2.53,.35,.68,1.15,1.05,2.24,1.05h15.12c1.09,0,1.89-.37,2.24-1.05,.36-.69,.22-1.65-.37-2.49Z" />
                </svg>
            )}
            {type === SvgIconType.check && (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="512"
                    height="512"
                >
                    <path d="M22.319,4.431,8.5,18.249a1,1,0,0,1-1.417,0L1.739,12.9a1,1,0,0,0-1.417,0h0a1,1,0,0,0,0,1.417l5.346,5.345a3.008,3.008,0,0,0,4.25,0L23.736,5.847a1,1,0,0,0,0-1.416h0A1,1,0,0,0,22.319,4.431Z" />
                </svg>
            )}
            {type === SvgIconType.marquee && (
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    className={className}
                >
                    <g>
                        <g>
                            <path
                                d="M492,365.5c-11.046,0-20,8.954-20,20v22c0,22.056-17.944,40-40,40h-22c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20
			h22c44.112,0,80-35.888,80-80v-22C512,374.454,503.046,365.5,492,365.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M432,24.5h-22c-11.046,0-20,8.954-20,20s8.954,20,20,20h22c22.056,0,40,17.944,40,40v22c0,11.046,8.954,20,20,20
			c11.046,0,20-8.954,20-20v-22C512,60.388,476.112,24.5,432,24.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M492,196.5c-11.046,0-20,8.954-20,20v80c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20v-80
			C512,205.454,503.046,196.5,492,196.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M102,447.5H80c-22.056,0-40-17.944-40-40v-22c0-11.046-8.954-20-20-20s-20,8.954-20,20v22c0,44.112,35.888,80,80,80h22
			c11.046,0,20-8.954,20-20C122,456.454,113.046,447.5,102,447.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M102,24.5H80c-44.112,0-80,35.888-80,80v22c0,11.046,8.954,20,20,20s20-8.954,20-20v-22c0-22.056,17.944-40,40-40h22
			c11.046,0,20-8.954,20-20S113.046,24.5,102,24.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M20,196.5c-11.046,0-20,8.954-20,20v80c0,11.046,8.954,20,20,20s20-8.954,20-20v-80C40,205.454,31.046,196.5,20,196.5z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M315,24.5H195c-11.046,0-20,8.954-20,20s8.954,20,20,20h120c11.046,0,20-8.954,20-20S326.046,24.5,315,24.5z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M315,447.5H195c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h120c11.046,0,20-8.954,20-20
			C335,456.454,326.046,447.5,315,447.5z"
                            />
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            )}
            {type === SvgIconType.marqueeMinus && (
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="305.002px"
                    height="305.002px"
                    viewBox="0 0 305.002 305.002"
                    className={className}
                >
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M152.502,305.001C68.411,305.001,0,236.59,0,152.501s68.411-152.5,152.502-152.5c84.089,0,152.5,68.411,152.5,152.5
                           S236.591,305.001,152.502,305.001z M152.502,25.001C82.197,25.001,25,82.197,25,152.501c0,70.305,57.197,127.5,127.502,127.5
                           c70.304,0,127.5-57.195,127.5-127.5C280.002,82.197,222.806,25.001,152.502,25.001z"
                                />
                            </g>
                            <g>
                                <path
                                    d="M225.998,165.001H79.006c-6.903,0-12.5-5.598-12.5-12.5c0-6.903,5.597-12.5,12.5-12.5h146.992
                           c6.903,0,12.5,5.597,12.5,12.5C238.498,159.403,232.901,165.001,225.998,165.001z"
                                />
                            </g>
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            )}
            {type === SvgIconType.move && (
                <svg
                    id="svg1265"
                    height="512"
                    viewBox="0 0 8.4666665 8.4666669"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g id="layer1" transform="translate(0 -288.533)">
                        <path
                            id="path1367"
                            d="m4.2206235 289.05563a.26460982.26460982 0 0 0 -.2051555.10335.26460982.26460982 0 0 0 -.0062.008l-1.0288778 1.02939a.26460982.26460982 0 1 0 .3731037.3731l.6071989-.60719v2.53679h-2.5352476l.6056478-.60513a.26460982.26460982 0 0 0 -.1917196-.45527.26460982.26460982 0 0 0 -.1834513.0801l-1.03301195 1.03508a.26460982.26460982 0 0 0 -.007752.006.26460982.26460982 0 0 0 .005684.41444.26460982.26460982 0 0 0 .007752.006l1.02732755 1.0294a.26460982.26460982 0 1 0 .3751709-.37311l-.6077149-.60771h2.537313v2.53731l-.6071981-.6072a.26460982.26460982 0 1 0 -.3731037.37466l1.0407634 1.04128.017571.0176a.26460982.26460982 0 0 0 .3963583-.0238l1.0355956-1.03508a.26460982.26460982 0 1 0 -.3731038-.37466l-.6077159.60511v-2.53524h2.5373129l-.6077148.60771a.26460982.26460982 0 1 0 .3731037.37311l1.0216431-1.02371a.26460982.26460982 0 0 0 .00775-.006.26460982.26460982 0 0 0 .00775-.42581.26460982.26460982 0 0 0 -.00568-.004l-1.0314599-1.03162a.26460982.26460982 0 1 0 -.3731038.37518l.6056476.60513h-2.5352486v-2.53525l.6077149.60565a.26460982.26460982 0 1 0 .3731037-.37311l-1.0355955-1.03508-.00568-.008a.26460982.26460982 0 0 0 -.2087726-.0977z"
                            font-variant-ligatures="normal"
                            font-variant-position="normal"
                            font-variant-caps="normal"
                            font-variant-numeric="normal"
                            font-variant-alternates="normal"
                            font-feature-settings="normal"
                            text-indent="0"
                            text-align="start"
                            text-decoration-line="none"
                            text-decoration-style="solid"
                            text-decoration-color="rgb(0,0,0)"
                            text-transform="none"
                            text-orientation="mixed"
                            white-space="normal"
                            shape-padding="0"
                            isolation="auto"
                            mix-blend-mode="normal"
                            solid-color="rgb(0,0,0)"
                            solid-opacity="1"
                            vectorEffect="none"
                        />
                    </g>
                </svg>
            )}
            {type === SvgIconType.zoom && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    width="512"
                    height="512"
                    className={className}
                >
                    <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" />
                    <path d="M13,9H11V7A1,1,0,0,0,9,7V9H7a1,1,0,0,0,0,2H9v2a1,1,0,0,0,2,0V11h2a1,1,0,0,0,0-2Z" />
                </svg>
            )}
        </div>
    );
};

export default SvgIcon;
