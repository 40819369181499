import React, { useState } from 'react';
import ChooseAudiosLibrary from './ChooseAudiosLibrary';
import ButtonsService from '../../../../services/buttons.service';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import toastSettings from '../../../../utils/toastSettings';
import CustomAudio from '../../../../components/CustomAudio';
import { PAGE_ENUM } from '../LibrarySwitches';
import { replaceButtonInButtonArray } from '../../../../utils/stateUtils';
import { assetToPath } from '../../../../utils/path_utils';
import UploadFile, {
    FILE_ENUM,
} from '../../../../components/UploadFileComp/UploadFile';
import { useAssetAudios } from '../../../../stores/assetStore';
const AudioSelect = ({
    savedButton,
    setSavedButton,
    bookInfo,
    pageNumber,
    setButtons,
    somethingSaving,
}) => {
    const [selectingAudios, setSelectingAudios] = useState(false);
    const [uploadingAudios, setUploadingAudios] = useState(false);
    const [audios, setAudios] = useAssetAudios();
    const privateAxios = useAxiosPrivate();

    const handleSelectAudio = async (audio) => {
        // Delete buttons property from the audio object
        delete audio.buttons;
        somethingSaving(true);
        const data = {
            ...bookInfo,
            page_number: pageNumber,
            audio: audio,
        };
        const response = await ButtonsService.add_audio_from_library_to_button(
            savedButton.key,
            data,
            privateAxios
        );
        if (response.err) {
            toast.error('Nepodařilo se přidat audio k tlačítku', toastSettings);
            return;
        }
        const newButton = {
            ...savedButton,
            audio: audio,
        };
        setAudios(response.audios);
        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);

        somethingSaving(false);
        setSelectingAudios(false);
        toast.success('Audio bylo přidáno k tlačítku.', toastSettings);
    };

    const handleUploadNewAudioFile = async (newAudio, progressFunc) => {
        const formData = new FormData();
        formData.append(
            'info',
            JSON.stringify({
                bookid: bookInfo.bookid,
                release: bookInfo.release,
                version: bookInfo.version,
                type: PAGE_ENUM.AUDIO,
                page_number: pageNumber,
            })
        );
        formData.append('file', newAudio);

        const response = await ButtonsService.upload_new_audio_video(
            savedButton.key,
            formData,
            privateAxios,
            progressFunc
        );

        if (response.err) {
            return response;
        }
        setAudios(response.audios);

        const newButton = {
            ...savedButton,
            audio: response.button.audio,
        };

        setSavedButton(newButton);
        replaceButtonInButtonArray(setButtons, newButton);
        return response;
    };
    return (
        <>
            <div className="info-split">
                <label className="info-label" htmlFor="excercise">
                    Audio:
                </label>
                {!savedButton.audio && (
                    <p className="body-3"> Zatím nevybráno</p>
                )}
                {savedButton.audio && (
                    <p className="body-3">{savedButton.audio.name}</p>
                )}
            </div>
            {savedButton.audio && (
                <div className="info-split with-padding">
                    <CustomAudio
                        src={assetToPath(
                            audios.find(
                                (aud) =>
                                    aud.file_path ===
                                    savedButton.audio.file_path
                            )
                        )}
                    />
                </div>
            )}
            {/* Choosing if user wants to add new images via library or upload */}
            <div className="button-split">
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setSelectingAudios(true)}
                >
                    Vybrat z knihovny
                </button>
                <button
                    className="btn btn-blue full-width"
                    onClick={() => setUploadingAudios(true)}
                >
                    Nahrát nové
                </button>
            </div>
            {selectingAudios && (
                <ChooseAudiosLibrary
                    savedButton={savedButton}
                    audios={audios}
                    closeFunc={() => setSelectingAudios(false)}
                    acceptFunc={(audio) => handleSelectAudio(audio)}
                />
            )}
            {uploadingAudios && (
                <UploadFile
                    closeFunc={() => setUploadingAudios(false)}
                    header="Nahrejte nové audio do knihovny"
                    fileType={FILE_ENUM.audio}
                    acceptText="Nahrát audio"
                    acceptFunc={handleUploadNewAudioFile}
                    alreadyUploadedFileNames={audios.map((audio) => audio.name)}
                />
            )}
        </>
    );
};

export default AudioSelect;
