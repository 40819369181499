import React, { cloneElement } from "react";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import toastSettings from "~/utils/toastSettings";

const OnClickRedirect = ({ title, position, urlRedirect, children }) => {
	// after click open in new tab
	const handleClick = () => {
		try {
			new URL(urlRedirect);
			window.open(urlRedirect, "_blank");
		} catch (err) {
			console.log(err);
			toast.error("Odkaz je neplatný", toastSettings);
		}
	};
	return (
		<Tooltip title={title} position={position}>
			<div onClick={handleClick} style={{ cursor: "pointer" }}>
				{children}
			</div>
		</Tooltip>
	);
};

export default OnClickRedirect;
