import React, { useRef, useState } from 'react';
import '../pages/styles/components.css';
import { toast } from 'react-toastify';
const toastError = {
    autoClose: 2000,
    position: 'bottom-right',
    closeOnClick: true,
    draggable: false,
    theme: 'colored',
};
const DragAndDrop = ({ file, setFile, acceptFiles, textOnBox }) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];

        if (file && acceptFiles.includes(file.type)) {
            setFile(file);
        } else {
            toast.error('Nahraný soubor není ve formátu PDF.', toastError);
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };
    const handleFileUpload = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file && acceptFiles.includes(file.type)) {
            setFile(file);
        } else {
            toast.error('Nahraný soubor není ve formátu PDF.', toastError);
        }
    };
    return (
        <>
            {!file ? (
                <div
                    className={`drag-drop ${isDragging ? 'drag-over' : ''}`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={handleClick}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                    />
                    <h1 className="header-2 centered">
                        {isDragging ? 'Pusťte soubor' : textOnBox}
                    </h1>
                </div>
            ) : (
                <div className="uploaded-file-div-inner">
                    <h4 className="header-4">
                        Soubor {file.name} byl úspěšně přidán.
                    </h4>
                    <button
                        className="btn btn-green"
                        onClick={() => {
                            setFile(null);
                        }}
                    >
                        Nahrát jiný soubor
                    </button>
                </div>
            )}
        </>
    );
};

export default DragAndDrop;
