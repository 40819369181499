import React, { useState } from "react";
import ChooseVideosLibrary from "./ChooseVideosLibrary";
import UploadFile, { FILE_ENUM } from "~/components/UploadFileComp/UploadFile";
import ChosenVideoThumbnail from "./ChosenVideoThumbnail";
import SplitInfoDisplay from "~/components/SplitInfoDisplay/SplitInfoDisplay";

const VideoLibrarySelect = ({
	savedButton,
	videos,
	handleAddVideo,
	handleAddNewVideo,
	setSavedButton,
	setButtons,
	pageNumber,
}) => {
	const [selectingVideo, setSelectingVideo] = useState(false);
	const [addNewVideo, setAddNewVideo] = useState(false);
	return (
		<>
			{!savedButton.video ? (
				<SplitInfoDisplay label="Video" value={undefined} />
			) : (
				<SplitInfoDisplay
					label="Video"
					value={savedButton.video.name}
				/>
			)}
			{savedButton.video && (
				<ChosenVideoThumbnail
					selectedButton={savedButton}
					setSavedButton={setSavedButton}
					setButtons={setButtons}
					pageNumber={pageNumber}
				/>
			)}
			<div className="button-split">
				<button
					className="btn btn-blue full-width"
					onClick={() => setSelectingVideo(true)}
				>
					Vybrat z knihovny
				</button>
				<button
					className="btn btn-blue full-width"
					onClick={() => setAddNewVideo(true)}
				>
					Nahrát nové
				</button>
			</div>
			{selectingVideo && (
				<ChooseVideosLibrary
					videos={videos}
					closeFunc={() => setSelectingVideo(false)}
					acceptFunc={async (temp) => {
						await handleAddVideo(temp);
						setSelectingVideo(false);
					}}
					savedButton={savedButton}
				/>
			)}
			{addNewVideo && (
				<UploadFile
					closeFunc={() => setAddNewVideo(false)}
					header="Nahrejte nové video do knihovny"
					fileType={FILE_ENUM.video}
					acceptText="Nahrát video"
					acceptFunc={handleAddNewVideo}
					alreadyUploadedFileNames={videos.map((video) => video.name)}
				/>
			)}
		</>
	);
};

export default VideoLibrarySelect;
