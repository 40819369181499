import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import "../pages/styles/components/modal.css";
import ReactDom from "react-dom";

const Modal = forwardRef(
	({ extra, children, closeFunction, acceptFuncion, acceptText }, ref) => {
		const [closing, setClosing] = useState(false);
		useEffect(() => {
			const handleEsc = (event) => {
				if (event.keyCode === 27) {
					handleClose();
				}
			};
			window.addEventListener("keydown", handleEsc);
			return () => {
				window.removeEventListener("keydown", handleEsc);
			};
		}, [closeFunction]);
		const handleClose = () => {
			setClosing(true);
			setTimeout(() => {
				closeFunction();
				setClosing(false);
			}, 600);
		};

		useImperativeHandle(ref, () => ({
			handleClose,
		}));

		return ReactDom.createPortal(
			<div className={`outer-modal ${closing ? "hide" : ""}`}>
				<div
					className={`inner-modal ${extra ? extra : ""} ${
						closing ? "hide" : ""
					}`}
				>
					{children}
					<div className="bottom-buttons">
						<button
							className="btn btn-yellow"
							onClick={handleClose}
						>
							Zavřít
						</button>
						<button
							className="btn btn-blue"
							onClick={acceptFuncion}
						>
							{acceptText ? acceptText : "Potvrdit výběr"}
						</button>
					</div>
				</div>
			</div>,
			document.getElementById("modal-popup")
		);
	}
);

export default Modal;
