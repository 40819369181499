import React from 'react';
import { repairPath } from '../../../../utils/path_utils';
import { BASE_STATIC } from '../../../../deffault/axios';

const getClassNamesForExcercise = (tempSelected, excercise) => {
    if (tempSelected?._id === excercise?._id) {
        return 'excercise-div excercise-selected';
    }
    if (tempSelected) {
        return 'excercise-div excercise-not-selected';
    }
    return 'excercise-div';
};
const shouldView = (excercise, searchedText) => {
    if (!searchedText) return true;
    const lower_case_searched = searchedText.toLowerCase();
    return excercise.name.toLowerCase().includes(lower_case_searched);
};

const ChooseExcerciseItem = ({
    excercise,
    searchText,
    tempSelected,
    setTempSelected,
}) => {
    return (
        <div
            className={`excercise-wrapper ${shouldView(excercise, searchText)}`}
        >
            <div
                className={`${getClassNamesForExcercise(
                    tempSelected,
                    excercise
                )}`}
                onClick={() => {
                    setTempSelected(excercise);
                }}
            >
                <img
                    src={BASE_STATIC + repairPath(excercise.picture_ref)}
                    className="excercise-thumbnail"
                    alt="excercise"
                />

                <span className="excercise-name">{excercise.name}</span>
            </div>
            {excercise.buttons && excercise.buttons.length > 0 && (
                <div className="library_elem_buttons bottom-left off">
                    {excercise.buttons
                        .sort((a, b) => a.page - b.page)
                        .map((button, i) => (
                            <button
                                key={i}
                                className="icon-button smaller circular library"
                                title={`Toto cvičení je propojeno s tlačítkem na straně ${button.page}`}
                            >
                                <p>{button.page}</p>
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

export default ChooseExcerciseItem;
