import axios from "../deffault/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        const response = await axios.get("/auth/refresh", {
            withCredentials: true,
        });
        setAuth((prev) => {
            console.log("Refreshed token was used.");
            return {
                ...prev,
                access: response.data.access_token,
                roles: response.data.roles,
                user_id: response.data.user_id,
            };
        });
        return response.data.access_token;
    };
    return refresh;
};

export default useRefreshToken;
