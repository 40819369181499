import React, { useCallback, useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../styles/tables.css';
import '../styles/buttons.css';
import '../styles/text.css';
import '../styles/interactives/interactiveList.css';

import { Roller } from 'react-awesome-spinners';
import CreateInteractive from '../interactives/CreateInteractive';
import ReleaseChoose from './ReleaseChoose';
import OneLineInteractive from './OneLineInteractive';
import { useInteractiveBookStore } from '~/stores/interactiveBookStore';

const InteractiveBooks = () => {
    const [interactiveBooks, setInteractiveBooks] = useState([]);
    const [interactiveBooksSet, setInteractiveBooksSet] = useState(false);
    const [search, setSearch] = useState('');
    const [createVisible, setCreateVisible] = useState(false);
    const [selectedRelease, setSelectedRelease] = useState(null);
    const privateAxios = useAxiosPrivate();
    const resetBook = useInteractiveBookStore((state) => state.reset);
    const get_interactive_books = useCallback(async () => {
        const response = await privateAxios.get('/interactive/');
        setInteractiveBooks(response.data);
        setInteractiveBooksSet(true);
    }, [privateAxios]);
    useEffect(() => {
        resetBook();
    }, []);
    useEffect(() => {
        get_interactive_books();
    }, [get_interactive_books]);
    const remove_book_from_list = (book_id, release, version) => {
        console.log('From remove book from list ', book_id, release, version);
        const version_before_dot = parseInt(version.split('_')[0]);
        const version_after_dot = parseInt(version.split('_')[1]);
        const new_interactive_books = interactiveBooks
            .map((book) => {
                book.interactive_books = book.interactive_books.filter(
                    (concrete_book) => {
                        if (
                            concrete_book.book_id === book_id &&
                            concrete_book.release === release &&
                            concrete_book.version_before_dot ===
                                version_before_dot &&
                            concrete_book.version_after_dot ===
                                version_after_dot
                        ) {
                            return false;
                        }
                        return true;
                    }
                );
                return book;
            })
            .filter((book) => book.interactive_books.length > 0);
        setInteractiveBooks(new_interactive_books);
    };
    const add_book_to_list = () => {
        get_interactive_books();
    };
    const handleClickedInteractive = (interactive_book, concrete_book) => {
        const selectedObject = {
            interactive_book: interactive_book,
            concrete_book: concrete_book,
        };
        setSelectedRelease(selectedObject);
    };

    return (
        <>
            {!interactiveBooksSet ? (
                <div className="loader-container">
                    <Roller color="#00b2ff" size={64} sizeUnit="px" />
                </div>
            ) : interactiveBooks.length === 0 ? (
                <>
                    <div className="books-container">
                        <h1 className="header-2 mg-b-4">
                            Interaktivní učebnice
                        </h1>
                        <div className="loader-container">
                            <h1 className="header-2">
                                Žádné interaktivní učebnice ještě nejsou
                                vytvořeny
                            </h1>
                        </div>
                    </div>
                    <div className="bottom-right-buttons">
                        <div className="bottom-content">
                            <button
                                className="btn btn-yellow"
                                onClick={() => setCreateVisible(true)}
                            >
                                Vytvořit novou
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="books-container">
                        <div className="top-with-input no-padding">
                            <h1 className="header-2">Interaktivní učebnice</h1>
                            <input
                                className="input-search"
                                placeholder="Vyhledat knihy"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <table className="interactive-list">
                            <thead>
                                <tr>
                                    <th>Katalog</th>
                                    <th>Předmět</th>
                                    <th>Název</th>
                                    <th>Vyd.</th>
                                    <th>Ver.</th>
                                    <th>Stav</th>
                                    <th>Ročník</th>
                                    <th>Vytvořil</th>
                                    <th>Typ</th>
                                    <th>Pozn.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {interactiveBooks.map(
                                    (interactive_book) =>
                                        interactive_book &&
                                        interactive_book.interactive_books.map(
                                            (concrete_book) => (
                                                <OneLineInteractive
                                                    key={
                                                        interactive_book.catalog +
                                                        concrete_book.release.toString() +
                                                        concrete_book.version_before_dot.toString() +
                                                        concrete_book.version_after_dot.toString()
                                                    }
                                                    interactive_book={
                                                        interactive_book
                                                    }
                                                    concrete_book={
                                                        concrete_book
                                                    }
                                                    handleOnClick={() =>
                                                        handleClickedInteractive(
                                                            interactive_book,
                                                            concrete_book
                                                        )
                                                    }
                                                />
                                            )
                                        )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="bottom-right-buttons">
                        <div className="bottom-content">
                            <button
                                className="btn btn-yellow"
                                onClick={() => setCreateVisible(true)}
                            >
                                Vytvořit novou
                            </button>
                        </div>
                    </div>
                </>
            )}
            <CreateInteractive
                visible={createVisible}
                closeFunction={() => {
                    setCreateVisible(false);
                }}
                addFunction={add_book_to_list}
            />
            {selectedRelease && (
                <ReleaseChoose
                    chosenObject={selectedRelease}
                    closeFunction={() => {
                        setSelectedRelease(null);
                    }}
                    removeFunction={remove_book_from_list}
                    actualizeBooks={add_book_to_list}
                />
            )}
        </>
    );
};

export default InteractiveBooks;
