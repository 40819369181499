const GET_STATE = (state) => {
    switch (state) {
        case 0:
            return 'Rozpracováno';
        case 1:
            return 'Testování';
        case 2:
            return 'Hotovo';
        case 3:
            return 'Publikováno';
        case 4:
            return 'Nepublikováno';
        default:
            return '';
    }
};
const GET_STATE_CLASS = (state) => {
    switch (state) {
        case 0:
            return 'in-progress';
        case 1:
            return 'testing';
        case 2:
            return 'done';
        case 3:
            return 'publicated';
        case 4:
            return 'not-publicated';
        default:
            return '';
    }
};
const GET_TYPE_TEXT = (book_type) => {
    switch (book_type) {
        case 0:
            return 'PS';
        case 1:
            return 'UČ';
        case 2:
            return 'ČT';
        default:
            return '';
    }
};
const shortenText = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length - 3) + '...';
};
// AUDIO: 0,
// VIDEO: 1,
// IMAGE: 2,
// EXCERCISE: 3,
const GET_TYPE_FROM_SWITCHER = (switch_type) => {
    switch (switch_type) {
        case 0:
            return 'AUDIO';
        case 1:
            return 'VIDEO';
        case 2:
            return 'IMAGE';
        case 3:
            return 'EXCERCISE';
        default:
            return '';
    }
};
const FILE_TYPE_TO_FILE_EXTENSION = (file_type) => {
    // 'video/mp4', 'video/webm', 'video/ogg', 'video/x-flv'
    // 'audio/mpeg', 'audio/wav', 'audio/ogg'
    //'image/jpeg','image/png','image/gif','image/svg+xml','image/webp','image/tiff','image/bmp',
    switch (file_type) {
        case 'image/jpeg':
            return 'jpg';
        case 'image/png':
            return 'png';
        case 'image/gif':
            return 'gif';
        case 'image/svg+xml':
            return 'svg';
        case 'image/webp':
            return 'webp';
        case 'image/tiff':
            return 'tiff';
        case 'image/bmp':
            return 'bmp';
        case 'video/mp4':
            return 'mp4';
        case 'video/webm':
            return 'webm';
        case 'video/ogg':
            return 'ogg';
        case 'video/x-flv':
            return 'flv';
        case 'audio/mpeg':
            return 'mp3';
        case 'audio/wav':
            return 'wav';
        case 'audio/ogg':
            return 'ogg';
        case 'application/zip':
            return 'zip';
        case 'application/x-zip-compressed':
            return 'zip';

        default:
            return '';
    }
};
const normalize_string_for_search = (string) => {
    return string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};
const extractBookInfo = (book) => {
    return {
        bookid: book.book_id,
        release: book.release,
        version_before_dot: book.version_before_dot,
        version_after_dot: book.version_after_dot,
    };
};
const bookInfoToRequest = (bookInfo) => {
    if (bookInfo === null) return null;
    if (bookInfo.version) return bookInfo;
    return {
        bookid: bookInfo.bookid,
        release: bookInfo.release,
        version: `${bookInfo.version_before_dot}_${bookInfo.version_after_dot}`,
    };
};
const get_book_version = (book) => {
    const formated_string = `${book.book_id} Vyd. ${book.release}. Ver. ${book.version_before_dot}.${book.version_after_dot} ${book.book.name}`;
    return formated_string;
};
const formatFileSize = (bytes) => {
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];
    let i = 0;

    while (bytes >= 1024 && i < units.length - 1) {
        bytes /= 1024;
        i++;
    }

    return `${parseFloat(bytes.toFixed(2))} ${units[i]}`;
};
export {
    GET_STATE,
    GET_STATE_CLASS,
    GET_TYPE_TEXT,
    shortenText,
    GET_TYPE_FROM_SWITCHER,
    FILE_TYPE_TO_FILE_EXTENSION,
    normalize_string_for_search,
    get_book_version,
    formatFileSize,
    extractBookInfo,
    bookInfoToRequest,
};
