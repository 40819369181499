import React, { useState, useEffect } from "react";
import "../../styles/editor/library.css";
import { useParams } from "react-router-dom";
import { PAGE_ENUM, LibrarySwitches } from "./LibrarySwitches";
import AudiosPage from "./AudiosPage";
import VideosPage from "./VideosPage";
import ImagesPage from "./ImagesPage";
import ExcercisesPage from "./ExcercisesPage";
import DeleteIcon from "../../../static/images/delete_white.png";
import {
	extractBookInfo,
	get_book_version,
	GET_TYPE_FROM_SWITCHER,
} from "../../../utils/translator";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import MediaExcercisesService from "../../../services/media_excercises.service";
import { toast } from "react-toastify";
import PopUpConfirm from "../../../components/PopUpConfirm";
import SearchInput from "../../../components/SearchInput";
import InteractiveService from "../../../services/interactive.service";
import { useAssetStore, useLoadingAssets } from "../../../stores/assetStore";
import {
	EDITOR_PAGES,
	useInteractiveBookStore,
} from "../../../stores/interactiveBookStore";
import EditorNavbar from "../EditorComponents/EditorNavbar";

const toastError = {
	autoClose: 2000,
	position: "bottom-right",
	closeOnClick: true,
	draggable: false,
	theme: "colored",
};
const getTextFromSwitcher = (switcher) => {
	switch (switcher) {
		case 0:
			return "é audio soubory";
		case 1:
			return "é video soubory";
		case 2:
			return "é obrázky";
		case 3:
			return "á cvičení";
		default:
			return "";
	}
};
const Library = () => {
	const [activeSection, setActiveSection] = useState(0);
	const { bookid, release, version } = useParams();
	const [book, setBook] = useState(null);
	const [deletingUnused, setDeletingUnused] = useState(false);
	const [reAsk, setReAsk] = useState(false);
	const [search, setSearch] = useState("");
	const privateAxios = useAxiosPrivate();
	const setAssets = useAssetStore((state) => state.setAssets);
	const [, setLoading] = useLoadingAssets();
	const passing_book_object = {
		bookid,
		release,
		version,
	};
	const clearAssets = useAssetStore((state) => state.clearAssets);
	const setCurrentEditorPage = useInteractiveBookStore(
		(state) => state.setCurrentEditorPage
	);
	const setGlobalBook = useInteractiveBookStore((state) => state.setOnlyBook);
	useEffect(() => {
		setCurrentEditorPage(EDITOR_PAGES.LIBRARY);
		const fetchBook = async () => {
			setLoading(true);
			const book_from_api = await InteractiveService.get_interactive(
				bookid,
				release,
				version,
				privateAxios
			);
			if (book_from_api.err) {
				toast.error("Nepodařilo se načíst knihu", toastError);
				return;
			}
			setBook(book_from_api);

			await setAssets(passing_book_object, privateAxios);
			setLoading(false);
			const bookInfo = extractBookInfo(book_from_api);
			setGlobalBook(
				bookInfo.bookid,
				bookInfo.release,
				bookInfo.version_before_dot,
				bookInfo.version_after_dot
			);
		};
		fetchBook();
		return () => {
			clearAssets();
		};
		// eslint-disable-next-line
	}, []);

	const handleDeleteUnused = async () => {
		const delete_type = GET_TYPE_FROM_SWITCHER(activeSection);
		const data = {
			bookid,
			release,
			version,
			media_kind: delete_type,
		};
		const response = await MediaExcercisesService.remove_unused_media(
			data,
			privateAxios
		);
		if (response.err) {
			toast.error(
				"Nepodařilo se odstranit nepoužité soubory",
				toastError
			);
			return;
		}
		toast.success(
			`Celkem bylo odstraněno ${response.result} souborů.`,
			toastError
		);
		setDeletingUnused(false);
		setReAsk(true);
		setTimeout(() => {
			setReAsk(false);
		}, 1000);
	};

	useEffect(() => {
		setSearch("");
	}, [activeSection]);

	return (
		<div className="library-background">
			<EditorNavbar />
			<div className="library-center">
				<LibrarySwitches
					changePageFunc={setActiveSection}
					activeSection={activeSection}
				/>

				{/* AUDIO */}
				{activeSection === PAGE_ENUM.AUDIO && (
					<AudiosPage
						book={passing_book_object}
						reAsk={reAsk}
						search={search}
					/>
				)}

				{/* VIDEO */}
				{activeSection === PAGE_ENUM.VIDEO && (
					<VideosPage
						book={passing_book_object}
						reAsk={reAsk}
						search={search}
					/>
				)}

				{/* PICTURES */}
				{activeSection === PAGE_ENUM.IMAGE && (
					<ImagesPage
						book={passing_book_object}
						reAsk={reAsk}
						search={search}
					/>
				)}

				{/* EXCERCISES */}
				{activeSection === PAGE_ENUM.EXCERCISE && (
					<ExcercisesPage
						book={passing_book_object}
						reAsk={reAsk}
						search={search}
					/>
				)}
				<div className="library-bottom-right-buttons">
					<button
						className="library-right-button delete"
						title="Odstranit nepoužité soubory"
						onClick={() => setDeletingUnused(true)}
					>
						<img src={DeleteIcon} alt="delete" />
					</button>
				</div>
				<div className="library-top-right-buttons">
					<SearchInput
						placeholder="Vyhledat"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				{book && (
					<div className="library-bottom-left-header">
						{get_book_version(book)}
					</div>
				)}
			</div>
			{deletingUnused && (
				<PopUpConfirm
					closeFunction={() => setDeletingUnused(false)}
					confirmFunction={handleDeleteUnused}
					question={`Opravdu chcete odstranit nepoužit${getTextFromSwitcher(
						activeSection
					)}?`}
				/>
			)}
		</div>
	);
};

export default Library;
